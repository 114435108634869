import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { NewsletterCreateFormFragment, MailcoachSegment } from '../../../generated/graphql';
import NewsletterForm from './NewsletterForm';

type NewsletterCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const NewsletterCreateOverlay = ({ onSuccess, ...overlayProps }: NewsletterCreateOverlayProps) => {
  const initialValues: NewsletterCreateFormFragment = {
    subject: '',
    body: '',
    segment: MailcoachSegment.Active,
    events: [],
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <NewsletterForm
        onCancel={closeOverlay}
        onCreated={({ createNewsletter }) => {
          onSuccess(createNewsletter.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default NewsletterCreateOverlay;
