/** @jsx jsx */
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import PaginatedTable from '../../../components/PaginatedTable';
import { useTagListQuery } from '../../../generated/graphql';
import TagCreateOverlay from '../components/TagCreateOverlay';
import TagListItem from '../components/TagListItem';
import { dummyTags } from '../dummy';

const TagListPage = () => {
  const history = useHistory();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const { data, loading, error } = useTagListQuery({
    fetchPolicy: 'network-only',
  });

  const onCreated = (modelId: string) => {
    AppToaster.success('Attribut hinzugefügt!');
    history.push(`/tags/${modelId}`);
  };

  const tags = data?.tags || [];

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.list}>
      <TagCreateOverlay
        isOpen={isCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsCreateDialogOpen(false)}
      />
      <ContentCardHeader
        rightElement={
          <Button
            text="Hinzufügen"
            icon={IconNames.ADD}
            intent={Intent.PRIMARY}
            css={styles.headerButton}
            onClick={() => setIsCreateDialogOpen(true)}
          />
        }
      />
      <PaginatedTable
        columns={['', 'Name', 'Beschreibung']}
        interactive
        loading={loading}
        hasError={!!error}
        empty={!tags.length}
      >
        {(loading ? dummyTags : tags || []).map((tag) => (
          <TagListItem
            key={tag.id}
            item={tag}
            skeleton={loading}
            onClick={() => !loading && history.push(`/tags/${tag.id}`)}
          />
        ))}
      </PaginatedTable>
    </ContentCard>
  );
};

export default TagListPage;

const styles = {
  headerButton: css`
    margin-left: 10px;
  `,
  list: css`
    margin-bottom: 20px;
  `,
};
