import { LocationDescriptorObject, LocationState, Path } from 'history';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

interface Navigate {
  (path: Path, state?: LocationState): void;
  (location: LocationDescriptorObject<LocationState>): void;
}

const isModifiedEvent = (event: React.MouseEvent) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export const useNavigate = (path: Path, state?: LocationState) => {
  const history = useHistory();

  const navigate = (event: React.MouseEvent) => {
    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      history.push(path, state);
    }
  };

  return useCallback(navigate, [history]);
};
