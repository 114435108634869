import { Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { Fragment } from 'react';
import { PersonTagIconFragment } from '../generated/graphql';
import TooltipIcon from './TooltipIcon';
import PersonTagIcon from './PersonTagIcon';
import PopoverIcon from './PopoverIcon';
import Text from './Text';

type PersonIcons = {
  notes?: string;
  tags: PersonTagIconFragment[];
};

type SoulIcons = {
  buys_ticket: boolean;
  needs_blind: boolean;
  needs_wheelchair: boolean;
  needs_wheeled_walker: boolean;
  needs_trainstation_fetch: boolean;
  needs_drive: boolean;
} & PersonIcons;

type CompanionIcons = {
  can_blind: boolean;
  can_wheelchair: boolean;
  can_wheeled_walker: boolean;
  can_drive: boolean;
} & PersonIcons;

type PersonIconListProps = {
  person: SoulIcons | CompanionIcons;
  position?: Position;
};

const isSoul = (person: SoulIcons | CompanionIcons): person is SoulIcons => 'buys_ticket' in person;

const PersonIconList = ({ person, position }: PersonIconListProps) => {
  if (isSoul(person)) {
    return (
      <Fragment>
        {person.notes && (
          <PopoverIcon position={position} marginRight>
            <Text small running>
              {person.notes}
            </Text>
          </PopoverIcon>
        )}
        {person.buys_ticket && (
          <TooltipIcon label="Selbstzahler" icon={IconNames.EURO} position={position} marginRight />
        )}
        {person.needs_wheelchair && (
          <TooltipIcon label="Rollstuhl" icon={IconNames.SHOPPING_CART} position={position} marginRight />
        )}
        {person.needs_wheeled_walker && (
          <TooltipIcon label="Rollator" icon={IconNames.WALK} position={position} marginRight />
        )}
        {person.needs_blind && <TooltipIcon label="Blind" icon={IconNames.EYE_OFF} position={position} marginRight />}
        {person.needs_drive && (
          <TooltipIcon
            label="Muss von Zuhause abgeholt werden"
            icon={IconNames.DRIVE_TIME}
            position={position}
            marginRight
          />
        )}
        {person.needs_trainstation_fetch && (
          <TooltipIcon
            label="Muss vom Bahnhof abgeholt werden"
            icon={IconNames.TRAIN}
            position={position}
            marginRight
          />
        )}
        {person.tags.map((tag) => (
          <PersonTagIcon key={tag.id} tag={tag} position={position} />
        ))}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {person.notes && (
        <PopoverIcon position={position} marginRight>
          <Text small running>
            {person.notes}
          </Text>
        </PopoverIcon>
      )}
      {/* {person.can_wheelchair && <TooltipIcon label="Kann Rollstuhl schieben" icon={IconNames.SHOPPING_CART} position={position} marginRight />}
      {person.can_wheeled_walker && (
        <TooltipIcon label="Kann Genießer mit Rollator begleiten" icon={IconNames.WALK} position={position} marginRight />
      )}
      {person.can_blind && <TooltipIcon label="Kann Blinde begleiten" icon={IconNames.EYE_OFF} position={position} marginRight />}
      {person.can_drive && (
        <TooltipIcon label="Kann Genießer von Zuhause abholen" icon={IconNames.DRIVE_TIME} position={position} marginRight />
      )} */}
      {person.tags.map((tag) => (
        <PersonTagIcon key={tag.id} tag={tag} position={position} />
      ))}
    </Fragment>
  );
};

export default PersonIconList;
