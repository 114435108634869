/** @jsx jsx */
import { gql } from '@apollo/client';
import { Elevation, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EmailLink from '../../../components/EmailLink';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import ResourceLink from '../../../components/ResourceLink';
import Text from '../../../components/Text';
import TrashTag from '../../../components/TrashTag';
import { useEmployeeDetailQuery } from '../../../generated/graphql';
import EmployeeDetailMenu from '../components/EmployeeDetailMenu';
import EmployeeEditOverlay from '../components/EmployeeEditOverlay';

const EmployeeDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { employeeId } = useParams();

  const options = {
    variables: {
      id: employeeId || '',
    },
  };

  const { data, loading, error } = useEmployeeDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.employee) return <NotFoundCard resource="Mitarbeiter" />;

  const { employee } = data;
  const { user } = employee;

  const address: string | null =
    user.street && user.postal_code && user.city ? `${user.street}, ${user.postal_code} ${user.city}` : null;

  return (
    <Fragment>
      <EmployeeEditOverlay id={employee.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{user.display_name}</span>
              <Tag minimal css={styles.personTag}>
                Mitarbeiter
              </Tag>
              {!!employee.deleted_at && <TrashTag />}
            </Fragment>
          }
          rightElement={<EmployeeDetailMenu employee={employee} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Person</PropertyListHeader>
            <PropertyListItem label="Email">
              <EmailLink email={user.email} />
            </PropertyListItem>

            <PropertyListItem label="Telefon" value={user.phone || '—'} showValueDisabled={!user.phone} />
            <PropertyListItem label="Mobil" value={user.mobile || '—'} showValueDisabled={!user.mobile} />
            <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
          </PropertyList>
          <PropertyListDivider />
          <PropertyList>
            <PropertyListHeader>Mitarbeiter Info</PropertyListHeader>
            <PropertyListItem label="Position" value={employee.position} />
            <PropertyListItem label="Rollen">
              {!!user.roles.length ? (
                user.roles.map((role) => <ResourceLink key={role.id} path={`/roles/${role.id}`} text={role.name} />)
              ) : (
                <Text disabled>—</Text>
              )}
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
    </Fragment>
  );
};

EmployeeDetailPage.fragments = {
  employee: gql`
    fragment EmployeeDetailPage on Employee {
      id
      position
      deleted_at
      user {
        id
        display_name
        first_name
        last_name
        email
        phone
        mobile
        street
        city
        postal_code
        roles {
          id
          name
        }
      }
    }
  `,
};

export default EmployeeDetailPage;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  overlayContent: css`
    left: calc(50vw - 225px);
    margin: 10vh 0;
    width: 450px;
    max-height: 80vh;
  `,
};
