import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useCompanionEditLazyQuery, CompanionEditFormFragment } from '../../../generated/graphql';
import CompanionForm from './CompanionForm';
import { formatDay } from '../../../helpers/dateTimeUtils';

interface ICompanionEditOverlayProps extends IOverlayProps {
  id: string;
}

const CompanionEditOverlay = ({ id, ...overlayProps }: ICompanionEditOverlayProps) => {
  const [loadCompanion, { data, called, loading, error }] = useCompanionEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadCompanion();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data || !data.companion) return <ErrorCard />;

    const initialValues: CompanionEditFormFragment = {
      ...data.companion,
      user: {
        ...data.companion.user,
        ...(data.companion.user.birthdate && { birthdate: formatDay(data.companion.user.birthdate) }),
      },
    };

    return (
      <CompanionForm
        onCancel={() => overlayProps.onClose?.()}
        onUpdated={() => {
          loadCompanion();
          overlayProps.onClose?.();
        }}
        initialValues={initialValues}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default CompanionEditOverlay;
