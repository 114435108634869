import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import client from '../client';
import EmailPage from '../modules/auth/pages/EmailPage';
import LoginPage from '../modules/auth/pages/LoginPage';
import ResetPage from '../modules/auth/pages/ResetPage';
import MainPage from '../pages/MainPage';
import NotFoundPage from '../pages/NotFoundPage';
import PrivateRoute from './PrivateRoute';
import config from '../helpers/config';

const App = () => (
  <ApolloProvider client={client}>
    <Router>
      <Switch>
        <Route path="/not-found" exact component={NotFoundPage} />
        <Route path="/auth/login" exact component={LoginPage} />
        <Route path="/auth/email" exact component={EmailPage} />
        <Route path="/auth/reset" exact component={ResetPage} />
        {/* Redirecting legacy signup accept links: */}
        <Route
          path="/accept/:token"
          exact
          render={({ match }) => {
            window.location.replace(`${config.baseUrl}/accept/${match.params.token}`);
            return null;
          }}
        />
        <Route path="/" exact>
          <Redirect to="/events" />
        </Route>
        <PrivateRoute path="/" component={MainPage} />
      </Switch>
    </Router>
  </ApolloProvider>
);

export default App;
