/** @jsx jsx */
import { Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import NewsletterList from '../components/NewsletterList';
import NewsletterListFilter from '../components/NewsletterListFilter';

const NewsletterArchiveListPage = () => (
  <ContentCard elevation={Elevation.TWO} css={styles.list}>
    <ContentCardHeader leftElement={<NewsletterListFilter archive />} />
    <NewsletterList />
  </ContentCard>
);

export default NewsletterArchiveListPage;

const styles = {
  list: css`
    margin-bottom: 20px;
  `,
};
