import React from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import InfiniteScrollTable from '../../../components/InfiniteScrollTable';
import {
  AttendListItemFragment,
  CompanionAttendsDocument,
  CompanionAttendsQuery,
  CompanionAttendsQueryVariables,
  SoulAttendsDocument,
  SoulAttendsQuery,
  SoulAttendsQueryVariables,
} from '../../../generated/graphql';
import { dummyAttends } from '../dummy';
import AttendListItem from '../../../components/AttendListItem';

type AttendListProps = {
  isSoul: boolean;
  prospectId: string;
};

type QueryDataType = SoulAttendsQuery | CompanionAttendsQuery;
type QueryVariablesType = SoulAttendsQueryVariables | CompanionAttendsQueryVariables;

const AttendList = ({ isSoul, prospectId }: AttendListProps) => {
  const query = isSoul ? SoulAttendsDocument : CompanionAttendsDocument;
  const { data, loading, error, networkStatus, fetchMore } = useQuery<QueryDataType, QueryVariablesType>(query, {
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: prospectId,
      // infinite flag is a hack to make the cache's "FielPolicy" read function
      // return all items
      // @ts-ignore
      infinite: true,
    },
  });

  const { data: attends, paginatorInfo } = (isSoul ? data?.soul?.attends : data?.companion?.attends) || {};
  const { currentPage, hasMorePages } = paginatorInfo || {};
  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <InfiniteScrollTable
      columns={[
        { heading: 'Veranstaltung' },
        { heading: isSoul ? 'Begleitung' : 'Genießer' },
        { heading: 'Zeitpunkt' },
      ]}
      loading={loading}
      loadingMore={loadingMore}
      showLoadingMore={false}
      empty={!attends?.length}
      hasMorePages={!!hasMorePages}
      hasError={!!error}
      onLoadMore={() =>
        fetchMore({
          variables: {
            page: (currentPage || 1) + 1,
          },
        })
      }
    >
      {(loading && !loadingMore ? dummyAttends : attends || []).map((item: AttendListItemFragment) => (
        <AttendListItem key={item.id} attend={item} skeleton={loading && !loadingMore} />
      ))}
      {hasMorePages && dummyAttends.map((attend) => <AttendListItem key={attend.id} attend={attend} skeleton />)}
    </InfiniteScrollTable>
  );
};

export default AttendList;
