/** @jsx jsx */
import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { NewsletterDetailPageFragment } from '../../../generated/graphql';
import config from '../../../helpers/config';
import { openTab } from '../../../helpers/utils';

type NewsletterDetailMenuProps = {
  newsletter: NewsletterDetailPageFragment;
};

const NewsletterDetailMenu = ({ newsletter }: NewsletterDetailMenuProps) => (
  <Fragment>
    <Popover
      position={Position.BOTTOM}
      content={
        <Menu>
          <MenuItem
            text="PDF Veranstaltungsliste"
            icon={IconNames.DOWNLOAD}
            onClick={() => openTab(`${config.baseUrl}/downloads/newsletters/${newsletter.id}`)}
          />
        </Menu>
      }
    >
      <Button icon={IconNames.MORE} css={styles.headerButton} />
    </Popover>
  </Fragment>
);

export default NewsletterDetailMenu;

const styles = {
  headerButton: css`
    margin-left: 10px;
  `,
};
