/** @jsx jsx */
import { Alert, Button, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import AppToaster from '../../../AppToaster';
import {
  ContactInfoFragment,
  useDeleteContactMutation,
  useForceDeleteContactMutation,
  useRestoreContactMutation,
} from '../../../generated/graphql';

type ContactDetailMenuProps = {
  contact: ContactInfoFragment;
  onEdit: () => void;
};

const ContactDetailMenu = ({ contact, onEdit }: ContactDetailMenuProps) => {
  const { id } = contact;
  const options = {
    variables: {
      id,
    },
  };
  const [isForceDeleteAlertOpen, setIsForceDeleteAlertOpen] = useState(false);

  const [deleteContact, { loading: deleting }] = useDeleteContactMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${contact.user.display_name}  wurde in den Papierkorb gelegt`),
  });
  const [restoreContact, { loading: restoring }] = useRestoreContactMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${contact.user.display_name}  wurde wiederhergestellt`),
  });
  const [forceDeleteContact, { loading: forceDeleting }] = useForceDeleteContactMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${contact.user.display_name}  wurde endgültig entfernt`),
  });

  if (!!contact.deleted_at) {
    return (
      <Fragment>
        <Alert
          isOpen={isForceDeleteAlertOpen}
          cancelButtonText="Abbrechen"
          confirmButtonText="Entfernen"
          icon={IconNames.DELETE}
          intent={Intent.DANGER}
          onClose={() => setIsForceDeleteAlertOpen(false)}
          onConfirm={forceDeleteContact as any}
        >
          <p>Soll dieser Ansprechpartner wirklich undwiderruflich entfernt werden?</p>
        </Alert>
        <Button
          text="Wiederherstellen"
          icon={IconNames.OFFLINE}
          css={styles.headerButton}
          onClick={restoreContact as any}
          loading={restoring}
        />
        <Popover
          position={Position.BOTTOM}
          content={
            <Menu>
              <MenuItem text="Bearbeiten" icon={IconNames.EDIT} onClick={onEdit} />
              <MenuItem
                text="Entfernen"
                icon={IconNames.DELETE}
                intent={Intent.DANGER}
                onClick={() => setIsForceDeleteAlertOpen(true)}
              />
            </Menu>
          }
        >
          <Button icon={IconNames.MORE} css={styles.headerButton} loading={forceDeleting} />
        </Popover>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Button text="Bearbeiten" icon={IconNames.EDIT} css={styles.headerButton} onClick={onEdit} />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteContact as any} />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} css={styles.headerButton} loading={deleting} />
      </Popover>
    </Fragment>
  );
};

export default ContactDetailMenu;

const styles = {
  statusTag: css`
    margin-left: 10px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
};
