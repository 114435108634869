/** @jsx jsx */
import { Button, IOverlayProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { gql } from '@apollo/client';
import { ContentCard, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import ContentOverlay, { OverlaySize } from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import RawHTML from '../../../components/RawHTML';
import Text from '../../../components/Text';
import { useEmailDetailsLazyQuery } from '../../../generated/graphql';
import { withoutBubbling } from '../../../helpers/utils';

type EmailOverlayProps = {
  id: string;
} & IOverlayProps;

const EmailOverlay = ({ id, ...overlayProps }: EmailOverlayProps) => {
  const [loadEmail, { data, called, loading, error }] = useEmailDetailsLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadEmail();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data?.email) return <ErrorCard />;

    return (
      <ContentCard>
        <ContentCardHeader
          leftElement={<span css={styles.heading}>{data.email.subject}</span>}
          rightElement={
            <Button onClick={withoutBubbling(() => overlayProps.onClose?.())} icon={IconNames.CROSS} minimal />
          }
        />
        <ContentCardScroll noFooter noPadding>
          {!!data.email.body ? <RawHTML>{data.email.body}</RawHTML> : <Text muted>Inhalt nicht verfügbar</Text>}
        </ContentCardScroll>
      </ContentCard>
    );
  };

  return (
    <ContentOverlay size={OverlaySize.Large} {...overlayProps}>
      {renderContent()}
    </ContentOverlay>
  );
};

EmailOverlay.fragments = {
  email: gql`
    fragment EmailOverlay on Email {
      id
      status
      subject
      sent_at
      body
    }
  `,
};

export default EmailOverlay;

const styles = {
  heading: css`
    font-size: 16px;
  `,
};
