import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import ErrorCard from './ErrorCard';

type NotFoundCardProps = {
  resource: string;
};

const NotFoundCard = ({ resource }: NotFoundCardProps) => (
  <ErrorCard icon={IconNames.INBOX_SEARCH} title="404" description={`${resource} konnte nicht gefunden werden!`} />
);

export default NotFoundCard;
