/** @jsx jsx */
import { gql } from '@apollo/client';
import { Classes, Elevation, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EmailLink from '../../../components/EmailLink';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import UserStatusTag from '../../../components/PersonStatusTag';
import PersonTagIcon from '../../../components/PersonTagIcon';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import TooltipTag from '../../../components/TooltipTag';
import { Intent, useCompanionDetailQuery } from '../../../generated/graphql';
import { getPersisted } from '../../../helpers/constants';
import { getAgeInYears } from '../../../helpers/dateTimeUtils';
import CompanionDetailMenu from './CompanionDetailMenu';

type CompanionInfoProps = {
  onEdit: () => void;
};

const CompanionInfo = ({ onEdit }: CompanionInfoProps) => {
  const { companionId } = useParams();
  const { data, loading, error } = useCompanionDetailQuery({
    variables: {
      id: companionId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.companion) return <NotFoundCard resource="Begleiter" />;

  const { companion } = data;
  const { user } = companion;

  const address: string | null =
    user.street && user.postal_code && user.city ? `${user.street}, ${user.postal_code} ${user.city}` : null;

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
      <ContentCardHeader
        leftElement={
          <Fragment>
            <span css={styles.heading}>{user.display_name}</span>
            <Tag minimal css={styles.personTag}>
              Begleiter
            </Tag>
            <UserStatusTag status={companion.user.status} deleted={!!companion.deleted_at} />
          </Fragment>
        }
        rightElement={<CompanionDetailMenu companion={companion} onEdit={onEdit} />}
      />
      <div css={styles.content}>
        <PropertyList>
          <PropertyListHeader>Person</PropertyListHeader>
          <PropertyListItem
            label="Alter"
            value={user.birthdate ? getAgeInYears(user.birthdate).toString() : '—'}
            showValueDisabled={!user.birthdate}
          />
          <PropertyListItem label="Email">
            <EmailLink email={user.email} />
            {!getPersisted('useLegacyNewsletter') && !user.is_newsletter_subscriber && (
              <TooltipTag
                tooltip="Dieser Begleiter ist nicht für den Newsletter angemeldet"
                label="kein newsletter"
                minimal
                intent={Intent.Warning}
                marginLeft
              />
            )}
          </PropertyListItem>

          <PropertyListItem label="Telefon" value={user.phone || '—'} showValueDisabled={!user.phone} />
          <PropertyListItem label="Mobil" value={user.mobile || '—'} showValueDisabled={!user.mobile} />
          <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
        </PropertyList>
        <PropertyListDivider />
        <PropertyList>
          <PropertyListHeader>Begleiter Info</PropertyListHeader>
          <PropertyListItem label="Besonderheiten">
            {companion.can_wheelchair && (
              <TooltipIcon label="Kann Rollstuhl schieben" icon={IconNames.SHOPPING_CART} marginRight />
            )}
            {companion.can_wheeled_walker && (
              <TooltipIcon label="Kann Genießer mit Rollator begleiten" icon={IconNames.WALK} marginRight />
            )}
            {companion.can_blind && <TooltipIcon label="Kann Blinde begleiten" icon={IconNames.EYE_OFF} />}
            {companion.can_drive && (
              <TooltipIcon label="Kann Genießer von Zuhause abholen" icon={IconNames.DRIVE_TIME} marginRight />
            )}
          </PropertyListItem>
          <PropertyListItem label="Attribute">
            {!!companion.tags.length ? (
              companion.tags.map((tag: any) => <PersonTagIcon key={tag.id} tag={tag} />)
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
          <PropertyListItem
            label="Organisation"
            value={companion.organisation ? companion.organisation.name : '—'}
            showValueDisabled={!companion.organisation}
          />
          <PropertyListItem label="Interne Notizen">
            {companion.notes ? (
              <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{companion.notes}</div>
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
        </PropertyList>
      </div>
    </ContentCard>
  );
};

CompanionInfo.fragments = {
  companion: gql`
    fragment CompanionInfo on Companion {
      id
      notes
      can_wheelchair
      can_wheeled_walker
      can_drive
      can_blind
      deleted_at
      user {
        id
        status
        display_name
        first_name
        last_name
        birthdate
        email
        gdpr_consent
        is_newsletter_subscriber
        phone
        mobile
        street
        city
        postal_code
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
};

export default CompanionInfo;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
