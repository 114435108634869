/** @jsx jsx */
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';

type SettingsDetailMenuProps = {
  onEdit: () => void;
};

const SettingsDetailMenu = ({ onEdit }: SettingsDetailMenuProps) => (
  <Fragment>
    <Button text="Bearbeiten" icon={IconNames.EDIT} css={styles.headerButton} onClick={onEdit} />
  </Fragment>
);

export default SettingsDetailMenu;

const styles = {
  headerButton: css`
    margin-left: 10px;
  `,
};
