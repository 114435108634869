/** @jsx jsx */
import { Button, Callout, Card, Intent, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useState, Fragment } from 'react';
import * as Yup from 'yup';
import FormGroup from '../../../components/FormGroup';
import ResourceLink from '../../../components/ResourceLink';
import TextInput from '../../../components/TextInput';
import { AppEnv, ENV } from '../../../helpers/constants';
import { getSubdomain } from '../../../helpers/utils';
import { useAuthInfoQuery } from '../../../generated/graphql';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import Text from '../../../components/Text';

interface ILockButton {
  locked: boolean;
  disabled?: boolean;
  onClick: any;
}

const LockButton = ({ locked, disabled, onClick }: ILockButton) => (
  <Tooltip content={`Passwort ${locked ? 'zeigen' : 'verstecken'}`} disabled={disabled}>
    <Button
      disabled={disabled}
      icon={locked ? 'unlock' : 'lock'}
      intent={Intent.WARNING}
      minimal={true}
      onClick={onClick}
    />
  </Tooltip>
);

export interface ILoginFormValues {
  email: string;
  password: string;
  branchId: string;
}

interface ILoginForm {
  onSubmit: (values: ILoginFormValues, formikHelpers: FormikHelpers<ILoginFormValues>) => void;
}

const LoginSchema = Yup.object({
  email: Yup.string().email('Keine gültige Email').required('Erforderlich'),
  password: Yup.string().min(2, 'Zu Kurz').max(50, 'Zu Lang').required('Erforderlich'),
});

const LoginForm = ({ onSubmit }: ILoginForm) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const { data, loading, error } = useAuthInfoQuery({
    variables: {
      branchSlug: getSubdomain(),
    },
  });
  const togglePasswordShown = useCallback(() => setPasswordShown(!passwordShown), [passwordShown]);

  if (loading) return <LoadingCard />;
  if (error || !data?.authInfo) return <ErrorCard />;

  const initialLoginFormValues: ILoginFormValues = {
    email: ENV === AppEnv.Dev ? 'admin@hamburg.kulturschluessel.org' : '',
    password: ENV === AppEnv.Dev ? 'testtest' : '',
    branchId: data.authInfo.id,
  };

  return (
    <Formik initialValues={initialLoginFormValues} validationSchema={LoginSchema} onSubmit={onSubmit}>
      {({ isSubmitting, status }) => (
        <Fragment>
          <div css={styles.branchTitle}>
            <Text large>{data.authInfo?.title}</Text>
          </div>
          <Card>
            {status && status.error && (
              <Callout intent={Intent.DANGER} css={styles.callout}>
                {status.error}
              </Callout>
            )}
            <Form>
              <FormGroup label="Email" labelFor="email" name="email">
                <TextInput name="email" type="email" placeholder="Email" />
              </FormGroup>
              <FormGroup label="Passwort" labelFor="password" name="password">
                <TextInput
                  name="password"
                  type={passwordShown ? 'text' : 'password'}
                  rightElement={
                    <LockButton locked={!passwordShown} disabled={isSubmitting} onClick={togglePasswordShown} />
                  }
                />
              </FormGroup>
              <Field name="branchId" type="hidden" />
              <Button
                type="submit"
                intent={Intent.SUCCESS}
                rightIcon={IconNames.LOG_IN}
                text="Login"
                loading={isSubmitting}
                fill
              />
              <div css={styles.forgotPasswordLink}>
                <ResourceLink path="/auth/email" text="Passwort vergessen?" last muted />
              </div>
            </Form>
          </Card>
        </Fragment>
      )}
    </Formik>
  );
};

export default LoginForm;

const styles = {
  branchTitle: css`
    margin-bottom: 20px;
  `,
  callout: css`
    margin-bottom: 15px;
  `,
  forgotPasswordLink: css`
    padding-top: 15px;
    float: right;
  `,
};
