/** @jsx jsx */
import { NonIdealState } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';

export interface NoResultsProps {
  icon?: IconName;
  title?: string;
  description?: string;
  action?: JSX.Element;
}

const NoResults = (props: NoResultsProps) => (
  <div css={styles.error}>
    <NonIdealState {...props} />
  </div>
);

NoResults.defaultProps =
  {
    title: 'Keine Resultate',
    icon: IconNames.INBOX_SEARCH,
    description: 'Für die gesetzten Filter.',
  } as Partial<NoResultsProps>;

export default NoResults;

const styles = {
  error: css`
    text-align: center;
    padding: 40px 0;
  `,
};
