/** @jsx jsx */
import { NonIdealState } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';

export interface ErrorProps {
  icon?: IconName;
  title?: string;
  description?: string;
  action?: JSX.Element;
}

const Error = (props: ErrorProps) => (
  <div css={styles.error}>
    <NonIdealState {...props} />
  </div>
);

Error.defaultProps =
  {
    title: 'Fehler',
    icon: IconNames.WARNING_SIGN,
    description: 'Ein unbekannter Fehler ist aufgetreten.',
  } as Partial<ErrorProps>;

export default Error;

const styles = {
  error: css`
    text-align: center;
    padding: 40px 0;
  `,
};
