/** @jsx jsx */
import { Elevation, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { gql } from '@apollo/client';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { PropertyList, PropertyListHeader, PropertyListItem } from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useRoleDetailQuery } from '../../../generated/graphql';
import RoleDetailMenu from '../components/RoleDetailMenu';
import RoleEditOverlay from '../components/RoleEditOverlay';

const RoleDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { roleId } = useParams();
  const { data, loading, error } = useRoleDetailQuery({
    variables: {
      id: roleId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.role) return <NotFoundCard resource="Lokation" />;

  const { role } = data;

  return (
    <Fragment>
      <RoleEditOverlay id={role.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{role.name}</span>
              <Tag minimal css={styles.personTag}>
                Rolle
              </Tag>
            </Fragment>
          }
          rightElement={<RoleDetailMenu role={role} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Details</PropertyListHeader>
            <PropertyListItem label="Berechtigungen">
              {role.permissions.map((permission) => (
                <Text key={permission.id}>{`${permission.title}, `}</Text>
              ))}
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
    </Fragment>
  );
};

RoleDetailPage.fragments = {
  role: gql`
    fragment RoleDetailPage on Role {
      id
      name
      permissions {
        id
        title
      }
    }
  `,
};

export default RoleDetailPage;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  table: css`
    width: 100%;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
