import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useSoulEditLazyQuery, SoulEditFormFragment } from '../../../generated/graphql';
import SoulForm from './SoulForm';
import { formatDay } from '../../../helpers/dateTimeUtils';

interface ISoulEditOverlayProps extends IOverlayProps {
  id: string;
}

const SoulEditOverlay = ({ id, ...overlayProps }: ISoulEditOverlayProps) => {
  const [loadSoul, { data, called, loading, error }] = useSoulEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadSoul();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data?.soul) return <ErrorCard />;

    const initialValues: SoulEditFormFragment = {
      ...data.soul,
      user: {
        ...data.soul.user,
        ...(data.soul.user.birthdate && { birthdate: formatDay(data.soul.user.birthdate) }),
      },
    };

    return (
      <SoulForm
        onCancel={() => overlayProps.onClose?.()}
        onUpdated={() => {
          loadSoul();
          overlayProps.onClose?.();
        }}
        initialValues={initialValues}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default SoulEditOverlay;
