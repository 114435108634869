/** @jsx jsx */
import { Classes, Colors, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { needsDarkText } from '../helpers/utils';

type CategorySkeleton = {
  name: string;
  color: string;
};

type CategoryTagProps = {
  category: CategorySkeleton;
  skeleton?: boolean;
};

const CategoryTag = ({ category, skeleton }: CategoryTagProps) => (
  <Tag
    className={skeleton ? Classes.SKELETON : ''}
    css={styles.tag}
    style={{
      backgroundColor: category.color,
      color: needsDarkText(category.color) ? Colors.DARK_GRAY1 : Colors.WHITE,
    }}
  >
    {category.name}
  </Tag>
);

export default CategoryTag;

const styles = {
  tag: css`
    margin: 0 5px;
  `,
};
