/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useApolloClient, useQuery, gql } from '@apollo/client';
import { useSubmit } from 'formik-apollo';
import { Redirect, useLocation } from 'react-router-dom';
import { useLoginMutation } from '../../../generated/graphql';
import { setPersisted } from '../../../helpers/constants';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import LoginForm, { ILoginFormValues } from '../components/LoginForm';
import { IS_AUTHENTICATED } from '../queries';

const defaultPage = { pathname: '/' };

const LoginPage = () => {
  const client = useApolloClient();
  const { state } = useLocation<{ from?: string }>();
  const { data } = useQuery(IS_AUTHENTICATED);
  const [login] = useLoginMutation();

  const handleSubmit = useSubmit({
    mutate: (values: ILoginFormValues) =>
      login({
        variables: {
          input: values,
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.login) {
        setPersisted('isLoggedIn', true);
        setPersisted('branch_id', data.login.user.branch.id);
        setPersisted('useLegacyNewsletter', data.login.user.branch.use_legacy_newsletter);
        setPersisted('is_superadmin', data.login.user.is_superadmin);
        setPersisted(
          'permissions',
          data.login.user.permissions.map((permission) => permission.name),
        );
        client.writeQuery({
          query: gql`
            query {
              isAuthenticated
            }
          `,
          data: {
            isAuthenticated: true,
          },
        });
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  if (!data) return null;
  if (data.isAuthenticated) return <Redirect to={(state && state.from) || defaultPage} />;
  return (
    <div css={styles.container}>
      <LoginForm onSubmit={handleSubmit} />
    </div>
  );
};

export default LoginPage;

const styles = {
  container: css`
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
};
