/** @jsx jsx */
import { Colors, Classes } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

type TableCellProps = {
  small?: boolean;
  muted?: boolean;
  center?: boolean;
  right?: boolean;
  children: React.ReactNode;
};

const TableCell = ({ children, small, muted, center, right }: TableCellProps) => {
  const classes = [];

  if (small) {
    classes.push(Classes.TEXT_SMALL);
  }

  if (muted) {
    classes.push(Classes.TEXT_MUTED);
  }

  if (muted) {
    classes.push(Classes.TEXT_MUTED);
  }

  return (
    <td css={[styles.tableCell, center && styles.center, right && styles.right]} className={classes.join(' ')}>
      {children}
    </td>
  );
};

export default TableCell;

const styles = {
  tableCell: css`
    vertical-align: middle !important;
    border-top: 1px ${Colors.LIGHT_GRAY1} solid;
    height: 55px;
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
    tr:first-of-type & {
      border-top: none;
    }
    tr.active & {
      background-color: ${Colors.LIGHT_GRAY5} !important;
    }
    tr:hover & {
      background-color: ${Colors.LIGHT_GRAY5} !important;
    }
  `,
  center: css`
    text-align: center !important;
  `,
  right: css`
    text-align: right !important;
  `,
};
