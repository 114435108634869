import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useTagEditLazyQuery } from '../../../generated/graphql';
import TagForm from './TagForm';

interface ITagEditOverlayProps extends IOverlayProps {
  id: string;
}

const TagEditOverlay = ({ id, ...overlayProps }: ITagEditOverlayProps) => {
  const [loadTag, { data, called, loading, error: loadError }] = useTagEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadTag();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (loadError || !data?.tag) return <ErrorCard />;
    return (
      <TagForm
        onCancel={() => overlayProps.onClose?.()}
        onUpdated={() => {
          loadTag();
          overlayProps.onClose?.();
        }}
        initialValues={data.tag}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default TagEditOverlay;
