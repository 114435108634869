import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { CategoryCreateFormFragment } from '../../../generated/graphql';
import CategoryForm from './CategoryForm';

type CategoryCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const CategoryCreateOverlay = ({ onSuccess, ...overlayProps }: CategoryCreateOverlayProps) => {
  const initialValues: CategoryCreateFormFragment = {
    name: '',
    color: '#000000',
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <CategoryForm
        onCancel={closeOverlay}
        onCreated={({ createCategory }) => {
          onSuccess(createCategory.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default CategoryCreateOverlay;
