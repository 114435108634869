/** @jsx jsx */
import { Intent, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { UserStatus } from '../generated/graphql';
import TrashTag from './TrashTag';

type PersonStatusTagProps = {
  status: UserStatus;
  deleted: boolean;
};

const PersonStatusTag = ({ status, deleted }: PersonStatusTagProps) => {
  if (deleted) {
    return <TrashTag />;
  }

  switch (status) {
    case UserStatus.New:
      return (
        <Tag css={styles.statusTag} intent={Intent.PRIMARY}>
          Neu
        </Tag>
      );
    case UserStatus.Passive:
      return (
        <Tag css={styles.statusTag} intent={Intent.WARNING}>
          Passiv
        </Tag>
      );
    default:
      return null;
  }
};

export default PersonStatusTag;

const styles = {
  statusTag: css`
    margin-left: 5px;
  `,
};
