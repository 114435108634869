import { Classes } from '@blueprintjs/core';
import { format, parseISO, subMinutes } from 'date-fns';
import React, { Fragment } from 'react';
import Text from '../../../components/Text';
import { EventDetailPageFragment, EventTicketRetrievalType } from '../../../generated/graphql';

type TicketInstructionsProps = {
  event: EventDetailPageFragment;
};

const TicketInstructions = ({ event }: TicketInstructionsProps) => {
  const cardCount = event.candidates_chosen ? `${event.candidates.length} Karten ` : 'Die Karten ';

  if (event.ticket_retrieval_type === EventTicketRetrievalType.Delivery) {
    return (
      <div className={Classes.RUNNING_TEXT}>
        {cardCount}
        <Text muted>werden dem Kartenverteiler </Text>
        per Post zugestellt
      </div>
    );
  }

  const ticketRetrievalTime = format(subMinutes(parseISO(event.start), event.ticket_time + 10), 'HH:mm');

  return (
    <div className={Classes.RUNNING_TEXT}>
      {cardCount}
      <Text muted>um</Text>
      {` ${ticketRetrievalTime} Uhr`} <br />
      {!!event.reservation_number && (
        <Fragment>
          <Text muted>unter der Res-Nummer: </Text>
          {event.reservation_number}
          <br />
        </Fragment>
      )}
      {event.ticket_retrieval_location}
      <Text muted> abholen und</Text> <br />
      {event.location.meeting_point}
      <Text muted> verteilen</Text>
    </div>
  );
};

export default TicketInstructions;
