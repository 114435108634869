import React, { Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import ContactCreateOverlay from '../../contacts/components/ContactCreateOverlay';
import DonorContacts from '../components/DonorContacts';
import DonorEditOverlay from '../components/DonorEditOverlay';
import DonorInfo from '../components/DonorInfo';
import DonorLocations from '../components/DonorLocations';

const DonorDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isContactCreateDialogOpen, setIsContactCreateDialogOpen] = useState(false);
  const history = useHistory();
  const { donorId } = useParams();

  const onCreated = (modelId: string) => {
    AppToaster.success('Ansprechpartner hinzugefügt!');
    history.push(`/contacts/${modelId}`);
  };

  return (
    <Fragment>
      <ContactCreateOverlay
        donorId={donorId || ''}
        isOpen={isContactCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsContactCreateDialogOpen(false)}
      />
      <DonorEditOverlay id={donorId || ''} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <DonorInfo onEdit={() => setIsEditDialogOpen(true)} />
      <DonorContacts onCreate={() => setIsContactCreateDialogOpen(true)} />
      <DonorLocations />
    </Fragment>
  );
};

export default DonorDetailPage;
