/** @jsx jsx */
import { Button, Callout, Card, Intent } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../../components/FormGroup';
import ResourceLink from '../../../components/ResourceLink';
import TextInput from '../../../components/TextInput';
import { ForgotPasswordInput, useAuthInfoQuery } from '../../../generated/graphql';
import { getSubdomain } from '../../../helpers/utils';
import LoadingCard from '../../../components/LoadingCard';
import ErrorCard from '../../../components/ErrorCard';
import { Fragment } from 'react';
import Text from '../../../components/Text';

type EmailFormProps = {
  onSubmit: (values: ForgotPasswordInput, formikHelpers: FormikHelpers<ForgotPasswordInput>) => void;
};

const ResetSchema = Yup.object({
  email: Yup.string().email('Keine gültige Email').required('Erforderlich'),
});

const EmailForm = ({ onSubmit }: EmailFormProps) => {
  const { data, loading, error } = useAuthInfoQuery({
    variables: {
      branchSlug: getSubdomain(),
    },
  });

  if (loading) return <LoadingCard />;
  if (error || !data?.authInfo) return <ErrorCard />;

  const initialValues: ForgotPasswordInput = {
    email: '',
    branchId: data.authInfo.id,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={ResetSchema} onSubmit={onSubmit}>
      {({ isSubmitting, status }) => (
        <Fragment>
          <div css={styles.branchTitle}>
            <Text large>{data.authInfo?.title}</Text>
          </div>
          <Card>
            {status && status.error && (
              <Callout intent={Intent.DANGER} css={styles.callout}>
                {status.error}
              </Callout>
            )}
            <Form>
              <FormGroup label="Email" labelFor="email" name="email">
                <TextInput name="email" type="email" placeholder="Email" />
              </FormGroup>
              <Field name="branchId" type="hidden" />
              <Button type="submit" intent={Intent.SUCCESS} text="Passwort zurücksetzen" loading={isSubmitting} fill />
              <div css={styles.forgotPasswordLink}>
                <ResourceLink path="/auth/login" text="Zurück zum Login" last muted />
              </div>
            </Form>
          </Card>
        </Fragment>
      )}
    </Formik>
  );
};

export default EmailForm;

const styles = {
  branchTitle: css`
    margin-bottom: 20px;
  `,
  callout: css`
    margin-bottom: 15px;
  `,
  forgotPasswordLink: css`
    padding-top: 15px;
    float: right;
  `,
};
