import React from 'react';
import { NetworkStatus } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import InfiniteScrollTable from '../../../components/InfiniteScrollTable';
import { UserStatus, useSoulListQuery } from '../../../generated/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { dummySouls } from '../dummy';
import SoulListItem from './SoulListItem';

const SoulList = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const status = (queryParams.get('status') as UserStatus) || UserStatus.Active;
  const filters = queryParams.get('filters');

  const { data, loading, error, fetchMore, networkStatus } = useSoulListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      status,
      // infinite flag is a hack to make the cache's "FielPolicy" read function
      // return all items
      // @ts-ignore
      infinite: true,
    },
  });

  const { data: souls, paginatorInfo } = data?.souls || {};
  const { currentPage, hasMorePages } = paginatorInfo || {};
  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <InfiniteScrollTable
      interactive
      columns={[{ heading: 'Name' }, { heading: 'Besonderheiten' }, { heading: 'Telefon' }, { heading: 'Email' }]}
      loading={loading}
      loadingMore={loadingMore}
      hasFilters={!!filters}
      empty={!souls?.length}
      hasMorePages={!!hasMorePages}
      hasError={!!error}
      onLoadMore={() =>
        fetchMore({
          variables: {
            page: (currentPage || 1) + 1,
          },
        })
      }
    >
      {(loading && !loadingMore ? dummySouls : souls || []).map((item) => (
        <SoulListItem
          key={item.id}
          item={item}
          skeleton={loading && !loadingMore}
          onClick={() => !loading && history.push(`/souls/${item!.id}`)}
        />
      ))}
    </InfiniteScrollTable>
  );
};

export default SoulList;
