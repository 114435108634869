import { gql } from '@apollo/client';
import { IconName, IconNames } from '@blueprintjs/icons';
import React from 'react';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import { EventListItemFragment, EventStatus } from '../../../generated/graphql';
import { formatDay, formatTime } from '../../../helpers/dateTimeUtils';
import AttendeeListStatus from '../../attendees/components/AttendeeListStatus';

export type EventListItemProps = {
  item: EventListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

type StatusMap = {
  [key in EventStatus]: { label: string; icon: IconName };
};

const statusMap: StatusMap = {
  [EventStatus.Draft]: {
    label: 'Entwurf',
    icon: IconNames.DOCUMENT,
  },
  [EventStatus.Ready]: {
    label: 'Bereit zur Veröffentlichung',
    icon: IconNames.SAVED,
  },
  [EventStatus.Published]: {
    label: 'Veröffentlicht',
    icon: IconNames.GLOBE_NETWORK,
  },
  [EventStatus.Closed]: {
    label: 'Warte auf Antworten',
    icon: IconNames.HISTORY,
  },
  [EventStatus.Cancelled]: {
    label: 'Ausgeschöpft',
    icon: IconNames.DISABLE,
  },
};

const EventListItem = ({ item, skeleton, onClick }: EventListItemProps) => (
  <tr onClick={onClick}>
    <TableCell>
      {item.archived ? (
        <TooltipIcon label="Archiviert" icon={IconNames.PROJECTS} skeleton={skeleton} />
      ) : (
        <TooltipIcon label={statusMap[item.status].label} icon={statusMap[item.status].icon} skeleton={skeleton} />
      )}
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{item.name}</Text> <br />
      <Text skeleton={skeleton} small muted>
        {item.location && item.location.name}
      </Text>
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{formatDay(item.start)}</Text> <br />
      <Text skeleton={skeleton} muted>
        {formatTime(item.start)}
        {!!item.end && ` - ${formatTime(item.end)}`}
        {' Uhr'}
      </Text>
    </TableCell>
    <TableCell>
      <AttendeeListStatus event={item} skeleton={skeleton} />
    </TableCell>
  </tr>
);

EventListItem.fragments = {
  event: gql`
    fragment EventListItem on Event {
      id
      name
      status
      start
      end
      available_tickets
      taken_tickets
      archived
      candidateCount @client
      attendeeCount @client
      signups {
        id
        is_candidate
        is_invited
        has_accepted
        has_rejected
      }
      location {
        id
        name
        accessible
      }
    }
  `,
};

export default EventListItem;
