/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { FetchResult } from '@apollo/client';
import { useSubmit } from 'formik-apollo';
import AppToaster from '../../../AppToaster';
import { ForgotPasswordInput, ForgotPasswordMutation, useForgotPasswordMutation } from '../../../generated/graphql';
import { SendResetLinkStatus } from '../../../helpers/constants';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import EmailForm from '../components/EmailForm';

const EmailPage = () => {
  const [sendEmail] = useForgotPasswordMutation();

  const handleSubmit = useSubmit<ForgotPasswordInput, FetchResult<ForgotPasswordMutation>>({
    mutate: (values) =>
      sendEmail({
        variables: {
          input: values,
        },
      }),
    onCompleted: ({ data }) => {
      if (data?.forgotPassword?.status === SendResetLinkStatus.Success) {
        AppToaster.success(
          'Eine Email mit Anweisungen zum Zurücksetzen ihres Passwortes ist auf dem Weg zu ihnen. Prüfen Sie ihr Postfach.',
        );
      }
    },
    getErrors: getLaravelValidationErrors,
  });

  return (
    <div css={styles.container}>
      <EmailForm onSubmit={handleSubmit} />
    </div>
  );
};

export default EmailPage;

const styles = {
  container: css`
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
};
