import { gql } from '@apollo/client';
import React from 'react';
import CategoryTag from '../../../components/CategoryTag';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { CategoryListItemFragment } from '../../../generated/graphql';

export type CategoryListProps = {
  item: CategoryListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

const CategoryListItem = ({ item, skeleton, onClick }: CategoryListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <CategoryTag category={item} skeleton={skeleton} />
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{item.description}</Text>
    </TableCell>
  </tr>
);

CategoryListItem.fragments = {
  category: gql`
    fragment CategoryListItem on Category {
      id
      name
      description
      color
      deleted_at
    }
  `,
};

export default CategoryListItem;
