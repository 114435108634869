/** @jsx jsx */
import { gql } from '@apollo/client';
import { Classes, Elevation, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EmailLink from '../../../components/EmailLink';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import UserStatusTag from '../../../components/PersonStatusTag';
import PersonTagIcon from '../../../components/PersonTagIcon';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import TooltipTag from '../../../components/TooltipTag';
import { Intent, useSoulDetailQuery } from '../../../generated/graphql';
import { getPersisted } from '../../../helpers/constants';
import { getTargetGroupText } from '../../../helpers/dataUtils';
import { getAgeInYears } from '../../../helpers/dateTimeUtils';
import SoulDetailMenu from './SoulDetailMenu';

type SoulInfoType = {
  onEdit: () => void;
};

const SoulInfo = ({ onEdit }: SoulInfoType) => {
  const { soulId } = useParams();
  const { data: soulData, loading, error } = useSoulDetailQuery({
    variables: {
      id: soulId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!soulData?.soul) return <NotFoundCard resource="Genießer" />;

  const { soul } = soulData;
  const { user } = soul;

  const address: string | null =
    user.street && user.postal_code && user.city ? `${user.street}, ${user.postal_code} ${user.city}` : null;

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
      <ContentCardHeader
        leftElement={
          <Fragment>
            <span css={styles.heading}>{user.display_name}</span>
            <Tag minimal css={styles.personTag}>
              Genießer
            </Tag>
            <UserStatusTag status={soul.user.status} deleted={!!soul.deleted_at} />
          </Fragment>
        }
        rightElement={<SoulDetailMenu soul={soul} onEdit={onEdit} />}
      />
      <div css={styles.content}>
        <PropertyList>
          <PropertyListHeader>Person</PropertyListHeader>
          <PropertyListItem
            label="Alter"
            value={user.birthdate ? getAgeInYears(user.birthdate).toString() : '—'}
            showValueDisabled={!user.birthdate}
          />
          <PropertyListItem label="Email">
            <EmailLink email={user.email} />
            {!getPersisted('useLegacyNewsletter') && !user.is_newsletter_subscriber && (
              <TooltipTag
                tooltip="Dieser Genießer ist nicht für den Newsletter angemeldet"
                label="kein newsletter"
                minimal
                intent={Intent.Warning}
                marginLeft
              />
            )}
          </PropertyListItem>

          <PropertyListItem label="Telefon" value={user.phone || '—'} showValueDisabled={!user.phone} />
          <PropertyListItem label="Mobil" value={user.mobile || '—'} showValueDisabled={!user.mobile} />
          <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
        </PropertyList>
        <PropertyListDivider />
        <PropertyList>
          <PropertyListHeader>Genießer Info</PropertyListHeader>
          <PropertyListItem label="Zielgruppe" value={getTargetGroupText(soul.target_group)} />
          <PropertyListItem label="Besonderheiten">
            {soul.buys_ticket && <TooltipIcon label="Selbstzahler" icon={IconNames.EURO} marginRight />}
            {soul.needs_wheelchair && <TooltipIcon label="Rollstuhl" icon={IconNames.SHOPPING_CART} marginRight />}
            {soul.needs_wheeled_walker && <TooltipIcon label="Rollator" icon={IconNames.WALK} marginRight />}
            {soul.needs_blind && <TooltipIcon label="Blind" icon={IconNames.EYE_OFF} marginRight />}
            {soul.needs_drive && (
              <TooltipIcon label="Muss von Zuhause abgeholt werden" icon={IconNames.DRIVE_TIME} marginRight />
            )}
            {soul.needs_trainstation_fetch && (
              <TooltipIcon label="Muss vom Bahnhof abgeholt werden" icon={IconNames.TRAIN} marginRight />
            )}
          </PropertyListItem>
          <PropertyListItem label="Attribute">
            {!!soul.tags.length ? (
              soul.tags.map((tag) => <PersonTagIcon key={tag.id} tag={tag} />)
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
          <PropertyListItem
            label="Organisation"
            value={soul.organisation ? soul.organisation.name : '—'}
            showValueDisabled={!soul.organisation}
          />
          <PropertyListItem label="Interne Notizen">
            {soul.notes ? (
              <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{soul.notes}</div>
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
        </PropertyList>
      </div>
    </ContentCard>
  );
};

SoulInfo.fragments = {
  soul: gql`
    fragment SoulInfo on Soul {
      id
      target_group
      notes
      buys_ticket
      needs_wheelchair
      needs_wheeled_walker
      needs_drive
      needs_trainstation_fetch
      needs_blind
      deleted_at
      user {
        id
        status
        display_name
        first_name
        last_name
        birthdate
        email
        gdpr_consent
        is_newsletter_subscriber
        phone
        mobile
        street
        city
        postal_code
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
};

export default SoulInfo;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
