/** @jsx jsx */
import { Classes, Colors, Icon, Popover, PopoverInteractionKind, Position, Intent } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';

type PopoverIconProps = {
  icon?: IconName;
  intent?: Intent;
  color?: string;
  position?: Position;
  skeleton?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
  children: React.ReactNode;
};

const getColor = (intent?: Intent, color?: string) => {
  if (intent) return undefined;
  if (color) return color;
  return Colors.GRAY1;
};

const PopoverIcon = ({
  icon,
  marginRight,
  marginLeft,
  intent,
  color,
  position,
  skeleton,
  children,
}: PopoverIconProps) => (
  <Popover interactionKind={PopoverInteractionKind.HOVER} hoverOpenDelay={100} position={position} disabled={skeleton}>
    <Icon
      icon={icon || IconNames.INFO_SIGN}
      intent={intent}
      color={getColor(intent, color)}
      className={skeleton ? Classes.SKELETON : ''}
      css={[marginRight && styles.marginRight, marginLeft && styles.marginLeft]}
    />
    <div css={styles.content}>{children}</div>
  </Popover>
);

export default PopoverIcon;

const styles = {
  marginRight: css`
    margin-right: 5px;
  `,
  marginLeft: css`
    margin-left: 5px;
  `,
  content: css`
    padding: 16px 20px;
    max-width: 500px;
  `,
};
