import { gql } from '@apollo/client';
import React from 'react';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { OrganisationListItemFragment } from '../../../generated/graphql';

export type OrganisationListProps = {
  item: OrganisationListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

const OrganisationListItem = ({ item, skeleton, onClick }: OrganisationListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Text skeleton={skeleton}>{item.name}</Text>
    </TableCell>
  </tr>
);

OrganisationListItem.fragments = {
  organisation: gql`
    fragment OrganisationListItem on Organisation {
      id
      name
      deleted_at
    }
  `,
};

export default OrganisationListItem;
