/** @jsx jsx */
import { gql } from '@apollo/client';
import { Elevation, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { PropertyList, PropertyListHeader, PropertyListItem } from '../../../components/PropertyList';
import RawHTML from '../../../components/RawHTML';
import Text from '../../../components/Text';
import { NewsletterStatus, useNewsletterDetailQuery } from '../../../generated/graphql';
import { getMailcoachSegmentText, getNewsletterStatusText } from '../../../helpers/dataUtils';
import { formatDateTime } from '../../../helpers/dateTimeUtils';
import NewsletterDetailMenu from '../components/NewsletterDetailMenu';

const NewsletterDetailPage = () => {
  const { newsletterId } = useParams();
  const { data, loading, error } = useNewsletterDetailQuery({
    variables: {
      id: newsletterId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.newsletter) return <NotFoundCard resource="Newsletter" />;

  const { newsletter } = data;

  return (
    <Fragment>
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{newsletter.subject}</span>
              <Tag minimal css={styles.personTag}>
                Newsletter
              </Tag>
            </Fragment>
          }
          rightElement={<NewsletterDetailMenu newsletter={newsletter} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Details</PropertyListHeader>
            <PropertyListItem label="Empfänger" value={getMailcoachSegmentText(newsletter.segment)} />
            <PropertyListItem label="Gesendet">
              {newsletter.status === NewsletterStatus.Sent ? (
                formatDateTime(newsletter.sent_at!, 'dd.MM.yyyy HH:mm')
              ) : (
                <Text muted>{getNewsletterStatusText(newsletter.status)} </Text>
              )}
            </PropertyListItem>
            <PropertyListItem label="Inhalt">
              <RawHTML>{newsletter.body}</RawHTML>
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
    </Fragment>
  );
};

NewsletterDetailPage.fragments = {
  newsletter: gql`
    fragment NewsletterDetailPage on Newsletter {
      id
      status
      subject
      body
      sent_at
      segment
      events {
        id
        name
      }
    }
  `,
};

export default NewsletterDetailPage;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  table: css`
    width: 100%;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
