/** @jsx jsx */
import { Classes, Colors, Icon, IconName, Intent, Position, Tooltip } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

type TooltipIconProps = {
  icon: IconName;
  intent?: Intent;
  color?: string;
  label: string;
  position?: Position;
  skeleton?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
};

const getColor = (intent?: Intent, color?: string) => {
  if (intent) return undefined;
  if (color) return color;
  return Colors.GRAY1;
};

const TooltipIcon = ({ icon, intent, marginRight, marginLeft, color, label, position, skeleton }: TooltipIconProps) => (
  <Tooltip content={label} position={position} disabled={skeleton}>
    <Icon
      icon={icon}
      intent={intent}
      color={getColor(intent, color)}
      className={skeleton ? Classes.SKELETON : ''}
      css={[marginRight && styles.marginRight, marginLeft && styles.marginLeft]}
    />
  </Tooltip>
);

export default TooltipIcon;

const styles = {
  marginRight: css`
    margin-right: 5px;
  `,
  marginLeft: css`
    margin-left: 5px;
  `,
};
