/** @jsx jsx */
import { Button, Classes, Colors, Intent } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { useFilterContext } from './Filter';
import Text from './Text';

const FilterHeader = () => {
  const { resetFilters, commitFilters } = useFilterContext();

  return (
    <div css={styles.header}>
      <Button text="Reset" small onClick={resetFilters} />
      <Text css={styles.title}>Filter</Text>
      <Button text="Fertig" small intent={Intent.PRIMARY} onClick={commitFilters} className={Classes.POPOVER_DISMISS} />
    </div>
  );
};

export default FilterHeader;

const styles = {
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    background-color: ${Colors.LIGHT_GRAY5};
  `,
  title: css`
    padding: 0 50px;
  `,
};
