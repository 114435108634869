/** @jsx jsx */
import { HTMLTable } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import Empty from './Empty';
import Error from './Error';
import TableHeaderCell from './TableHeaderCell';

type PaginatedTableProps = {
  columns: string[];
  interactive?: boolean;
  loading: boolean;
  empty: boolean;
  hasError: boolean;
  children: React.ReactNode;
};

const PaginatedTable = ({ columns, interactive, loading, empty, hasError, children }: PaginatedTableProps) => {
  if (!loading && (hasError || empty)) {
    return hasError ? <Error /> : <Empty />;
  }

  return (
    <HTMLTable interactive={interactive && !loading} condensed css={styles.table}>
      <thead>
        <tr>
          {columns.map((column) => (
            <TableHeaderCell key={column}>{column}</TableHeaderCell>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </HTMLTable>
  );
};

export default PaginatedTable;

const styles = {
  table: css`
    width: 100%;
  `,
};
