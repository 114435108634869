/** @jsx jsx */
import { HTMLSelect } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { UserStatus } from '../generated/graphql';

type PersonStatusSelectProps = {
  value: UserStatus;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
};

const statusOptions = [
  {
    value: UserStatus.Active,
    label: 'Aktive',
  },
  {
    value: UserStatus.Passive,
    label: 'Passive',
  },
  {
    value: UserStatus.New,
    label: 'Neue',
  },
];

const PersonStatusSelect = ({ value, onChange }: PersonStatusSelectProps) => (
  <HTMLSelect css={styles.statusSelect} options={statusOptions} value={value} onChange={onChange} />
);

export default PersonStatusSelect;

const styles = {
  statusSelect: css`
    margin-right: 10px;
  `,
};
