/** @jsx jsx */
import { Classes, Intent, Position, Tag, Tooltip } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

type TooltipTagProps = {
  label: string;
  tooltip: string;
  intent?: Intent;
  minimal?: boolean;
  position?: Position;
  skeleton?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
};

const TooltipTag = ({
  label,
  tooltip,
  minimal,
  marginRight,
  marginLeft,
  intent,
  position,
  skeleton,
}: TooltipTagProps) => (
  <Tooltip content={tooltip} position={position} disabled={skeleton}>
    <Tag
      intent={intent}
      minimal={minimal}
      className={skeleton ? Classes.SKELETON : ''}
      css={[marginRight && styles.marginRight, marginLeft && styles.marginLeft]}
    >
      {label}
    </Tag>
  </Tooltip>
);

export default TooltipTag;

const styles = {
  marginRight: css`
    margin-right: 5px;
  `,
  marginLeft: css`
    margin-left: 5px;
  `,
};
