/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import AttendList from './AttendList';

type AttendsTabProps = {
  isSoul: boolean;
  prospectId: string;
};

const AttendsTab = ({ isSoul, prospectId }: AttendsTabProps) => (
  <div css={styles.tabPanel}>
    <AttendList isSoul={isSoul} prospectId={prospectId} />
  </div>
);

export default AttendsTab;

const styles = {
  tabPanel: css`
    margin: 0 -20px;
    overflow-y: auto;
  `,
};
