/** @jsx jsx */
import { FetchResult, gql } from '@apollo/client';
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import * as Yup from 'yup';
import ColorInput from '../../../components/ColorInput';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import {
  CategoryCreateFormFragment,
  CategoryEditFormFragment,
  CreateCategoryMutation,
  UpdateCategoryInput,
  UpdateCategoryMutation,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
  color: Yup.string().required('Erforderlich'),
});

type CategoryFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateCategoryMutation) => void;
  onUpdated?: (data: UpdateCategoryMutation) => void;
  onError?: () => void;
  initialValues: CategoryCreateFormFragment | CategoryEditFormFragment;
};

const isEditData = (
  initialValues: CategoryCreateFormFragment | CategoryEditFormFragment,
): initialValues is CategoryEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateCategoryMutation | UpdateCategoryMutation): data is UpdateCategoryMutation =>
  'updateCategory' in data;

const CategoryForm = ({ onCancel, onCreated, onUpdated, onError, initialValues }: CategoryFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateCategory] = useUpdateCategoryMutation();
  const [createCategory] = useCreateCategoryMutation();
  const handleSubmit = useSubmit<
    CategoryCreateFormFragment | CategoryEditFormFragment,
    FetchResult<CreateCategoryMutation> | FetchResult<UpdateCategoryMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateCategory({
            variables: {
              input: buildDiff(initialValues, values) as UpdateCategoryInput,
            },
          })
        : createCategory({
            variables: {
              input: {
                ...values,
              },
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Kategorie {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
              <TextInput name="name" placeholder="Name" />
            </FormGroup>
            <FormGroup label="Farbe" labelInfo="(erforderlich)" name="color">
              <ColorInput name="color" />
            </FormGroup>

            <FormGroup name="description">
              <TextAreaInput name="description" placeholder="Beschreibung" fill rows={10} />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

CategoryForm.fragments = {
  create: gql`
    fragment CategoryCreateForm on Category {
      name
      description
      color
    }
  `,
  edit: gql`
    fragment CategoryEditForm on Category {
      id
      name
      description
      color
    }
  `,
};

export default CategoryForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
