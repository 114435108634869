/** @jsx jsx */
import { Alert, Button, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import AppToaster from '../../../AppToaster';
import {
  CategoryDetailPageFragment,
  useDeleteCategoryMutation,
  useForceDeleteCategoryMutation,
  useRestoreCategoryMutation,
} from '../../../generated/graphql';

type CategoryDetailMenuProps = {
  category: CategoryDetailPageFragment;
  onEdit: () => void;
};

const CategoryDetailMenu = ({ category, onEdit }: CategoryDetailMenuProps) => {
  const { id } = category;
  const options = {
    variables: {
      id,
    },
  };
  const [isForceDeleteAlertOpen, setIsForceDeleteAlertOpen] = useState(false);

  const [deleteCategory, { loading: deleting }] = useDeleteCategoryMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${category.name}  wurde in den Papierkorb gelegt`),
  });
  const [restoreCategory, { loading: restoring }] = useRestoreCategoryMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${category.name}  wurde wiederhergestellt`),
  });
  const [forceDeleteCategory, { loading: forceDeleting }] = useForceDeleteCategoryMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${category.name}  wurde endgültig entfernt`),
  });

  if (!!category.deleted_at) {
    return (
      <Fragment>
        <Alert
          isOpen={isForceDeleteAlertOpen}
          cancelButtonText="Abbrechen"
          confirmButtonText="Entfernen"
          icon={IconNames.DELETE}
          intent={Intent.DANGER}
          onClose={() => setIsForceDeleteAlertOpen(false)}
          onConfirm={forceDeleteCategory as any}
        >
          <p>Soll diese Kategorie wirklich undwiderruflich entfernt werden?</p>
        </Alert>
        <Button
          text="Wiederherstellen"
          icon={IconNames.OFFLINE}
          css={styles.headerButton}
          onClick={restoreCategory as any}
          loading={restoring}
        />
        <Popover
          position={Position.BOTTOM}
          content={
            <Menu>
              <MenuItem text="Bearbeiten" icon={IconNames.EDIT} onClick={onEdit} />
              <MenuItem
                text="Entfernen"
                icon={IconNames.DELETE}
                intent={Intent.DANGER}
                onClick={() => setIsForceDeleteAlertOpen(true)}
              />
            </Menu>
          }
        >
          <Button icon={IconNames.MORE} css={styles.headerButton} loading={forceDeleting} />
        </Popover>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Button text="Bearbeiten" icon={IconNames.EDIT} css={styles.headerButton} onClick={onEdit} />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteCategory as any} />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} css={styles.headerButton} loading={deleting} />
      </Popover>
    </Fragment>
  );
};

export default CategoryDetailMenu;

const styles = {
  statusTag: css`
    margin-left: 10px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
};
