/** @jsx jsx */
import { NonIdealState } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';

export interface EmptyProps {
  icon?: IconName;
  title?: string;
  description?: string;
  action?: JSX.Element;
}

const Empty = (props: EmptyProps) => (
  <div css={styles.error}>
    <NonIdealState {...props} />
  </div>
);

Empty.defaultProps =
  {
    title: 'Keine Daten',
    icon: IconNames.INBOX,
    description: 'Derzeit keine Daten vorhanden.',
  } as Partial<EmptyProps>;

export default Empty;

const styles = {
  error: css`
    text-align: center;
    padding: 40px 0;
  `,
};
