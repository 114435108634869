import { IOverlayProps } from '@blueprintjs/core';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { AddSignupsInput, useAddSignupsMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import AddSignupsForm, { AddSignupsFormValues } from './AddSignupsForm';

interface IAddSignupsOverlayProps extends IOverlayProps {
  eventId: string;
}

const AddSignupsOverlay = ({ eventId, ...overlayProps }: IAddSignupsOverlayProps) => {
  const [addSignups] = useAddSignupsMutation();
  const handleSubmit = useSubmit({
    mutate: (values: AddSignupsFormValues) => {
      const input: AddSignupsInput = {
        ...values,
        eventId,
        loneSouls: values.loneSouls ? values.loneSouls.map((loneSoul) => loneSoul.id.toString()) : undefined,
        loneCompanions: values.loneCompanions
          ? values.loneCompanions.map((loneCompanion) => loneCompanion.id.toString())
          : undefined,
        group: values.group?.companion
          ? {
              companion: values.group.companion.id.toString(),
              souls: values.group.souls.map((soul) => soul.id.toString()),
            }
          : undefined,
      };
      return addSignups({
        variables: {
          input,
        },
      });
    },
    onCompleted: () => {
      overlayProps.onClose && overlayProps.onClose();
    },
    getErrors: getLaravelValidationErrors,
  });

  return (
    <ContentOverlay {...overlayProps}>
      <AddSignupsForm onCancel={overlayProps.onClose} onSubmit={handleSubmit} initialValues={{}} />
    </ContentOverlay>
  );
};

export default AddSignupsOverlay;
