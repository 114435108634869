/** @jsx jsx */
import { FetchResult, gql } from '@apollo/client';
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import PersonOrganisationSelect from '../../../components/PersonOrganisationSelect';
import PersonTagMultiSelect from '../../../components/PersonTagMultiSelect';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import ToggleInput from '../../../components/ToggleInput';
import {
  CompanionCreateFormFragment,
  CompanionEditFormFragment,
  CreateCompanionInput,
  CreateCompanionMutation,
  UpdateCompanionInput,
  UpdateCompanionMutation,
  useCreateCompanionMutation,
  useUpdateCompanionMutation,
} from '../../../generated/graphql';
import { convertGermanToIntl } from '../../../helpers/dateTimeUtils';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';

const validationSchema = Yup.object({
  user: Yup.object({
    first_name: Yup.string().required('Erforderlich'),
    last_name: Yup.string().required('Erforderlich'),
    birthdate: Yup.string()
      .nullable()
      .matches(/^(3[01]|[12][0-9]|0[1-9])\.(1[012]|0[1-9])\.((?:19|20)\d{2})$/, 'Kein gültiges Datum'),
    email: Yup.string().email('Keine gültige Email').nullable(),
  }),
});

type CompanionFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateCompanionMutation) => void;
  onUpdated?: (data: UpdateCompanionMutation) => void;
  onError?: () => void;
  initialValues: CompanionCreateFormFragment | CompanionEditFormFragment;
};

const isEditData = (
  initialValues: CompanionCreateFormFragment | CompanionEditFormFragment,
): initialValues is CompanionEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateCompanionMutation | UpdateCompanionMutation): data is UpdateCompanionMutation =>
  'updateCompanion' in data;

const buildUpdateInput = (
  initialValues: CompanionEditFormFragment,
  values: CompanionEditFormFragment,
): UpdateCompanionInput => {
  const { user, tags, organisation, ...otherValues }: CompanionEditFormFragment = buildDiff(initialValues, values);

  return {
    ...otherValues,
    ...(!!user && {
      user: {
        update: {
          ...user,
          ...(user.birthdate && { birthdate: convertGermanToIntl(user.birthdate) }),
        },
      },
    }),
    ...(!!tags && {
      tags: {
        sync: tags.map((tag) => tag.id),
      },
    }),
    ...(typeof organisation !== 'undefined' && {
      organisation: {
        connect: organisation?.id,
        disconnect: !organisation,
      },
    }),
  };
};

const buildCreateInput = (values: CompanionCreateFormFragment): CreateCompanionInput => ({
  ...values,
  user: {
    create: {
      ...values.user,
      ...(values.user.birthdate && { birthdate: convertGermanToIntl(values.user.birthdate) }),
    },
  },
  tags: {
    sync: values.tags.map((tag) => tag.id),
  },
  organisation: {
    connect: values.organisation?.id,
  },
});

const CompanionForm = ({ onCancel, onCreated, onUpdated, onError, initialValues }: CompanionFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateCompanion] = useUpdateCompanionMutation();
  const [createCompanion] = useCreateCompanionMutation();
  const handleSubmit = useSubmit<
    CompanionCreateFormFragment | CompanionEditFormFragment,
    FetchResult<CreateCompanionMutation> | FetchResult<UpdateCompanionMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateCompanion({
            variables: {
              input: buildUpdateInput(initialValues as CompanionEditFormFragment, values),
            },
          })
        : createCompanion({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Begleiter {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Vorname" labelInfo="(erforderlich)" name="user.first_name">
              <TextInput name="user.first_name" placeholder="Vorname" />
            </FormGroup>
            <FormGroup label="Nachname" labelInfo="(erforderlich)" name="user.last_name">
              <TextInput name="user.last_name" placeholder="Nachname" />
            </FormGroup>
            <FormGroup label="Email" labelInfo="(erforderlich)" name="user.email">
              <TextInput name="user.email" type="email" placeholder="Email" />
            </FormGroup>
            <FormGroup label="Geburtsdatum" name="user.birthdate">
              <TextInput name="user.birthdate" placeholder="TT.MM.JJJJ" />
            </FormGroup>
            <FormGroup
              label="Datenschutz"
              name="user.gdpr_consent"
              helperText="Begleiter erhält keine Newsletter, wenn keine ausdrückliche Einwilligung vorliegt"
            >
              <ToggleInput name="user.gdpr_consent" label="Newsletter-Einwilligung erteilt" />
            </FormGroup>
            <FormGroup label="Telefon" name="user.phone">
              <TextInput name="user.phone" type="text" placeholder="Telefon" />
            </FormGroup>
            <FormGroup label="Mobil" name="user.mobile">
              <TextInput name="user.mobile" type="text" placeholder="Mobil" />
            </FormGroup>
            <FormGroup label="Adresse" name="user.street">
              <TextInput name="user.street" type="text" placeholder="Straße" />
            </FormGroup>
            <FormGroup name="user.postal_code">
              <TextInput name="user.postal_code" type="text" placeholder="PLZ" />
            </FormGroup>
            <FormGroup name="user.city">
              <TextInput name="user.city" type="text" placeholder="Ort" />
            </FormGroup>

            <Divider css={styles.formDivider} />
            <FormGroup label="Besonderheiten" name="can_wheelchair">
              <ToggleInput name="can_wheelchair" label="Kann Rollstuhl schieben" />
              <ToggleInput name="can_blind" label="Kann Blinde begleiten" />
              <ToggleInput name="can_wheeled_walker" label="Kann Genießer mit Rollator begleiten" />
              <ToggleInput name="can_drive" label="Kann Genießer von Zuhause abholen" />
            </FormGroup>
            <FormGroup label="Attribute" name="tags">
              <PersonTagMultiSelect name="tags" />
            </FormGroup>
            <FormGroup label="Organisation" name="organisation">
              <PersonOrganisationSelect name="organisation" />
            </FormGroup>
            <FormGroup label="Interne Notizen" name="notes">
              <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

CompanionForm.fragments = {
  // This is only used for Formik initialValues
  create: gql`
    fragment CompanionCreateForm on Companion {
      notes
      can_wheelchair
      can_wheeled_walker
      can_drive
      can_blind
      user {
        first_name
        last_name
        status
        birthdate
        email
        gdpr_consent
        phone
        mobile
        street
        city
        postal_code
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
  edit: gql`
    fragment CompanionEditForm on Companion {
      id
      notes
      can_wheelchair
      can_wheeled_walker
      can_drive
      can_blind
      user {
        id
        first_name
        last_name
        birthdate
        email
        phone
        mobile
        street
        city
        postal_code
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
};

export default CompanionForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
