/** @jsx jsx */
import { Alert, Button, ButtonGroup, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import AppToaster from '../../../AppToaster';
import {
  SoulInfoFragment,
  useActivateSoulMutation,
  useApproveSoulMutation,
  useDeactivateSoulMutation,
  useDeleteSoulMutation,
  useDenySoulMutation,
  useForceDeleteSoulMutation,
  useRestoreSoulMutation,
  UserStatus,
} from '../../../generated/graphql';

type SoulDetailMenuProps = {
  soul: SoulInfoFragment;
  onEdit: () => void;
};

const SoulDetailMenu = ({ soul, onEdit }: SoulDetailMenuProps) => {
  const { user, id } = soul;
  const options = {
    variables: {
      id,
    },
  };
  const [isForceDeleteAlertOpen, setIsForceDeleteAlertOpen] = useState(false);
  const [approveSoul, { loading: approving }] = useApproveSoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${user.display_name}  wurde freigeschaltet`),
  });
  const [denySoul, { loading: denying }] = useDenySoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${user.display_name}  wurde abgelehnt`),
  });
  const [activateSoul, { loading: activating }] = useActivateSoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${user.display_name}  wurde ACTIV geschaltet`),
  });
  const [deactivateSoul, { loading: deactivating }] = useDeactivateSoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${user.display_name}  wurde PASSIV geschaltet`),
  });
  const [deleteSoul, { loading: deleting }] = useDeleteSoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${user.display_name}  wurde in den Papierkorb gelegt`),
  });
  const [restoreSoul, { loading: restoring }] = useRestoreSoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${user.display_name}  wurde wiederhergestellt`),
  });
  const [forceDeleteSoul, { loading: forceDeleting }] = useForceDeleteSoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${user.display_name}  wurde endgültig entfernt`),
  });

  if (!!soul.deleted_at) {
    return (
      <Fragment>
        <Alert
          isOpen={isForceDeleteAlertOpen}
          cancelButtonText="Abbrechen"
          confirmButtonText="Entfernen"
          icon={IconNames.DELETE}
          intent={Intent.DANGER}
          onClose={() => setIsForceDeleteAlertOpen(false)}
          onConfirm={forceDeleteSoul as any}
        >
          <p>Soll dieser Genießer wirklich undwiderruflich entfernt werden?</p>
        </Alert>
        <Button
          text="Wiederherstellen"
          icon={IconNames.OFFLINE}
          css={styles.headerButton}
          onClick={restoreSoul as any}
          loading={restoring}
        />
        <Popover
          position={Position.BOTTOM}
          content={
            <Menu>
              <MenuItem text="Bearbeiten" icon={IconNames.EDIT} onClick={onEdit} />
              <MenuItem
                text="Entfernen"
                icon={IconNames.DELETE}
                intent={Intent.DANGER}
                onClick={() => setIsForceDeleteAlertOpen(true)}
              />
            </Menu>
          }
        >
          <Button icon={IconNames.MORE} css={styles.headerButton} loading={forceDeleting} />
        </Popover>
      </Fragment>
    );
  }

  if (user.status === UserStatus.New) {
    return (
      <Fragment>
        <ButtonGroup css={styles.headerButton}>
          <Button text="Bestätigen" icon={IconNames.TICK} onClick={approveSoul as any} loading={approving} />
          <Button text="Ablehnen" icon={IconNames.CROSS} onClick={denySoul as any} loading={denying} />
        </ButtonGroup>
        <Popover
          position={Position.BOTTOM}
          content={
            <Menu>
              <MenuItem text="Bearbeiten" icon={IconNames.EDIT} onClick={onEdit} />
              <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteSoul as any} />
            </Menu>
          }
        >
          <Button icon={IconNames.MORE} css={styles.headerButton} />
        </Popover>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Button text="Bearbeiten" icon={IconNames.EDIT} css={styles.headerButton} onClick={onEdit} />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            {user.status === UserStatus.Active ? (
              <MenuItem text="Passiv schalten" icon={IconNames.OFFLINE} onClick={deactivateSoul as any} />
            ) : (
              <MenuItem text="Aktiv schalten" icon={IconNames.OFFLINE} onClick={activateSoul as any} />
            )}
            <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteSoul as any} />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} css={styles.headerButton} loading={activating || deactivating || deleting} />
      </Popover>
    </Fragment>
  );
};

export default SoulDetailMenu;

const styles = {
  statusTag: css`
    margin-left: 10px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
};
