import React from 'react';
import { gql } from '@apollo/client';
import EmailLink from '../../../components/EmailLink';
import { DonorContactListItemFragment } from '../../../generated/graphql';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';

export type DonorListProps = {
  item: DonorContactListItemFragment;
  skeleton?: boolean;
  onClick?: () => void;
};

const DonorContactListItem = ({ item, skeleton, onClick }: DonorListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Text skeleton={skeleton}>{item.user.display_name}</Text>
    </TableCell>
    <TableCell>
      <EmailLink email={item.user.email} skeleton={skeleton} />
    </TableCell>
    <TableCell muted>
      <Text skeleton={skeleton}>{item.user.phone || item.user.mobile || '—'}</Text>
    </TableCell>
  </tr>
);

DonorContactListItem.fragments = {
  contact: gql`
    fragment DonorContactListItem on Contact {
      id
      user {
        id
        display_name
        email
        phone
        mobile
      }
    }
  `,
};

export default DonorContactListItem;
