/** @jsx jsx */
import { Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import PaginatedTable from '../../../components/PaginatedTable';
import { useLocationDonorsQuery } from '../../../generated/graphql';
import DonorListItem from '../../donors/components/DonorListItem';
import { dummyDonors } from '../../donors/dummy';

const LocationDonors = () => {
  const { locationId } = useParams();
  const history = useHistory();
  const { data, loading, error } = useLocationDonorsQuery({
    // fetchPolicy: 'network-only',
    variables: {
      id: locationId || '',
    },
  });

  const donors = data?.location?.donors || [];

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
      <ContentCardHeader
        leftElement={
          <Fragment>
            <span css={styles.heading}>Spender</span>
          </Fragment>
        }
      />
      <PaginatedTable
        columns={['Name', 'Ansprechpartner']}
        interactive
        loading={loading}
        hasError={!!error}
        empty={!donors?.length}
      >
        {(loading ? dummyDonors : donors || []).map((donor) => (
          <DonorListItem
            key={donor.id}
            item={donor}
            skeleton={loading}
            onClick={() => !loading && history.push(`/donors/${donor!.id}`)}
          />
        ))}
      </PaginatedTable>
    </ContentCard>
  );
};

export default LocationDonors;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
