import { gql } from '@apollo/client';
import React, { Fragment, useState } from 'react';
import PersonIconList from '../../../components/PersonIconList';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { useProspectGroupSignupQuery } from '../../../generated/graphql';
import GroupInfoDrawer from './GroupInfoDrawer';

export type ProspectGroupListItemProps = {
  id: string;
  addGroup: (companionSignupId: string, soulSignupIds: string[]) => void;
};

const ProspectGroupListItem = ({ id, addGroup }: ProspectGroupListItemProps) => {
  const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState(false);
  const { data, loading, error } = useProspectGroupSignupQuery({
    variables: {
      id,
    },
  });

  if (loading || error || !data || !data.signup) {
    return null;
  }

  const { signup } = data;
  const prospect = signup.companion!;
  const { organisation } = prospect;

  return (
    <Fragment>
      <GroupInfoDrawer
        signup={signup}
        isOpen={isInfoDrawerOpen}
        onClose={() => setIsInfoDrawerOpen(false)}
        addGroup={addGroup}
      />
      <tr onClick={() => setIsInfoDrawerOpen(true)}>
        <TableCell>{!!organisation && organisation.name}</TableCell>
        <TableCell>
          <PersonIconList person={prospect} />
          <br />
          <Text small muted>
            {signup.notes}
          </Text>
        </TableCell>
        <TableCell>{signup.group.length + 1}</TableCell>
      </tr>
    </Fragment>
  );
};

ProspectGroupListItem.fragments = {
  prospect: gql`
    fragment ProspectGroupListItem on Signup {
      id
      notes
      priority
      is_first_attend
      conflicting_signups {
        id
        event {
          id
          name
          start
        }
      }
      companion {
        id
        notes
        can_blind
        can_wheelchair
        can_wheeled_walker
        can_drive
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
        user {
          id
          display_name
          birthdate
        }
      }
      group {
        id
        notes
        priority
        is_first_attend
        conflicting_signups {
          id
          event {
            id
            name
            start
          }
        }
        soul {
          id
          notes
          buys_ticket
          needs_blind
          needs_wheelchair
          needs_wheeled_walker
          needs_drive
          needs_trainstation_fetch
          tags {
            id
            name
            icon
            intent
          }
          user {
            id
            display_name
            birthdate
          }
        }
      }
    }
  `,
};

export default ProspectGroupListItem;
