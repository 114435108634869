import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import CompanionEditOverlay from '../components/CompanionEditOverlay';
import CompanionInfo from '../components/CompanionInfo';
import CompanionEmails from '../components/CompanionEmails';
import CompanionAttends from '../components/CompanionAttends';
import CompanionFutureSignups from '../components/CompanionFutureSignups';

const CompanionDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { companionId } = useParams();

  return (
    <Fragment>
      <CompanionEditOverlay
        id={companionId || ''}
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
      />
      <CompanionInfo onEdit={() => setIsEditDialogOpen(true)} />
      <CompanionFutureSignups />
      <CompanionAttends />
      <CompanionEmails />
    </Fragment>
  );
};

export default CompanionDetailPage;
