import { InMemoryCache } from '@apollo/client';
import introspectionResult from './generated/introspection-result.json';

const offsetLimitPaginatedField = (keyArgs: (string | string[])[] | false = false) => ({
  keyArgs,
  merge(existing: any, incoming: any, { args, storage }: { args: any; storage: any }) {
    if (!storage) storage = {};
    storage.paginatorInfos = {
      ...storage.paginatorInfos,
      [incoming.paginatorInfo.currentPage]: incoming.paginatorInfo,
    };

    if (!existing) return incoming;
    const merged = existing.data.slice(0);
    const limit = args.first;
    const offset = (incoming.paginatorInfo?.currentPage - 1) * limit;

    incoming.data.forEach((item: any, index: number) => {
      merged[offset + index] = item;
    });

    return {
      ...incoming,
      data: merged,
    };
  },
  read(existing: any, { args: { page = 1, first }, variables, storage }: { args: any; variables?: any; storage: any }) {
    if (!existing || variables?.infinite) return existing;

    const limit = first;
    const offset = (page - 1) * limit;

    return {
      ...existing,
      data: existing?.data?.slice(offset, offset + limit),
      paginatorInfo: storage.paginatorInfos[page],
    };
  },
});

const cacheRedirect = (typename: string, keyArgs: (string | string[])[] | false = ['id']) => ({
  keyArgs,
  read(existing: any, { args, toReference }: { args: Record<string, any> | null; toReference: any }) {
    return (
      existing ||
      toReference({
        __typename: typename,
        id: args!.id,
      })
    );
  },
});

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        events: offsetLimitPaginatedField(['status', 'start', 'donor', 'archived', 'orderBy']),
        souls: offsetLimitPaginatedField(['status']),
        companions: offsetLimitPaginatedField(['status']),
        employees: offsetLimitPaginatedField(['status']),
        donors: offsetLimitPaginatedField(),
        locations: offsetLimitPaginatedField(),
        newsletters: offsetLimitPaginatedField(['sent_at']),
        signup: cacheRedirect('Signup'),
        soul: cacheRedirect('Soul'),
        companion: cacheRedirect('Companion'),
        donor: cacheRedirect('Donor'),
        location: cacheRedirect('Location'),
        organisation: cacheRedirect('Organisation'),
        category: cacheRedirect('Category'),
        allotment: cacheRedirect('Allotment'),
        contact: cacheRedirect('Contact'),
        event: cacheRedirect('Event'),
        employee: cacheRedirect('Employee'),
        role: cacheRedirect('Role'),
        newsletter: cacheRedirect('Newsletter'),
      },
    },
    Soul: {
      fields: {
        attends: offsetLimitPaginatedField(),
        future_signups: offsetLimitPaginatedField(),
        emails: offsetLimitPaginatedField(),
      },
    },
    Companion: {
      fields: {
        attends: offsetLimitPaginatedField(),
        future_signups: offsetLimitPaginatedField(),
        emails: offsetLimitPaginatedField(),
      },
    },
  },
});

export default cache;
