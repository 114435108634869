/** @jsx jsx */
import { Elevation, Tag, Intent } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { gql } from '@apollo/client';
import { Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { useEventDetailQuery, EventStatus } from '../../../generated/graphql';
import AttendeeGroup from '../../attendees/components/AttendeeGroup';
import AttendeeListContainer from '../../attendees/components/AttendeeListContainer';
import AttendeeListItem from '../../attendees/components/AttendeeListItem';
import AttendeeLoneCompanion from '../../attendees/components/AttendeeLoneCompanion';
import AttendeeLoneSoul from '../../attendees/components/AttendeeLoneSoul';
import AttendeeTeam from '../../attendees/components/AttendeeTeam';
import EventCloneOverlay from '../components/EventCloneOverlay';
import EventDetailMenu from '../components/EventDetailMenu';
import EventEditOverlay from '../components/EventEditOverlay';
import EventInfo from '../components/EventInfo';
import SignupList from '../components/SignupList';
import SignupListItem from '../components/SignupListItem';
import SignupMenu from '../components/SignupMenu';
import EventCancelOverlay from '../components/EventCancelOverlay';

const EventDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState<boolean>(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
  const { eventId } = useParams();
  const history = useHistory();
  const { data, loading, error } = useEventDetailQuery({
    variables: {
      id: eventId || '',
    },
  });
  const onCloned = (modelId: string) => {
    AppToaster.success('Veranstaltung hinzugefügt!');
    setIsCloneDialogOpen(false);
    history.push(`/events/${modelId}`);
  };
  const onCancelled = () => {
    AppToaster.success('Veranstaltung wurde abgesagt, alle Teilnehmer wurden informiert!');
    setIsCancelDialogOpen(false);
  };

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.event) return <NotFoundCard resource="Veranstaltung" />;

  const { event } = data;

  return (
    <Fragment>
      <EventEditOverlay id={event.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <EventCloneOverlay
        isOpen={isCloneDialogOpen}
        id={data.event.id}
        onSuccess={onCloned}
        onClose={() => setIsCloneDialogOpen(false)}
      />
      <EventCancelOverlay
        isOpen={isCancelDialogOpen}
        id={data.event.id}
        onSuccess={onCancelled}
        onClose={() => setIsCancelDialogOpen(false)}
      />
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{event.name}</span>
              {event.status === EventStatus.Cancelled && (
                <Tag minimal intent={Intent.DANGER} css={styles.personTag}>
                  Abgesagt
                </Tag>
              )}
              {event.archived && (
                <Tag minimal css={styles.personTag}>
                  Archiviert
                </Tag>
              )}
            </Fragment>
          }
          rightElement={
            <EventDetailMenu
              event={event}
              onEdit={() => setIsEditDialogOpen(true)}
              onClone={() => setIsCloneDialogOpen(true)}
              onCancel={() => setIsCancelDialogOpen(true)}
            />
          }
        />
        <div css={styles.content}>
          <EventInfo event={event} />
        </div>
      </ContentCard>
      {event.status !== EventStatus.Draft &&
        event.status !== EventStatus.Ready &&
        event.status !== EventStatus.Cancelled && (
          <div css={styles.listsContainer}>
            <div css={styles.leftList}>
              <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
                <ContentCardHeader
                  leftElement={
                    <Fragment>
                      <span css={styles.heading}>Interessenten</span>
                    </Fragment>
                  }
                  rightElement={<SignupMenu event={event} />}
                />
                <SignupList event={event} />
              </ContentCard>
            </div>
            <div css={styles.rightList}>
              <AttendeeListContainer event={event} />
            </div>
          </div>
        )}
    </Fragment>
  );
};

EventDetailPage.fragments = {
  event: gql`
    fragment EventDetailPage on Event {
      id
      name
      status
      start
      end
      notes
      description
      taken_tickets
      available_tickets
      candidates_chosen
      ticket_type #Needed for update
      ticket_time
      ticket_retrieval_type
      ticket_retrieval_location
      reservation_number
      archived
      needsInvite @client
      candidateCount @client
      attendeeCount @client
      prospects @client {
        ...AttendeeListItem
      }
      candidates @client {
        ...AttendeeListItem
      }
      attendees @client {
        ...AttendeeListItem
      }
      groups @client {
        ...AttendeeGroup
      }
      teams @client {
        ...AttendeeTeam
      }
      loneSouls @client {
        ...AttendeeLoneSoul
      }
      loneCompanions @client {
        ...AttendeeLoneCompanion
      }
      cardholder {
        id
        user {
          id
          display_name
          mobile
          phone
          email
        }
      }
      signups {
        ...AttendeeListItem
        group {
          ...AttendeeListItem
        }
        leader {
          ...AttendeeListItem
        }
      }
      contact {
        id
        user {
          id
          display_name
          mobile
          phone
          email
        }
      }
      location {
        id
        name
        accessible
        meeting_point
        public_transport
        street
        postal_code
        city
      }
    }
    ${SignupListItem.fragments.prospects}
    ${AttendeeListItem.fragments.attendees}
    ${AttendeeGroup.fragments.groups}
    ${AttendeeTeam.fragments.teams}
    ${AttendeeLoneSoul.fragments.loneSoul}
    ${AttendeeLoneCompanion.fragments.loneCompanion}
  `,
};

export default EventDetailPage;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  personTag: css`
    margin-left: 10px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
  listsContainer: css`
    display: flex;
  `,
  leftList: css`
    width: 50%;
    padding-right: 10px;
  `,
  rightList: css`
    width: 50%;
    padding-left: 10px;
  `,
};
