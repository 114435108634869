import React, { Fragment } from 'react';
import { gql } from '@apollo/client';
import CategoryTag from './CategoryTag';
import Text from './Text';
import { AttendListItemFragment } from '../generated/graphql';
import { formatToNowStrict } from '../helpers/dateTimeUtils';
import TableCell from './TableCell';
import { Link } from 'react-router-dom';

export type AttendListItemProps = {
  attend: AttendListItemFragment;
  skeleton?: boolean;
};

const renderMatchingInfo = (attend: AttendListItemFragment, skeleton?: boolean) => {
  // Display companion name
  if (attend.leader.length) {
    return (
      <Link to={`/companions/${attend.leader[0].companion?.id}`}>
        <Text skeleton={skeleton}>{attend.leader[0].companion?.user.display_name}</Text>
      </Link>
    );
  }

  // List group members
  if (attend.group.length) {
    return attend.group.map((item, index) => (
      <Fragment>
        <Link to={`/souls/${item.soul?.id}`}>
          <Text skeleton={skeleton}>{item.soul?.user.display_name}</Text>
        </Link>
        {index < attend.group.length - 1 && <br />}
      </Fragment>
    ));
  }

  // Show none
  return (
    <Text skeleton={skeleton} muted>
      —
    </Text>
  );
};

const AttendListItem = ({ attend, skeleton }: AttendListItemProps) => (
  <tr>
    <TableCell>
      {attend.event.categories.map((category) => (
        <CategoryTag key={category.id} category={category} skeleton={skeleton} />
      ))}
      <Text skeleton={skeleton}>{attend.event.name}</Text>
    </TableCell>
    <TableCell>{renderMatchingInfo(attend, skeleton)}</TableCell>
    <TableCell>
      <Text skeleton={skeleton} muted>
        {formatToNowStrict(attend.event.start)}
      </Text>
    </TableCell>
  </tr>
);

AttendListItem.fragments = {
  attend: gql`
    fragment AttendListItem on Signup {
      id
      event {
        id
        name
        start
        categories {
          id
          name
          color
        }
      }
      leader {
        id
        companion {
          id
          user {
            id
            display_name
          }
        }
      }
      group {
        id
        soul {
          id
          user {
            id
            display_name
          }
        }
      }
    }
  `,
};

export default AttendListItem;
