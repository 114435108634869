import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import PersonIconList from '../../../components/PersonIconList';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import TooltipTag from '../../../components/TooltipTag';
import { ProspectGroupListItemFragment } from '../../../generated/graphql';
import ConflictingSignups from './ConflictingSignups';
import { getAgeInYears } from '../../../helpers/dateTimeUtils';

type SignupType = ProspectGroupListItemFragment | ProspectGroupListItemFragment['group'][0];

export type GroupMemberListItemProps = {
  signup: SignupType;
};

const isLeaderSignup = (signup: SignupType): signup is ProspectGroupListItemFragment => 'group' in signup;

const GroupMemberListItem = ({ signup }: GroupMemberListItemProps) => {
  const prospect = isLeaderSignup(signup) ? signup.companion! : signup.soul!;
  const isSoul = !isLeaderSignup(signup);
  const { user } = prospect;

  return (
    <tr>
      <TableCell>
        <TooltipIcon
          label={isSoul ? 'Genießer' : 'Begleiter'}
          icon={isSoul ? IconNames.HEART : IconNames.HAND}
          marginRight
        />
        {user!.display_name}
        {!!user.birthdate && <Text muted> ({getAgeInYears(user.birthdate).toString()})</Text>}
      </TableCell>
      <TableCell>
        {signup.is_first_attend && (
          <TooltipTag minimal intent={Intent.SUCCESS} label="Neu" tooltip="Erste Teilnahme" marginRight />
        )}
        <ConflictingSignups signups={signup.conflicting_signups} userName={user.display_name} />
        <PersonIconList person={prospect} />
        <br />
        <Text small muted>
          {signup.notes}
        </Text>
      </TableCell>
      <TableCell>{signup.priority}</TableCell>
    </tr>
  );
};

export default GroupMemberListItem;
