import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { SoulCreateFormFragment, UserStatus } from '../../../generated/graphql';
import SoulForm from './SoulForm';

type SoulCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const SoulCreateOverlay = ({ onSuccess, ...overlayProps }: SoulCreateOverlayProps) => {
  const initialValues: SoulCreateFormFragment = {
    buys_ticket: false,
    needs_blind: false,
    needs_drive: false,
    needs_trainstation_fetch: false,
    needs_wheelchair: false,
    needs_wheeled_walker: false,
    user: {
      status: UserStatus.Active,
      first_name: '',
      last_name: '',
      gdpr_consent: true,
    },
    organisation: null,
    tags: [],
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <SoulForm
        onCancel={closeOverlay}
        onCreated={({ createSoul }) => {
          onSuccess(createSoul.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default SoulCreateOverlay;
