/** @jsx jsx */
import { Checkbox, Collapse, Colors } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useFilterContext } from './Filter';

type FilterItemProps<T> = {
  name: string;
  label: string;
  defaultValue?: T;
  children: ({
    value,
    onChange,
  }: {
    value: T | null | undefined;
    onChange: (value: T | null | undefined) => void;
  }) => React.ReactNode;
};

const FilterItem = <T extends any>({ name, label, defaultValue, children }: FilterItemProps<T>) => {
  const { values, setFilterValue } = useFilterContext();
  const isActive = values[name] !== undefined;

  const onChange = (value: T | null | undefined) => setFilterValue(name, value);

  return (
    <Fragment>
      <div css={styles.header}>
        <Checkbox
          css={styles.checkbox}
          checked={isActive}
          onChange={(event) =>
            event.currentTarget.checked ? setFilterValue(name, defaultValue || null) : setFilterValue(name, undefined)
          }
          label={label}
        />
      </div>
      <Collapse isOpen={isActive}>
        <div css={styles.content}>{children({ value: values[name], onChange })}</div>
      </Collapse>
    </Fragment>
  );
};

export default FilterItem;

const styles = {
  header: css`
    padding: 12px;
    box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
  `,
  checkbox: css`
    margin-bottom: 0;
  `,
  content: css`
    padding: 12px;
    background-color: ${Colors.LIGHT_GRAY5};
    box-shadow: inset 0 0 1px 0 rgba(16, 22, 26, 0.15);
  `,
};
