import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useContactEditLazyQuery } from '../../../generated/graphql';
import ContactForm from './ContactForm';

interface IContactEditOverlayProps extends IOverlayProps {
  id: string;
}

const ContactEditOverlay = ({ id, ...overlayProps }: IContactEditOverlayProps) => {
  const [loadContact, { data, called, loading, error }] = useContactEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadContact();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data?.contact) return <ErrorCard />;

    return (
      <ContactForm
        onCancel={() => overlayProps.onClose?.()}
        onUpdated={() => {
          loadContact();
          overlayProps.onClose?.();
        }}
        initialValues={data.contact}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default ContactEditOverlay;
