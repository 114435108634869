import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { LocationCreateFormFragment } from '../../../generated/graphql';
import LocationForm from './LocationForm';

type LocationCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const LocationCreateOverlay = ({ onSuccess, ...overlayProps }: LocationCreateOverlayProps) => {
  const initialValues: LocationCreateFormFragment = {
    name: '',
    street: '',
    postal_code: '',
    city: '',
    meeting_point: 'An der Kasse',
    public_transport: '',
    accessible: false,
    donors: [],
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <LocationForm
        onCancel={closeOverlay}
        onCreated={({ createLocation }) => {
          onSuccess(createLocation.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default LocationCreateOverlay;
