/** @jsx jsx */
import { Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import PaginatedTable from '../../../components/PaginatedTable';
import { useDonorLocationsQuery } from '../../../generated/graphql';
import LocationListItem from '../../locations/components/LocationListItem';
import { dummyLocations } from '../../locations/dummy';

const DonorLocations = () => {
  const { donorId } = useParams();
  const history = useHistory();
  const { data, loading, error } = useDonorLocationsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: donorId || '',
    },
  });

  const locations = data?.donor?.locations || [];

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
      <ContentCardHeader
        leftElement={
          <Fragment>
            <span css={styles.heading}>Lokationen</span>
          </Fragment>
        }
      />
      <PaginatedTable
        columns={['Name', 'Adresse', 'Treffpunkt']}
        interactive
        loading={loading}
        hasError={!!error}
        empty={!locations.length}
      >
        {(loading ? dummyLocations : locations || []).map((location) => (
          <LocationListItem
            key={location.id}
            item={location}
            skeleton={loading}
            onClick={() => !loading && history.push(`/locations/${location.id}`)}
          />
        ))}
      </PaginatedTable>
    </ContentCard>
  );
};

export default DonorLocations;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
