import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { AllotmentCreateFormFragment } from '../../../generated/graphql';
import AllotmentForm from './AllotmentForm';

type AllotmentCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const AllotmentCreateOverlay = ({ onSuccess, ...overlayProps }: AllotmentCreateOverlayProps) => {
  const initialValues: AllotmentCreateFormFragment = {
    name: '',
    amount: 0,
    start: '',
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <AllotmentForm
        onCancel={closeOverlay}
        onCreated={({ createAllotment }) => {
          onSuccess(createAllotment.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default AllotmentCreateOverlay;
