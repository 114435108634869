/** @jsx jsx */
import { Intent, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

const TrashTag = () => (
  <Tag css={styles.statusTag} intent={Intent.DANGER}>
    Im Papierkorb
  </Tag>
);

export default TrashTag;

const styles = {
  statusTag: css`
    margin-left: 5px;
  `,
};
