/** @jsx jsx */
import { Classes, HTMLTable, Spinner } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { ContentCardFooter } from './ContentCard';
import Empty from './Empty';
import Error from './Error';
import InfiniteScrollWrapper from './InfiniteScrollWrapper';
import NoResults from './NoResults';
import TableHeaderCell from './TableHeaderCell';

type ColumnType = {
  heading: string;
  width?: number;
  minWidth?: number;
};

type InfiniteScrollTableProps = {
  columns: ColumnType[];
  interactive?: boolean;
  loading: boolean;
  loadingMore: boolean;
  showLoadingMore?: boolean;
  onLoadMore: () => void;
  hasFilters?: boolean;
  empty: boolean;
  hasMorePages: boolean;
  hasError: boolean;
  children: React.ReactNode;
};

const InfiniteScrollTable = ({
  columns,
  interactive,
  loading,
  loadingMore,
  showLoadingMore = true,
  onLoadMore,
  hasFilters,
  hasMorePages,
  empty,
  hasError,
  children,
}: InfiniteScrollTableProps) => {
  if (!loading) {
    if (hasError) return <Error />;
    if (empty) return hasFilters ? <NoResults /> : <Empty />;
  }

  return (
    <InfiniteScrollWrapper loading={loading} onLoadMore={onLoadMore} hasMorePages={hasMorePages}>
      <HTMLTable interactive={interactive && !loading} condensed css={styles.table}>
        <thead>
          <tr>
            {columns.map(({ heading, minWidth, width }) => (
              <TableHeaderCell key={heading} width={width} minWidth={minWidth}>
                {heading}
              </TableHeaderCell>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </HTMLTable>
      {showLoadingMore && loadingMore && (
        <ContentCardFooter>
          <div css={styles.loadingMoreContainer}>
            <Spinner size={16} />
            <div className={Classes.TEXT_MUTED} css={styles.loadingMoreText}>
              Lade mehr
            </div>
          </div>
        </ContentCardFooter>
      )}
    </InfiniteScrollWrapper>
  );
};

export default InfiniteScrollTable;

const styles = {
  table: css`
    width: 100%;
  `,
  loadingMoreContainer: css`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  loadingMoreText: css`
    padding-left: 10px;
  `,
};
