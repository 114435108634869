/** @jsx jsx */
import { HTMLTable } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import Empty from '../../../components/Empty';
import Error from '../../../components/Error';
import NoResults from '../../../components/NoResults';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { useCategoryListQuery } from '../../../generated/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { dummyCategories } from '../dummy';
import CategoryListItem from './CategoryListItem';

const LoadingTable = () => (
  <HTMLTable condensed css={styles.table}>
    <thead>
      <tr>
        <TableHeaderCell>Name</TableHeaderCell>
        <TableHeaderCell>Beschreibung</TableHeaderCell>
      </tr>
    </thead>
    <tbody>
      {dummyCategories.map((item, index) => (
        <CategoryListItem key={index} item={item} skeleton />
      ))}
    </tbody>
  </HTMLTable>
);

const CategoryList = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const filters = queryParams.get('filters');

  const { data, loading, error } = useCategoryListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoadingTable />;
  }

  if (error) {
    return <Error />;
  }

  if (!data || !data.categories) {
    return filters ? <NoResults /> : <Empty />;
  }

  return (
    <HTMLTable interactive condensed css={styles.table}>
      <thead>
        <tr>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Beschreibung</TableHeaderCell>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.categories.map((category) => (
            <CategoryListItem
              key={category.id}
              item={category}
              onClick={() => history.push(`/categories/${category.id}`)}
            />
          ))}
      </tbody>
    </HTMLTable>
  );
};

export default CategoryList;

const styles = {
  table: css`
    width: 100%;
  `,
  loadingMoreContainer: css`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  loadingMoreText: css`
    padding-left: 10px;
  `,
};
