/** @jsx jsx */
import { Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import { EventDetailPageFragment, EventStatus } from '../../../generated/graphql';
import AttendeeList from './AttendeeList';
import AttendeeListMenu from './AttendeeListMenu';
import AttendeeListStatus from './AttendeeListStatus';

type AttendeeListContainerProps = {
  event: EventDetailPageFragment;
};

const AttendeeListContainer = ({ event }: AttendeeListContainerProps) => (
  <Fragment>
    <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
      <ContentCardHeader
        leftElement={
          <Fragment>
            <span css={styles.heading}>Teilnehmerliste</span>
          </Fragment>
        }
        rightElement={<AttendeeListMenu event={event} />}
      />
      {event.status === EventStatus.Closed && (
        <div css={styles.content}>
          <AttendeeListStatus event={event} />
        </div>
      )}
    </ContentCard>
    <AttendeeList event={event} />
  </Fragment>
);

export default AttendeeListContainer;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  content: css`
    padding: 16px 20px;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
