/** @jsx jsx */
import { FetchResult } from '@apollo/client';
import { Button, ControlGroup, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import TextInput from '../../../components/TextInput';
import { UpdateBranchInput, UpdateBranchMutation, useUpdateBranchMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import { openTab } from '../../../helpers/utils';

const validationSchema = Yup.object({
  redirect_events: Yup.string().typeError('Erforderlich').url().required('Erforderlich'),
  redirect_ticket_accept_ok: Yup.string().typeError('Erforderlich').url().required('Erforderlich'),
  redirect_ticket_accept_not_found: Yup.string().typeError('Erforderlich').url().required('Erforderlich'),
  redirect_password_reset: Yup.string().typeError('Erforderlich').url().required('Erforderlich'),
});

type SettingsFormProps = {
  onCancel?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  initialValues: UpdateBranchInput;
};

const SettingsForm = ({ onCancel, onSuccess, onError, initialValues }: SettingsFormProps) => {
  const [updateBranch] = useUpdateBranchMutation();
  const handleSubmit = useSubmit<UpdateBranchInput, FetchResult<UpdateBranchMutation>>({
    mutate: (values) =>
      updateBranch({
        variables: {
          input: values,
        },
      }),
    onCompleted: () => onSuccess?.(),
    onError,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, values, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Einstellungen bearbeiten</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup
              label="Veranstaltungsliste"
              labelInfo="(erforderlich)"
              helperText="Dieser Link sollte auf ihrer Seite die derzeitige Veranstaltungsliste anzeigen."
              name="redirect_events"
            >
              <ControlGroup>
                <TextInput name="redirect_events" placeholder="https://" fill />
                <Button text="Test" rightIcon={IconNames.SHARE} onClick={() => openTab(values.redirect_events || '')} />
              </ControlGroup>
            </FormGroup>
            <FormGroup
              label="Rückmeldung erfolgreich"
              labelInfo="(erforderlich)"
              helperText="Auf diese Seite werden Genießer und Begleiter weitergeleitet, wenn Ihre Rückmeldung auf eine angebotene Veranstaltungskarte erfolgreich war."
              name="redirect_ticket_accept_ok"
            >
              <ControlGroup>
                <TextInput name="redirect_ticket_accept_ok" placeholder="https://" fill />
                <Button
                  text="Test"
                  rightIcon={IconNames.SHARE}
                  onClick={() => openTab(values.redirect_ticket_accept_ok || '')}
                />
              </ControlGroup>
            </FormGroup>
            <FormGroup
              label="Rückmeldung nicht gefunden"
              labelInfo="(erforderlich)"
              helperText="Auf diese Seite werden Genießer und Begleiter weitergeleitet, wenn ihre Anmeldung nicht gefunden wurde und somit keine Rückmeldung erfolgen konnte."
              name="redirect_ticket_accept_not_found"
            >
              <ControlGroup>
                <TextInput name="redirect_ticket_accept_not_found" placeholder="https://" fill />
                <Button
                  text="Test"
                  rightIcon={IconNames.SHARE}
                  onClick={() => openTab(values.redirect_ticket_accept_not_found || '')}
                />
              </ControlGroup>
            </FormGroup>
            <FormGroup
              label="Passwort Zurücksetzen"
              labelInfo="(erforderlich)"
              helperText="Auf diese Seite werden Genießer und Begleiter weitergeleitet, um ihr neues Passwort zu wählen. 'email' und 'token' werden als GET parameter angehängt."
              name="redirect_password_reset"
            >
              <ControlGroup>
                <TextInput name="redirect_password_reset" placeholder="https://" fill />
                <Button
                  text="Test"
                  rightIcon={IconNames.SHARE}
                  onClick={() => openTab(values.redirect_password_reset || '')}
                />
              </ControlGroup>
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text="Änderungen Sichern"
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

export default SettingsForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
};
