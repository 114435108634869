import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import { EmailUsersInput } from '../generated/graphql';
import ContentOverlay from './ContentOverlay';
import EmailUsersForm from './EmailUsersForm';
import { GeneralMultiSelectItem } from './GeneralMultiSelect';

type EmailUsersOverlayProps = {
  onSuccess: () => void;
  users: GeneralMultiSelectItem[];
} & IOverlayProps;

const EmailUsersOverlay = ({ users, onSuccess, ...overlayProps }: EmailUsersOverlayProps) => {
  const initialValues: EmailUsersInput = {
    subject: '',
    body: '',
    users: users.map((user) => user.id),
  };

  return (
    <ContentOverlay {...overlayProps}>
      <EmailUsersForm
        onCancel={() => overlayProps.onClose?.()}
        onSuccess={onSuccess}
        initialValues={initialValues}
        users={users}
      />
    </ContentOverlay>
  );
};

export default EmailUsersOverlay;
