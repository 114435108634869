import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { CancelEventInput } from '../../../generated/graphql';
import EventCancelForm from './EventCancelForm';

type EventCancelOverlayProps = {
  id: string;
  onSuccess: () => void;
} & IOverlayProps;

const EventCancelOverlay = ({ id, onSuccess, ...overlayProps }: EventCancelOverlayProps) => {
  const initialValues: CancelEventInput = {
    id,
    reason: '',
  };

  return (
    <ContentOverlay {...overlayProps}>
      <EventCancelForm onCancel={() => overlayProps.onClose?.()} onSuccess={onSuccess} initialValues={initialValues} />
    </ContentOverlay>
  );
};

export default EventCancelOverlay;
