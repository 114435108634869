import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useEventEditLazyQuery } from '../../../generated/graphql';
import EventForm from './EventForm';

interface IEventEditOverlayProps extends IOverlayProps {
  id: string;
}

const EventEditOverlay = ({ id, ...overlayProps }: IEventEditOverlayProps) => {
  const [loadEvent, { data, called, loading, error }] = useEventEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadEvent();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data || !data.event) return <ErrorCard />;
    return (
      <EventForm
        onCancel={() => overlayProps.onClose?.()}
        onUpdated={() => {
          loadEvent();
          overlayProps.onClose?.();
        }}
        initialValues={data.event}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default EventEditOverlay;
