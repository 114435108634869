/** @jsx jsx */
import { HTMLTable } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { EventDetailPageFragment } from '../../../generated/graphql';
import SignupListItem from './SignupListItem';

type SignupListProps = {
  event: EventDetailPageFragment;
};

const SignupList = ({ event }: SignupListProps) => (
  <HTMLTable condensed css={styles.table}>
    <colgroup>
      <col span={1} style={{ width: 40 }} />
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: 40 }} />
    </colgroup>
    <thead>
      <tr>
        <TableHeaderCell>Rolle</TableHeaderCell>
        <TableHeaderCell>Name</TableHeaderCell>
        <TableHeaderCell>Info</TableHeaderCell>
        <TableHeaderCell></TableHeaderCell>
      </tr>
    </thead>
    <tbody>
      {event.prospects.map((prospect) => (
        <SignupListItem key={prospect.id} item={prospect} />
      ))}
    </tbody>
  </HTMLTable>
);

export default SignupList;

const styles = {
  table: css`
    width: 100%;
  `,
};
