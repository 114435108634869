import { ApolloClient, ApolloLink, HttpLink, gql } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import AppToaster from './AppToaster';
import cache from './cache';
import config from './helpers/config';
import { getPersisted, setPersisted, unsetPersisted } from './helpers/constants';
import { getCsrfToken } from './helpers/utils';
import { resolvers, typeDefs } from './resolvers';

// Strip __typename from variables
const withoutTypename = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation);
});

const withCsrfTokenHeader = setContext(async (_, { headers }) => {
  const csrfToken = await getCsrfToken();

  return {
    headers: {
      ...headers,
      'X-XSRF-TOKEN': csrfToken,
    },
  };
});

const handleErrors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) handleFirstGraphQLErrors(graphQLErrors);

  if (networkError) {
    handleNetworkError(networkError);
  }
});

const handleFirstGraphQLErrors = (errors) => {
  if (!errors || !errors.length) return;

  const firstError = errors[0];
  switch (firstError.extensions.category) {
    case 'authentication':
      setPersisted('isLoggedIn', false);
      unsetPersisted('branch_id');
      unsetPersisted('useLegacyNewsletter');
      unsetPersisted('is_superadmin');
      unsetPersisted('permissions');
      cache.writeQuery({
        query: gql`
          query {
            isAuthenticated
          }
        `,
        data: {
          isAuthenticated: false,
        },
      });
      break;
    case 'validation':
      AppToaster.danger('Ungültige Daten');
      break;
    case 'internal':
    case 'operation':
    default:
      AppToaster.danger(firstError.message);
  }
};

const handleNetworkError = (networkError) => {
  AppToaster.danger('Ein unbekannter Fehler ist aufgetreten');
};

const client = new ApolloClient({
  link: ApolloLink.from([
    handleErrors,
    withoutTypename,
    withCsrfTokenHeader,
    new HttpLink({
      uri: `${config.baseUrl}/graphql`,
      credentials: 'include',
    }),
  ]),
  cache,
  resolvers,
  typeDefs,
});

const writeInitalData = () => {
  cache.writeQuery({
    query: gql`
      query {
        isAuthenticated
      }
    `,
    data: {
      isAuthenticated: !!getPersisted('isLoggedIn'),
    },
  });
};

writeInitalData();

client.onResetStore(writeInitalData);

export default client;
