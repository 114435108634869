import { gql } from '@apollo/client';
import { Button, Intent, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import AppToaster from '../../../AppToaster';
import PersonIconList from '../../../components/PersonIconList';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { RemoveSignupFragment, SignupListItemFragment, useDeleteSignupMutation } from '../../../generated/graphql';
import TooltipIcon from '../../../components/TooltipIcon';
import { Link } from 'react-router-dom';

export type SignupListItemProps = {
  item: SignupListItemFragment;
};

const SignupListItem = ({ item: signup }: SignupListItemProps) => {
  const prospect = signup.soul ? signup.soul : signup.companion!;
  const { user, organisation } = prospect;

  const [deleteSignup, { loading }] = useDeleteSignupMutation({
    variables: {
      id: signup.id,
    },
    onCompleted: () => AppToaster.success(`${user.display_name} wurde als Interessent entfernt`),
    update: (store, { data }) => {
      if (data && data.deleteSignup) {
        const event = store.readFragment<RemoveSignupFragment>({
          id: `Event:${signup.event_id}`,
          fragment: SignupListItem.fragments.removeSignup,
        });

        if (event) {
          const changedEvent = {
            ...event,
            signups: event.signups.filter((storedSignup) => storedSignup.id !== signup.id),
            prospects: event.prospects.filter((storedSignup) => storedSignup.id !== signup.id),
          };
          store.writeFragment({
            id: `Event:${signup.event_id}`,
            fragment: SignupListItem.fragments.removeSignup,
            data: changedEvent,
          });
        }
      }
    },
  });

  return (
    <tr>
      <TableCell>
        <TooltipIcon
          label={signup.soul ? 'Genießer' : 'Begleiter'}
          icon={signup.soul ? IconNames.HEART : IconNames.HAND}
          marginRight
        />
      </TableCell>
      <TableCell>
        <Link to={`/${signup.soul ? 'souls' : 'companions'}/${signup.soul ? signup.soul.id : signup.companion?.id}`}>
          {user!.display_name}
        </Link>
        <br />
        {!!organisation && (
          <Text small muted>
            {organisation.name}
          </Text>
        )}
      </TableCell>
      <TableCell>
        <PersonIconList person={prospect} />
        <br />
        <Text small muted>
          {signup.notes}
        </Text>
      </TableCell>
      <TableCell>
        <Tooltip content="Entfernen">
          <Button
            small
            intent={Intent.DANGER}
            icon={IconNames.TRASH}
            loading={loading}
            onClick={() => deleteSignup()}
          />
        </Tooltip>
      </TableCell>
    </tr>
  );
};

SignupListItem.fragments = {
  removeSignup: gql`
    fragment RemoveSignup on Event {
      prospects @client {
        id
      }
      signups {
        id
      }
    }
  `,
  prospects: gql`
    fragment SignupListItem on Signup {
      id
      event_id
      notes
      companion {
        id
        notes
        can_blind
        can_wheelchair
        can_wheeled_walker
        can_drive
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
        user {
          id
          display_name
        }
      }
      soul {
        id
        notes
        buys_ticket
        needs_blind
        needs_wheelchair
        needs_wheeled_walker
        needs_drive
        needs_trainstation_fetch
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
        user {
          id
          display_name
        }
      }
    }
  `,
};

export default SignupListItem;
