import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import LocationEditOverlay from '../components/LocationEditOverlay';
import LocationInfo from '../components/LocationInfo';
import LocationDonors from '../components/LocationDonors';

const LocationDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { locationId } = useParams();

  return (
    <Fragment>
      <LocationEditOverlay id={locationId || ''} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <LocationInfo onEdit={() => setIsEditDialogOpen(true)} />
      <LocationDonors />
    </Fragment>
  );
};

export default LocationDetailPage;
