/** @jsx jsx */
import { Classes, Intent, Popover, PopoverInteractionKind, Position, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

type PopoverTagProps = {
  label: string;
  intent?: Intent;
  minimal?: boolean;
  position?: Position;
  skeleton?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
  children: React.ReactNode;
};

const PopoverTag = ({
  label,
  minimal,
  marginRight,
  marginLeft,
  intent,
  position,
  skeleton,
  children,
}: PopoverTagProps) => (
  <Popover interactionKind={PopoverInteractionKind.HOVER} hoverOpenDelay={100} position={position} disabled={skeleton}>
    <Tag
      intent={intent}
      minimal={minimal}
      className={skeleton ? Classes.SKELETON : ''}
      css={[marginRight && styles.marginRight, marginLeft && styles.marginLeft]}
    >
      {label}
    </Tag>
    <div css={styles.content}>{children}</div>
  </Popover>
);

export default PopoverTag;

const styles = {
  marginRight: css`
    margin-right: 5px;
  `,
  marginLeft: css`
    margin-left: 5px;
  `,
  content: css`
    padding: 16px 20px;
    max-width: 500px;
  `,
};
