/** @jsx jsx */
import { Alert, Button, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import AppToaster from '../../../AppToaster';
import {
  OrganisationDetailPageFragment,
  useDeleteOrganisationMutation,
  useForceDeleteOrganisationMutation,
  useRestoreOrganisationMutation,
} from '../../../generated/graphql';

type OrganisationDetailMenuProps = {
  organisation: OrganisationDetailPageFragment;
  onEdit: () => void;
};

const OrganisationDetailMenu = ({ organisation, onEdit }: OrganisationDetailMenuProps) => {
  const { id } = organisation;
  const options = {
    variables: {
      id,
    },
  };
  const [isForceDeleteAlertOpen, setIsForceDeleteAlertOpen] = useState(false);

  const [deleteOrganisation, { loading: deleting }] = useDeleteOrganisationMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${organisation.name}  wurde in den Papierkorb gelegt`),
  });
  const [restoreOrganisation, { loading: restoring }] = useRestoreOrganisationMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${organisation.name}  wurde wiederhergestellt`),
  });
  const [forceDeleteOrganisation, { loading: forceDeleting }] = useForceDeleteOrganisationMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${organisation.name}  wurde endgültig entfernt`),
  });

  if (!!organisation.deleted_at) {
    return (
      <Fragment>
        <Alert
          isOpen={isForceDeleteAlertOpen}
          cancelButtonText="Abbrechen"
          confirmButtonText="Entfernen"
          icon={IconNames.DELETE}
          intent={Intent.DANGER}
          onClose={() => setIsForceDeleteAlertOpen(false)}
          onConfirm={forceDeleteOrganisation as any}
        >
          <p>Soll diese Lokation wirklich undwiderruflich entfernt werden?</p>
        </Alert>
        <Button
          text="Wiederherstellen"
          icon={IconNames.OFFLINE}
          css={styles.headerButton}
          onClick={restoreOrganisation as any}
          loading={restoring}
        />
        <Popover
          position={Position.BOTTOM}
          content={
            <Menu>
              <MenuItem text="Bearbeiten" icon={IconNames.EDIT} onClick={onEdit} />
              <MenuItem
                text="Entfernen"
                icon={IconNames.DELETE}
                intent={Intent.DANGER}
                onClick={() => setIsForceDeleteAlertOpen(true)}
              />
            </Menu>
          }
        >
          <Button icon={IconNames.MORE} css={styles.headerButton} loading={forceDeleting} />
        </Popover>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Button text="Bearbeiten" icon={IconNames.EDIT} css={styles.headerButton} onClick={onEdit} />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteOrganisation as any} />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} css={styles.headerButton} loading={deleting} />
      </Popover>
    </Fragment>
  );
};

export default OrganisationDetailMenu;

const styles = {
  statusTag: css`
    margin-left: 10px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
};
