import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { OrganisationCreateFormFragment } from '../../../generated/graphql';
import OrganisationForm from './OrganisationForm';

type OrganisationCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const OrganisationCreateOverlay = ({ onSuccess, ...overlayProps }: OrganisationCreateOverlayProps) => {
  const initialValues: OrganisationCreateFormFragment = {
    name: '',
    souls: [],
    companions: [],
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <OrganisationForm
        onCancel={closeOverlay}
        onCreated={({ createOrganisation }) => {
          onSuccess(createOrganisation.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default OrganisationCreateOverlay;
