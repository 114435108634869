/** @jsx jsx */
import { gql } from '@apollo/client';
import { Elevation, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import PersonTagIcon from '../../../components/PersonTagIcon';
import { PropertyList, PropertyListHeader, PropertyListItem } from '../../../components/PropertyList';
import { useTagDetailQuery } from '../../../generated/graphql';
import TagDetailMenu from '../components/TagDetailMenu';
import TagEditOverlay from '../components/TagEditOverlay';

const TagDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { tagId } = useParams();
  const { data, loading, error } = useTagDetailQuery({
    variables: {
      id: tagId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.tag) return <NotFoundCard resource="Attribut" />;

  const { tag } = data;

  return (
    <Fragment>
      <TagEditOverlay id={tag.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{tag.name}</span>
              <Tag minimal css={styles.personTag}>
                Attribut
              </Tag>
            </Fragment>
          }
          rightElement={<TagDetailMenu tag={tag} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Attribut</PropertyListHeader>
            <PropertyListItem label="Icon">
              <PersonTagIcon tag={tag} />
            </PropertyListItem>
            <PropertyListItem label="Name" value={tag.name} />
            <PropertyListItem
              label="Beschreibung"
              value={tag.description || '—'}
              showValueDisabled={!tag.description}
            />
          </PropertyList>
        </div>
      </ContentCard>
    </Fragment>
  );
};

TagDetailPage.fragments = {
  tag: gql`
    fragment TagDetailPage on Tag {
      id
      name
      icon
      intent
      description
    }
  `,
};

export default TagDetailPage;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  table: css`
    width: 100%;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
