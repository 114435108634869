/** @jsx jsx */
import { FetchResult, gql } from '@apollo/client';
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import TextInput from '../../../components/TextInput';
import {
  CreateRoleInput,
  CreateRoleMutation,
  RoleCreateFormFragment,
  RoleEditFormFragment,
  UpdateRoleInput,
  UpdateRoleMutation,
  useCreateRoleMutation,
  useUpdateRoleMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';
import PermissionMultiSelect from './PermissionMultiSelect';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
});

type RoleFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateRoleMutation) => void;
  onUpdated?: (data: UpdateRoleMutation) => void;
  onError?: () => void;
  initialValues: RoleCreateFormFragment | RoleEditFormFragment;
};

const isEditData = (
  initialValues: RoleCreateFormFragment | RoleEditFormFragment,
): initialValues is RoleEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateRoleMutation | UpdateRoleMutation): data is UpdateRoleMutation =>
  'updateRole' in data;

const buildUpdateInput = (initialValues: RoleEditFormFragment, values: RoleEditFormFragment): UpdateRoleInput => {
  const { permissions, ...otherValues }: RoleEditFormFragment = buildDiff(initialValues, values);

  return {
    ...otherValues,
    ...(!!permissions && {
      permissions: {
        sync: values.permissions.map((permission) => permission.id),
      },
    }),
  };
};

const buildCreateInput = (values: RoleCreateFormFragment): CreateRoleInput => ({
  ...values,
  permissions: {
    sync: values.permissions.map((permission) => permission.id),
  },
});

const RoleForm = ({ onCancel, onCreated, onUpdated, onError, initialValues }: RoleFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateRole] = useUpdateRoleMutation();
  const [createRole] = useCreateRoleMutation();
  const handleSubmit = useSubmit<
    RoleCreateFormFragment | RoleEditFormFragment,
    FetchResult<CreateRoleMutation> | FetchResult<UpdateRoleMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateRole({
            variables: {
              input: buildUpdateInput(initialValues as RoleEditFormFragment, values),
            },
          })
        : createRole({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Rolle {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
              <TextInput name="name" placeholder="Name" />
            </FormGroup>

            <FormGroup label="Berechtigungen" name="permissions">
              <PermissionMultiSelect name="permissions" />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

RoleForm.fragments = {
  // This is only used for Formik initialValues
  create: gql`
    fragment RoleCreateForm on Role {
      name
      permissions {
        id
      }
    }
  `,
  edit: gql`
    fragment RoleEditForm on Role {
      id
      name
      permissions {
        id
        title
      }
    }
  `,
};

export default RoleForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
