/** @jsx jsx */
import { Button, Callout, FileInput, IFileInputProps, Spinner, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent, Fragment, useRef, useState } from 'react';
import AppToaster from '../AppToaster';
import config from '../helpers/config';
import { useFocusOnError } from '../helpers/forms';
import Text from './Text';
import { readCookie } from '../helpers/utils';

type FileUploadInputProps = {
  name: string;
  onUpload: () => void;
  onSuccess: () => void;
  onError: () => void;
} & IFileInputProps;

const upload = async (file: File) => {
  const csrfCookie = readCookie('XSRF-TOKEN');

  const response = await fetch(`${config.baseUrl}/vapor/signed-storage-url`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(csrfCookie && { 'X-XSRF-TOKEN': csrfCookie }),
    },
    credentials: 'include',
    body: JSON.stringify({
      content_type: file.type,
    }),
  });

  const data = await response.json();
  let headers = data.headers;
  if ('Host' in headers) {
    delete headers.Host;
  }

  await fetch(data.url, {
    method: 'PUT',
    body: file,
    headers,
  });

  data.fileName = file.name;

  return data;
};

const FileUploadInput = ({ name, onUpload, onSuccess, onError, disabled, ...otherProps }: FileUploadInputProps) => {
  const [uploading, setUploading] = useState(false);
  const formik = useFormikContext();
  const [field] = useField<{ url: string; name: string } | null | undefined>(name);
  const fieldRef = useRef<HTMLInputElement>();
  useFocusOnError({ fieldRef, name: name });

  const onFileSelected = async ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    if (!currentTarget?.files?.[0]) return;

    try {
      onUpload();
      setUploading(true);
      const uploadData = await upload(currentTarget.files[0]);
      setUploading(false);
      onSuccess();
      formik.setFieldValue(name, {
        url: uploadData.key,
        name: uploadData.fileName,
      });
    } catch (e) {
      setUploading(false);
      onError();
      AppToaster.danger('Datei konnte nicht hochgeladen werden');
    }
  };

  const onDeleteFile = () => {
    formik.setFieldValue(name, null);
  };

  return uploading ? (
    <Spinner />
  ) : (
    <Fragment>
      {field.value ? (
        <Callout>
          <div css={styles.calloutContent}>
            <Text>{field.value.name}</Text>
            <Tooltip content="Entfernen">
              <Button icon={IconNames.SMALL_CROSS} onClick={onDeleteFile} small />
            </Tooltip>
          </div>
        </Callout>
      ) : (
        <FileInput
          {...otherProps}
          text="Datei wählen..."
          buttonText="Wählen"
          onInputChange={onFileSelected}
          inputProps={{
            ref: (ref) => (fieldRef.current = ref || undefined), // Blueprint still uses old callback refs
          }}
          disabled={disabled || formik.isSubmitting}
        />
      )}
    </Fragment>
  );
};

export default FileUploadInput;

const styles = {
  calloutContent: css`
    display: flex;
    justify-content: space-between;
  `,
};
