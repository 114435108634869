/** @jsx jsx */
import { HTMLTable } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import Empty from '../../../components/Empty';
import Error from '../../../components/Error';
import NoResults from '../../../components/NoResults';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { useOrganisationListQuery } from '../../../generated/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { dummyOrganisations } from '../dummy';
import OrganisationListItem from './OrganisationListItem';

const TableWrapper = ({ children }: { children: React.ReactNode }) => (
  <HTMLTable condensed interactive css={styles.table}>
    <thead>
      <tr>
        <TableHeaderCell>Name</TableHeaderCell>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </HTMLTable>
);

const OrganisationList = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const filters = queryParams.get('filters');

  const { data, loading, error } = useOrganisationListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    fetchPolicy: 'network-only',
  });

  if (loading)
    return (
      <TableWrapper>
        {dummyOrganisations.map((item, index) => (
          <OrganisationListItem key={index} item={item} skeleton />
        ))}
      </TableWrapper>
    );

  if (error) return <Error />;

  if (!data || !data.organisations) {
    return filters ? <NoResults /> : <Empty />;
  }

  return (
    <TableWrapper>
      {data.organisations.map((organisation) => (
        <OrganisationListItem
          key={organisation.id}
          item={organisation}
          onClick={() => history.push(`/organisations/${organisation.id}`)}
        />
      ))}
    </TableWrapper>
  );
};

export default OrganisationList;

const styles = {
  table: css`
    width: 100%;
  `,
};
