import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useEventEditLazyQuery } from '../../../generated/graphql';
import EventForm from './EventForm';

type EventCloneOverlayProps = {
  id: string;
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const EventCloneOverlay = ({ id, onSuccess, ...overlayProps }: EventCloneOverlayProps) => {
  const [loadEvent, { data, called, loading, error }] = useEventEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadEvent();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data?.event) return <ErrorCard />;

    // Remove id from initialValues to signal "create"
    const { id, ...initialValues } = data.event;

    return (
      <EventForm
        onCancel={() => overlayProps.onClose?.()}
        onCreated={({ createEvent }) => {
          onSuccess(createEvent.id);
        }}
        initialValues={initialValues}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default EventCloneOverlay;
