import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import SoulEditOverlay from '../components/SoulEditOverlay';
import SoulEmails from '../components/SoulEmails';
import SoulInfo from '../components/SoulInfo';
import SoulAttends from '../components/SoulAttends';
import SoulFutureSignups from '../components/SoulFutureSignups';

const SoulDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { soulId } = useParams();

  return (
    <Fragment>
      <SoulEditOverlay id={soulId || ''} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <SoulInfo onEdit={() => setIsEditDialogOpen(true)} />
      <SoulFutureSignups />
      <SoulAttends />
      <SoulEmails />
    </Fragment>
  );
};

export default SoulDetailPage;
