/** @jsx jsx */
import { gql } from '@apollo/client';
import { Button, Colors, Intent, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Dispatch, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PersonIconList from '../../../components/PersonIconList';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import TooltipTag from '../../../components/TooltipTag';
import { CandidateListItemFragment, useCandidateSignupQuery } from '../../../generated/graphql';
import { getSignupStatus, SignupStatus } from '../../../helpers/dataUtils';
import { getAgeInYears } from '../../../helpers/dateTimeUtils';
import { ActionType } from '../types';
import ConflictingSignups from './ConflictingSignups';

export type CandidateListItemProps = {
  id: string;
  isInGroup?: boolean;
  isInTeam?: boolean;
  dispatch: Dispatch<ActionType>;
};

const CandidateStatusText = ({ status, item }: { status: SignupStatus; item: CandidateListItemFragment }) => {
  const user = item.soul ? item.soul.user : item.companion!.user;

  switch (status) {
    case SignupStatus.CANDIDATE:
      return (
        <Text small muted>
          Noch nicht eingeladen.
        </Text>
      );
    case SignupStatus.ACCEPTED:
      return (
        <Text small intent={Intent.SUCCESS}>
          Bestätigt!
        </Text>
      );
    case SignupStatus.NO_EMAIL:
      return (
        <Fragment>
          <Text small>{user.phone || user.mobile || '-'} </Text>
          <Text small muted>
            (keine Email)
          </Text>
        </Fragment>
      );
    case SignupStatus.EMAIL_OPENED:
      return (
        <Text small muted>
          Einladungsmail gelesen.
        </Text>
      );
    case SignupStatus.EMAIL_SENT:
      return (
        <Text small muted>
          Einladungsmail verschickt.
        </Text>
      );
    case SignupStatus.EMAIL_REJECTED:
      return (
        <Text small intent={Intent.WARNING}>
          Einladungsmail abgewiesen!
        </Text>
      );
    case SignupStatus.EMAIL_ADDRESS_INVALID:
      return (
        <Text small intent={Intent.WARNING}>
          E-Mail Adresse ungültig!
        </Text>
      );
    default:
      return (
        <Text small intent={Intent.WARNING}>
          Unbekannter Fehler!
        </Text>
      );
  }
};

const getIndicatorColors = (status: SignupStatus): { iconColor: string; indicatorColor: string } => {
  const successStatuses = [SignupStatus.ACCEPTED];
  const warningStatuses = [
    SignupStatus.EMAIL_REJECTED,
    SignupStatus.EMAIL_ADDRESS_INVALID,
    SignupStatus.EMAIL_UNKNOWN_ERROR,
  ];

  if (successStatuses.includes(status)) {
    return {
      indicatorColor: Colors.GREEN3,
      iconColor: Colors.LIGHT_GRAY5,
    };
  }

  if (warningStatuses.includes(status)) {
    return {
      indicatorColor: Colors.ORANGE3,
      iconColor: Colors.LIGHT_GRAY5,
    };
  }

  return {
    indicatorColor: Colors.LIGHT_GRAY5,
    iconColor: Colors.GRAY1,
  };
};

const CandidateListItem = ({ id, isInGroup, isInTeam, dispatch }: CandidateListItemProps) => {
  const { data, loading, error } = useCandidateSignupQuery({
    variables: {
      id,
    },
  });

  if (loading || error || !data?.signup) {
    return null;
  }

  const { signup } = data;
  const attendee = signup.soul ? signup.soul : signup.companion!;
  const isSoul = !!signup.soul;
  const { user, organisation } = attendee;
  const status = getSignupStatus(signup);
  const { indicatorColor, iconColor } = getIndicatorColors(status);

  const remove = () => {
    if (isInGroup) {
      return dispatch({
        type: isSoul ? 'removeGroupMember' : 'removeGroupMember',
        payload: signup.id,
      });
    }

    if (isInTeam) {
      return dispatch({
        type: isSoul ? 'removeTeamMember' : 'removeTeam',
        payload: signup.id,
      });
    }

    return dispatch({
      type: isSoul ? 'removeLoneSoul' : 'removeLoneCompanion',
      payload: signup.id,
    });
  };

  return (
    <div css={styles.container}>
      <div css={styles.indicator(indicatorColor)}>
        <TooltipIcon
          label={isSoul ? 'Genießer' : 'Begleiter'}
          icon={isSoul ? IconNames.HEART : IconNames.HAND}
          color={iconColor}
          marginLeft
        />
      </div>
      <div css={styles.name}>
        <Link to={`/${isSoul ? 'souls' : 'companions'}/${attendee?.id}`}>{user!.display_name}</Link>
        {!!user.birthdate && <Text muted> ({getAgeInYears(user.birthdate).toString()})</Text>}
        <br />
        {!!organisation && (
          <Text muted small>
            {organisation.name}
          </Text>
        )}
      </div>
      <div css={styles.status}>
        {signup.is_first_attend && (
          <TooltipTag minimal intent={Intent.SUCCESS} label="Neu" tooltip="Erste Teilnahme" marginRight />
        )}
        <ConflictingSignups signups={signup.conflicting_signups} userName={user.display_name} />
        <PersonIconList person={attendee} />
        <br />
        <CandidateStatusText item={signup} status={status} />
      </div>
      <div css={styles.action}>
        <Tooltip content="Entfernen">
          <Button small icon={IconNames.SMALL_CROSS} onClick={remove} />
        </Tooltip>
      </div>
    </div>
  );
};

CandidateListItem.fragments = {
  candidate: gql`
    fragment CandidateListItem on Signup {
      id
      event_id
      token
      is_candidate
      is_invited
      has_accepted
      has_rejected
      is_cardholder
      notes
      priority
      is_first_attend
      conflicting_signups {
        id
        event {
          id
          name
          start
        }
      }
      emails {
        id
        status
        opened_first_at
      }
      companion {
        id
        notes
        can_blind
        can_wheelchair
        can_wheeled_walker
        can_drive
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
        user {
          id
          display_name
          birthdate
          email
          phone
          mobile
          lat
          lng
        }
      }
      soul {
        id
        notes
        buys_ticket
        needs_blind
        needs_wheelchair
        needs_wheeled_walker
        needs_drive
        needs_trainstation_fetch
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
        user {
          id
          display_name
          birthdate
          email
          phone
          mobile
          lat
          lng
        }
      }
    }
  `,
};

export default CandidateListItem;

const styles = {
  container: css`
    min-height: 55px;
    display: flex;
    align-items: center;
    &:not(:first-of-type) {
      box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
      div:first-of-type {
        box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
      }
    }
  `,
  indicator: (color: string) => css`
    width: 40px;
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    background-color: ${color};
  `,
  name: css`
    padding: 6px 11px;
    width: calc(50% - 40px);
  `,
  status: css`
    padding: 6px 11px;
    width: calc(50% - 40px);
  `,
  action: css`
    width: 40px;
  `,
};
