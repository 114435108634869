/** @jsx jsx */
import { FetchResult, gql } from '@apollo/client';
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { addDays, parseISO } from 'date-fns';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import DatePickerInput from '../../../components/DatePickerInput';
import FormGroup from '../../../components/FormGroup';
import NumberInput from '../../../components/NumberInput';
import TextInput from '../../../components/TextInput';
import {
  AllotmentCreateFormFragment,
  AllotmentEditFormFragment,
  CreateAllotmentMutation,
  UpdateAllotmentInput,
  UpdateAllotmentMutation,
  useCreateAllotmentMutation,
  useUpdateAllotmentMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
  amount: Yup.number().integer('Muss Ganzzahl sein').positive('Muss positiv sein').required('Erforderlich'),
  start: Yup.date().typeError('Muss gültiges Datum sein').required('Erforderlich'),
  end: Yup.date().typeError('Muss gültiges Datum sein').nullable(),
});

type AllotmentFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateAllotmentMutation) => void;
  onUpdated?: (data: UpdateAllotmentMutation) => void;
  onError?: () => void;
  initialValues: AllotmentCreateFormFragment | AllotmentEditFormFragment;
};

const isEditData = (
  initialValues: AllotmentCreateFormFragment | AllotmentEditFormFragment,
): initialValues is AllotmentEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateAllotmentMutation | UpdateAllotmentMutation): data is UpdateAllotmentMutation =>
  'updateAllotment' in data;

const AllotmentForm = ({ onCancel, onCreated, onUpdated, onError, initialValues }: AllotmentFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateAllotment] = useUpdateAllotmentMutation();
  const [createAllotment] = useCreateAllotmentMutation();
  const handleSubmit = useSubmit<
    AllotmentCreateFormFragment | AllotmentEditFormFragment,
    FetchResult<CreateAllotmentMutation> | FetchResult<UpdateAllotmentMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateAllotment({
            variables: {
              input: buildDiff(initialValues, values) as UpdateAllotmentInput,
            },
          })
        : createAllotment({
            variables: {
              input: {
                ...values,
              },
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, values, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Kontingent {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
              <TextInput name="name" placeholder="Name" />
            </FormGroup>
            <FormGroup label="Karten" labelInfo="(erforderlich)" name="amount">
              <NumberInput name="amount" placeholder="Karten" min={1} />
            </FormGroup>
            <FormGroup label="Start" labelInfo="(erforderlich)" name="start">
              <DatePickerInput name="start" />
            </FormGroup>
            <FormGroup label="Ende" name="end" helperText="Frei lassen für unbegrenzte Gültigkeit">
              <DatePickerInput name="end" minDate={addDays(parseISO(values.start), 1)} />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

AllotmentForm.fragments = {
  create: gql`
    fragment AllotmentCreateForm on Allotment {
      name
      amount
      start
      end
    }
  `,
  edit: gql`
    fragment AllotmentEditForm on Allotment {
      id
      name
      status
      amount
      amount_left
      start
      end
    }
  `,
};

export default AllotmentForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
