import { useEffect, useRef } from 'react';
import config from './config';

let csrfCookieRefreshPromise: Promise<Response> | null = null;

export const arrayUnique = <T>(arr: T[]) => arr.filter((elem, pos, arr) => arr.indexOf(elem) === pos);

export const assertNever = (x: never): never => {
  throw new Error('Unexpected object: ' + x);
};

export const readCookie = (name: string) => {
  var match = document.cookie.match(new RegExp('(^|;\\s*)(' + name + ')=([^;]*)'));
  return match ? decodeURIComponent(match[3]) : null;
};

const fetchNewCsrfToken = async () => {
  // Start new CSRF token request if not already running
  if (!csrfCookieRefreshPromise) {
    csrfCookieRefreshPromise = fetch(`${config.baseUrl}/sanctum/csrf-cookie`, {
      credentials: 'include',
    });
  }

  // Await running CSRF token request
  await csrfCookieRefreshPromise;

  // Once done, reset promise placeholder
  csrfCookieRefreshPromise = null;

  return readCookie('XSRF-TOKEN');
};

export const getCsrfToken = async () => {
  let csrfToken = readCookie('XSRF-TOKEN');

  // Refresh token if not existing or expired
  if (!csrfToken) {
    csrfToken = await fetchNewCsrfToken();
  }

  return csrfToken;
};

export const buildDiff = (lhs: any, rhs: any): any => {
  // If nothing changed, drop key
  if (JSON.stringify(lhs) === JSON.stringify(rhs)) return undefined;

  // We don't want to go deeper into arrays, just use new value
  if (Array.isArray(rhs)) return [...rhs];

  // Handle null specifically (because typeof null === 'object')
  if (rhs === null) return rhs;

  // If it's a primitive, just return changed value
  if (typeof rhs !== 'object') return rhs;

  // For object recursively traverse the entries
  return Object.keys(rhs).reduce((acc, key) => {
    // We always! need to retain ids
    if (key === 'id') {
      return {
        ...acc,
        id: rhs.id,
      };
    }

    const diff = buildDiff(lhs?.[key], rhs[key]);

    // Drop key if nothing changed
    return typeof diff === 'undefined'
      ? acc
      : {
          ...acc,
          [key]: diff,
        };
  }, {});
};

// From: https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/
export const needsDarkText = (hexcolor: string) => {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split('')
      .map((hex) => hex + hex)
      .join('');
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio (See: https://24ways.org/2010/calculating-color-contrast/)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128;
};

// from https://usehooks.com/usePrevious/
export const usePrevious = <T>(value: T) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const withoutBubbling = (callback?: () => void): React.MouseEventHandler<HTMLElement> => (event) => {
  event.stopPropagation();

  if (callback) {
    event.preventDefault();
    callback();
  }
};

export const classNames = (...params: string[]) => params.join(' ');

export const getSubdomain = () => {
  const parts = window.location.host.split('.');
  return parts.length >= 3 ? parts[0] : '';
};

export const openTab = (url: string) => {
  window.open(url, '_blank');
};
