/** @jsx jsx */
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import PaginatedTable from '../../../components/PaginatedTable';
import { useDonorContactsQuery } from '../../../generated/graphql';
import { dummyContacts } from '../dummy';
import DonorContactListItem from './DonorContactListItem';

type DonorContactsProps = {
  onCreate: () => void;
};

const DonorContacts = ({ onCreate }: DonorContactsProps) => {
  const { donorId } = useParams();
  const history = useHistory();
  const { data, loading, error } = useDonorContactsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: donorId || '',
    },
  });

  const contacts = data?.donor?.contacts || [];

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
      <ContentCardHeader
        leftElement={
          <Fragment>
            <span css={styles.heading}>Ansprechpartner</span>
          </Fragment>
        }
        rightElement={
          <Button
            text="Hinzufügen"
            icon={IconNames.ADD}
            intent={Intent.PRIMARY}
            css={styles.headerButton}
            onClick={onCreate}
          />
        }
      />
      <PaginatedTable
        columns={['Name', 'Email', 'Telefon']}
        interactive
        loading={loading}
        hasError={!!error}
        empty={!contacts.length}
      >
        {(loading ? dummyContacts : contacts || []).map((contact) => (
          <DonorContactListItem
            key={contact.id}
            item={contact}
            skeleton={loading}
            onClick={() => !loading && history.push(`/contacts/${contact.id}`)}
          />
        ))}
      </PaginatedTable>
    </ContentCard>
  );
};

export default DonorContacts;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
