import { Intent } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { EmailListItemFragment, EmailStatus } from '../../../generated/graphql';
import { formatDayAndTime } from '../../../helpers/dateTimeUtils';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import EmailOverlay from './EmailOverlay';

type StatusMap = Record<EmailStatus, { label: string; icon: IconName; intent?: Intent }>;

const statusMap: StatusMap = {
  [EmailStatus.Scheduled]: {
    label: 'Geplant',
    icon: IconNames.CALENDAR,
  },
  [EmailStatus.Queued]: {
    label: 'In Warteschlange',
    icon: IconNames.TIME,
  },
  [EmailStatus.Delayed]: {
    label: 'In Zustellung...',
    icon: IconNames.TIME,
  },
  [EmailStatus.Sent]: {
    label: 'Versandt',
    icon: IconNames.ENVELOPE,
    intent: Intent.SUCCESS,
  },
  [EmailStatus.Opened]: {
    label: 'Geöffnet',
    icon: IconNames.EYE_ON,
    intent: Intent.SUCCESS,
  },
  [EmailStatus.Rejected]: {
    label: 'Abgewiesen: ',
    icon: IconNames.HAND,
    intent: Intent.DANGER,
  },
  [EmailStatus.MarkedSpam]: {
    label: 'Als SPAM markiert!',
    icon: IconNames.THUMBS_DOWN,
    intent: Intent.DANGER,
  },
  [EmailStatus.HardBounced]: {
    label: 'Zustellung gescheitert!',
    icon: IconNames.ERROR,
    intent: Intent.DANGER,
  },
  [EmailStatus.SoftBounced]: {
    label: 'Zustellung gescheitert!',
    icon: IconNames.ERROR,
    intent: Intent.DANGER,
  },
  [EmailStatus.Invalid]: {
    label: 'Unbekannter Fehler',
    icon: IconNames.WARNING_SIGN,
    intent: Intent.WARNING,
  },
  [EmailStatus.Unknown]: {
    label: 'Unbekannter Fehler',
    icon: IconNames.WARNING_SIGN,
    intent: Intent.WARNING,
  },
};

export type EmailListProps = {
  item: EmailListItemFragment;
  skeleton?: boolean;
};

const EmailListItem = ({ item, skeleton }: EmailListProps) => {
  const [isEmailOverlayOpen, setIsEmailOverlayOpen] = useState(false);

  return (
    <tr onClick={() => !skeleton && setIsEmailOverlayOpen(true)}>
      <EmailOverlay id={item.id} isOpen={isEmailOverlayOpen} onClose={() => setIsEmailOverlayOpen(false)} />
      <TableCell>
        <TooltipIcon
          label={statusMap[item.status].label}
          icon={statusMap[item.status].icon}
          intent={statusMap[item.status].intent}
          skeleton={skeleton}
          marginRight
        />
      </TableCell>
      <TableCell>
        <Text skeleton={skeleton}>{item.subject}</Text>
      </TableCell>
      <TableCell>
        {item.sent_at ? (
          <Text skeleton={skeleton}>{formatDayAndTime(item.sent_at)}</Text>
        ) : (
          <Text muted skeleton={skeleton}>
            -
          </Text>
        )}
      </TableCell>
    </tr>
  );
};

EmailListItem.fragments = {
  emails: gql`
    fragment EmailListItem on Email {
      id
      status
      subject
      sent_at
    }
  `,
};

export default EmailListItem;
