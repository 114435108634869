import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useBranchDetailLazyQuery } from '../../../generated/graphql';
import { getPersisted } from '../../../helpers/constants';
import SettingsForm from './SettingsForm';

const SettingsEditOverlay = (overlayProps: IOverlayProps) => {
  const [loadSettings, { data, called, loading, error }] = useBranchDetailLazyQuery({
    variables: {
      branchId: getPersisted('branch_id'),
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadSettings();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data?.branch) return <ErrorCard />;

    // API Key is not editable
    const { api_key, ...initialValues } = data.branch;

    return (
      <SettingsForm
        onCancel={() => overlayProps.onClose?.()}
        onSuccess={() => {
          loadSettings();
          overlayProps.onClose?.();
        }}
        onError={() => console.log('Error')}
        initialValues={initialValues}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default SettingsEditOverlay;
