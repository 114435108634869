/** @jsx jsx */
import { Alert, Button, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import AppToaster from '../../../AppToaster';
import {
  EventDetailPageFragment,
  useDeleteEventMutation,
  EventStatus,
  usePublishEventMutation,
} from '../../../generated/graphql';
import { isInFuture } from '../../../helpers/dateTimeUtils';

type EventDetailMenuProps = {
  event: EventDetailPageFragment;
  onEdit: () => void;
  onClone: () => void;
  onCancel: () => void;
};

const EventDetailMenu = ({ event, onEdit, onClone, onCancel }: EventDetailMenuProps) => {
  const { id } = event;
  const [isForceDeleteAlertOpen, setIsForceDeleteAlertOpen] = useState(false);
  const [isPublishAlertOpen, setIsPublishAlertOpen] = useState(false);
  const [deleteEvent, { loading: deleting }] = useDeleteEventMutation({
    variables: {
      id,
    },
    onCompleted: () => AppToaster.success(`${event.name}  wurde in den Papierkorb gelegt`),
  });
  const [publishEvent, { loading: publishing }] = usePublishEventMutation({
    variables: {
      id,
    },
    onCompleted: () => AppToaster.success(`${event.name}  wurde veröffentlicht!`),
  });

  return (
    <Fragment>
      <Alert
        isOpen={isForceDeleteAlertOpen}
        cancelButtonText="Abbrechen"
        confirmButtonText="Entfernen"
        icon={IconNames.DELETE}
        intent={Intent.DANGER}
        onClose={() => setIsForceDeleteAlertOpen(false)}
        onConfirm={deleteEvent as any}
      >
        <p>Soll diese Veranstaltung wirklich undwiderruflich entfernt werden?</p>
      </Alert>
      <Alert
        isOpen={isPublishAlertOpen}
        cancelButtonText="Abbrechen"
        confirmButtonText="Veröffentlichen"
        icon={IconNames.GLOBE_NETWORK}
        intent={Intent.SUCCESS}
        onClose={() => setIsPublishAlertOpen(false)}
        onConfirm={publishEvent as any}
      >
        <p>Soll diese Veranstaltung wirklich veröffentlicht werden?</p>
        <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
      </Alert>
      <Button text="Bearbeiten" icon={IconNames.EDIT} css={styles.headerButton} onClick={onEdit} />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            {(event.status === EventStatus.Draft || event.status === EventStatus.Ready) && (
              <MenuItem
                text="Veröffentlichen"
                icon={IconNames.GLOBE_NETWORK}
                intent={Intent.SUCCESS}
                onClick={() => setIsPublishAlertOpen(true)}
                disabled={event.status !== EventStatus.Ready}
              />
            )}
            <MenuItem text="Duplizieren" icon={IconNames.DUPLICATE} onClick={onClone} />
            {event.status === EventStatus.Closed && isInFuture(event.start) ? (
              <MenuItem text="Absagen" icon={IconNames.BAN_CIRCLE} intent={Intent.DANGER} onClick={onCancel} />
            ) : (
              <MenuItem
                text="Entfernen"
                icon={IconNames.DELETE}
                intent={Intent.DANGER}
                onClick={() => setIsForceDeleteAlertOpen(true)}
              />
            )}
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} css={styles.headerButton} loading={deleting || publishing} />
      </Popover>
    </Fragment>
  );
};

export default EventDetailMenu;

const styles = {
  statusTag: css`
    margin-left: 10px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
};
