/** @jsx jsx */
import { FetchResult, gql } from '@apollo/client';
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import {
  ContactCreateFormFragment,
  ContactEditFormFragment,
  CreateContactInput,
  CreateContactMutation,
  UpdateContactInput,
  UpdateContactMutation,
  useCreateContactMutation,
  useUpdateContactMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';

const validationSchema = Yup.object({
  user: Yup.object({
    first_name: Yup.string().required('Erforderlich'),
    last_name: Yup.string().required('Erforderlich'),
    email: Yup.string().email('Keine gültige Email').required('Erforderlich'),
  }),
});

type ContactFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateContactMutation) => void;
  onUpdated?: (data: UpdateContactMutation) => void;
  onError?: () => void;
  initialValues: ContactCreateFormFragment | ContactEditFormFragment;
};

const isEditData = (
  initialValues: ContactCreateFormFragment | ContactEditFormFragment,
): initialValues is ContactEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateContactMutation | UpdateContactMutation): data is UpdateContactMutation =>
  'updateContact' in data;

const buildUpdateInput = (
  initialValues: ContactEditFormFragment,
  values: ContactEditFormFragment,
): UpdateContactInput => {
  const { user, ...otherValues }: ContactEditFormFragment = buildDiff(initialValues, values);

  return {
    ...otherValues,
    ...(!!user && {
      user: {
        update: user,
      },
    }),
  };
};

const buildCreateInput = (values: ContactCreateFormFragment): CreateContactInput => ({
  ...values,
  user: {
    create: values.user,
  },
});

const ContactForm = ({ onCancel, onCreated, onUpdated, onError, initialValues }: ContactFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateContact] = useUpdateContactMutation();
  const [createContact] = useCreateContactMutation();
  const handleSubmit = useSubmit<
    ContactCreateFormFragment | ContactEditFormFragment,
    FetchResult<CreateContactMutation> | FetchResult<UpdateContactMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateContact({
            variables: {
              input: buildUpdateInput(initialValues as ContactEditFormFragment, values),
            },
          })
        : createContact({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Ansprechpartner {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Vorname" labelInfo="(erforderlich)" name={'user.first_name'}>
              <TextInput name={'user.first_name'} placeholder="Vorname" />
            </FormGroup>
            <FormGroup label="Nachname" labelInfo="(erforderlich)" name={'user.last_name'}>
              <TextInput name={'user.last_name'} placeholder="Nachname" />
            </FormGroup>
            <FormGroup label="Email" labelInfo="(erforderlich)" name={'user.email'}>
              <TextInput name={'user.email'} type="email" placeholder="Email" />
            </FormGroup>
            <FormGroup label="Telefon" name={'user.phone'}>
              <TextInput name={'user.phone'} type="text" placeholder="Telefon" />
            </FormGroup>
            <FormGroup label="Mobil" name={'user.mobile'}>
              <TextInput name={'user.mobile'} type="text" placeholder="Mobil" />
            </FormGroup>
            <FormGroup label="Adresse" name={'user.street'}>
              <TextInput name={'user.street'} type="text" placeholder="Straße" />
            </FormGroup>
            <FormGroup name={'user.postal_code'}>
              <TextInput name={'user.postal_code'} type="text" placeholder="PLZ" />
            </FormGroup>
            <FormGroup name={'user.city'}>
              <TextInput name={'user.city'} type="text" placeholder="Ort" />
            </FormGroup>

            <Divider css={styles.formDivider} />
            <FormGroup name="notes">
              <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

ContactForm.fragments = {
  create: gql`
    fragment ContactCreateForm on Contact {
      notes
      donor_id
      user {
        first_name
        last_name
        email
        phone
        mobile
        street
        city
        postal_code
      }
    }
  `,
  edit: gql`
    fragment ContactEditForm on Contact {
      id
      notes
      user {
        id
        first_name
        last_name
        email
        phone
        mobile
        street
        city
        postal_code
      }
    }
  `,
};

export default ContactForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
