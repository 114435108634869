/** @jsx jsx */
import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { EventDetailPageFragment } from '../../../generated/graphql';
import AddSignupsOverlay from './AddSignupsOverlay';
import EmailUsersOverlay from '../../../components/EmailUsersOverlay';
import AppToaster from '../../../AppToaster';

type SignupsMenuProps = {
  event: EventDetailPageFragment;
};

const SignupsMenu = ({ event }: SignupsMenuProps) => {
  const [isAddSignupsOverlayOpen, setIsAddSignupsOverlayOpen] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogAlertOpen] = useState(false);

  const onEmailsSent = () => {
    AppToaster.success('Emails wurden versandt!');
    setIsEmailDialogAlertOpen(false);
  };

  const users = event.prospects
    .filter((prospect) => (prospect.soul ? prospect.soul.user.email : prospect.companion!.user.email))
    .map((prospect) =>
      prospect.soul
        ? {
            id: prospect.soul.user.id,
            label: prospect.soul.user.display_name,
          }
        : {
            id: prospect.companion!.user.id,
            label: prospect.companion!.user.display_name,
          },
    );

  return (
    <Fragment>
      <AddSignupsOverlay
        eventId={event.id}
        onClose={() => setIsAddSignupsOverlayOpen(false)}
        isOpen={isAddSignupsOverlayOpen}
      />
      <EmailUsersOverlay
        isOpen={isEmailDialogOpen}
        onSuccess={onEmailsSent}
        onClose={() => setIsEmailDialogAlertOpen(false)}
        users={users}
      />
      <Button
        text="Hinzufügen"
        icon={IconNames.ADD}
        css={styles.headerButton}
        onClick={() => setIsAddSignupsOverlayOpen(true)}
      />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem
              text="Nachricht an alle Interessenten"
              icon={IconNames.ENVELOPE}
              onClick={() => setIsEmailDialogAlertOpen(true)}
            />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} css={styles.headerButton} />
      </Popover>
    </Fragment>
  );
};

export default SignupsMenu;

const styles = {
  statusTag: css`
    margin-left: 10px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
};
