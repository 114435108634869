/** @jsx jsx */
import { FetchResult, gql } from '@apollo/client';
import { Button, Divider, Elevation, Intent, Colors } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import TextInput from '../../../components/TextInput';
import ToggleInput from '../../../components/ToggleInput';
import {
  CreateLocationMutation,
  LocationCreateFormFragment,
  LocationEditFormFragment,
  UpdateLocationInput,
  UpdateLocationMutation,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  CreateLocationInput,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';
import MultiSelectSearchInput from '../../../components/MultiSelectSearchInput';
import { DonorHit } from '../../../types';
import { Highlight } from 'react-instantsearch-dom';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
  street: Yup.string().required('Erforderlich'),
  postal_code: Yup.string().required('Erforderlich'),
  city: Yup.string().required('Erforderlich'),
  meeting_point: Yup.string().required('Erforderlich'),
  public_transport: Yup.string().required('Erforderlich'),
  accessible: Yup.boolean().required('Erforderlich'),
});

type LocationFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateLocationMutation) => void;
  onUpdated?: (data: UpdateLocationMutation) => void;
  onError?: () => void;
  initialValues: LocationCreateFormFragment | LocationEditFormFragment;
};

const isEditData = (
  initialValues: LocationCreateFormFragment | LocationEditFormFragment,
): initialValues is LocationEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateLocationMutation | UpdateLocationMutation): data is UpdateLocationMutation =>
  'updateLocation' in data;

const buildUpdateInput = (
  initialValues: LocationEditFormFragment,
  values: LocationEditFormFragment,
): UpdateLocationInput => {
  const { donors, ...otherValues }: LocationEditFormFragment = buildDiff(initialValues, values);

  return {
    ...otherValues,
    ...(!!donors && {
      donors: {
        sync: donors.map((donor) => donor.id),
      },
    }),
  };
};

const buildCreateInput = (values: LocationCreateFormFragment): CreateLocationInput => ({
  ...values,
  donors: {
    sync: values.donors.map((donor) => donor.id),
  },
});

const LocationForm = ({ onCancel, onCreated, onUpdated, onError, initialValues }: LocationFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateLocation] = useUpdateLocationMutation({ refetchQueries: ['LocationDonors'] });
  const [createLocation] = useCreateLocationMutation();
  const handleSubmit = useSubmit<
    LocationCreateFormFragment | LocationEditFormFragment,
    FetchResult<CreateLocationMutation> | FetchResult<UpdateLocationMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateLocation({
            variables: {
              input: buildUpdateInput(initialValues as LocationEditFormFragment, values),
            },
          })
        : createLocation({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Lokation {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
              <TextInput name="name" placeholder="Name" />
            </FormGroup>
            <FormGroup label="Adresse" name="street" labelInfo="(erforderlich)">
              <TextInput name="street" type="text" placeholder="Straße" />
            </FormGroup>
            <FormGroup name="postal_code">
              <TextInput name="postal_code" type="text" placeholder="PLZ" />
            </FormGroup>
            <FormGroup name="city">
              <TextInput name="city" type="text" placeholder="Ort" />
            </FormGroup>

            <Divider css={styles.formDivider} />

            <FormGroup name="accessible">
              <ToggleInput name="accessible" label="Barrierefrei" />
            </FormGroup>
            <FormGroup label="Treffpunkt" labelInfo="(erforderlich)" name="meeting_point">
              <TextInput name="meeting_point" placeholder="Treffpunkt" />
            </FormGroup>
            <FormGroup label="Öff. Nahverkehr" labelInfo="(erforderlich)" name="public_transport">
              <TextInput name="public_transport" placeholder="Öff. Nahverkehr" />
            </FormGroup>
            <Divider css={styles.formDivider} />
            <FormGroup label="Spender" name="donors" labelInfo="(erforderlich)">
              <MultiSelectSearchInput<DonorHit>
                name="donors"
                index="donors"
                labelPath="name"
                renderItem={(hit) => <Highlight hit={hit} attribute="name" css={styles.highlight} />}
              />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

LocationForm.fragments = {
  // This is only used for Formik initialValues
  create: gql`
    fragment LocationCreateForm on Location {
      name
      street
      city
      postal_code
      meeting_point
      public_transport
      accessible
      donors {
        id
      }
    }
  `,
  edit: gql`
    fragment LocationEditForm on Location {
      id
      name
      street
      city
      postal_code
      meeting_point
      public_transport
      accessible
      donors {
        id
        name
      }
    }
  `,
};

export default LocationForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
  highlight: css`
    em {
      font-style: normal;
      color: ${Colors.BLUE1};
    }
  `,
};
