import { AppEnv, ENV } from './constants';

const envConfig = {
  [AppEnv.Dev]: {
    baseUrl: 'http://api.culture-key-ng.test',
    algolia: {
      appId: '36C42DQ95B',
      appSecret: 'c854c72ecdc5f302dd798e56ec478709',
    },
  },
  [AppEnv.Stage]: {
    baseUrl: 'https://staging-api.wulffinteractive.com',
    algolia: {
      appId: '2PU5TKEU32',
      appSecret: '28efc5c3ad9af17bc045d3de3a19efe8',
    },
  },
  [AppEnv.Prod]: {
    baseUrl: 'https://api.wulffinteractive.com',
    algolia: {
      appId: '???',
      appSecret: '???',
    },
  },
};

const config = envConfig[ENV];

export default config;
