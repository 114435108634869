/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Fragment, useState, useCallback } from 'react';
import OverviewStats from '../components/OverviewStats';
import StatsSettings, { initialState } from '../components/StatsSettings';
import StatsDownload from '../components/StatsDownload';
import TargetGroupStats from '../components/TargetGroupStats';
import CategoryStats from '../components/CategoryStats';

const StatsDetailPage = () => {
  const [settings, setSettings] = useState({ range: initialState.range, rangeType: initialState.rangeType });
  const onChange = useCallback((settings) => setSettings(settings), [setSettings]);

  return (
    <Fragment>
      <div css={styles.topRow}>
        <StatsSettings onChange={onChange} />
        <OverviewStats range={settings.range} rangeType={settings.rangeType} />
        <StatsDownload range={settings.range} rangeType={settings.rangeType} />
      </div>
      <div css={styles.bottomRow}>
        <TargetGroupStats range={settings.range} rangeType={settings.rangeType} />
        <CategoryStats range={settings.range} rangeType={settings.rangeType} />
      </div>
    </Fragment>
  );
};

export default StatsDetailPage;

const styles = {
  topRow: css`
    display: flex;
  `,
  bottomRow: css`
    display: flex;
    align-items: flex-start;
  `,
};
