/** @jsx jsx */
import { Classes, Divider } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

interface PropertyListProps {
  children: React.ReactNode;
}

interface PropertyListHeaderProps {
  children: React.ReactNode;
}

interface PropertyListItemValueProps {
  label: string;
  value: string;
  showValueDisabled?: boolean;
  runningText?: boolean;
  children?: React.ReactNode;
}

interface PropertyListItemChildrenProps {
  label: string;
  value?: string;
  showValueDisabled?: boolean;
  runningText?: boolean;
  children: React.ReactNode;
}

export const PropertyList = ({ children }: PropertyListProps) => <div css={styles.container}>{children}</div>;

export const PropertyListDivider = () => <Divider css={styles.divider} />;

export const PropertyListHeader = ({ children }: PropertyListHeaderProps) => <div css={styles.header}>{children}</div>;

export const PropertyListItem = ({
  label,
  value,
  showValueDisabled,
  runningText,
  children,
}: PropertyListItemValueProps | PropertyListItemChildrenProps) => {
  const classes = [];

  if (showValueDisabled) {
    classes.push(Classes.TEXT_DISABLED);
  }
  if (runningText) {
    classes.push(Classes.RUNNING_TEXT);
  }

  return (
    <div css={styles.itemContainer}>
      <div css={styles.itemLabel} className={Classes.TEXT_MUTED}>
        {label}
      </div>
      <div css={styles.itemValue} className={classes.join(' ')}>
        {children || value}
      </div>
    </div>
  );
};

const styles = {
  container: css`
    flex: 1;
  `,
  divider: css`
    margin: -4px 16px;
  `,
  header: css`
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 10px;
  `,
  itemContainer: css`
    display: flex;
    padding: 6px 0;
  `,
  itemLabel: css`
    flex: 0 0 30%;
    line-height: 20px;
  `,
  itemValue: css`
    margin-left: 10px;
  `,
};
