/** @jsx jsx */
import { useApolloClient, gql } from '@apollo/client';
import { Button, Colors, Navbar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import AppToaster from '../AppToaster';
import { useLogoutMutation } from '../generated/graphql';
import { setPersisted, unsetPersisted } from '../helpers/constants';
import SearchBar from '../modules/search/components/SearchBar';

const TopNavBar = () => {
  const history = useHistory();
  const client = useApolloClient();
  const [logout, { loading }] = useLogoutMutation({
    onCompleted: () => {
      setPersisted('isLoggedIn', false);
      unsetPersisted('branch_id');
      unsetPersisted('useLegacyNewsletter');
      unsetPersisted('is_superadmin');
      unsetPersisted('permissions');
      AppToaster.success('Du wurdest abgemeldet');
      history.push('/auth/login', { logout: true });

      // The store reset works in two stages:
      // Stage 1: reset authentication flag (so LoginPage does not immediately redirect us back to /events)
      // Stage 2: reset the store with a timeout (otherwise the currently loaded query will reload
      // which will fail as user is no longer authenticated)
      client.writeQuery({
        query: gql`
          query {
            isAuthenticated
          }
        `,
        data: {
          isAuthenticated: false,
        },
      });
      setImmediate(() => client.resetStore());
    },
  });

  return (
    <Navbar fixedToTop css={styles.navBar}>
      <SearchBar />
      <div css={styles.navBarSide}>
        <Button text="Abmelden" minimal icon={IconNames.LOG_OUT} loading={loading} onClick={() => logout()} />
      </div>
    </Navbar>
  );
};

export default TopNavBar;

const styles = {
  navBar: css`
    box-shadow: none;
    background-color: ${Colors.LIGHT_GRAY5};
    max-width: 1070px;
    left: auto !important;
    right: auto !important;
    width: calc(100vw - 210px);
    height: 50px;
    padding: 5px 10px 0;
    margin-left: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  navBarSide: css`
    display: flex;
  `,
};
