/** @jsx jsx */
import { Card, Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { gql } from '@apollo/client';
import { AttendeeTeamFragment } from '../../../generated/graphql';
import AttendeeListItem from './AttendeeListItem';

type AttendeeTeamProps = {
  item: AttendeeTeamFragment;
};

const AttendeeTeam = ({ item: companion }: AttendeeTeamProps) => (
  <Card css={styles.spacedCard} elevation={Elevation.TWO}>
    <AttendeeListItem item={companion} />
    {companion.group.map((soul) => (
      <AttendeeListItem key={soul.id} item={soul} />
    ))}
  </Card>
);

AttendeeTeam.fragments = {
  teams: gql`
    fragment AttendeeTeam on Signup {
      ...AttendeeListItem
      group {
        ...AttendeeListItem
      }
    }
    ${AttendeeListItem.fragments.attendees}
  `,
};

export default AttendeeTeam;

const styles = {
  spacedCard: css`
    overflow: hidden;
    padding: 0;
    margin-bottom: 12px;
  `,
};
