/** @jsx jsx */
import { Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EventList from '../components/EventList';
import EventListFilter from '../components/EventListFilter';

const EventArchiveListPage = () => (
  <ContentCard elevation={Elevation.TWO} css={styles.list}>
    <ContentCardHeader leftElement={<EventListFilter archive />} />
    <EventList archive />
  </ContentCard>
);

export default EventArchiveListPage;

const styles = {
  headerButton: css`
    margin-left: 10px;
  `,
  list: css`
    margin-bottom: 20px;
  `,
};
