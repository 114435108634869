/** @jsx jsx */
import { Classes } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import { withoutBubbling } from '../helpers/utils';

type ResourceLinkProps = {
  path: string;
  skeleton?: boolean;
  text: string;
  last?: boolean;
  muted?: boolean;
};

const ResourceLink = ({ path, text, skeleton, last, muted }: ResourceLinkProps) => {
  const history = useHistory();
  const classes = [];

  if (skeleton) {
    classes.push(Classes.SKELETON);
  }

  if (!path || muted) {
    classes.push(Classes.TEXT_MUTED);
  }

  if (!path) {
    return (
      <span css={!last && styles.resourceLink} className={classes.join(' ')}>
        {text}
      </span>
    );
  }

  return (
    <a
      href={path}
      onClick={withoutBubbling(() => history.push(path))}
      className={classes.join(' ')}
      css={!last && styles.resourceLink}
    >
      {text}
    </a>
  );
};

export default ResourceLink;

const styles = {
  resourceLink: css`
    margin-right: 5px;
  `,
};
