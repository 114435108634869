/** @jsx jsx */
import { HTMLTable } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { ProspectGroupListItemFragment } from '../../../generated/graphql';
import GroupMemberListItem from './GroupMemberListItem';

type GroupMemberListProps = {
  signup: ProspectGroupListItemFragment;
};

const GroupMemberList = ({ signup }: GroupMemberListProps) => (
  <HTMLTable condensed css={styles.table}>
    <colgroup>
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: 60 }} />
    </colgroup>
    <thead>
      <tr>
        <TableHeaderCell>Name</TableHeaderCell>
        <TableHeaderCell>Info</TableHeaderCell>
        <TableHeaderCell>Priorität</TableHeaderCell>
      </tr>
    </thead>
    <tbody>
      <GroupMemberListItem signup={signup} />
      {signup.group.map((soulSignup) => (
        <GroupMemberListItem key={soulSignup.id} signup={soulSignup} />
      ))}
    </tbody>
  </HTMLTable>
);

export default GroupMemberList;

const styles = {
  table: css`
    width: 100%;
  `,
};
