/** @jsx jsx */
import { FetchResult, gql } from '@apollo/client';
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import TextInput from '../../../components/TextInput';
import {
  CreateEmployeeInput,
  CreateEmployeeMutation,
  EmployeeCreateFormFragment,
  EmployeeEditFormFragment,
  UpdateEmployeeInput,
  UpdateEmployeeMutation,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';
import RoleMultiSelect from './RoleMultiSelect';

const validationSchema = Yup.object({
  position: Yup.string().required('Erforderlich'),
  user: Yup.object({
    first_name: Yup.string().required('Erforderlich'),
    last_name: Yup.string().required('Erforderlich'),
    email: Yup.string().required('Erforderlich').email('Keine gültige Email'),
  }),
});

type EmployeeFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateEmployeeMutation) => void;
  onUpdated?: (data: UpdateEmployeeMutation) => void;
  onError?: () => void;
  initialValues: EmployeeCreateFormFragment | EmployeeEditFormFragment;
};

const isEditData = (
  initialValues: EmployeeCreateFormFragment | EmployeeEditFormFragment,
): initialValues is EmployeeEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateEmployeeMutation | UpdateEmployeeMutation): data is UpdateEmployeeMutation =>
  'updateEmployee' in data;

const buildUpdateInput = (
  initialValues: EmployeeEditFormFragment,
  values: EmployeeEditFormFragment,
): UpdateEmployeeInput => {
  const { user, ...otherValues }: EmployeeEditFormFragment = buildDiff(initialValues, values);

  return {
    ...otherValues,
    ...(!!user && {
      user: {
        update: {
          ...user,
          ...(!!user.roles && {
            roles: {
              sync: user.roles.map((role) => role.id),
            },
          }),
        },
      },
    }),
  };
};

const buildCreateInput = (values: EmployeeCreateFormFragment): CreateEmployeeInput => ({
  ...values,
  user: {
    create: {
      ...values.user,
      roles: {
        sync: values.user.roles.map((role) => role.id),
      },
    },
  },
});

const EmployeeForm = ({ onCancel, onCreated, onUpdated, onError, initialValues }: EmployeeFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [createEmployee] = useCreateEmployeeMutation();
  const handleSubmit = useSubmit<
    EmployeeCreateFormFragment | EmployeeEditFormFragment,
    FetchResult<CreateEmployeeMutation> | FetchResult<UpdateEmployeeMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateEmployee({
            variables: {
              input: buildUpdateInput(initialValues as EmployeeEditFormFragment, values),
            },
          })
        : createEmployee({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Mitarbeiter {isEdit ? 'bearbeiten' : 'hinzufügen'}</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Vorname" labelInfo="(erforderlich)" name="user.first_name">
              <TextInput name="user.first_name" placeholder="Vorname" />
            </FormGroup>
            <FormGroup label="Nachname" labelInfo="(erforderlich)" name="user.last_name">
              <TextInput name="user.last_name" placeholder="Nachname" />
            </FormGroup>
            <FormGroup label="Email" name="user.email" labelInfo="(erforderlich)">
              <TextInput name="user.email" type="email" placeholder="Email" />
            </FormGroup>
            <FormGroup label="Telefon" name="user.phone">
              <TextInput name="user.phone" type="text" placeholder="Telefon" />
            </FormGroup>
            <FormGroup label="Mobil" name="user.mobile">
              <TextInput name="user.mobile" type="text" placeholder="Mobil" />
            </FormGroup>
            <FormGroup label="Adresse" name="user.street">
              <TextInput name="user.street" type="text" placeholder="Straße" />
            </FormGroup>
            <FormGroup name="user.postal_code">
              <TextInput name="user.postal_code" type="text" placeholder="PLZ" />
            </FormGroup>
            <FormGroup name="user.city">
              <TextInput name="user.city" type="text" placeholder="Ort" />
            </FormGroup>

            <Divider css={styles.formDivider} />
            <FormGroup label="Position" labelInfo="(erforderlich)" name="position">
              <TextInput name="position" placeholder="Position" />
            </FormGroup>
            <FormGroup label="Rollen" name="user.roles">
              <RoleMultiSelect name="user.roles" />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

EmployeeForm.fragments = {
  create: gql`
    fragment EmployeeCreateForm on Employee {
      position
      user {
        status
        first_name
        last_name
        email
        phone
        mobile
        street
        city
        postal_code
        roles {
          id
        }
      }
    }
  `,
  edit: gql`
    fragment EmployeeEditForm on Employee {
      id
      position
      user {
        id
        status
        first_name
        last_name
        email
        phone
        mobile
        street
        city
        postal_code
        roles {
          id
          name
        }
      }
    }
  `,
};

export default EmployeeForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
  formDivider: css`
    margin: 25px -20px;
  `,
};
