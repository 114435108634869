/** @jsx jsx */
import { gql } from '@apollo/client';
import { Classes, Elevation, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EmailLink from '../../../components/EmailLink';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import TrashTag from '../../../components/TrashTag';
import { useContactDetailQuery } from '../../../generated/graphql';
import ContactDetailMenu from './ContactDetailMenu';

type ContactInfoProps = {
  onEdit: () => void;
};

const ContactInfo = ({ onEdit }: ContactInfoProps) => {
  const { contactId } = useParams();
  const { data, loading, error } = useContactDetailQuery({
    variables: {
      id: contactId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.contact) return <NotFoundCard resource="Ansprechpartner" />;

  const { contact } = data;
  const { user } = contact;

  const address: string | null =
    user.street && user.postal_code && user.city ? `${user.street}, ${user.postal_code} ${user.city}` : null;

  return (
    <Fragment>
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{user.display_name}</span>
              <Tag minimal css={styles.personTag}>
                Ansprechpartner
              </Tag>
              {!!contact.deleted_at && <TrashTag />}
            </Fragment>
          }
          rightElement={<ContactDetailMenu contact={contact} onEdit={onEdit} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Person</PropertyListHeader>
            <PropertyListItem label="Email">
              <EmailLink email={user.email} />
            </PropertyListItem>

            <PropertyListItem label="Telefon" value={user.phone || '—'} showValueDisabled={!user.phone} />
            <PropertyListItem label="Mobil" value={user.mobile || '—'} showValueDisabled={!user.mobile} />
            <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
          </PropertyList>
          <PropertyListDivider />
          <PropertyList>
            <PropertyListHeader>Ansprechpartner Info</PropertyListHeader>
            <PropertyListItem label="Interne Notizen">
              {contact.notes ? (
                <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{contact.notes}</div>
              ) : (
                <Text disabled>—</Text>
              )}
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
    </Fragment>
  );
};

ContactInfo.fragments = {
  contact: gql`
    fragment ContactInfo on Contact {
      id
      notes
      deleted_at
      user {
        id
        display_name
        first_name
        last_name
        email
        phone
        mobile
        street
        city
        postal_code
      }
    }
  `,
};

export default ContactInfo;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  overlayContent: css`
    left: calc(50vw - 225px);
    margin: 10vh 0;
    width: 450px;
    max-height: 80vh;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
