/** @jsx jsx */
import { FetchResult } from '@apollo/client';
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { EmailUsersInput, EmailUsersMutation, useEmailUsersMutation } from '../generated/graphql';
import { getLaravelValidationErrors } from '../helpers/graphql';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from './ContentCard';
import FormGroup from './FormGroup';
import RichTextInput, { decorator, entityToHTML, htmlToEntity } from './RichTextInput';
import TextInput from './TextInput';
import GeneralMultiSelect, { GeneralMultiSelectItem } from './GeneralMultiSelect';

const validationSchema = Yup.object({
  subject: Yup.string().required('Erforderlich'),
});

type EmailUsersFormProps = {
  onCancel?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  users: GeneralMultiSelectItem[];
  initialValues: EmailUsersInput;
};

type EmailUsersFormValues = Omit<EmailUsersInput, 'body' | 'users'> & {
  body: EditorState;
  users: GeneralMultiSelectItem[];
};

const EmailUsersForm = ({ users, onCancel, onSuccess, onError, initialValues }: EmailUsersFormProps) => {
  const [emailUsers] = useEmailUsersMutation();
  const handleSubmit = useSubmit<EmailUsersFormValues, FetchResult<EmailUsersMutation>>({
    mutate: (values) =>
      emailUsers({
        variables: {
          input: {
            ...values,
            users: values.users.map((user) => user.id),
            body: convertToHTML({ entityToHTML })(values.body.getCurrentContent()),
          },
        },
      }),
    onCompleted: () => onSuccess?.(),
    onError,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik
      initialValues={{
        ...initialValues,
        body: EditorState.createWithContent(convertFromHTML({ htmlToEntity })(initialValues.body), decorator),
        users: users.filter((user) => initialValues.users.includes(user.id)),
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Email verfassen</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Empfänger" name="users">
              <GeneralMultiSelect name="users" items={users} />
            </FormGroup>
            <FormGroup label="Betreff" labelInfo="(erforderlich)" name="subject">
              <TextInput name="subject" placeholder="Betreff" />
            </FormGroup>
            <FormGroup
              label="Nachricht"
              labelInfo="(erforderlich)"
              name="body"
              helperText="Ohne Anrede oder Grußformeln"
            >
              <RichTextInput name="body" />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text="Senden"
                  loading={isSubmitting}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

export default EmailUsersForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
};
