import { Callout, Card, Intent } from '@blueprintjs/core';
import React from 'react';

const NotFoundPage = () => (
  <div style={styles.container}>
    <Card>
      <Callout intent={Intent.DANGER}>Seite nicht gefunden (404)</Callout>
    </Card>
  </div>
);

export default NotFoundPage;

const styles = {
  container: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
