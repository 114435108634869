/** @jsx jsx */
import { Button, Intent, Menu, MenuItem, Popover, Position, Alert } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { EventDetailPageFragment, useInviteCandidatesMutation } from '../../../generated/graphql';
import EmailUsersOverlay from '../../../components/EmailUsersOverlay';
import AppToaster from '../../../AppToaster';
import { useNavigate } from '../../../hooks/useNavigate';

type AttendeeListMenuProps = {
  event: EventDetailPageFragment;
};

const AttendeeListMenu = ({ event }: AttendeeListMenuProps) => {
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [isInviteAlertOpen, setIsInviteAlertOpen] = useState(false);
  const navigateToMatching = useNavigate(`/events/${event.id}/matching`);
  const [inviteCandidates, { loading: inviting }] = useInviteCandidatesMutation({
    variables: {
      id: event.id,
    },
    onCompleted: () => AppToaster.success('Alle Teilnehmer wurden eingeladen!'),
  });

  const onEmailsSent = () => {
    AppToaster.success('Emails wurden versandt!');
    setIsEmailDialogOpen(false);
  };

  if (!event.candidates_chosen) {
    return (
      <Button
        text="Erstellen"
        intent={Intent.PRIMARY}
        icon={IconNames.SAVED}
        css={styles.headerButton}
        onClick={navigateToMatching}
      />
    );
  }

  const users = event.candidates
    .filter((candidate) => (candidate.soul ? candidate.soul.user.email : candidate.companion!.user.email))
    .map((candidate) =>
      candidate.soul
        ? {
            id: candidate.soul.user.id,
            label: candidate.soul.user.display_name,
          }
        : {
            id: candidate.companion!.user.id,
            label: candidate.companion!.user.display_name,
          },
    );

  return (
    <Fragment>
      <EmailUsersOverlay
        isOpen={isEmailDialogOpen}
        onSuccess={onEmailsSent}
        onClose={() => setIsEmailDialogOpen(false)}
        users={users}
      />
      <Alert
        isOpen={isInviteAlertOpen}
        cancelButtonText="Abbrechen"
        confirmButtonText="Teilnehmer Einladen"
        icon={IconNames.TAKE_ACTION}
        intent={Intent.PRIMARY}
        onClose={() => setIsInviteAlertOpen(false)}
        onConfirm={inviteCandidates as any}
      >
        <p>
          Sollen jetzt die Genießer und Beleiter auf der Teilnehmerliste eingeladen werden?
          <br />
          <br />
          Die Einladungen werden per Email versendet.
        </p>
      </Alert>
      <Button text="Bearbeiten" icon={IconNames.EDIT} css={styles.headerButton} onClick={navigateToMatching} />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            {event.needsInvite && (
              <MenuItem
                text="Teilnehmer Einladen"
                icon={IconNames.TAKE_ACTION}
                onClick={() => setIsInviteAlertOpen(true)}
              />
            )}
            <MenuItem
              text="Nachricht an alle Teilnehmer"
              icon={IconNames.ENVELOPE}
              onClick={() => setIsEmailDialogOpen(true)}
            />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} css={styles.headerButton} loading={inviting} />
      </Popover>
    </Fragment>
  );
};

export default AttendeeListMenu;

const styles = {
  statusTag: css`
    margin-left: 10px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
};
