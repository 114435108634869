/** @jsx jsx */
import { Elevation, Button, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import { StatsRangeType } from '../../../generated/graphql';
import config from '../../../helpers/config';
import { openTab } from '../../../helpers/utils';

type StatsDownloadType = {
  rangeType: StatsRangeType;
  range: string;
};

const StatsDownload = ({ range, rangeType }: StatsDownloadType) => (
  <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
    <ContentCardHeader leftElement={<span css={styles.heading}>Daten-Export</span>} />
    <div css={styles.content}>
      <Button
        intent={Intent.PRIMARY}
        text="Excel-Export herunterladen"
        icon={IconNames.DOWNLOAD}
        onClick={() => openTab(`${config.baseUrl}/downloads/stats?range=${range}&rangeType=${rangeType}`)}
      />
    </div>
  </ContentCard>
);

export default StatsDownload;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  content: css`
    padding: 16px 20px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  spacedCard: css`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  `,
};
