import React from 'react';
import { gql } from '@apollo/client';
import { Intent } from '@blueprintjs/core';
import CategoryTag from './CategoryTag';
import TableCell from './TableCell';
import Text from './Text';
import { EventStatus, FutureSignupListItemFragment } from '../generated/graphql';
import { getSignupStatus, SignupStatus } from '../helpers/dataUtils';
import { formatDay } from '../helpers/dateTimeUtils';

export type FutureSignupListItemProps = {
  signup: FutureSignupListItemFragment;
  skeleton?: boolean;
};

const renderSignupStatus = (signup: FutureSignupListItemFragment, skeleton: boolean | undefined) => {
  if (signup.event.status === EventStatus.Cancelled) {
    return (
      <Text small muted skeleton={skeleton}>
        Veranstaltung abgesagt
      </Text>
    );
  }

  if (signup.event.status === EventStatus.Draft || signup.event.status === EventStatus.Ready) {
    return (
      <Text small muted skeleton={skeleton}>
        Unbekannter Fehler
      </Text>
    );
  }

  if (!signup.event.candidates_chosen) {
    return (
      <Text small muted skeleton={skeleton}>
        Noch keine Teilnehmerliste
      </Text>
    );
  }

  switch (getSignupStatus(signup)) {
    case SignupStatus.ACCEPTED:
      return (
        <Text small intent={Intent.SUCCESS} skeleton={skeleton}>
          Zusage
        </Text>
      );
    case SignupStatus.REJECTED:
      return (
        <Text small intent={Intent.DANGER} skeleton={skeleton}>
          Absage
        </Text>
      );
    case SignupStatus.PROSPECT:
      return (
        <Text small muted skeleton={skeleton}>
          Interessent
        </Text>
      );
    case SignupStatus.CANDIDATE:
      return (
        <Text small skeleton={skeleton}>
          Kandidat
        </Text>
      );
    default:
      return (
        <Text small intent={Intent.PRIMARY} skeleton={skeleton}>
          Warte auf Zusage
        </Text>
      );
  }
};

const FutureSignupListItem = ({ signup, skeleton }: FutureSignupListItemProps) => (
  <tr>
    <TableCell>
      {signup.event.categories.map((category) => (
        <CategoryTag key={category.id} category={category} skeleton={skeleton} />
      ))}
      <Text skeleton={skeleton}>{signup.event.name}</Text>
    </TableCell>
    <TableCell>{renderSignupStatus(signup, skeleton)}</TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{formatDay(signup.event.start)}</Text>
    </TableCell>
  </tr>
);

FutureSignupListItem.fragments = {
  futureSignup: gql`
    fragment FutureSignupListItem on Signup {
      id
      is_candidate
      is_invited
      has_accepted
      has_rejected
      emails {
        id
        status
        opened_first_at
      }
      event {
        id
        name
        status
        candidates_chosen
        start
        categories {
          id
          name
          color
        }
      }
    }
  `,
};

export default FutureSignupListItem;
