/** @jsx jsx */
import { FetchResult } from '@apollo/client';
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import { CancelEventInput, CancelEventMutation, useCancelEventMutation } from '../../../generated/graphql';
import { getLaravelValidationErrors } from '../../../helpers/graphql';
import RichTextInput, { decorator, entityToHTML, htmlToEntity } from '../../../components/RichTextInput';
import { EditorState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';

const validationSchema = Yup.object({
  reason: Yup.string().required('Erforderlich'),
});

type EventCancelFormProps = {
  onCancel?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  initialValues: CancelEventInput;
};

type EventCancelFormValues = Omit<CancelEventInput, 'reason'> & {
  reason: EditorState;
};

const EventCancelForm = ({ onCancel, onSuccess, onError, initialValues }: EventCancelFormProps) => {
  const [cancelEvent] = useCancelEventMutation();
  const handleSubmit = useSubmit<EventCancelFormValues, FetchResult<CancelEventMutation>>({
    mutate: (values) =>
      cancelEvent({
        variables: {
          input: {
            ...values,
            reason: convertToHTML({ entityToHTML })(values.reason.getCurrentContent()),
          },
        },
      }),
    onCompleted: () => onSuccess?.(),
    onError,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik
      initialValues={{
        ...initialValues,
        reason: EditorState.createWithContent(convertFromHTML({ htmlToEntity })(initialValues.reason), decorator),
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<span css={styles.heading}>Veranstaltung absagen</span>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup
              label="Grund"
              name="reason"
              labelInfo="(erforderlich)"
              helperText="Wird per Email an Teilnehmer kommuniziert"
            >
              <RichTextInput name="reason" />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} css={styles.footerButton} />
                <Button
                  text="Absagen"
                  loading={isSubmitting}
                  intent={Intent.DANGER}
                  onClick={submitForm}
                  css={styles.footerButton}
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

export default EventCancelForm;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  footerButton: css`
    margin-left: 10px;
  `,
};
