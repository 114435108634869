/** @jsx jsx */
import { Alert, Button, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import AppToaster from '../../../AppToaster';
import { RoleDetailPageFragment, useDeleteRoleMutation } from '../../../generated/graphql';

type RoleDetailMenuProps = {
  role: RoleDetailPageFragment;
  onEdit: () => void;
};

const RoleDetailMenu = ({ role, onEdit }: RoleDetailMenuProps) => {
  const { id } = role;
  const options = {
    variables: {
      id,
    },
  };
  const [isForceDeleteAlertOpen, setIsForceDeleteAlertOpen] = useState(false);

  const [deleteRole, { loading: deleting }] = useDeleteRoleMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${role.name}  wurde in den Papierkorb gelegt`),
  });

  return (
    <Fragment>
      <Alert
        isOpen={isForceDeleteAlertOpen}
        cancelButtonText="Abbrechen"
        confirmButtonText="Entfernen"
        icon={IconNames.DELETE}
        intent={Intent.DANGER}
        onClose={() => setIsForceDeleteAlertOpen(false)}
        onConfirm={deleteRole as any}
      >
        <p>Soll diese Rolle wirklich undwiderruflich entfernt werden?</p>
      </Alert>
      <Button text="Bearbeiten" icon={IconNames.EDIT} css={styles.headerButton} onClick={onEdit} />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem
              text="Entfernen"
              icon={IconNames.DELETE}
              intent={Intent.DANGER}
              onClick={() => setIsForceDeleteAlertOpen(true)}
            />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} css={styles.headerButton} loading={deleting} />
      </Popover>
    </Fragment>
  );
};

export default RoleDetailMenu;

const styles = {
  statusTag: css`
    margin-left: 10px;
  `,
  headerButton: css`
    margin-left: 10px;
  `,
};
