import React from 'react';
import MainContent from '../components/MainContent';
import Sidebar from '../components/Sidebar';
import TopNavBar from '../components/TopNavbar';

const MainPage: React.FC = () => (
  <div style={styles.container}>
    <Sidebar />
    <div style={styles.main}>
      <TopNavBar />
      <MainContent />
    </div>
  </div>
);

export default MainPage;

const styles = {
  container: {
    display: 'flex',
    minHeight: '100vh',
    maxWidth: 1280,
    margin: '0 auto',
  },
  main: {
    marginLeft: 230,
    flexGrow: 1,
  },
};
