/** @jsx jsx */
import { Callout, Divider, Elevation, Intent } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import NotFoundCard from '../../../components/NotFoundCard';
import Text from '../../../components/Text';
import { StatsRangeType, useOverviewStatsQuery } from '../../../generated/graphql';

type OverviewStatsType = {
  rangeType: StatsRangeType;
  range: string;
};

const OverviewStats = ({ range, rangeType }: OverviewStatsType) => {
  const { data, loading, error } = useOverviewStatsQuery({
    variables: {
      input: {
        range,
        range_type: rangeType,
      },
    },
  });

  if (error) return <ErrorCard css={styles.spacedCard} />;
  if (!loading && !data?.overviewStats) return <NotFoundCard resource="Übergreifend" css={styles.spacedCard} />;

  const stats = data?.overviewStats;

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
      <ContentCardHeader leftElement={<span css={styles.heading}>Übergreifend</span>} />
      <div css={styles.content}>
        <Callout css={styles.metricsContainer}>
          <div css={styles.metric}>
            <Text large skeleton={loading}>
              {stats?.tickets ?? '000'}
            </Text>
            <br />
            <Text muted small skeleton={loading}>
              Karten angeboten
            </Text>
          </div>
          <Divider css={styles.metricDivider} />
          <div css={styles.metric}>
            <Text large intent={Intent.PRIMARY} skeleton={loading}>
              {stats?.signups && stats.tickets
                ? `${stats.signups} (${Math.round((stats.signups / stats.tickets) * 100)}%)`
                : '000 (00%)'}
            </Text>
            <br />
            <Text muted small skeleton={loading}>
              Anmeldungen
            </Text>
          </div>
          <Divider css={styles.metricDivider} />
          <div css={styles.metric}>
            <Text large intent={Intent.SUCCESS} skeleton={loading}>
              {stats?.attends && stats.tickets
                ? `${stats.attends} (${Math.round((stats.attends / stats.tickets) * 100)}%)`
                : '000 (00%)'}
            </Text>
            <br />
            <Text muted small skeleton={loading}>
              Teilnahmen
            </Text>
          </div>
        </Callout>
      </div>
    </ContentCard>
  );
};

export default OverviewStats;

const styles = {
  heading: css`
    font-size: 16px;
  `,
  content: css`
    padding: 16px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  spacedCard: css`
    margin-bottom: 20px;
    margin-right: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `,
  metricsContainer: css`
    display: flex;
    justify-content: space-between;
  `,
  metric: css`
    text-align: center;
    flex-grow: 1;
  `,
  metricDivider: css`
    margin-left: 16px;
    margin-right: 16px;
  `,
};
