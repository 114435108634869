import { gql } from '@apollo/client';
import React from 'react';
import PersonIconList from '../../../components/PersonIconList';
import PersonTagIcon from '../../../components/PersonTagIcon';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { OrganisationDetailPageFragment } from '../../../generated/graphql';

export type CompanionListProps = {
  item: OrganisationDetailPageFragment['companions'][0];
  onClick?: () => void;
};

const OrganisationCompanionListItem = ({ item, onClick }: CompanionListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Text>{item.user.display_name}</Text>
    </TableCell>
    <TableCell>
      <PersonIconList person={item} />
    </TableCell>
  </tr>
);

OrganisationCompanionListItem.fragments = {
  companion: gql`
    fragment OrganisationCompanionListItem on Companion {
      id
      can_wheelchair
      can_wheeled_walker
      can_drive
      can_blind
      user {
        id
        display_name
      }
      tags {
        ...PersonTagIcon
      }
    }
    ${PersonTagIcon.fragments.tag}
  `,
};

export default OrganisationCompanionListItem;
