import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { CompanionCreateFormFragment, UserStatus } from '../../../generated/graphql';
import CompanionForm from './CompanionForm';

type CompanionCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
} & IOverlayProps;

const CompanionCreateOverlay = ({ onSuccess, ...overlayProps }: CompanionCreateOverlayProps) => {
  const initialValues: CompanionCreateFormFragment = {
    can_blind: false,
    can_drive: false,
    can_wheelchair: false,
    can_wheeled_walker: false,
    user: {
      status: UserStatus.Active,
      first_name: '',
      last_name: '',
      gdpr_consent: true,
    },
    organisation: null,
    tags: [],
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <CompanionForm
        onCancel={closeOverlay}
        onCreated={({ createCompanion }) => {
          onSuccess(createCompanion.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default CompanionCreateOverlay;
