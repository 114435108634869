import { Button, Classes, Collapse, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { Fragment, useState } from 'react';
import EmailLink from '../../../components/EmailLink';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { EventDetailPageFragment, EventTicketType } from '../../../generated/graphql';
import { formatDateTime, formatTime } from '../../../helpers/dateTimeUtils';
import TicketInstructions from '../components/TicketInstructions';
import { Link } from 'react-router-dom';

type EventInfoProps = {
  event: EventDetailPageFragment;
};

const EventInfo = ({ event }: EventInfoProps) => {
  const [fullDescriptionShown, setFullDescriptionShown] = useState<boolean>(false);
  const [publicTransportShown, setPublicTransportShown] = useState<boolean>(false);

  return (
    <Fragment>
      <PropertyList>
        <PropertyListHeader>Veranstaltung</PropertyListHeader>
        <PropertyListItem label="Datum">
          {formatDateTime(event.start, 'dd.MM.yyyy HH:mm')}
          {event.end ? ` - ${formatTime(event.end)} Uhr` : ' Uhr'}
        </PropertyListItem>
        <PropertyListItem label="Verfügbare Karten">
          {event.ticket_type === EventTicketType.Unlimited && <Text muted>Freier Eintritt</Text>}
          {event.ticket_type !== EventTicketType.Unlimited && (
            <Fragment>
              <Text>{event.available_tickets?.toString()}</Text>
              {event.ticket_type === EventTicketType.Allotment && <Text muted> (Kontingent)</Text>}
            </Fragment>
          )}
        </PropertyListItem>
        <PropertyListItem label="Ansprechpartener" runningText>
          <Link to={`/contacts/${event.contact.id}`}>{event.contact.user.display_name}</Link>
          <br />
          <Text muted>
            <Icon icon={IconNames.PHONE} iconSize={12} />
            {` ${event.contact.user.phone || '—'}`}
          </Text>
          {' | '}
          <Text muted>
            <Icon icon={IconNames.MOBILE_PHONE} iconSize={12} />
            {` ${event.contact.user.mobile || '—'}`}
          </Text>{' '}
          <br />
          <EmailLink email={event.contact.user.email} />
        </PropertyListItem>
        <PropertyListItem
          label="Interne Notizen"
          value={event.notes || '—'}
          showValueDisabled={!event.notes}
          runningText
        />
        <PropertyListItem label="Beschreibung">
          {event.description ? (
            <Fragment>
              <Collapse isOpen={fullDescriptionShown} className={Classes.RUNNING_TEXT}>
                {event.description}
                <br />
                <br />
              </Collapse>
              <Button
                small
                onClick={() => setFullDescriptionShown(!fullDescriptionShown)}
                text={fullDescriptionShown ? 'Ausblenden' : 'Einblenden'}
                rightIcon={fullDescriptionShown ? IconNames.CARET_UP : IconNames.CARET_DOWN}
              />
            </Fragment>
          ) : (
            <Text disabled>—</Text>
          )}
        </PropertyListItem>
      </PropertyList>
      <PropertyListDivider />
      <PropertyList>
        {!!event.cardholder && (
          <PropertyListItem label="Kartenverteiler">
            <Link to={`/companions/${event.cardholder.id}`}>{event.cardholder.user.display_name}</Link>
            <br />
            <Text muted>
              <Icon icon={IconNames.PHONE} iconSize={12} />
              {` ${event.cardholder.user.phone || '—'}`}
            </Text>
            {' | '}
            <Text muted>
              <Icon icon={IconNames.MOBILE_PHONE} iconSize={12} />
              {` ${event.cardholder.user.mobile || '—'}`}
            </Text>{' '}
            <br />
            <EmailLink email={event.cardholder.user.email} />
          </PropertyListItem>
        )}
        <PropertyListItem label="Anweisung">
          <TicketInstructions event={event} />
        </PropertyListItem>
        <PropertyListItem label="Lokation" runningText>
          {event.location.name}
          <br />
          <Text muted>{`${event.location.street}, ${event.location.postal_code} ${event.location.city}`}</Text>
        </PropertyListItem>
        <PropertyListItem label="Öff. Nahverkehr">
          <Collapse isOpen={publicTransportShown} className={Classes.RUNNING_TEXT}>
            {event.location.public_transport}
            <br />
            <br />
          </Collapse>
          <Button
            small
            onClick={() => setPublicTransportShown(!publicTransportShown)}
            text={publicTransportShown ? 'Ausblenden' : 'Einblenden'}
            rightIcon={publicTransportShown ? IconNames.CARET_UP : IconNames.CARET_DOWN}
          />
        </PropertyListItem>
      </PropertyList>
    </Fragment>
  );
};

export default EventInfo;
