/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
  DateTime: string;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: string;
};

export type AddGroupSignupInput = {
  companion: Scalars['ID'];
  souls: Array<Scalars['ID']>;
};

export type AddSignupsInput = {
  eventId: Scalars['ID'];
  loneSouls?: Maybe<Array<Scalars['ID']>>;
  loneCompanions?: Maybe<Array<Scalars['ID']>>;
  group?: Maybe<AddGroupSignupInput>;
  notes?: Maybe<Scalars['String']>;
};

export type Allotment = {
   __typename?: 'Allotment';
  id: Scalars['ID'];
  name: Scalars['String'];
  status: AllotmentStatus;
  amount: Scalars['Int'];
  amount_left: Scalars['Int'];
  start: Scalars['DateTime'];
  end?: Maybe<Scalars['DateTime']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export enum AllotmentStatus {
  Planned = 'PLANNED',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Depleted = 'DEPLETED'
}

export type Applicant = Companion | Soul;

export enum Archived {
  /** Only return archived results. */
  Only = 'ONLY',
  /** Return both archived and non-archived results. */
  With = 'WITH',
  /** Only return non-archived results. */
  Without = 'WITHOUT'
}

export type AuthInfo = {
   __typename?: 'AuthInfo';
  id: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type AuthPayload = {
   __typename?: 'AuthPayload';
  user: User;
};

export type Branch = {
   __typename?: 'Branch';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  api_key: Scalars['String'];
  use_legacy_newsletter: Scalars['Boolean'];
  redirect_events?: Maybe<Scalars['String']>;
  redirect_ticket_accept_ok?: Maybe<Scalars['String']>;
  redirect_ticket_accept_not_found?: Maybe<Scalars['String']>;
  redirect_password_reset?: Maybe<Scalars['String']>;
};

export type CancelEventInput = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type CandidatesInput = {
  cardholder: Scalars['ID'];
  groups: Array<GroupInput>;
  teams: Array<GroupInput>;
  souls: Array<Scalars['ID']>;
  companions: Array<Scalars['ID']>;
};

export type Category = {
   __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type CategoryDataPayload = {
   __typename?: 'CategoryDataPayload';
  tickets: Scalars['Int'];
  signups: Scalars['Int'];
  previous_tickets: Scalars['Int'];
  previous_signups: Scalars['Int'];
};

export type CategoryStatsInput = {
  range_type: StatsRangeType;
  range: Scalars['String'];
  target_groups?: Maybe<Array<SoulTargetGroup>>;
};

export type CategoryStatsPayload = {
   __typename?: 'CategoryStatsPayload';
  category: Category;
  data: CategoryDataPayload;
};

export type Companion = Person & {
   __typename?: 'Companion';
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  can_wheelchair: Scalars['Boolean'];
  can_wheeled_walker: Scalars['Boolean'];
  can_drive: Scalars['Boolean'];
  can_blind: Scalars['Boolean'];
  user: User;
  organisation?: Maybe<Organisation>;
  tags: Array<Tag>;
  signups?: Maybe<SignupPaginator>;
  emails?: Maybe<EmailPaginator>;
  future_signups?: Maybe<SignupPaginator>;
  attends?: Maybe<SignupPaginator>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export type CompanionUserArgs = {
  trashed?: Maybe<Trashed>;
};


export type CompanionOrganisationArgs = {
  trashed?: Maybe<Trashed>;
};


export type CompanionSignupsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type CompanionEmailsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type CompanionFuture_SignupsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type CompanionAttendsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

/** A paginated list of Companion items. */
export type CompanionPaginator = {
   __typename?: 'CompanionPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Companion items. */
  data: Array<Companion>;
};

export type Contact = {
   __typename?: 'Contact';
  id: Scalars['ID'];
  donor_id: Scalars['ID'];
  user: User;
  emails?: Maybe<EmailPaginator>;
  donor: Donor;
  notes?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export type ContactUserArgs = {
  trashed?: Maybe<Trashed>;
};


export type ContactEmailsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type ContactDonorArgs = {
  trashed?: Maybe<Trashed>;
};

export type CreateAllotmentInput = {
  name: Scalars['String'];
  amount: Scalars['Int'];
  start: Scalars['DateTime'];
  end?: Maybe<Scalars['DateTime']>;
};

export type CreateCategoryInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color: Scalars['String'];
};

export type CreateCompanionInput = {
  notes?: Maybe<Scalars['String']>;
  can_wheelchair?: Maybe<Scalars['Boolean']>;
  can_wheeled_walker?: Maybe<Scalars['Boolean']>;
  can_drive?: Maybe<Scalars['Boolean']>;
  can_blind?: Maybe<Scalars['Boolean']>;
  user: UpdateUserRelation;
  tags?: Maybe<UpdateTagsRelation>;
  organisation?: Maybe<UpdateOrganisationRelation>;
};

export type CreateContactInput = {
  donor_id: Scalars['ID'];
  user?: Maybe<UpdateUserRelation>;
  notes?: Maybe<Scalars['String']>;
};

export type CreateDonorInput = {
  name: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  locations: UpdateLocationsRelation;
};

export type CreateEmployeeInput = {
  position: Scalars['String'];
  user: UpdateUserRelation;
};

export type CreateEventInput = {
  name: Scalars['String'];
  status: EventStatus;
  description?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  end?: Maybe<Scalars['DateTime']>;
  ticket_type: EventTicketType;
  tickets?: Maybe<Scalars['Int']>;
  ticket_time?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['String']>;
  ticket_retrieval_location?: Maybe<Scalars['String']>;
  ticket_retrieval_type?: Maybe<EventTicketRetrievalType>;
  notes?: Maybe<Scalars['String']>;
  donor: UpdateDonorRelation;
  contact: UpdateContactRelation;
  location: UpdateLocationRelation;
  allotment?: Maybe<UpdateAllotmentRelation>;
  categories?: Maybe<UpdateCategoriesRelation>;
};

export type CreateLocationInput = {
  name: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  postal_code: Scalars['String'];
  meeting_point: Scalars['String'];
  public_transport?: Maybe<Scalars['String']>;
  accessible: Scalars['Boolean'];
  donors: UpdateDonorsRelation;
};

export type CreateMediaInput = {
  file_path: Scalars['String'];
  file_name: Scalars['String'];
};

export type CreateNewsletterInput = {
  subject: Scalars['String'];
  body: Scalars['String'];
  segment: MailcoachSegment;
  events?: Maybe<UpdateEventsRelation>;
};

export type CreateOrganisationInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  souls?: Maybe<UpdateOrganisationSoulRelation>;
  companions?: Maybe<UpdateOrganisationCompanionRelation>;
};

export type CreateRoleInput = {
  name: Scalars['String'];
  permissions?: Maybe<UpdatePermissionsRelation>;
};

export type CreateSoulInput = {
  target_group?: Maybe<SoulTargetGroup>;
  notes?: Maybe<Scalars['String']>;
  buys_ticket?: Maybe<Scalars['Boolean']>;
  needs_wheelchair?: Maybe<Scalars['Boolean']>;
  needs_wheeled_walker?: Maybe<Scalars['Boolean']>;
  needs_drive?: Maybe<Scalars['Boolean']>;
  needs_trainstation_fetch?: Maybe<Scalars['Boolean']>;
  needs_blind?: Maybe<Scalars['Boolean']>;
  user: UpdateUserRelation;
  tags?: Maybe<UpdateTagsRelation>;
  organisation?: Maybe<UpdateOrganisationRelation>;
};

export type CreateTagInput = {
  name: Scalars['String'];
  icon: Scalars['String'];
  intent: Intent;
  description?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  roles?: Maybe<UpdateRolesRelation>;
  status?: Maybe<UserStatus>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  gdpr_consent?: Maybe<Scalars['Boolean']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
};


export type DateRange = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};


export type DateTimeRange = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type Donor = {
   __typename?: 'Donor';
  id: Scalars['ID'];
  name: Scalars['String'];
  public: Scalars['Boolean'];
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  contacts: Array<Contact>;
  locations: Array<Location>;
  logo?: Maybe<Media>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export type DonorContactsArgs = {
  trashed?: Maybe<Trashed>;
};


export type DonorLocationsArgs = {
  trashed?: Maybe<Trashed>;
};

/** A paginated list of Donor items. */
export type DonorPaginator = {
   __typename?: 'DonorPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Donor items. */
  data: Array<Donor>;
};

export type Email = {
   __typename?: 'Email';
  id: Scalars['ID'];
  status: EmailStatus;
  subject: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['DateTime']>;
  opened_first_at?: Maybe<Scalars['DateTime']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Email items. */
export type EmailPaginator = {
   __typename?: 'EmailPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Email items. */
  data: Array<Email>;
};

export enum EmailStatus {
  Queued = 'QUEUED',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
  Opened = 'OPENED',
  Rejected = 'REJECTED',
  Invalid = 'INVALID',
  SoftBounced = 'SOFT_BOUNCED',
  HardBounced = 'HARD_BOUNCED',
  MarkedSpam = 'MARKED_SPAM',
  Delayed = 'DELAYED',
  Unknown = 'UNKNOWN'
}

export type EmailUsersInput = {
  subject: Scalars['String'];
  body: Scalars['String'];
  users: Array<Scalars['String']>;
};

export type Employee = {
   __typename?: 'Employee';
  id: Scalars['ID'];
  position: Scalars['String'];
  user: User;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export type EmployeeUserArgs = {
  trashed?: Maybe<Trashed>;
};

/** A paginated list of Employee items. */
export type EmployeePaginator = {
   __typename?: 'EmployeePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Employee items. */
  data: Array<Employee>;
};

export type Event = {
   __typename?: 'Event';
  allotment?: Maybe<Allotment>;
  archived: Scalars['Boolean'];
  archived_at?: Maybe<Scalars['DateTime']>;
  attendeeCount: Scalars['Int'];
  attendees: Array<Signup>;
  available_tickets?: Maybe<Scalars['Int']>;
  candidateCount: Scalars['Int'];
  candidates: Array<Signup>;
  candidates_chosen: Scalars['Boolean'];
  cardholder?: Maybe<Companion>;
  categories: Array<Category>;
  contact: Contact;
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  donor: Donor;
  end?: Maybe<Scalars['DateTime']>;
  groups: Array<Signup>;
  id: Scalars['ID'];
  inviteeCount: Scalars['Int'];
  invitees: Array<Signup>;
  location: Location;
  loneCompanions: Array<Signup>;
  loneSouls: Array<Signup>;
  name: Scalars['String'];
  needsInvite: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  prospectCount: Scalars['Int'];
  prospects: Array<Signup>;
  reservation_number?: Maybe<Scalars['String']>;
  signups: Array<Signup>;
  slug: Scalars['String'];
  start: Scalars['DateTime'];
  status: EventStatus;
  taken_tickets: Scalars['Int'];
  teams: Array<Signup>;
  ticket_retrieval_location?: Maybe<Scalars['String']>;
  ticket_retrieval_type?: Maybe<EventTicketRetrievalType>;
  ticket_time: Scalars['Int'];
  ticket_type: EventTicketType;
  tickets?: Maybe<Scalars['Int']>;
  updated_at: Scalars['DateTime'];
};


export type EventCardholderArgs = {
  trashed?: Maybe<Trashed>;
};


export type EventCategoriesArgs = {
  trashed?: Maybe<Trashed>;
};


export type EventContactArgs = {
  trashed?: Maybe<Trashed>;
};


export type EventDonorArgs = {
  trashed?: Maybe<Trashed>;
};


export type EventLocationArgs = {
  trashed?: Maybe<Trashed>;
};


export type EventSignupsArgs = {
  trashed?: Maybe<Trashed>;
};

/** A paginated list of Event items. */
export type EventPaginator = {
   __typename?: 'EventPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Event items. */
  data: Array<Event>;
};

export enum EventStatus {
  Draft = 'DRAFT',
  Ready = 'READY',
  Published = 'PUBLISHED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export enum EventTicketRetrievalType {
  Onsite = 'ONSITE',
  Delivery = 'DELIVERY'
}

export enum EventTicketType {
  Fixed = 'FIXED',
  Allotment = 'ALLOTMENT',
  Unlimited = 'UNLIMITED'
}

export type ForgotPasswordInput = {
  email: Scalars['String'];
  branchId: Scalars['ID'];
};

export type ForgotPasswordResponse = {
   __typename?: 'ForgotPasswordResponse';
  status: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type GroupInput = {
  companion: Scalars['ID'];
  souls: Array<Scalars['ID']>;
};

export enum Intent {
  None = 'none',
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger'
}

export type Location = {
   __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  postal_code: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  meeting_point: Scalars['String'];
  public_transport?: Maybe<Scalars['String']>;
  accessible: Scalars['Boolean'];
  donors: Array<Donor>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Location items. */
export type LocationPaginator = {
   __typename?: 'LocationPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Location items. */
  data: Array<Location>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  branchId: Scalars['ID'];
};

export type LogoutResponse = {
   __typename?: 'LogoutResponse';
  status: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export enum MailcoachSegment {
  Active = 'ACTIVE',
  Souls = 'SOULS',
  Companions = 'COMPANIONS',
  All = 'ALL'
}

export type Media = {
   __typename?: 'Media';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  thumb_url?: Maybe<Scalars['String']>;
};

export type Mutation = {
   __typename?: 'Mutation';
  login: AuthPayload;
  logout: LogoutResponse;
  forgotPassword: ForgotPasswordResponse;
  updateForgottenPassword: ForgotPasswordResponse;
  createTag: Tag;
  updateTag?: Maybe<Tag>;
  deleteTag?: Maybe<Tag>;
  emailUsers?: Maybe<Scalars['Boolean']>;
  createSoul: Soul;
  approveSoul: Soul;
  denySoul: Soul;
  activateSoul: Soul;
  deactivateSoul: Soul;
  updateSoul?: Maybe<Soul>;
  deleteSoul?: Maybe<Soul>;
  restoreSoul?: Maybe<Soul>;
  forceDeleteSoul?: Maybe<Soul>;
  createCompanion: Companion;
  approveCompanion: Companion;
  denyCompanion: Companion;
  activateCompanion: Companion;
  deactivateCompanion: Companion;
  updateCompanion?: Maybe<Companion>;
  deleteCompanion?: Maybe<Companion>;
  restoreCompanion?: Maybe<Companion>;
  forceDeleteCompanion?: Maybe<Companion>;
  createEmployee: Employee;
  updateEmployee?: Maybe<Employee>;
  deleteEmployee?: Maybe<Employee>;
  restoreEmployee?: Maybe<Employee>;
  forceDeleteEmployee?: Maybe<Employee>;
  createOrganisation: Organisation;
  updateOrganisation?: Maybe<Organisation>;
  deleteOrganisation?: Maybe<Organisation>;
  restoreOrganisation?: Maybe<Organisation>;
  forceDeleteOrganisation?: Maybe<Organisation>;
  createDonor: Donor;
  updateDonor?: Maybe<Donor>;
  deleteDonor?: Maybe<Donor>;
  restoreDonor?: Maybe<Donor>;
  forceDeleteDonor?: Maybe<Donor>;
  setDonorLogo?: Maybe<Donor>;
  createLocation: Location;
  updateLocation?: Maybe<Location>;
  deleteLocation?: Maybe<Location>;
  restoreLocation?: Maybe<Location>;
  forceDeleteLocation?: Maybe<Location>;
  createContact: Contact;
  updateContact?: Maybe<Contact>;
  deleteContact?: Maybe<Contact>;
  restoreContact?: Maybe<Contact>;
  forceDeleteContact?: Maybe<Contact>;
  createRole: Role;
  updateRole?: Maybe<Role>;
  deleteRole?: Maybe<Role>;
  createCategory: Category;
  updateCategory?: Maybe<Category>;
  deleteCategory?: Maybe<Category>;
  restoreCategory?: Maybe<Category>;
  forceDeleteCategory?: Maybe<Category>;
  createAllotment: Allotment;
  updateAllotment?: Maybe<Allotment>;
  deleteAllotment?: Maybe<Allotment>;
  deleteSignup?: Maybe<Signup>;
  createEvent: Event;
  updateEvent?: Maybe<Event>;
  cancelEvent?: Maybe<Event>;
  publishEvent?: Maybe<Event>;
  deleteEvent?: Maybe<Event>;
  restoreEvent?: Maybe<Event>;
  forceDeleteEvent?: Maybe<Event>;
  acceptSignups?: Maybe<Event>;
  addSignups?: Maybe<Event>;
  updateCandidates?: Maybe<Event>;
  inviteCandidates?: Maybe<Event>;
  createNewsletter: Newsletter;
  deleteNewsletter?: Maybe<Newsletter>;
  updateBranch?: Maybe<Branch>;
  regenerateApiKey: Branch;
};


export type MutationLoginArgs = {
  input?: Maybe<LoginInput>;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationUpdateForgottenPasswordArgs = {
  input?: Maybe<NewPasswordWithCodeInput>;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationEmailUsersArgs = {
  input: EmailUsersInput;
};


export type MutationCreateSoulArgs = {
  input: CreateSoulInput;
};


export type MutationApproveSoulArgs = {
  id: Scalars['ID'];
};


export type MutationDenySoulArgs = {
  id: Scalars['ID'];
};


export type MutationActivateSoulArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateSoulArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateSoulArgs = {
  input: UpdateSoulInput;
};


export type MutationDeleteSoulArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreSoulArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type MutationForceDeleteSoulArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type MutationCreateCompanionArgs = {
  input: CreateCompanionInput;
};


export type MutationApproveCompanionArgs = {
  id: Scalars['ID'];
};


export type MutationDenyCompanionArgs = {
  id: Scalars['ID'];
};


export type MutationActivateCompanionArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateCompanionArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCompanionArgs = {
  input: UpdateCompanionInput;
};


export type MutationDeleteCompanionArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreCompanionArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type MutationForceDeleteCompanionArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};


export type MutationDeleteEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationForceDeleteEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateOrganisationArgs = {
  input: CreateOrganisationInput;
};


export type MutationUpdateOrganisationArgs = {
  input: UpdateOrganisationInput;
};


export type MutationDeleteOrganisationArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreOrganisationArgs = {
  id: Scalars['ID'];
};


export type MutationForceDeleteOrganisationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDonorArgs = {
  input: CreateDonorInput;
};


export type MutationUpdateDonorArgs = {
  input: UpdateDonorInput;
};


export type MutationDeleteDonorArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreDonorArgs = {
  id: Scalars['ID'];
};


export type MutationForceDeleteDonorArgs = {
  id: Scalars['ID'];
};


export type MutationSetDonorLogoArgs = {
  input: SetDonorLogoInput;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreLocationArgs = {
  id: Scalars['ID'];
};


export type MutationForceDeleteLocationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreContactArgs = {
  id: Scalars['ID'];
};


export type MutationForceDeleteContactArgs = {
  id: Scalars['ID'];
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationForceDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAllotmentArgs = {
  input: CreateAllotmentInput;
};


export type MutationUpdateAllotmentArgs = {
  input: UpdateAllotmentInput;
};


export type MutationDeleteAllotmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSignupArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationCancelEventArgs = {
  input: CancelEventInput;
};


export type MutationPublishEventArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreEventArgs = {
  id: Scalars['ID'];
};


export type MutationForceDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationAcceptSignupsArgs = {
  id: Scalars['ID'];
  token: Scalars['String'];
};


export type MutationAddSignupsArgs = {
  input: AddSignupsInput;
};


export type MutationUpdateCandidatesArgs = {
  input: UpdateCandidatesInput;
};


export type MutationInviteCandidatesArgs = {
  id: Scalars['ID'];
};


export type MutationCreateNewsletterArgs = {
  input: CreateNewsletterInput;
};


export type MutationDeleteNewsletterArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateBranchArgs = {
  input: UpdateBranchInput;
};


export type MutationRegenerateApiKeyArgs = {
  id: Scalars['ID'];
};

export type NewPasswordWithCodeInput = {
  email: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type Newsletter = {
   __typename?: 'Newsletter';
  id: Scalars['ID'];
  status: NewsletterStatus;
  subject: Scalars['String'];
  body: Scalars['String'];
  segment: MailcoachSegment;
  sent_at?: Maybe<Scalars['DateTime']>;
  events: Array<Event>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Newsletter items. */
export type NewsletterPaginator = {
   __typename?: 'NewsletterPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Newsletter items. */
  data: Array<Newsletter>;
};

export enum NewsletterStatus {
  Draft = 'DRAFT',
  Sending = 'SENDING',
  Sent = 'SENT'
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type Organisation = {
   __typename?: 'Organisation';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  souls: Array<Soul>;
  companions: Array<Companion>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type OverviewStatsInput = {
  range_type: StatsRangeType;
  range: Scalars['String'];
};

export type OverviewStatsPayload = {
   __typename?: 'OverviewStatsPayload';
  tickets: Scalars['Int'];
  signups: Scalars['Int'];
  attends: Scalars['Int'];
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
   __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
   __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type Permission = {
   __typename?: 'Permission';
  id: Scalars['ID'];
  name: Scalars['String'];
  title: Scalars['String'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type Person = {
  id: Scalars['ID'];
  user: User;
  organisation?: Maybe<Organisation>;
  tags: Array<Tag>;
};

export type Query = {
   __typename?: 'Query';
  allotment?: Maybe<Allotment>;
  allotments: Array<Allotment>;
  authInfo?: Maybe<AuthInfo>;
  branch?: Maybe<Branch>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  categoryStats: Array<CategoryStatsPayload>;
  companion?: Maybe<Companion>;
  companions?: Maybe<CompanionPaginator>;
  contact?: Maybe<Contact>;
  donor?: Maybe<Donor>;
  donors?: Maybe<DonorPaginator>;
  email?: Maybe<Email>;
  employee?: Maybe<Employee>;
  employees?: Maybe<EmployeePaginator>;
  event?: Maybe<Event>;
  events?: Maybe<EventPaginator>;
  isAuthenticated: Scalars['Boolean'];
  location?: Maybe<Location>;
  locations?: Maybe<LocationPaginator>;
  newsletter?: Maybe<Newsletter>;
  newsletters?: Maybe<NewsletterPaginator>;
  organisation?: Maybe<Organisation>;
  organisations: Array<Organisation>;
  overviewStats: OverviewStatsPayload;
  permission?: Maybe<Permission>;
  permissions: Array<Permission>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  signup?: Maybe<Signup>;
  soul?: Maybe<Soul>;
  souls?: Maybe<SoulPaginator>;
  tag?: Maybe<Tag>;
  tags: Array<Tag>;
  targetGroupStats: Array<TargetGroupStatsPayload>;
};


export type QueryAllotmentArgs = {
  id: Scalars['ID'];
};


export type QueryAuthInfoArgs = {
  branchSlug: Scalars['String'];
};


export type QueryBranchArgs = {
  id: Scalars['ID'];
};


export type QueryCategoriesArgs = {
  trashed?: Maybe<Trashed>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryCategoryStatsArgs = {
  input: CategoryStatsInput;
};


export type QueryCompanionArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryCompanionsArgs = {
  status: UserStatus;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryContactArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryDonorArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryDonorsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryEmailArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryEmployeesArgs = {
  status: UserStatus;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryEventArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryEventsArgs = {
  status?: Maybe<EventStatus>;
  donor?: Maybe<Scalars['Int']>;
  start?: Maybe<DateTimeRange>;
  archived?: Maybe<Archived>;
  orderBy?: Maybe<Array<OrderByClause>>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryLocationsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryNewsletterArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryNewslettersArgs = {
  sent_at?: Maybe<DateTimeRange>;
  orderBy?: Maybe<Array<OrderByClause>>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryOrganisationArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryOrganisationsArgs = {
  trashed?: Maybe<Trashed>;
};


export type QueryOverviewStatsArgs = {
  input: OverviewStatsInput;
};


export type QueryPermissionArgs = {
  id: Scalars['ID'];
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QuerySignupArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QuerySoulArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QuerySoulsArgs = {
  status: UserStatus;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTargetGroupStatsArgs = {
  input: TargetGroupStatsInput;
};

export type RegisterInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type Role = {
   __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type SetDonorLogoInput = {
  id: Scalars['ID'];
  logo: UpdateMediaInput;
};

export type Signup = {
   __typename?: 'Signup';
  id: Scalars['ID'];
  event_id: Scalars['String'];
  is_candidate: Scalars['Boolean'];
  is_invited: Scalars['Boolean'];
  has_accepted: Scalars['Boolean'];
  has_rejected: Scalars['Boolean'];
  token: Scalars['String'];
  is_cardholder: Scalars['Boolean'];
  priority: Scalars['Int'];
  event: Event;
  soul?: Maybe<Soul>;
  conflicting_signups: Array<Signup>;
  is_first_attend: Scalars['Boolean'];
  companion?: Maybe<Companion>;
  group: Array<Signup>;
  leader: Array<Signup>;
  emails: Array<Email>;
  notes?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Signup items. */
export type SignupPaginator = {
   __typename?: 'SignupPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Signup items. */
  data: Array<Signup>;
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Soul = Person & {
   __typename?: 'Soul';
  id: Scalars['ID'];
  target_group?: Maybe<SoulTargetGroup>;
  notes?: Maybe<Scalars['String']>;
  buys_ticket: Scalars['Boolean'];
  needs_wheelchair: Scalars['Boolean'];
  needs_wheeled_walker: Scalars['Boolean'];
  needs_drive: Scalars['Boolean'];
  needs_trainstation_fetch: Scalars['Boolean'];
  needs_blind: Scalars['Boolean'];
  user: User;
  organisation?: Maybe<Organisation>;
  tags: Array<Tag>;
  signups?: Maybe<SignupPaginator>;
  emails?: Maybe<EmailPaginator>;
  future_signups?: Maybe<SignupPaginator>;
  attends?: Maybe<SignupPaginator>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export type SoulUserArgs = {
  trashed?: Maybe<Trashed>;
};


export type SoulOrganisationArgs = {
  trashed?: Maybe<Trashed>;
};


export type SoulSignupsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type SoulEmailsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type SoulFuture_SignupsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type SoulAttendsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

/** A paginated list of Soul items. */
export type SoulPaginator = {
   __typename?: 'SoulPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Soul items. */
  data: Array<Soul>;
};

export enum SoulTargetGroup {
  Disabled = 'DISABLED',
  Child = 'CHILD',
  Immigrant = 'IMMIGRANT',
  Senior = 'SENIOR',
  SociallyInNeed = 'SOCIALLY_IN_NEED',
  None = 'NONE'
}

export enum StatsRangeType {
  Year = 'YEAR',
  Month = 'MONTH'
}

export type Tag = {
   __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  icon: Scalars['String'];
  intent: Intent;
  description?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type TargetGroupDataPayload = {
   __typename?: 'TargetGroupDataPayload';
  signups: Scalars['Int'];
  attends: Scalars['Int'];
  previous_signups: Scalars['Int'];
  previous_attends: Scalars['Int'];
};

export type TargetGroupStatsInput = {
  range_type: StatsRangeType;
  range: Scalars['String'];
  categories?: Maybe<Array<Scalars['ID']>>;
};

export type TargetGroupStatsPayload = {
   __typename?: 'TargetGroupStatsPayload';
  target_group: SoulTargetGroup;
  data: TargetGroupDataPayload;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UpdateAllotmentInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};

export type UpdateAllotmentRelation = {
  connect?: Maybe<Scalars['ID']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UpdateBranchInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  redirect_events?: Maybe<Scalars['String']>;
  redirect_ticket_accept_ok?: Maybe<Scalars['String']>;
  redirect_ticket_accept_not_found?: Maybe<Scalars['String']>;
  redirect_password_reset?: Maybe<Scalars['String']>;
};

export type UpdateCandidatesInput = {
  id: Scalars['ID'];
  candidates: CandidatesInput;
};

export type UpdateCategoriesRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateCategoryInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type UpdateCompanionInput = {
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  can_wheelchair?: Maybe<Scalars['Boolean']>;
  can_wheeled_walker?: Maybe<Scalars['Boolean']>;
  can_drive?: Maybe<Scalars['Boolean']>;
  can_blind?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UpdateUserRelation>;
  tags?: Maybe<UpdateTagsRelation>;
  organisation?: Maybe<UpdateOrganisationRelation>;
};

export type UpdateContactInput = {
  id: Scalars['ID'];
  donor_id?: Maybe<Scalars['ID']>;
  user?: Maybe<UpdateUserRelation>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateContactRelation = {
  connect?: Maybe<Scalars['ID']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UpdateDonorInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  locations?: Maybe<UpdateLocationsRelation>;
};

export type UpdateDonorRelation = {
  connect?: Maybe<Scalars['ID']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UpdateDonorsRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateEmployeeInput = {
  id: Scalars['ID'];
  position?: Maybe<Scalars['String']>;
  user?: Maybe<UpdateUserRelation>;
};

export type UpdateEventInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EventStatus>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  ticket_type?: Maybe<EventTicketType>;
  tickets?: Maybe<Scalars['Int']>;
  ticket_time?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['String']>;
  ticket_retrieval_location?: Maybe<Scalars['String']>;
  ticket_retrieval_type?: Maybe<EventTicketRetrievalType>;
  notes?: Maybe<Scalars['String']>;
  donor?: Maybe<UpdateDonorRelation>;
  contact?: Maybe<UpdateContactRelation>;
  location?: Maybe<UpdateLocationRelation>;
  allotment?: Maybe<UpdateAllotmentRelation>;
  categories?: Maybe<UpdateCategoriesRelation>;
};

export type UpdateEventsRelation = {
  sync: Array<Scalars['ID']>;
};

export type UpdateLocationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  meeting_point?: Maybe<Scalars['String']>;
  public_transport?: Maybe<Scalars['String']>;
  accessible?: Maybe<Scalars['Boolean']>;
  donors?: Maybe<UpdateDonorsRelation>;
};

export type UpdateLocationRelation = {
  connect?: Maybe<Scalars['ID']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UpdateLocationsRelation = {
  create?: Maybe<Array<CreateLocationInput>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateMediaInput = {
  create?: Maybe<CreateMediaInput>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UpdateNewsletterInput = {
  id: Scalars['ID'];
  subject: Scalars['String'];
  body: Scalars['String'];
  segment: MailcoachSegment;
  events?: Maybe<UpdateEventsRelation>;
};

export type UpdateOrganisationCompanionRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateOrganisationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  souls?: Maybe<UpdateOrganisationSoulRelation>;
  companions?: Maybe<UpdateOrganisationCompanionRelation>;
};

export type UpdateOrganisationRelation = {
  connect?: Maybe<Scalars['ID']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UpdateOrganisationSoulRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdatePermissionsRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateRoleInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<UpdatePermissionsRelation>;
};

export type UpdateRolesRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateSoulInput = {
  id: Scalars['ID'];
  target_group?: Maybe<SoulTargetGroup>;
  notes?: Maybe<Scalars['String']>;
  buys_ticket?: Maybe<Scalars['Boolean']>;
  needs_wheelchair?: Maybe<Scalars['Boolean']>;
  needs_wheeled_walker?: Maybe<Scalars['Boolean']>;
  needs_drive?: Maybe<Scalars['Boolean']>;
  needs_trainstation_fetch?: Maybe<Scalars['Boolean']>;
  needs_blind?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UpdateUserRelation>;
  tags?: Maybe<UpdateTagsRelation>;
  organisation?: Maybe<UpdateOrganisationRelation>;
};

export type UpdateTagInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  intent?: Maybe<Intent>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateTagsRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  roles?: Maybe<UpdateRolesRelation>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gdpr_consent?: Maybe<Scalars['Boolean']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
};

export type UpdateUserRelation = {
  update?: Maybe<UpdateUserInput>;
  create?: Maybe<CreateUserInput>;
};

export type User = {
   __typename?: 'User';
  id: Scalars['ID'];
  branch: Branch;
  roles: Array<Role>;
  permissions: Array<Permission>;
  status: UserStatus;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  display_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  gdpr_consent: Scalars['Boolean'];
  is_newsletter_subscriber: Scalars['Boolean'];
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  is_superadmin: Scalars['Boolean'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export enum UserStatus {
  All = 'ALL',
  New = 'NEW',
  Active = 'ACTIVE',
  Passive = 'PASSIVE'
}

export type AttendListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id'>
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'start'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'color'>
    )> }
  ), leader: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & { companion?: Maybe<(
      { __typename?: 'Companion' }
      & Pick<Companion, 'id'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'display_name'>
      ) }
    )> }
  )>, group: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & { soul?: Maybe<(
      { __typename?: 'Soul' }
      & Pick<Soul, 'id'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'display_name'>
      ) }
    )> }
  )> }
);

export type FutureSignupListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected'>
  & { emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status' | 'opened_first_at'>
  )>, event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'status' | 'candidates_chosen' | 'start'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'color'>
    )> }
  ) }
);

export type PersonOrganisationSelectFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name'>
);

export type PersonTagIconFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
);

export type PersonTagMultiSelectFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
);

export type Unnamed_1_QueryVariables = {};


export type Unnamed_1_Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'isAuthenticated'>
);

export type AllotmentCreateFormFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'name' | 'amount' | 'start' | 'end'>
);

export type AllotmentEditFormFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name' | 'status' | 'amount' | 'amount_left' | 'start' | 'end'>
);

export type AllotmentListItemFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name' | 'status' | 'amount' | 'amount_left' | 'start' | 'end'>
);

export type AllotmentDetailPageFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name' | 'status' | 'amount' | 'amount_left' | 'start' | 'end'>
);

export type AllotmentListQueryVariables = {};


export type AllotmentListQuery = (
  { __typename?: 'Query' }
  & { allotments: Array<(
    { __typename?: 'Allotment' }
    & AllotmentListItemFragment
  )> }
);

export type AllotmentDetailQueryVariables = {
  id: Scalars['ID'];
};


export type AllotmentDetailQuery = (
  { __typename?: 'Query' }
  & { allotment?: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id'>
    & AllotmentDetailPageFragment
  )> }
);

export type AllotmentEditQueryVariables = {
  id: Scalars['ID'];
};


export type AllotmentEditQuery = (
  { __typename?: 'Query' }
  & { allotment?: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id'>
    & AllotmentEditFormFragment
  )> }
);

export type CreateAllotmentMutationVariables = {
  input: CreateAllotmentInput;
};


export type CreateAllotmentMutation = (
  { __typename?: 'Mutation' }
  & { createAllotment: (
    { __typename?: 'Allotment' }
    & AllotmentDetailPageFragment
  ) }
);

export type UpdateAllotmentMutationVariables = {
  input: UpdateAllotmentInput;
};


export type UpdateAllotmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAllotment?: Maybe<(
    { __typename?: 'Allotment' }
    & AllotmentDetailPageFragment
  )> }
);

export type DeleteAllotmentMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteAllotmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteAllotment?: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id'>
  )> }
);

export type AttendeeGroupFragment = (
  { __typename?: 'Signup' }
  & { group: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )> }
  & AttendeeListItemFragment
);

export type AcceptSignupsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id'>
  & { signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id' | 'token' | 'has_accepted' | 'has_rejected'>
  )> }
);

export type AttendeeListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'notes'>
  & { emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status' | 'opened_first_at'>
  )>, companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile'>
    ) }
  )>, soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'notes' | 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile'>
    ) }
  )> }
);

export type AttendeeLoneCompanionFragment = (
  { __typename?: 'Signup' }
  & AttendeeListItemFragment
);

export type AttendeeLoneSoulFragment = (
  { __typename?: 'Signup' }
  & AttendeeListItemFragment
);

export type AttendeeTeamFragment = (
  { __typename?: 'Signup' }
  & { group: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )> }
  & AttendeeListItemFragment
);

export type Unnamed_2_QueryVariables = {};


export type Unnamed_2_Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'isAuthenticated'>
);

export type IsUserAuthenticatedQueryVariables = {};


export type IsUserAuthenticatedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isAuthenticated'>
);

export type AuthInfoQueryVariables = {
  branchSlug: Scalars['String'];
};


export type AuthInfoQuery = (
  { __typename?: 'Query' }
  & { authInfo?: Maybe<(
    { __typename?: 'AuthInfo' }
    & Pick<AuthInfo, 'id' | 'slug' | 'title'>
  )> }
);

export type LoginMutationVariables = {
  input: LoginInput;
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'display_name' | 'is_superadmin'>
      & { branch: (
        { __typename?: 'Branch' }
        & Pick<Branch, 'id' | 'use_legacy_newsletter'>
      ), permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type LogoutMutationVariables = {};


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout: (
    { __typename?: 'LogoutResponse' }
    & Pick<LogoutResponse, 'status' | 'message'>
  ) }
);

export type ForgotPasswordMutationVariables = {
  input: ForgotPasswordInput;
};


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword: (
    { __typename?: 'ForgotPasswordResponse' }
    & Pick<ForgotPasswordResponse, 'status' | 'message'>
  ) }
);

export type UpdateForgottenPasswordMutationVariables = {
  input: NewPasswordWithCodeInput;
};


export type UpdateForgottenPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateForgottenPassword: (
    { __typename?: 'ForgotPasswordResponse' }
    & Pick<ForgotPasswordResponse, 'status' | 'message'>
  ) }
);

export type CategoryCreateFormFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'name' | 'description' | 'color'>
);

export type CategoryEditFormFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'description' | 'color'>
);

export type CategoryListItemFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'description' | 'color' | 'deleted_at'>
);

export type CategoryDetailPageFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'description' | 'color' | 'deleted_at'>
);

export type CategoryListQueryVariables = {};


export type CategoryListQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & CategoryListItemFragment
  )> }
);

export type CategoryDetailQueryVariables = {
  id: Scalars['ID'];
};


export type CategoryDetailQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
    & CategoryDetailPageFragment
  )> }
);

export type CategoryEditQueryVariables = {
  id: Scalars['ID'];
};


export type CategoryEditQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
    & CategoryEditFormFragment
  )> }
);

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput;
};


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: (
    { __typename?: 'Category' }
    & CategoryDetailPageFragment
  ) }
);

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput;
};


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory?: Maybe<(
    { __typename?: 'Category' }
    & CategoryDetailPageFragment
  )> }
);

export type DeleteCategoryMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategory?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'deleted_at'>
  )> }
);

export type RestoreCategoryMutationVariables = {
  id: Scalars['ID'];
};


export type RestoreCategoryMutation = (
  { __typename?: 'Mutation' }
  & { restoreCategory?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteCategoryMutationVariables = {
  id: Scalars['ID'];
};


export type ForceDeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteCategory?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )> }
);

export type CompanionCreateFormFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'notes' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'first_name' | 'last_name' | 'status' | 'birthdate' | 'email' | 'gdpr_consent' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type CompanionEditFormFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'notes' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type CompanionInfoFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'notes' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind' | 'deleted_at'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'display_name' | 'first_name' | 'last_name' | 'birthdate' | 'email' | 'gdpr_consent' | 'is_newsletter_subscriber' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type CompanionListItemFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'display_name' | 'email' | 'phone' | 'mobile'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type CompanionListQueryVariables = {
  status: UserStatus;
  page?: Maybe<Scalars['Int']>;
};


export type CompanionListQuery = (
  { __typename?: 'Query' }
  & { companions?: Maybe<(
    { __typename?: 'CompanionPaginator' }
    & { data: Array<(
      { __typename?: 'Companion' }
      & CompanionListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type CompanionDetailQueryVariables = {
  id: Scalars['ID'];
};


export type CompanionDetailQuery = (
  { __typename?: 'Query' }
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & CompanionInfoFragment
  )> }
);

export type CompanionEmailsQueryVariables = {
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
};


export type CompanionEmailsQuery = (
  { __typename?: 'Query' }
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { emails?: Maybe<(
      { __typename?: 'EmailPaginator' }
      & { data: Array<(
        { __typename?: 'Email' }
        & EmailListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages' | 'lastPage'>
      ) }
    )> }
  )> }
);

export type CompanionEditQueryVariables = {
  id: Scalars['ID'];
};


export type CompanionEditQuery = (
  { __typename?: 'Query' }
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & CompanionEditFormFragment
  )> }
);

export type CreateCompanionMutationVariables = {
  input: CreateCompanionInput;
};


export type CreateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { createCompanion: (
    { __typename?: 'Companion' }
    & CompanionInfoFragment
  ) }
);

export type UpdateCompanionMutationVariables = {
  input: UpdateCompanionInput;
};


export type UpdateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanion?: Maybe<(
    { __typename?: 'Companion' }
    & CompanionInfoFragment
  )> }
);

export type ApproveCompanionMutationVariables = {
  id: Scalars['ID'];
};


export type ApproveCompanionMutation = (
  { __typename?: 'Mutation' }
  & { approveCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DenyCompanionMutationVariables = {
  id: Scalars['ID'];
};


export type DenyCompanionMutation = (
  { __typename?: 'Mutation' }
  & { denyCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type ActivateCompanionMutationVariables = {
  id: Scalars['ID'];
};


export type ActivateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { activateCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DeactivateCompanionMutationVariables = {
  id: Scalars['ID'];
};


export type DeactivateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { deactivateCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DeleteCompanionMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteCompanionMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'deleted_at'>
  )> }
);

export type RestoreCompanionMutationVariables = {
  id: Scalars['ID'];
};


export type RestoreCompanionMutation = (
  { __typename?: 'Mutation' }
  & { restoreCompanion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteCompanionMutationVariables = {
  id: Scalars['ID'];
};


export type ForceDeleteCompanionMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteCompanion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
  )> }
);

export type ContactCreateFormFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'notes' | 'donor_id'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ) }
);

export type ContactEditFormFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'notes'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ) }
);

export type ContactInfoFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'notes' | 'deleted_at'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name' | 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ) }
);

export type ContactDetailQueryVariables = {
  id: Scalars['ID'];
};


export type ContactDetailQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & ContactInfoFragment
  )> }
);

export type ContactEditQueryVariables = {
  id: Scalars['ID'];
};


export type ContactEditQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & ContactEditFormFragment
  )> }
);

export type ContactEmailsQueryVariables = {
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
};


export type ContactEmailsQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { emails?: Maybe<(
      { __typename?: 'EmailPaginator' }
      & { data: Array<(
        { __typename?: 'Email' }
        & EmailListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages' | 'lastPage'>
      ) }
    )> }
  )> }
);

export type CreateContactMutationVariables = {
  input: CreateContactInput;
};


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact: (
    { __typename?: 'Contact' }
    & ContactInfoFragment
  ) }
);

export type UpdateContactMutationVariables = {
  input: UpdateContactInput;
};


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact?: Maybe<(
    { __typename?: 'Contact' }
    & ContactInfoFragment
  )> }
);

export type DeleteContactMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'deleted_at'>
  )> }
);

export type RestoreContactMutationVariables = {
  id: Scalars['ID'];
};


export type RestoreContactMutation = (
  { __typename?: 'Mutation' }
  & { restoreContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteContactMutationVariables = {
  id: Scalars['ID'];
};


export type ForceDeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
  )> }
);

export type DonorContactListItemFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile'>
  ) }
);

export type DonorCreateFormFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'name' | 'street' | 'city' | 'postal_code' | 'notes'>
  & { logo?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'name' | 'url'>
  )>, locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  )> }
);

export type DonorEditFormFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'notes'>
  & { logo?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'name' | 'url'>
  )>, locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )> }
);

export type DonorInfoFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'notes' | 'deleted_at'>
  & { logo?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'name' | 'thumb_url'>
  )> }
);

export type DonorListItemFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'id' | 'name'>
  & { contacts: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )> }
);

export type DonorListQueryVariables = {
  page?: Maybe<Scalars['Int']>;
};


export type DonorListQuery = (
  { __typename?: 'Query' }
  & { donors?: Maybe<(
    { __typename?: 'DonorPaginator' }
    & { data: Array<(
      { __typename?: 'Donor' }
      & DonorListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type DonorDetailQueryVariables = {
  id: Scalars['ID'];
};


export type DonorDetailQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & DonorInfoFragment
  )> }
);

export type DonorContactsQueryVariables = {
  id: Scalars['ID'];
};


export type DonorContactsQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { contacts: Array<(
      { __typename?: 'Contact' }
      & DonorContactListItemFragment
    )> }
  )> }
);

export type DonorContactSelectQueryVariables = {
  id: Scalars['ID'];
};


export type DonorContactSelectQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { contacts: Array<(
      { __typename?: 'Contact' }
      & ContactSelectFragment
    )> }
  )> }
);

export type DonorLocationsQueryVariables = {
  id: Scalars['ID'];
};


export type DonorLocationsQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { locations: Array<(
      { __typename?: 'Location' }
      & LocationListItemFragment
    )> }
  )> }
);

export type DonorLocationSelectQueryVariables = {
  id: Scalars['ID'];
};


export type DonorLocationSelectQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { locations: Array<(
      { __typename?: 'Location' }
      & LocationSelectFragment
    )> }
  )> }
);

export type CreateDonorMutationVariables = {
  input: CreateDonorInput;
};


export type CreateDonorMutation = (
  { __typename?: 'Mutation' }
  & { createDonor: (
    { __typename?: 'Donor' }
    & DonorInfoFragment
  ) }
);

export type DonorEditQueryVariables = {
  id: Scalars['ID'];
};


export type DonorEditQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & DonorEditFormFragment
  )> }
);

export type UpdateDonorMutationVariables = {
  input: UpdateDonorInput;
};


export type UpdateDonorMutation = (
  { __typename?: 'Mutation' }
  & { updateDonor?: Maybe<(
    { __typename?: 'Donor' }
    & DonorInfoFragment
  )> }
);

export type SetDonorLogoMutationVariables = {
  input: SetDonorLogoInput;
};


export type SetDonorLogoMutation = (
  { __typename?: 'Mutation' }
  & { setDonorLogo?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { logo?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'name' | 'thumb_url'>
    )> }
  )> }
);

export type DeleteDonorMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteDonorMutation = (
  { __typename?: 'Mutation' }
  & { deleteDonor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id' | 'deleted_at'>
  )> }
);

export type RestoreDonorMutationVariables = {
  id: Scalars['ID'];
};


export type RestoreDonorMutation = (
  { __typename?: 'Mutation' }
  & { restoreDonor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteDonorMutationVariables = {
  id: Scalars['ID'];
};


export type ForceDeleteDonorMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteDonor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  )> }
);

export type EmailListItemFragment = (
  { __typename?: 'Email' }
  & Pick<Email, 'id' | 'status' | 'subject' | 'sent_at'>
);

export type EmailOverlayFragment = (
  { __typename?: 'Email' }
  & Pick<Email, 'id' | 'status' | 'subject' | 'sent_at' | 'body'>
);

export type EmailDetailsQueryVariables = {
  id: Scalars['ID'];
};


export type EmailDetailsQuery = (
  { __typename?: 'Query' }
  & { email?: Maybe<(
    { __typename?: 'Email' }
    & Pick<Email, 'id'>
    & EmailOverlayFragment
  )> }
);

export type EmployeeCreateFormFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'position'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'status' | 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id'>
    )> }
  ) }
);

export type EmployeeEditFormFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'position'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);

export type EmployeeListItemFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'position'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name' | 'email' | 'phone' | 'mobile'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);

export type RoleMultiSelectFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
);

export type EmployeeDetailPageFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'position' | 'deleted_at'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name' | 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);

export type EmployeeListQueryVariables = {
  status: UserStatus;
  page?: Maybe<Scalars['Int']>;
};


export type EmployeeListQuery = (
  { __typename?: 'Query' }
  & { employees?: Maybe<(
    { __typename?: 'EmployeePaginator' }
    & { data: Array<(
      { __typename?: 'Employee' }
      & EmployeeListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type EmployeeDetailQueryVariables = {
  id: Scalars['ID'];
};


export type EmployeeDetailQuery = (
  { __typename?: 'Query' }
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeDetailPageFragment
  )> }
);

export type EmployeeEditQueryVariables = {
  id: Scalars['ID'];
};


export type EmployeeEditQuery = (
  { __typename?: 'Query' }
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeEditFormFragment
  )> }
);

export type CreateEmployeeMutationVariables = {
  input: CreateEmployeeInput;
};


export type CreateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createEmployee: (
    { __typename?: 'Employee' }
    & EmployeeDetailPageFragment
  ) }
);

export type UpdateEmployeeMutationVariables = {
  input: UpdateEmployeeInput;
};


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeDetailPageFragment
  )> }
);

export type DeleteEmployeeMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { deleteEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'deleted_at'>
  )> }
);

export type RestoreEmployeeMutationVariables = {
  id: Scalars['ID'];
};


export type RestoreEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { restoreEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteEmployeeMutationVariables = {
  id: Scalars['ID'];
};


export type ForceDeleteEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )> }
);

export type AllotmentSelectFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name'>
);

export type CategoryMultiSelectFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name'>
);

export type ContactSelectFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name'>
  ) }
);

export type EventCreateFormFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'name' | 'status' | 'description' | 'start' | 'end' | 'tickets' | 'ticket_time' | 'ticket_type' | 'reservation_number' | 'ticket_retrieval_type' | 'ticket_retrieval_location' | 'notes'>
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )>, donor: (
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  ), contact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
  ), location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ), allotment?: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id'>
  )> }
);

export type EventEditFormFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'description' | 'start' | 'end' | 'tickets' | 'ticket_time' | 'ticket_type' | 'reservation_number' | 'ticket_retrieval_type' | 'ticket_retrieval_location' | 'notes'>
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>, donor: (
    { __typename?: 'Donor' }
    & Pick<Donor, 'id' | 'name'>
  ), contact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  ), location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  ), allotment?: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id' | 'name'>
  )> }
);

export type EventListItemFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'start' | 'end' | 'available_tickets' | 'taken_tickets' | 'archived' | 'candidateCount' | 'attendeeCount'>
  & { signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected'>
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'accessible'>
  ) }
);

export type LocationSelectFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name'>
);

export type RemoveSignupFragment = (
  { __typename?: 'Event' }
  & { prospects: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
  )>, signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
  )> }
);

export type SignupListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'notes'>
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )>, soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'notes' | 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )> }
);

export type EventDetailPageFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'start' | 'end' | 'notes' | 'description' | 'taken_tickets' | 'available_tickets' | 'candidates_chosen' | 'ticket_type' | 'ticket_time' | 'ticket_retrieval_type' | 'ticket_retrieval_location' | 'reservation_number' | 'archived' | 'needsInvite' | 'candidateCount' | 'attendeeCount'>
  & { prospects: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )>, candidates: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )>, attendees: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )>, groups: Array<(
    { __typename?: 'Signup' }
    & AttendeeGroupFragment
  )>, teams: Array<(
    { __typename?: 'Signup' }
    & AttendeeTeamFragment
  )>, loneSouls: Array<(
    { __typename?: 'Signup' }
    & AttendeeLoneSoulFragment
  )>, loneCompanions: Array<(
    { __typename?: 'Signup' }
    & AttendeeLoneCompanionFragment
  )>, cardholder?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'mobile' | 'phone' | 'email'>
    ) }
  )>, signups: Array<(
    { __typename?: 'Signup' }
    & { group: Array<(
      { __typename?: 'Signup' }
      & AttendeeListItemFragment
    )>, leader: Array<(
      { __typename?: 'Signup' }
      & AttendeeListItemFragment
    )> }
    & AttendeeListItemFragment
  )>, contact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'mobile' | 'phone' | 'email'>
    ) }
  ), location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'accessible' | 'meeting_point' | 'public_transport' | 'street' | 'postal_code' | 'city'>
  ) }
);

export type EventListQueryVariables = {
  page?: Maybe<Scalars['Int']>;
  status?: Maybe<EventStatus>;
  start?: Maybe<DateTimeRange>;
  donor?: Maybe<Scalars['Int']>;
  archived?: Maybe<Archived>;
  orderByField?: Maybe<Scalars['String']>;
  orderByOrder?: Maybe<SortOrder>;
};


export type EventListQuery = (
  { __typename?: 'Query' }
  & { events?: Maybe<(
    { __typename?: 'EventPaginator' }
    & { data: Array<(
      { __typename?: 'Event' }
      & EventListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type EventDetailQueryVariables = {
  id: Scalars['ID'];
};


export type EventDetailQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & EventDetailPageFragment
  )> }
);

export type EventEditQueryVariables = {
  id: Scalars['ID'];
};


export type EventEditQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & EventEditFormFragment
  )> }
);

export type UpdateEventMutationVariables = {
  input: UpdateEventInput;
};


export type UpdateEventMutation = (
  { __typename?: 'Mutation' }
  & { updateEvent?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type CancelEventMutationVariables = {
  input: CancelEventInput;
};


export type CancelEventMutation = (
  { __typename?: 'Mutation' }
  & { cancelEvent?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type CreateEventMutationVariables = {
  input: CreateEventInput;
};


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename?: 'Event' }
    & EventDetailPageFragment
  ) }
);

export type DeleteEventMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteEvent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )> }
);

export type PublishEventMutationVariables = {
  id: Scalars['ID'];
};


export type PublishEventMutation = (
  { __typename?: 'Mutation' }
  & { publishEvent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'status'>
  )> }
);

export type DeleteSignupMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteSignupMutation = (
  { __typename?: 'Mutation' }
  & { deleteSignup?: Maybe<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
  )> }
);

export type AcceptSignupsMutationVariables = {
  id: Scalars['ID'];
  token: Scalars['String'];
};


export type AcceptSignupsMutation = (
  { __typename?: 'Mutation' }
  & { acceptSignups?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type AddSignupsMutationVariables = {
  input: AddSignupsInput;
};


export type AddSignupsMutation = (
  { __typename?: 'Mutation' }
  & { addSignups?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type InviteCandidatesMutationVariables = {
  id: Scalars['ID'];
};


export type InviteCandidatesMutation = (
  { __typename?: 'Mutation' }
  & { inviteCandidates?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type LocationCreateFormFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible'>
  & { donors: Array<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  )> }
);

export type LocationEditFormFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible'>
  & { donors: Array<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id' | 'name'>
  )> }
);

export type LocationInfoFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible' | 'deleted_at'>
);

export type LocationListItemFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible' | 'deleted_at'>
);

export type LocationListQueryVariables = {
  page?: Maybe<Scalars['Int']>;
};


export type LocationListQuery = (
  { __typename?: 'Query' }
  & { locations?: Maybe<(
    { __typename?: 'LocationPaginator' }
    & { data: Array<(
      { __typename?: 'Location' }
      & LocationListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type LocationDetailQueryVariables = {
  id: Scalars['ID'];
};


export type LocationDetailQuery = (
  { __typename?: 'Query' }
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
    & LocationInfoFragment
  )> }
);

export type LocationDonorsQueryVariables = {
  id: Scalars['ID'];
};


export type LocationDonorsQuery = (
  { __typename?: 'Query' }
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
    & { donors: Array<(
      { __typename?: 'Donor' }
      & DonorListItemFragment
    )> }
  )> }
);

export type CreateLocationMutationVariables = {
  input: CreateLocationInput;
};


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation: (
    { __typename?: 'Location' }
    & LocationInfoFragment
  ) }
);

export type LocationEditQueryVariables = {
  id: Scalars['ID'];
};


export type LocationEditQuery = (
  { __typename?: 'Query' }
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
    & LocationEditFormFragment
  )> }
);

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput;
};


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation?: Maybe<(
    { __typename?: 'Location' }
    & LocationInfoFragment
  )> }
);

export type DeleteLocationMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & { deleteLocation?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'deleted_at'>
  )> }
);

export type RestoreLocationMutationVariables = {
  id: Scalars['ID'];
};


export type RestoreLocationMutation = (
  { __typename?: 'Mutation' }
  & { restoreLocation?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteLocationMutationVariables = {
  id: Scalars['ID'];
};


export type ForceDeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteLocation?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  )> }
);

export type CandidateListFragment = (
  { __typename?: 'Signup' }
  & { group: Array<(
    { __typename?: 'Signup' }
    & CandidateListItemFragment
  )>, leader: Array<(
    { __typename?: 'Signup' }
    & CandidateListItemFragment
  )> }
  & CandidateListItemFragment
);

export type CandidateListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'is_cardholder' | 'notes' | 'priority' | 'is_first_attend'>
  & { conflicting_signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'start'>
    ) }
  )>, emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status' | 'opened_first_at'>
  )>, companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng'>
    ) }
  )>, soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'notes' | 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng'>
    ) }
  )> }
);

export type CompanionMatchListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'notes' | 'priority'>
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng'>
    ) }
  )> }
);

export type CompanionMatchingTabFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id'>
  & { signups: Array<(
    { __typename?: 'Signup' }
    & CompanionMatchListItemFragment
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'street' | 'postal_code' | 'city' | 'lat' | 'lng'>
  ) }
);

export type ProspectGroupListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'notes' | 'priority' | 'is_first_attend'>
  & { conflicting_signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'start'>
    ) }
  )>, companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'birthdate'>
    ) }
  )>, group: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id' | 'notes' | 'priority' | 'is_first_attend'>
    & { conflicting_signups: Array<(
      { __typename?: 'Signup' }
      & Pick<Signup, 'id'>
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name' | 'start'>
      ) }
    )>, soul?: Maybe<(
      { __typename?: 'Soul' }
      & Pick<Soul, 'id' | 'notes' | 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
      )>, user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'display_name' | 'birthdate'>
      ) }
    )> }
  )> }
);

export type ProspectListFragment = (
  { __typename?: 'Signup' }
  & { group: Array<(
    { __typename?: 'Signup' }
    & ProspectListItemFragment
  )>, leader: Array<(
    { __typename?: 'Signup' }
    & ProspectListItemFragment
  )> }
  & ProspectListItemFragment
);

export type ProspectListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'notes' | 'priority' | 'is_first_attend'>
  & { conflicting_signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'start'>
    ) }
  )>, emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status' | 'opened_first_at'>
  )>, companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'notes' | 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng'>
    ) }
  )>, soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'notes' | 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng'>
    ) }
  )> }
);

export type MatchingPageFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'start' | 'end' | 'taken_tickets' | 'available_tickets' | 'candidates_chosen'>
  & { cardholder?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name' | 'mobile' | 'phone' | 'email'>
    ) }
  )>, categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'color'>
  )>, signups: Array<(
    { __typename?: 'Signup' }
    & CandidateListFragment
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'accessible' | 'meeting_point' | 'public_transport' | 'street' | 'postal_code' | 'city' | 'lat' | 'lng'>
  ) }
);

export type EventMatchingQueryVariables = {
  id: Scalars['ID'];
};


export type EventMatchingQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & MatchingPageFragment
  )> }
);

export type UpdateEventCandidatesMutationVariables = {
  input: UpdateCandidatesInput;
};


export type UpdateEventCandidatesMutation = (
  { __typename?: 'Mutation' }
  & { updateCandidates?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type CandidateSignupQueryVariables = {
  id: Scalars['ID'];
};


export type CandidateSignupQuery = (
  { __typename?: 'Query' }
  & { signup?: Maybe<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & CandidateListItemFragment
  )> }
);

export type ProspectSignupQueryVariables = {
  id: Scalars['ID'];
};


export type ProspectSignupQuery = (
  { __typename?: 'Query' }
  & { signup?: Maybe<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & ProspectListItemFragment
  )> }
);

export type ProspectGroupSignupQueryVariables = {
  id: Scalars['ID'];
};


export type ProspectGroupSignupQuery = (
  { __typename?: 'Query' }
  & { signup?: Maybe<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & ProspectGroupListItemFragment
  )> }
);

export type CompanionMatchingQueryVariables = {
  id: Scalars['ID'];
};


export type CompanionMatchingQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & CompanionMatchingTabFragment
  )> }
);

export type SoulAttendsQueryVariables = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type SoulAttendsQuery = (
  { __typename?: 'Query' }
  & { soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { attends?: Maybe<(
      { __typename?: 'SignupPaginator' }
      & { data: Array<(
        { __typename?: 'Signup' }
        & AttendListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
      ) }
    )> }
  )> }
);

export type CompanionAttendsQueryVariables = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type CompanionAttendsQuery = (
  { __typename?: 'Query' }
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { attends?: Maybe<(
      { __typename?: 'SignupPaginator' }
      & { data: Array<(
        { __typename?: 'Signup' }
        & AttendListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
      ) }
    )> }
  )> }
);

export type SoulFutureSignupsQueryVariables = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type SoulFutureSignupsQuery = (
  { __typename?: 'Query' }
  & { soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { future_signups?: Maybe<(
      { __typename?: 'SignupPaginator' }
      & { data: Array<(
        { __typename?: 'Signup' }
        & FutureSignupListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
      ) }
    )> }
  )> }
);

export type CompanionFutureSignupsQueryVariables = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type CompanionFutureSignupsQuery = (
  { __typename?: 'Query' }
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { future_signups?: Maybe<(
      { __typename?: 'SignupPaginator' }
      & { data: Array<(
        { __typename?: 'Signup' }
        & FutureSignupListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
      ) }
    )> }
  )> }
);

export type NewsletterEventListItemFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'start' | 'end'>
  & { location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  ) }
);

export type NewsletterCreateFormFragment = (
  { __typename?: 'Newsletter' }
  & Pick<Newsletter, 'subject' | 'body' | 'segment'>
  & { events: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )> }
);

export type NewsletterListItemFragment = (
  { __typename?: 'Newsletter' }
  & Pick<Newsletter, 'id' | 'subject' | 'status' | 'sent_at' | 'segment'>
);

export type NewsletterDetailPageFragment = (
  { __typename?: 'Newsletter' }
  & Pick<Newsletter, 'id' | 'status' | 'subject' | 'body' | 'sent_at' | 'segment'>
  & { events: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name'>
  )> }
);

export type NewsletterListQueryVariables = {
  page?: Maybe<Scalars['Int']>;
  sentAt?: Maybe<DateTimeRange>;
};


export type NewsletterListQuery = (
  { __typename?: 'Query' }
  & { newsletters?: Maybe<(
    { __typename?: 'NewsletterPaginator' }
    & { data: Array<(
      { __typename?: 'Newsletter' }
      & NewsletterListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type NewsletterDetailQueryVariables = {
  id: Scalars['ID'];
};


export type NewsletterDetailQuery = (
  { __typename?: 'Query' }
  & { newsletter?: Maybe<(
    { __typename?: 'Newsletter' }
    & Pick<Newsletter, 'id'>
    & NewsletterDetailPageFragment
  )> }
);

export type CreateNewsletterMutationVariables = {
  input: CreateNewsletterInput;
};


export type CreateNewsletterMutation = (
  { __typename?: 'Mutation' }
  & { createNewsletter: (
    { __typename?: 'Newsletter' }
    & NewsletterDetailPageFragment
  ) }
);

export type OrganisationCompanionListItemFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type OrganisationCreateFormFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'name' | 'description' | 'notes'>
  & { souls: Array<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
  )>, companions: Array<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
  )> }
);

export type OrganisationEditFormFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name' | 'description' | 'notes' | 'deleted_at'>
  & { souls: Array<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )>, companions: Array<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'display_name'>
    ) }
  )> }
);

export type OrganisationListItemFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name' | 'deleted_at'>
);

export type OrganisationSoulListItemFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'display_name'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type OrganisationDetailPageFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name' | 'description' | 'notes' | 'deleted_at'>
  & { souls: Array<(
    { __typename?: 'Soul' }
    & OrganisationSoulListItemFragment
  )>, companions: Array<(
    { __typename?: 'Companion' }
    & OrganisationCompanionListItemFragment
  )> }
);

export type OrganisationListQueryVariables = {};


export type OrganisationListQuery = (
  { __typename?: 'Query' }
  & { organisations: Array<(
    { __typename?: 'Organisation' }
    & OrganisationListItemFragment
  )> }
);

export type OrganisationDetailQueryVariables = {
  id: Scalars['ID'];
};


export type OrganisationDetailQuery = (
  { __typename?: 'Query' }
  & { organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
    & OrganisationDetailPageFragment
  )> }
);

export type OrganisationEditQueryVariables = {
  id: Scalars['ID'];
};


export type OrganisationEditQuery = (
  { __typename?: 'Query' }
  & { organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
    & OrganisationEditFormFragment
  )> }
);

export type CreateOrganisationMutationVariables = {
  input: CreateOrganisationInput;
};


export type CreateOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { createOrganisation: (
    { __typename?: 'Organisation' }
    & OrganisationDetailPageFragment
  ) }
);

export type UpdateOrganisationMutationVariables = {
  input: UpdateOrganisationInput;
};


export type UpdateOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganisation?: Maybe<(
    { __typename?: 'Organisation' }
    & OrganisationDetailPageFragment
  )> }
);

export type DeleteOrganisationMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrganisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'deleted_at'>
  )> }
);

export type RestoreOrganisationMutationVariables = {
  id: Scalars['ID'];
};


export type RestoreOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { restoreOrganisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteOrganisationMutationVariables = {
  id: Scalars['ID'];
};


export type ForceDeleteOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteOrganisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
  )> }
);

export type PermissionMultiSelectFragment = (
  { __typename?: 'Permission' }
  & Pick<Permission, 'id' | 'title'>
);

export type RoleCreateFormFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'name'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id'>
  )> }
);

export type RoleEditFormFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id' | 'title'>
  )> }
);

export type RoleListItemFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id' | 'title'>
  )> }
);

export type RoleDetailPageFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id' | 'title'>
  )> }
);

export type RoleListQueryVariables = {};


export type RoleListQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & RoleListItemFragment
  )> }
);

export type RoleDetailQueryVariables = {
  id: Scalars['ID'];
};


export type RoleDetailQuery = (
  { __typename?: 'Query' }
  & { role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
    & RoleDetailPageFragment
  )> }
);

export type CreateRoleMutationVariables = {
  input: CreateRoleInput;
};


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole: (
    { __typename?: 'Role' }
    & RoleDetailPageFragment
  ) }
);

export type RoleEditQueryVariables = {
  id: Scalars['ID'];
};


export type RoleEditQuery = (
  { __typename?: 'Query' }
  & { role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
    & RoleEditFormFragment
  )> }
);

export type UpdateRoleMutationVariables = {
  input: UpdateRoleInput;
};


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateRole?: Maybe<(
    { __typename?: 'Role' }
    & RoleDetailPageFragment
  )> }
);

export type DeleteRoleMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & { deleteRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
  )> }
);

export type SettingsDetailPageFragment = (
  { __typename?: 'Branch' }
  & Pick<Branch, 'id' | 'slug' | 'api_key' | 'redirect_events' | 'redirect_ticket_accept_ok' | 'redirect_ticket_accept_not_found' | 'redirect_password_reset'>
);

export type BranchDetailQueryVariables = {
  branchId: Scalars['ID'];
};


export type BranchDetailQuery = (
  { __typename?: 'Query' }
  & { branch?: Maybe<(
    { __typename?: 'Branch' }
    & Pick<Branch, 'id'>
    & SettingsDetailPageFragment
  )> }
);

export type UpdateBranchMutationVariables = {
  input: UpdateBranchInput;
};


export type UpdateBranchMutation = (
  { __typename?: 'Mutation' }
  & { updateBranch?: Maybe<(
    { __typename?: 'Branch' }
    & SettingsDetailPageFragment
  )> }
);

export type RegenerateApiKeyMutationVariables = {
  branchId: Scalars['ID'];
};


export type RegenerateApiKeyMutation = (
  { __typename?: 'Mutation' }
  & { regenerateApiKey: (
    { __typename?: 'Branch' }
    & SettingsDetailPageFragment
  ) }
);

export type SoulCreateFormFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'target_group' | 'notes' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'first_name' | 'last_name' | 'status' | 'birthdate' | 'email' | 'gdpr_consent' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type SoulEditFormFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'target_group' | 'notes' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'birthdate' | 'email' | 'gdpr_consent' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type SoulInfoFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'target_group' | 'notes' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind' | 'deleted_at'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'display_name' | 'first_name' | 'last_name' | 'birthdate' | 'email' | 'gdpr_consent' | 'is_newsletter_subscriber' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type SoulListItemFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'display_name' | 'email' | 'phone' | 'mobile'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type SoulListQueryVariables = {
  status: UserStatus;
  page?: Maybe<Scalars['Int']>;
};


export type SoulListQuery = (
  { __typename?: 'Query' }
  & { souls?: Maybe<(
    { __typename?: 'SoulPaginator' }
    & { data: Array<(
      { __typename?: 'Soul' }
      & SoulListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type SoulDetailQueryVariables = {
  id: Scalars['ID'];
};


export type SoulDetailQuery = (
  { __typename?: 'Query' }
  & { soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & SoulInfoFragment
  )> }
);

export type SoulEmailsQueryVariables = {
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
};


export type SoulEmailsQuery = (
  { __typename?: 'Query' }
  & { soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { emails?: Maybe<(
      { __typename?: 'EmailPaginator' }
      & { data: Array<(
        { __typename?: 'Email' }
        & EmailListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages' | 'lastPage'>
      ) }
    )> }
  )> }
);

export type SoulEditQueryVariables = {
  id: Scalars['ID'];
};


export type SoulEditQuery = (
  { __typename?: 'Query' }
  & { soul?: Maybe<(
    { __typename?: 'Soul' }
    & SoulEditFormFragment
  )> }
);

export type CreateSoulMutationVariables = {
  input: CreateSoulInput;
};


export type CreateSoulMutation = (
  { __typename?: 'Mutation' }
  & { createSoul: (
    { __typename?: 'Soul' }
    & SoulInfoFragment
  ) }
);

export type UpdateSoulMutationVariables = {
  input: UpdateSoulInput;
};


export type UpdateSoulMutation = (
  { __typename?: 'Mutation' }
  & { updateSoul?: Maybe<(
    { __typename?: 'Soul' }
    & SoulInfoFragment
  )> }
);

export type ApproveSoulMutationVariables = {
  id: Scalars['ID'];
};


export type ApproveSoulMutation = (
  { __typename?: 'Mutation' }
  & { approveSoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DenySoulMutationVariables = {
  id: Scalars['ID'];
};


export type DenySoulMutation = (
  { __typename?: 'Mutation' }
  & { denySoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type ActivateSoulMutationVariables = {
  id: Scalars['ID'];
};


export type ActivateSoulMutation = (
  { __typename?: 'Mutation' }
  & { activateSoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DeactivateSoulMutationVariables = {
  id: Scalars['ID'];
};


export type DeactivateSoulMutation = (
  { __typename?: 'Mutation' }
  & { deactivateSoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status'>
    ) }
  ) }
);

export type DeleteSoulMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteSoulMutation = (
  { __typename?: 'Mutation' }
  & { deleteSoul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'deleted_at'>
  )> }
);

export type RestoreSoulMutationVariables = {
  id: Scalars['ID'];
};


export type RestoreSoulMutation = (
  { __typename?: 'Mutation' }
  & { restoreSoul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'deleted_at'>
  )> }
);

export type ForceDeleteSoulMutationVariables = {
  id: Scalars['ID'];
};


export type ForceDeleteSoulMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteSoul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
  )> }
);

export type CategoryStatsFragment = (
  { __typename?: 'CategoryStatsPayload' }
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'color'>
  ), data: (
    { __typename?: 'CategoryDataPayload' }
    & Pick<CategoryDataPayload, 'tickets' | 'signups' | 'previous_tickets' | 'previous_signups'>
  ) }
);

export type TargetGroupStatsFragment = (
  { __typename?: 'TargetGroupStatsPayload' }
  & Pick<TargetGroupStatsPayload, 'target_group'>
  & { data: (
    { __typename?: 'TargetGroupDataPayload' }
    & Pick<TargetGroupDataPayload, 'signups' | 'attends' | 'previous_signups' | 'previous_attends'>
  ) }
);

export type OverviewStatsQueryVariables = {
  input: OverviewStatsInput;
};


export type OverviewStatsQuery = (
  { __typename?: 'Query' }
  & { overviewStats: (
    { __typename?: 'OverviewStatsPayload' }
    & Pick<OverviewStatsPayload, 'tickets' | 'signups' | 'attends'>
  ) }
);

export type TargetGroupStatsQueryVariables = {
  input: TargetGroupStatsInput;
};


export type TargetGroupStatsQuery = (
  { __typename?: 'Query' }
  & { targetGroupStats: Array<(
    { __typename?: 'TargetGroupStatsPayload' }
    & Pick<TargetGroupStatsPayload, 'target_group'>
    & TargetGroupStatsFragment
  )> }
);

export type CategoryStatsQueryVariables = {
  input: CategoryStatsInput;
};


export type CategoryStatsQuery = (
  { __typename?: 'Query' }
  & { categoryStats: Array<(
    { __typename?: 'CategoryStatsPayload' }
    & { category: (
      { __typename?: 'Category' }
      & Pick<Category, 'id'>
    ) }
    & CategoryStatsFragment
  )> }
);

export type TagCreateFormFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'name' | 'icon' | 'intent' | 'description'>
);

export type TagEditFormFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent' | 'description'>
);

export type TagListItemFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent' | 'description'>
);

export type TagDetailPageFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent' | 'description'>
);

export type TagListQueryVariables = {};


export type TagListQuery = (
  { __typename?: 'Query' }
  & { tags: Array<(
    { __typename?: 'Tag' }
    & TagListItemFragment
  )> }
);

export type TagDetailQueryVariables = {
  id: Scalars['ID'];
};


export type TagDetailQuery = (
  { __typename?: 'Query' }
  & { tag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id'>
    & TagDetailPageFragment
  )> }
);

export type TagEditQueryVariables = {
  id: Scalars['ID'];
};


export type TagEditQuery = (
  { __typename?: 'Query' }
  & { tag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id'>
    & TagEditFormFragment
  )> }
);

export type CreateTagMutationVariables = {
  input: CreateTagInput;
};


export type CreateTagMutation = (
  { __typename?: 'Mutation' }
  & { createTag: (
    { __typename?: 'Tag' }
    & TagDetailPageFragment
  ) }
);

export type UpdateTagMutationVariables = {
  input: UpdateTagInput;
};


export type UpdateTagMutation = (
  { __typename?: 'Mutation' }
  & { updateTag?: Maybe<(
    { __typename?: 'Tag' }
    & TagDetailPageFragment
  )> }
);

export type DeleteTagMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteTagMutation = (
  { __typename?: 'Mutation' }
  & { deleteTag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id'>
  )> }
);

export type TagsQueryVariables = {};


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagMultiSelectFragment
  )> }
);

export type PermissionsQueryVariables = {};


export type PermissionsQuery = (
  { __typename?: 'Query' }
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & PermissionMultiSelectFragment
  )> }
);

export type RolesQueryVariables = {};


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & RoleMultiSelectFragment
  )> }
);

export type CategoriesQueryVariables = {};


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & CategoryMultiSelectFragment
  )> }
);

export type AllotmentsQueryVariables = {};


export type AllotmentsQuery = (
  { __typename?: 'Query' }
  & { allotments: Array<(
    { __typename?: 'Allotment' }
    & AllotmentSelectFragment
  )> }
);

export type OrganisationsQueryVariables = {};


export type OrganisationsQuery = (
  { __typename?: 'Query' }
  & { organisations: Array<(
    { __typename?: 'Organisation' }
    & PersonOrganisationSelectFragment
  )> }
);

export type EmailUsersMutationVariables = {
  input: EmailUsersInput;
};


export type EmailUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'emailUsers'>
);

export const AttendListItemFragmentDoc = gql`
    fragment AttendListItem on Signup {
  id
  event {
    id
    name
    start
    categories {
      id
      name
      color
    }
  }
  leader {
    id
    companion {
      id
      user {
        id
        display_name
      }
    }
  }
  group {
    id
    soul {
      id
      user {
        id
        display_name
      }
    }
  }
}
    `;
export const FutureSignupListItemFragmentDoc = gql`
    fragment FutureSignupListItem on Signup {
  id
  is_candidate
  is_invited
  has_accepted
  has_rejected
  emails {
    id
    status
    opened_first_at
  }
  event {
    id
    name
    status
    candidates_chosen
    start
    categories {
      id
      name
      color
    }
  }
}
    `;
export const PersonOrganisationSelectFragmentDoc = gql`
    fragment PersonOrganisationSelect on Organisation {
  id
  name
}
    `;
export const PersonTagMultiSelectFragmentDoc = gql`
    fragment PersonTagMultiSelect on Tag {
  id
  name
  icon
  intent
}
    `;
export const AllotmentCreateFormFragmentDoc = gql`
    fragment AllotmentCreateForm on Allotment {
  name
  amount
  start
  end
}
    `;
export const AllotmentEditFormFragmentDoc = gql`
    fragment AllotmentEditForm on Allotment {
  id
  name
  status
  amount
  amount_left
  start
  end
}
    `;
export const AllotmentListItemFragmentDoc = gql`
    fragment AllotmentListItem on Allotment {
  id
  name
  status
  amount
  amount_left
  start
  end
}
    `;
export const AllotmentDetailPageFragmentDoc = gql`
    fragment AllotmentDetailPage on Allotment {
  id
  name
  status
  amount
  amount_left
  start
  end
}
    `;
export const AcceptSignupsFragmentDoc = gql`
    fragment AcceptSignups on Event {
  id
  signups {
    id
    token
    has_accepted
    has_rejected
  }
}
    `;
export const CategoryCreateFormFragmentDoc = gql`
    fragment CategoryCreateForm on Category {
  name
  description
  color
}
    `;
export const CategoryEditFormFragmentDoc = gql`
    fragment CategoryEditForm on Category {
  id
  name
  description
  color
}
    `;
export const CategoryListItemFragmentDoc = gql`
    fragment CategoryListItem on Category {
  id
  name
  description
  color
  deleted_at
}
    `;
export const CategoryDetailPageFragmentDoc = gql`
    fragment CategoryDetailPage on Category {
  id
  name
  description
  color
  deleted_at
}
    `;
export const CompanionCreateFormFragmentDoc = gql`
    fragment CompanionCreateForm on Companion {
  notes
  can_wheelchair
  can_wheeled_walker
  can_drive
  can_blind
  user {
    first_name
    last_name
    status
    birthdate
    email
    gdpr_consent
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const CompanionEditFormFragmentDoc = gql`
    fragment CompanionEditForm on Companion {
  id
  notes
  can_wheelchair
  can_wheeled_walker
  can_drive
  can_blind
  user {
    id
    first_name
    last_name
    birthdate
    email
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const CompanionInfoFragmentDoc = gql`
    fragment CompanionInfo on Companion {
  id
  notes
  can_wheelchair
  can_wheeled_walker
  can_drive
  can_blind
  deleted_at
  user {
    id
    status
    display_name
    first_name
    last_name
    birthdate
    email
    gdpr_consent
    is_newsletter_subscriber
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const PersonTagIconFragmentDoc = gql`
    fragment PersonTagIcon on Tag {
  id
  name
  icon
  intent
}
    `;
export const CompanionListItemFragmentDoc = gql`
    fragment CompanionListItem on Companion {
  id
  can_wheelchair
  can_wheeled_walker
  can_drive
  can_blind
  user {
    id
    status
    display_name
    email
    phone
    mobile
  }
  organisation {
    id
    name
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const ContactCreateFormFragmentDoc = gql`
    fragment ContactCreateForm on Contact {
  notes
  donor_id
  user {
    first_name
    last_name
    email
    phone
    mobile
    street
    city
    postal_code
  }
}
    `;
export const ContactEditFormFragmentDoc = gql`
    fragment ContactEditForm on Contact {
  id
  notes
  user {
    id
    first_name
    last_name
    email
    phone
    mobile
    street
    city
    postal_code
  }
}
    `;
export const ContactInfoFragmentDoc = gql`
    fragment ContactInfo on Contact {
  id
  notes
  deleted_at
  user {
    id
    display_name
    first_name
    last_name
    email
    phone
    mobile
    street
    city
    postal_code
  }
}
    `;
export const DonorContactListItemFragmentDoc = gql`
    fragment DonorContactListItem on Contact {
  id
  user {
    id
    display_name
    email
    phone
    mobile
  }
}
    `;
export const DonorCreateFormFragmentDoc = gql`
    fragment DonorCreateForm on Donor {
  name
  street
  city
  postal_code
  notes
  logo {
    name
    url
  }
  locations {
    id
  }
}
    `;
export const DonorEditFormFragmentDoc = gql`
    fragment DonorEditForm on Donor {
  id
  name
  street
  city
  postal_code
  notes
  logo {
    name
    url
  }
  locations {
    id
    name
  }
}
    `;
export const DonorInfoFragmentDoc = gql`
    fragment DonorInfo on Donor {
  id
  name
  street
  city
  postal_code
  notes
  logo {
    name
    thumb_url
  }
  deleted_at
}
    `;
export const DonorListItemFragmentDoc = gql`
    fragment DonorListItem on Donor {
  id
  name
  contacts {
    id
    user {
      id
      display_name
    }
  }
}
    `;
export const EmailListItemFragmentDoc = gql`
    fragment EmailListItem on Email {
  id
  status
  subject
  sent_at
}
    `;
export const EmailOverlayFragmentDoc = gql`
    fragment EmailOverlay on Email {
  id
  status
  subject
  sent_at
  body
}
    `;
export const EmployeeCreateFormFragmentDoc = gql`
    fragment EmployeeCreateForm on Employee {
  position
  user {
    status
    first_name
    last_name
    email
    phone
    mobile
    street
    city
    postal_code
    roles {
      id
    }
  }
}
    `;
export const EmployeeEditFormFragmentDoc = gql`
    fragment EmployeeEditForm on Employee {
  id
  position
  user {
    id
    status
    first_name
    last_name
    email
    phone
    mobile
    street
    city
    postal_code
    roles {
      id
      name
    }
  }
}
    `;
export const EmployeeListItemFragmentDoc = gql`
    fragment EmployeeListItem on Employee {
  id
  position
  user {
    id
    display_name
    email
    phone
    mobile
    roles {
      id
      name
    }
  }
}
    `;
export const RoleMultiSelectFragmentDoc = gql`
    fragment RoleMultiSelect on Role {
  id
  name
}
    `;
export const EmployeeDetailPageFragmentDoc = gql`
    fragment EmployeeDetailPage on Employee {
  id
  position
  deleted_at
  user {
    id
    display_name
    first_name
    last_name
    email
    phone
    mobile
    street
    city
    postal_code
    roles {
      id
      name
    }
  }
}
    `;
export const AllotmentSelectFragmentDoc = gql`
    fragment AllotmentSelect on Allotment {
  id
  name
}
    `;
export const CategoryMultiSelectFragmentDoc = gql`
    fragment CategoryMultiSelect on Category {
  id
  name
}
    `;
export const ContactSelectFragmentDoc = gql`
    fragment ContactSelect on Contact {
  id
  user {
    id
    display_name
  }
}
    `;
export const EventCreateFormFragmentDoc = gql`
    fragment EventCreateForm on Event {
  name
  status
  description
  start
  end
  tickets
  ticket_time
  ticket_type
  reservation_number
  ticket_retrieval_type
  ticket_retrieval_location
  notes
  categories {
    id
  }
  donor {
    id
  }
  contact {
    id
  }
  location {
    id
  }
  allotment {
    id
  }
}
    `;
export const EventEditFormFragmentDoc = gql`
    fragment EventEditForm on Event {
  id
  name
  status
  description
  start
  end
  tickets
  ticket_time
  ticket_type
  reservation_number
  ticket_retrieval_type
  ticket_retrieval_location
  notes
  categories {
    id
    name
  }
  donor {
    id
    name
  }
  contact {
    id
    user {
      id
      display_name
    }
  }
  location {
    id
    name
  }
  allotment {
    id
    name
  }
}
    `;
export const EventListItemFragmentDoc = gql`
    fragment EventListItem on Event {
  id
  name
  status
  start
  end
  available_tickets
  taken_tickets
  archived
  candidateCount @client
  attendeeCount @client
  signups {
    id
    is_candidate
    is_invited
    has_accepted
    has_rejected
  }
  location {
    id
    name
    accessible
  }
}
    `;
export const LocationSelectFragmentDoc = gql`
    fragment LocationSelect on Location {
  id
  name
}
    `;
export const RemoveSignupFragmentDoc = gql`
    fragment RemoveSignup on Event {
  prospects @client {
    id
  }
  signups {
    id
  }
}
    `;
export const SignupListItemFragmentDoc = gql`
    fragment SignupListItem on Signup {
  id
  event_id
  notes
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
    }
  }
  soul {
    id
    notes
    buys_ticket
    needs_blind
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
    }
  }
}
    `;
export const AttendeeListItemFragmentDoc = gql`
    fragment AttendeeListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  notes
  emails {
    id
    status
    opened_first_at
  }
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      email
      phone
      mobile
    }
  }
  soul {
    id
    notes
    buys_ticket
    needs_blind
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      email
      phone
      mobile
    }
  }
}
    `;
export const AttendeeGroupFragmentDoc = gql`
    fragment AttendeeGroup on Signup {
  ...AttendeeListItem
  group {
    ...AttendeeListItem
  }
}
    ${AttendeeListItemFragmentDoc}`;
export const AttendeeTeamFragmentDoc = gql`
    fragment AttendeeTeam on Signup {
  ...AttendeeListItem
  group {
    ...AttendeeListItem
  }
}
    ${AttendeeListItemFragmentDoc}`;
export const AttendeeLoneSoulFragmentDoc = gql`
    fragment AttendeeLoneSoul on Signup {
  ...AttendeeListItem
}
    ${AttendeeListItemFragmentDoc}`;
export const AttendeeLoneCompanionFragmentDoc = gql`
    fragment AttendeeLoneCompanion on Signup {
  ...AttendeeListItem
}
    ${AttendeeListItemFragmentDoc}`;
export const EventDetailPageFragmentDoc = gql`
    fragment EventDetailPage on Event {
  id
  name
  status
  start
  end
  notes
  description
  taken_tickets
  available_tickets
  candidates_chosen
  ticket_type
  ticket_time
  ticket_retrieval_type
  ticket_retrieval_location
  reservation_number
  archived
  needsInvite @client
  candidateCount @client
  attendeeCount @client
  prospects @client {
    ...AttendeeListItem
  }
  candidates @client {
    ...AttendeeListItem
  }
  attendees @client {
    ...AttendeeListItem
  }
  groups @client {
    ...AttendeeGroup
  }
  teams @client {
    ...AttendeeTeam
  }
  loneSouls @client {
    ...AttendeeLoneSoul
  }
  loneCompanions @client {
    ...AttendeeLoneCompanion
  }
  cardholder {
    id
    user {
      id
      display_name
      mobile
      phone
      email
    }
  }
  signups {
    ...AttendeeListItem
    group {
      ...AttendeeListItem
    }
    leader {
      ...AttendeeListItem
    }
  }
  contact {
    id
    user {
      id
      display_name
      mobile
      phone
      email
    }
  }
  location {
    id
    name
    accessible
    meeting_point
    public_transport
    street
    postal_code
    city
  }
}
    ${AttendeeListItemFragmentDoc}
${AttendeeGroupFragmentDoc}
${AttendeeTeamFragmentDoc}
${AttendeeLoneSoulFragmentDoc}
${AttendeeLoneCompanionFragmentDoc}`;
export const LocationCreateFormFragmentDoc = gql`
    fragment LocationCreateForm on Location {
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
  donors {
    id
  }
}
    `;
export const LocationEditFormFragmentDoc = gql`
    fragment LocationEditForm on Location {
  id
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
  donors {
    id
    name
  }
}
    `;
export const LocationInfoFragmentDoc = gql`
    fragment LocationInfo on Location {
  id
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
  deleted_at
}
    `;
export const LocationListItemFragmentDoc = gql`
    fragment LocationListItem on Location {
  id
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
  deleted_at
}
    `;
export const CompanionMatchListItemFragmentDoc = gql`
    fragment CompanionMatchListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  notes
  priority
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      birthdate
      email
      phone
      mobile
      lat
      lng
    }
  }
}
    `;
export const CompanionMatchingTabFragmentDoc = gql`
    fragment CompanionMatchingTab on Event {
  id
  signups {
    ...CompanionMatchListItem
  }
  location {
    id
    street
    postal_code
    city
    lat
    lng
  }
}
    ${CompanionMatchListItemFragmentDoc}`;
export const ProspectGroupListItemFragmentDoc = gql`
    fragment ProspectGroupListItem on Signup {
  id
  notes
  priority
  is_first_attend
  conflicting_signups {
    id
    event {
      id
      name
      start
    }
  }
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      birthdate
    }
  }
  group {
    id
    notes
    priority
    is_first_attend
    conflicting_signups {
      id
      event {
        id
        name
        start
      }
    }
    soul {
      id
      notes
      buys_ticket
      needs_blind
      needs_wheelchair
      needs_wheeled_walker
      needs_drive
      needs_trainstation_fetch
      tags {
        id
        name
        icon
        intent
      }
      user {
        id
        display_name
        birthdate
      }
    }
  }
}
    `;
export const ProspectListItemFragmentDoc = gql`
    fragment ProspectListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  notes
  priority
  is_first_attend
  conflicting_signups {
    id
    event {
      id
      name
      start
    }
  }
  emails {
    id
    status
    opened_first_at
  }
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      birthdate
      email
      phone
      mobile
      lat
      lng
    }
  }
  soul {
    id
    notes
    buys_ticket
    needs_blind
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      birthdate
      email
      phone
      mobile
      lat
      lng
    }
  }
}
    `;
export const ProspectListFragmentDoc = gql`
    fragment ProspectList on Signup {
  ...ProspectListItem
  group {
    ...ProspectListItem
  }
  leader {
    ...ProspectListItem
  }
}
    ${ProspectListItemFragmentDoc}`;
export const CandidateListItemFragmentDoc = gql`
    fragment CandidateListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  is_cardholder
  notes
  priority
  is_first_attend
  conflicting_signups {
    id
    event {
      id
      name
      start
    }
  }
  emails {
    id
    status
    opened_first_at
  }
  companion {
    id
    notes
    can_blind
    can_wheelchair
    can_wheeled_walker
    can_drive
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      birthdate
      email
      phone
      mobile
      lat
      lng
    }
  }
  soul {
    id
    notes
    buys_ticket
    needs_blind
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
    user {
      id
      display_name
      birthdate
      email
      phone
      mobile
      lat
      lng
    }
  }
}
    `;
export const CandidateListFragmentDoc = gql`
    fragment CandidateList on Signup {
  ...CandidateListItem
  group {
    ...CandidateListItem
  }
  leader {
    ...CandidateListItem
  }
}
    ${CandidateListItemFragmentDoc}`;
export const MatchingPageFragmentDoc = gql`
    fragment MatchingPage on Event {
  id
  name
  status
  start
  end
  taken_tickets
  available_tickets
  candidates_chosen
  cardholder {
    id
    user {
      id
      display_name
      mobile
      phone
      email
    }
  }
  categories {
    id
    name
    color
  }
  signups {
    ...CandidateList
  }
  location {
    id
    name
    accessible
    meeting_point
    public_transport
    street
    postal_code
    city
    lat
    lng
  }
}
    ${CandidateListFragmentDoc}`;
export const NewsletterEventListItemFragmentDoc = gql`
    fragment NewsletterEventListItem on Event {
  id
  name
  start
  end
  location {
    id
    name
  }
}
    `;
export const NewsletterCreateFormFragmentDoc = gql`
    fragment NewsletterCreateForm on Newsletter {
  subject
  body
  segment
  events {
    id
  }
}
    `;
export const NewsletterListItemFragmentDoc = gql`
    fragment NewsletterListItem on Newsletter {
  id
  subject
  status
  sent_at
  segment
}
    `;
export const NewsletterDetailPageFragmentDoc = gql`
    fragment NewsletterDetailPage on Newsletter {
  id
  status
  subject
  body
  sent_at
  segment
  events {
    id
    name
  }
}
    `;
export const OrganisationCreateFormFragmentDoc = gql`
    fragment OrganisationCreateForm on Organisation {
  name
  description
  notes
  souls {
    id
  }
  companions {
    id
  }
}
    `;
export const OrganisationEditFormFragmentDoc = gql`
    fragment OrganisationEditForm on Organisation {
  id
  name
  description
  notes
  souls {
    id
    user {
      id
      display_name
    }
  }
  companions {
    id
    user {
      id
      display_name
    }
  }
  deleted_at
}
    `;
export const OrganisationListItemFragmentDoc = gql`
    fragment OrganisationListItem on Organisation {
  id
  name
  deleted_at
}
    `;
export const OrganisationSoulListItemFragmentDoc = gql`
    fragment OrganisationSoulListItem on Soul {
  id
  buys_ticket
  needs_wheelchair
  needs_wheeled_walker
  needs_drive
  needs_trainstation_fetch
  needs_blind
  user {
    id
    display_name
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const OrganisationCompanionListItemFragmentDoc = gql`
    fragment OrganisationCompanionListItem on Companion {
  id
  can_wheelchair
  can_wheeled_walker
  can_drive
  can_blind
  user {
    id
    display_name
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const OrganisationDetailPageFragmentDoc = gql`
    fragment OrganisationDetailPage on Organisation {
  id
  name
  description
  notes
  souls {
    ...OrganisationSoulListItem
  }
  companions {
    ...OrganisationCompanionListItem
  }
  deleted_at
}
    ${OrganisationSoulListItemFragmentDoc}
${OrganisationCompanionListItemFragmentDoc}`;
export const PermissionMultiSelectFragmentDoc = gql`
    fragment PermissionMultiSelect on Permission {
  id
  title
}
    `;
export const RoleCreateFormFragmentDoc = gql`
    fragment RoleCreateForm on Role {
  name
  permissions {
    id
  }
}
    `;
export const RoleEditFormFragmentDoc = gql`
    fragment RoleEditForm on Role {
  id
  name
  permissions {
    id
    title
  }
}
    `;
export const RoleListItemFragmentDoc = gql`
    fragment RoleListItem on Role {
  id
  name
  permissions {
    id
    title
  }
}
    `;
export const RoleDetailPageFragmentDoc = gql`
    fragment RoleDetailPage on Role {
  id
  name
  permissions {
    id
    title
  }
}
    `;
export const SettingsDetailPageFragmentDoc = gql`
    fragment SettingsDetailPage on Branch {
  id
  slug
  api_key
  redirect_events
  redirect_ticket_accept_ok
  redirect_ticket_accept_not_found
  redirect_password_reset
}
    `;
export const SoulCreateFormFragmentDoc = gql`
    fragment SoulCreateForm on Soul {
  target_group
  notes
  buys_ticket
  needs_wheelchair
  needs_wheeled_walker
  needs_drive
  needs_trainstation_fetch
  needs_blind
  user {
    first_name
    last_name
    status
    birthdate
    email
    gdpr_consent
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const SoulEditFormFragmentDoc = gql`
    fragment SoulEditForm on Soul {
  id
  target_group
  notes
  buys_ticket
  needs_wheelchair
  needs_wheeled_walker
  needs_drive
  needs_trainstation_fetch
  needs_blind
  user {
    id
    first_name
    last_name
    birthdate
    email
    gdpr_consent
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const SoulInfoFragmentDoc = gql`
    fragment SoulInfo on Soul {
  id
  target_group
  notes
  buys_ticket
  needs_wheelchair
  needs_wheeled_walker
  needs_drive
  needs_trainstation_fetch
  needs_blind
  deleted_at
  user {
    id
    status
    display_name
    first_name
    last_name
    birthdate
    email
    gdpr_consent
    is_newsletter_subscriber
    phone
    mobile
    street
    city
    postal_code
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const SoulListItemFragmentDoc = gql`
    fragment SoulListItem on Soul {
  id
  buys_ticket
  needs_wheelchair
  needs_wheeled_walker
  needs_drive
  needs_trainstation_fetch
  needs_blind
  user {
    id
    status
    display_name
    email
    phone
    mobile
  }
  organisation {
    id
    name
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const CategoryStatsFragmentDoc = gql`
    fragment CategoryStats on CategoryStatsPayload {
  category {
    id
    name
    color
  }
  data {
    tickets
    signups
    previous_tickets
    previous_signups
  }
}
    `;
export const TargetGroupStatsFragmentDoc = gql`
    fragment TargetGroupStats on TargetGroupStatsPayload {
  target_group
  data {
    signups
    attends
    previous_signups
    previous_attends
  }
}
    `;
export const TagCreateFormFragmentDoc = gql`
    fragment TagCreateForm on Tag {
  name
  icon
  intent
  description
}
    `;
export const TagEditFormFragmentDoc = gql`
    fragment TagEditForm on Tag {
  id
  name
  icon
  intent
  description
}
    `;
export const TagListItemFragmentDoc = gql`
    fragment TagListItem on Tag {
  id
  name
  icon
  intent
  description
}
    `;
export const TagDetailPageFragmentDoc = gql`
    fragment TagDetailPage on Tag {
  id
  name
  icon
  intent
  description
}
    `;
export const AllotmentListDocument = gql`
    query AllotmentList {
  allotments {
    ...AllotmentListItem
  }
}
    ${AllotmentListItemFragmentDoc}`;

/**
 * __useAllotmentListQuery__
 *
 * To run a query within a React component, call `useAllotmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllotmentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllotmentListQuery, AllotmentListQueryVariables>) {
        return ApolloReactHooks.useQuery<AllotmentListQuery, AllotmentListQueryVariables>(AllotmentListDocument, baseOptions);
      }
export function useAllotmentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentListQuery, AllotmentListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllotmentListQuery, AllotmentListQueryVariables>(AllotmentListDocument, baseOptions);
        }
export type AllotmentListQueryHookResult = ReturnType<typeof useAllotmentListQuery>;
export type AllotmentListLazyQueryHookResult = ReturnType<typeof useAllotmentListLazyQuery>;
export type AllotmentListQueryResult = ApolloReactCommon.QueryResult<AllotmentListQuery, AllotmentListQueryVariables>;
export const AllotmentDetailDocument = gql`
    query AllotmentDetail($id: ID!) {
  allotment(id: $id) {
    id
    ...AllotmentDetailPage
  }
}
    ${AllotmentDetailPageFragmentDoc}`;

/**
 * __useAllotmentDetailQuery__
 *
 * To run a query within a React component, call `useAllotmentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllotmentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllotmentDetailQuery, AllotmentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<AllotmentDetailQuery, AllotmentDetailQueryVariables>(AllotmentDetailDocument, baseOptions);
      }
export function useAllotmentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentDetailQuery, AllotmentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllotmentDetailQuery, AllotmentDetailQueryVariables>(AllotmentDetailDocument, baseOptions);
        }
export type AllotmentDetailQueryHookResult = ReturnType<typeof useAllotmentDetailQuery>;
export type AllotmentDetailLazyQueryHookResult = ReturnType<typeof useAllotmentDetailLazyQuery>;
export type AllotmentDetailQueryResult = ApolloReactCommon.QueryResult<AllotmentDetailQuery, AllotmentDetailQueryVariables>;
export const AllotmentEditDocument = gql`
    query AllotmentEdit($id: ID!) {
  allotment(id: $id) {
    id
    ...AllotmentEditForm
  }
}
    ${AllotmentEditFormFragmentDoc}`;

/**
 * __useAllotmentEditQuery__
 *
 * To run a query within a React component, call `useAllotmentEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllotmentEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllotmentEditQuery, AllotmentEditQueryVariables>) {
        return ApolloReactHooks.useQuery<AllotmentEditQuery, AllotmentEditQueryVariables>(AllotmentEditDocument, baseOptions);
      }
export function useAllotmentEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentEditQuery, AllotmentEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllotmentEditQuery, AllotmentEditQueryVariables>(AllotmentEditDocument, baseOptions);
        }
export type AllotmentEditQueryHookResult = ReturnType<typeof useAllotmentEditQuery>;
export type AllotmentEditLazyQueryHookResult = ReturnType<typeof useAllotmentEditLazyQuery>;
export type AllotmentEditQueryResult = ApolloReactCommon.QueryResult<AllotmentEditQuery, AllotmentEditQueryVariables>;
export const CreateAllotmentDocument = gql`
    mutation CreateAllotment($input: CreateAllotmentInput!) {
  createAllotment(input: $input) {
    ...AllotmentDetailPage
  }
}
    ${AllotmentDetailPageFragmentDoc}`;
export type CreateAllotmentMutationFn = ApolloReactCommon.MutationFunction<CreateAllotmentMutation, CreateAllotmentMutationVariables>;

/**
 * __useCreateAllotmentMutation__
 *
 * To run a mutation, you first call `useCreateAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAllotmentMutation, { data, loading, error }] = useCreateAllotmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAllotmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAllotmentMutation, CreateAllotmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAllotmentMutation, CreateAllotmentMutationVariables>(CreateAllotmentDocument, baseOptions);
      }
export type CreateAllotmentMutationHookResult = ReturnType<typeof useCreateAllotmentMutation>;
export type CreateAllotmentMutationResult = ApolloReactCommon.MutationResult<CreateAllotmentMutation>;
export type CreateAllotmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAllotmentMutation, CreateAllotmentMutationVariables>;
export const UpdateAllotmentDocument = gql`
    mutation UpdateAllotment($input: UpdateAllotmentInput!) {
  updateAllotment(input: $input) {
    ...AllotmentDetailPage
  }
}
    ${AllotmentDetailPageFragmentDoc}`;
export type UpdateAllotmentMutationFn = ApolloReactCommon.MutationFunction<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>;

/**
 * __useUpdateAllotmentMutation__
 *
 * To run a mutation, you first call `useUpdateAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllotmentMutation, { data, loading, error }] = useUpdateAllotmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAllotmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>(UpdateAllotmentDocument, baseOptions);
      }
export type UpdateAllotmentMutationHookResult = ReturnType<typeof useUpdateAllotmentMutation>;
export type UpdateAllotmentMutationResult = ApolloReactCommon.MutationResult<UpdateAllotmentMutation>;
export type UpdateAllotmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>;
export const DeleteAllotmentDocument = gql`
    mutation DeleteAllotment($id: ID!) {
  deleteAllotment(id: $id) {
    id
  }
}
    `;
export type DeleteAllotmentMutationFn = ApolloReactCommon.MutationFunction<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>;

/**
 * __useDeleteAllotmentMutation__
 *
 * To run a mutation, you first call `useDeleteAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllotmentMutation, { data, loading, error }] = useDeleteAllotmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAllotmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>(DeleteAllotmentDocument, baseOptions);
      }
export type DeleteAllotmentMutationHookResult = ReturnType<typeof useDeleteAllotmentMutation>;
export type DeleteAllotmentMutationResult = ApolloReactCommon.MutationResult<DeleteAllotmentMutation>;
export type DeleteAllotmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>;
export const IsUserAuthenticatedDocument = gql`
    query IsUserAuthenticated {
  isAuthenticated @client
}
    `;

/**
 * __useIsUserAuthenticatedQuery__
 *
 * To run a query within a React component, call `useIsUserAuthenticatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserAuthenticatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserAuthenticatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUserAuthenticatedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>) {
        return ApolloReactHooks.useQuery<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>(IsUserAuthenticatedDocument, baseOptions);
      }
export function useIsUserAuthenticatedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>(IsUserAuthenticatedDocument, baseOptions);
        }
export type IsUserAuthenticatedQueryHookResult = ReturnType<typeof useIsUserAuthenticatedQuery>;
export type IsUserAuthenticatedLazyQueryHookResult = ReturnType<typeof useIsUserAuthenticatedLazyQuery>;
export type IsUserAuthenticatedQueryResult = ApolloReactCommon.QueryResult<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>;
export const AuthInfoDocument = gql`
    query AuthInfo($branchSlug: String!) {
  authInfo(branchSlug: $branchSlug) {
    id
    slug
    title
  }
}
    `;

/**
 * __useAuthInfoQuery__
 *
 * To run a query within a React component, call `useAuthInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthInfoQuery({
 *   variables: {
 *      branchSlug: // value for 'branchSlug'
 *   },
 * });
 */
export function useAuthInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthInfoQuery, AuthInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthInfoQuery, AuthInfoQueryVariables>(AuthInfoDocument, baseOptions);
      }
export function useAuthInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthInfoQuery, AuthInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthInfoQuery, AuthInfoQueryVariables>(AuthInfoDocument, baseOptions);
        }
export type AuthInfoQueryHookResult = ReturnType<typeof useAuthInfoQuery>;
export type AuthInfoLazyQueryHookResult = ReturnType<typeof useAuthInfoLazyQuery>;
export type AuthInfoQueryResult = ApolloReactCommon.QueryResult<AuthInfoQuery, AuthInfoQueryVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    user {
      id
      branch {
        id
        use_legacy_newsletter
      }
      email
      display_name
      is_superadmin
      permissions {
        id
        name
      }
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    status
    message
  }
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    status
    message
  }
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const UpdateForgottenPasswordDocument = gql`
    mutation UpdateForgottenPassword($input: NewPasswordWithCodeInput!) {
  updateForgottenPassword(input: $input) {
    status
    message
  }
}
    `;
export type UpdateForgottenPasswordMutationFn = ApolloReactCommon.MutationFunction<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>;

/**
 * __useUpdateForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateForgottenPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateForgottenPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateForgottenPasswordMutation, { data, loading, error }] = useUpdateForgottenPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateForgottenPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>(UpdateForgottenPasswordDocument, baseOptions);
      }
export type UpdateForgottenPasswordMutationHookResult = ReturnType<typeof useUpdateForgottenPasswordMutation>;
export type UpdateForgottenPasswordMutationResult = ApolloReactCommon.MutationResult<UpdateForgottenPasswordMutation>;
export type UpdateForgottenPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>;
export const CategoryListDocument = gql`
    query CategoryList {
  categories {
    ...CategoryListItem
  }
}
    ${CategoryListItemFragmentDoc}`;

/**
 * __useCategoryListQuery__
 *
 * To run a query within a React component, call `useCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoryListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, baseOptions);
      }
export function useCategoryListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, baseOptions);
        }
export type CategoryListQueryHookResult = ReturnType<typeof useCategoryListQuery>;
export type CategoryListLazyQueryHookResult = ReturnType<typeof useCategoryListLazyQuery>;
export type CategoryListQueryResult = ApolloReactCommon.QueryResult<CategoryListQuery, CategoryListQueryVariables>;
export const CategoryDetailDocument = gql`
    query CategoryDetail($id: ID!) {
  category(id: $id, trashed: WITH) {
    id
    ...CategoryDetailPage
  }
}
    ${CategoryDetailPageFragmentDoc}`;

/**
 * __useCategoryDetailQuery__
 *
 * To run a query within a React component, call `useCategoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryDetailQuery, CategoryDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryDetailQuery, CategoryDetailQueryVariables>(CategoryDetailDocument, baseOptions);
      }
export function useCategoryDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryDetailQuery, CategoryDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryDetailQuery, CategoryDetailQueryVariables>(CategoryDetailDocument, baseOptions);
        }
export type CategoryDetailQueryHookResult = ReturnType<typeof useCategoryDetailQuery>;
export type CategoryDetailLazyQueryHookResult = ReturnType<typeof useCategoryDetailLazyQuery>;
export type CategoryDetailQueryResult = ApolloReactCommon.QueryResult<CategoryDetailQuery, CategoryDetailQueryVariables>;
export const CategoryEditDocument = gql`
    query CategoryEdit($id: ID!) {
  category(id: $id, trashed: WITH) {
    id
    ...CategoryEditForm
  }
}
    ${CategoryEditFormFragmentDoc}`;

/**
 * __useCategoryEditQuery__
 *
 * To run a query within a React component, call `useCategoryEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryEditQuery, CategoryEditQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryEditQuery, CategoryEditQueryVariables>(CategoryEditDocument, baseOptions);
      }
export function useCategoryEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryEditQuery, CategoryEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryEditQuery, CategoryEditQueryVariables>(CategoryEditDocument, baseOptions);
        }
export type CategoryEditQueryHookResult = ReturnType<typeof useCategoryEditQuery>;
export type CategoryEditLazyQueryHookResult = ReturnType<typeof useCategoryEditLazyQuery>;
export type CategoryEditQueryResult = ApolloReactCommon.QueryResult<CategoryEditQuery, CategoryEditQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    ...CategoryDetailPage
  }
}
    ${CategoryDetailPageFragmentDoc}`;
export type CreateCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, baseOptions);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($input: UpdateCategoryInput!) {
  updateCategory(input: $input) {
    ...CategoryDetailPage
  }
}
    ${CategoryDetailPageFragmentDoc}`;
export type UpdateCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, baseOptions);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($id: ID!) {
  deleteCategory(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, baseOptions);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const RestoreCategoryDocument = gql`
    mutation RestoreCategory($id: ID!) {
  restoreCategory(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreCategoryMutationFn = ApolloReactCommon.MutationFunction<RestoreCategoryMutation, RestoreCategoryMutationVariables>;

/**
 * __useRestoreCategoryMutation__
 *
 * To run a mutation, you first call `useRestoreCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCategoryMutation, { data, loading, error }] = useRestoreCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreCategoryMutation, RestoreCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreCategoryMutation, RestoreCategoryMutationVariables>(RestoreCategoryDocument, baseOptions);
      }
export type RestoreCategoryMutationHookResult = ReturnType<typeof useRestoreCategoryMutation>;
export type RestoreCategoryMutationResult = ApolloReactCommon.MutationResult<RestoreCategoryMutation>;
export type RestoreCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreCategoryMutation, RestoreCategoryMutationVariables>;
export const ForceDeleteCategoryDocument = gql`
    mutation ForceDeleteCategory($id: ID!) {
  forceDeleteCategory(id: $id) {
    id
  }
}
    `;
export type ForceDeleteCategoryMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteCategoryMutation, ForceDeleteCategoryMutationVariables>;

/**
 * __useForceDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useForceDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteCategoryMutation, { data, loading, error }] = useForceDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteCategoryMutation, ForceDeleteCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteCategoryMutation, ForceDeleteCategoryMutationVariables>(ForceDeleteCategoryDocument, baseOptions);
      }
export type ForceDeleteCategoryMutationHookResult = ReturnType<typeof useForceDeleteCategoryMutation>;
export type ForceDeleteCategoryMutationResult = ApolloReactCommon.MutationResult<ForceDeleteCategoryMutation>;
export type ForceDeleteCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteCategoryMutation, ForceDeleteCategoryMutationVariables>;
export const CompanionListDocument = gql`
    query CompanionList($status: UserStatus!, $page: Int) {
  companions(status: $status, first: 20, page: $page) {
    data {
      ...CompanionListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${CompanionListItemFragmentDoc}`;

/**
 * __useCompanionListQuery__
 *
 * To run a query within a React component, call `useCompanionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionListQuery, CompanionListQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionListQuery, CompanionListQueryVariables>(CompanionListDocument, baseOptions);
      }
export function useCompanionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionListQuery, CompanionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionListQuery, CompanionListQueryVariables>(CompanionListDocument, baseOptions);
        }
export type CompanionListQueryHookResult = ReturnType<typeof useCompanionListQuery>;
export type CompanionListLazyQueryHookResult = ReturnType<typeof useCompanionListLazyQuery>;
export type CompanionListQueryResult = ApolloReactCommon.QueryResult<CompanionListQuery, CompanionListQueryVariables>;
export const CompanionDetailDocument = gql`
    query CompanionDetail($id: ID!) {
  companion(id: $id, trashed: WITH) {
    id
    ...CompanionInfo
  }
}
    ${CompanionInfoFragmentDoc}`;

/**
 * __useCompanionDetailQuery__
 *
 * To run a query within a React component, call `useCompanionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanionDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionDetailQuery, CompanionDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionDetailQuery, CompanionDetailQueryVariables>(CompanionDetailDocument, baseOptions);
      }
export function useCompanionDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionDetailQuery, CompanionDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionDetailQuery, CompanionDetailQueryVariables>(CompanionDetailDocument, baseOptions);
        }
export type CompanionDetailQueryHookResult = ReturnType<typeof useCompanionDetailQuery>;
export type CompanionDetailLazyQueryHookResult = ReturnType<typeof useCompanionDetailLazyQuery>;
export type CompanionDetailQueryResult = ApolloReactCommon.QueryResult<CompanionDetailQuery, CompanionDetailQueryVariables>;
export const CompanionEmailsDocument = gql`
    query CompanionEmails($id: ID!, $page: Int) {
  companion(id: $id, trashed: WITH) {
    id
    emails(first: 10, page: $page) {
      data {
        ...EmailListItem
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
}
    ${EmailListItemFragmentDoc}`;

/**
 * __useCompanionEmailsQuery__
 *
 * To run a query within a React component, call `useCompanionEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionEmailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanionEmailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionEmailsQuery, CompanionEmailsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionEmailsQuery, CompanionEmailsQueryVariables>(CompanionEmailsDocument, baseOptions);
      }
export function useCompanionEmailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionEmailsQuery, CompanionEmailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionEmailsQuery, CompanionEmailsQueryVariables>(CompanionEmailsDocument, baseOptions);
        }
export type CompanionEmailsQueryHookResult = ReturnType<typeof useCompanionEmailsQuery>;
export type CompanionEmailsLazyQueryHookResult = ReturnType<typeof useCompanionEmailsLazyQuery>;
export type CompanionEmailsQueryResult = ApolloReactCommon.QueryResult<CompanionEmailsQuery, CompanionEmailsQueryVariables>;
export const CompanionEditDocument = gql`
    query CompanionEdit($id: ID!) {
  companion(id: $id, trashed: WITH) {
    ...CompanionEditForm
  }
}
    ${CompanionEditFormFragmentDoc}`;

/**
 * __useCompanionEditQuery__
 *
 * To run a query within a React component, call `useCompanionEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanionEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionEditQuery, CompanionEditQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionEditQuery, CompanionEditQueryVariables>(CompanionEditDocument, baseOptions);
      }
export function useCompanionEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionEditQuery, CompanionEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionEditQuery, CompanionEditQueryVariables>(CompanionEditDocument, baseOptions);
        }
export type CompanionEditQueryHookResult = ReturnType<typeof useCompanionEditQuery>;
export type CompanionEditLazyQueryHookResult = ReturnType<typeof useCompanionEditLazyQuery>;
export type CompanionEditQueryResult = ApolloReactCommon.QueryResult<CompanionEditQuery, CompanionEditQueryVariables>;
export const CreateCompanionDocument = gql`
    mutation CreateCompanion($input: CreateCompanionInput!) {
  createCompanion(input: $input) {
    ...CompanionInfo
  }
}
    ${CompanionInfoFragmentDoc}`;
export type CreateCompanionMutationFn = ApolloReactCommon.MutationFunction<CreateCompanionMutation, CreateCompanionMutationVariables>;

/**
 * __useCreateCompanionMutation__
 *
 * To run a mutation, you first call `useCreateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanionMutation, { data, loading, error }] = useCreateCompanionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanionMutation, CreateCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanionMutation, CreateCompanionMutationVariables>(CreateCompanionDocument, baseOptions);
      }
export type CreateCompanionMutationHookResult = ReturnType<typeof useCreateCompanionMutation>;
export type CreateCompanionMutationResult = ApolloReactCommon.MutationResult<CreateCompanionMutation>;
export type CreateCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanionMutation, CreateCompanionMutationVariables>;
export const UpdateCompanionDocument = gql`
    mutation UpdateCompanion($input: UpdateCompanionInput!) {
  updateCompanion(input: $input) {
    ...CompanionInfo
  }
}
    ${CompanionInfoFragmentDoc}`;
export type UpdateCompanionMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanionMutation, UpdateCompanionMutationVariables>;

/**
 * __useUpdateCompanionMutation__
 *
 * To run a mutation, you first call `useUpdateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanionMutation, { data, loading, error }] = useUpdateCompanionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanionMutation, UpdateCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanionMutation, UpdateCompanionMutationVariables>(UpdateCompanionDocument, baseOptions);
      }
export type UpdateCompanionMutationHookResult = ReturnType<typeof useUpdateCompanionMutation>;
export type UpdateCompanionMutationResult = ApolloReactCommon.MutationResult<UpdateCompanionMutation>;
export type UpdateCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanionMutation, UpdateCompanionMutationVariables>;
export const ApproveCompanionDocument = gql`
    mutation ApproveCompanion($id: ID!) {
  approveCompanion(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type ApproveCompanionMutationFn = ApolloReactCommon.MutationFunction<ApproveCompanionMutation, ApproveCompanionMutationVariables>;

/**
 * __useApproveCompanionMutation__
 *
 * To run a mutation, you first call `useApproveCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCompanionMutation, { data, loading, error }] = useApproveCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveCompanionMutation, ApproveCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveCompanionMutation, ApproveCompanionMutationVariables>(ApproveCompanionDocument, baseOptions);
      }
export type ApproveCompanionMutationHookResult = ReturnType<typeof useApproveCompanionMutation>;
export type ApproveCompanionMutationResult = ApolloReactCommon.MutationResult<ApproveCompanionMutation>;
export type ApproveCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveCompanionMutation, ApproveCompanionMutationVariables>;
export const DenyCompanionDocument = gql`
    mutation DenyCompanion($id: ID!) {
  denyCompanion(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type DenyCompanionMutationFn = ApolloReactCommon.MutationFunction<DenyCompanionMutation, DenyCompanionMutationVariables>;

/**
 * __useDenyCompanionMutation__
 *
 * To run a mutation, you first call `useDenyCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenyCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denyCompanionMutation, { data, loading, error }] = useDenyCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDenyCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DenyCompanionMutation, DenyCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<DenyCompanionMutation, DenyCompanionMutationVariables>(DenyCompanionDocument, baseOptions);
      }
export type DenyCompanionMutationHookResult = ReturnType<typeof useDenyCompanionMutation>;
export type DenyCompanionMutationResult = ApolloReactCommon.MutationResult<DenyCompanionMutation>;
export type DenyCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<DenyCompanionMutation, DenyCompanionMutationVariables>;
export const ActivateCompanionDocument = gql`
    mutation ActivateCompanion($id: ID!) {
  activateCompanion(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type ActivateCompanionMutationFn = ApolloReactCommon.MutationFunction<ActivateCompanionMutation, ActivateCompanionMutationVariables>;

/**
 * __useActivateCompanionMutation__
 *
 * To run a mutation, you first call `useActivateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompanionMutation, { data, loading, error }] = useActivateCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateCompanionMutation, ActivateCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateCompanionMutation, ActivateCompanionMutationVariables>(ActivateCompanionDocument, baseOptions);
      }
export type ActivateCompanionMutationHookResult = ReturnType<typeof useActivateCompanionMutation>;
export type ActivateCompanionMutationResult = ApolloReactCommon.MutationResult<ActivateCompanionMutation>;
export type ActivateCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateCompanionMutation, ActivateCompanionMutationVariables>;
export const DeactivateCompanionDocument = gql`
    mutation DeactivateCompanion($id: ID!) {
  deactivateCompanion(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type DeactivateCompanionMutationFn = ApolloReactCommon.MutationFunction<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>;

/**
 * __useDeactivateCompanionMutation__
 *
 * To run a mutation, you first call `useDeactivateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCompanionMutation, { data, loading, error }] = useDeactivateCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>(DeactivateCompanionDocument, baseOptions);
      }
export type DeactivateCompanionMutationHookResult = ReturnType<typeof useDeactivateCompanionMutation>;
export type DeactivateCompanionMutationResult = ApolloReactCommon.MutationResult<DeactivateCompanionMutation>;
export type DeactivateCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>;
export const DeleteCompanionDocument = gql`
    mutation DeleteCompanion($id: ID!) {
  deleteCompanion(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteCompanionMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanionMutation, DeleteCompanionMutationVariables>;

/**
 * __useDeleteCompanionMutation__
 *
 * To run a mutation, you first call `useDeleteCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanionMutation, { data, loading, error }] = useDeleteCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanionMutation, DeleteCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanionMutation, DeleteCompanionMutationVariables>(DeleteCompanionDocument, baseOptions);
      }
export type DeleteCompanionMutationHookResult = ReturnType<typeof useDeleteCompanionMutation>;
export type DeleteCompanionMutationResult = ApolloReactCommon.MutationResult<DeleteCompanionMutation>;
export type DeleteCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanionMutation, DeleteCompanionMutationVariables>;
export const RestoreCompanionDocument = gql`
    mutation RestoreCompanion($id: ID!) {
  restoreCompanion(id: $id, trashed: WITH) {
    id
    deleted_at
  }
}
    `;
export type RestoreCompanionMutationFn = ApolloReactCommon.MutationFunction<RestoreCompanionMutation, RestoreCompanionMutationVariables>;

/**
 * __useRestoreCompanionMutation__
 *
 * To run a mutation, you first call `useRestoreCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCompanionMutation, { data, loading, error }] = useRestoreCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreCompanionMutation, RestoreCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreCompanionMutation, RestoreCompanionMutationVariables>(RestoreCompanionDocument, baseOptions);
      }
export type RestoreCompanionMutationHookResult = ReturnType<typeof useRestoreCompanionMutation>;
export type RestoreCompanionMutationResult = ApolloReactCommon.MutationResult<RestoreCompanionMutation>;
export type RestoreCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreCompanionMutation, RestoreCompanionMutationVariables>;
export const ForceDeleteCompanionDocument = gql`
    mutation ForceDeleteCompanion($id: ID!) {
  forceDeleteCompanion(id: $id, trashed: WITH) {
    id
  }
}
    `;
export type ForceDeleteCompanionMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteCompanionMutation, ForceDeleteCompanionMutationVariables>;

/**
 * __useForceDeleteCompanionMutation__
 *
 * To run a mutation, you first call `useForceDeleteCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteCompanionMutation, { data, loading, error }] = useForceDeleteCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteCompanionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteCompanionMutation, ForceDeleteCompanionMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteCompanionMutation, ForceDeleteCompanionMutationVariables>(ForceDeleteCompanionDocument, baseOptions);
      }
export type ForceDeleteCompanionMutationHookResult = ReturnType<typeof useForceDeleteCompanionMutation>;
export type ForceDeleteCompanionMutationResult = ApolloReactCommon.MutationResult<ForceDeleteCompanionMutation>;
export type ForceDeleteCompanionMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteCompanionMutation, ForceDeleteCompanionMutationVariables>;
export const ContactDetailDocument = gql`
    query ContactDetail($id: ID!) {
  contact(id: $id, trashed: WITH) {
    id
    ...ContactInfo
  }
}
    ${ContactInfoFragmentDoc}`;

/**
 * __useContactDetailQuery__
 *
 * To run a query within a React component, call `useContactDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactDetailQuery, ContactDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactDetailQuery, ContactDetailQueryVariables>(ContactDetailDocument, baseOptions);
      }
export function useContactDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactDetailQuery, ContactDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactDetailQuery, ContactDetailQueryVariables>(ContactDetailDocument, baseOptions);
        }
export type ContactDetailQueryHookResult = ReturnType<typeof useContactDetailQuery>;
export type ContactDetailLazyQueryHookResult = ReturnType<typeof useContactDetailLazyQuery>;
export type ContactDetailQueryResult = ApolloReactCommon.QueryResult<ContactDetailQuery, ContactDetailQueryVariables>;
export const ContactEditDocument = gql`
    query ContactEdit($id: ID!) {
  contact(id: $id, trashed: WITH) {
    id
    ...ContactEditForm
  }
}
    ${ContactEditFormFragmentDoc}`;

/**
 * __useContactEditQuery__
 *
 * To run a query within a React component, call `useContactEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactEditQuery, ContactEditQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactEditQuery, ContactEditQueryVariables>(ContactEditDocument, baseOptions);
      }
export function useContactEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactEditQuery, ContactEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactEditQuery, ContactEditQueryVariables>(ContactEditDocument, baseOptions);
        }
export type ContactEditQueryHookResult = ReturnType<typeof useContactEditQuery>;
export type ContactEditLazyQueryHookResult = ReturnType<typeof useContactEditLazyQuery>;
export type ContactEditQueryResult = ApolloReactCommon.QueryResult<ContactEditQuery, ContactEditQueryVariables>;
export const ContactEmailsDocument = gql`
    query ContactEmails($id: ID!, $page: Int) {
  contact(id: $id, trashed: WITH) {
    id
    emails(first: 10, page: $page) {
      data {
        ...EmailListItem
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
}
    ${EmailListItemFragmentDoc}`;

/**
 * __useContactEmailsQuery__
 *
 * To run a query within a React component, call `useContactEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactEmailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useContactEmailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactEmailsQuery, ContactEmailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactEmailsQuery, ContactEmailsQueryVariables>(ContactEmailsDocument, baseOptions);
      }
export function useContactEmailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactEmailsQuery, ContactEmailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactEmailsQuery, ContactEmailsQueryVariables>(ContactEmailsDocument, baseOptions);
        }
export type ContactEmailsQueryHookResult = ReturnType<typeof useContactEmailsQuery>;
export type ContactEmailsLazyQueryHookResult = ReturnType<typeof useContactEmailsLazyQuery>;
export type ContactEmailsQueryResult = ApolloReactCommon.QueryResult<ContactEmailsQuery, ContactEmailsQueryVariables>;
export const CreateContactDocument = gql`
    mutation CreateContact($input: CreateContactInput!) {
  createContact(input: $input) {
    ...ContactInfo
  }
}
    ${ContactInfoFragmentDoc}`;
export type CreateContactMutationFn = ApolloReactCommon.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, baseOptions);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = ApolloReactCommon.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    ...ContactInfo
  }
}
    ${ContactInfoFragmentDoc}`;
export type UpdateContactMutationFn = ApolloReactCommon.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, baseOptions);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = ApolloReactCommon.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: ID!) {
  deleteContact(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteContactMutationFn = ApolloReactCommon.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, baseOptions);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = ApolloReactCommon.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const RestoreContactDocument = gql`
    mutation RestoreContact($id: ID!) {
  restoreContact(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreContactMutationFn = ApolloReactCommon.MutationFunction<RestoreContactMutation, RestoreContactMutationVariables>;

/**
 * __useRestoreContactMutation__
 *
 * To run a mutation, you first call `useRestoreContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreContactMutation, { data, loading, error }] = useRestoreContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreContactMutation, RestoreContactMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreContactMutation, RestoreContactMutationVariables>(RestoreContactDocument, baseOptions);
      }
export type RestoreContactMutationHookResult = ReturnType<typeof useRestoreContactMutation>;
export type RestoreContactMutationResult = ApolloReactCommon.MutationResult<RestoreContactMutation>;
export type RestoreContactMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreContactMutation, RestoreContactMutationVariables>;
export const ForceDeleteContactDocument = gql`
    mutation ForceDeleteContact($id: ID!) {
  forceDeleteContact(id: $id) {
    id
  }
}
    `;
export type ForceDeleteContactMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteContactMutation, ForceDeleteContactMutationVariables>;

/**
 * __useForceDeleteContactMutation__
 *
 * To run a mutation, you first call `useForceDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteContactMutation, { data, loading, error }] = useForceDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteContactMutation, ForceDeleteContactMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteContactMutation, ForceDeleteContactMutationVariables>(ForceDeleteContactDocument, baseOptions);
      }
export type ForceDeleteContactMutationHookResult = ReturnType<typeof useForceDeleteContactMutation>;
export type ForceDeleteContactMutationResult = ApolloReactCommon.MutationResult<ForceDeleteContactMutation>;
export type ForceDeleteContactMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteContactMutation, ForceDeleteContactMutationVariables>;
export const DonorListDocument = gql`
    query DonorList($page: Int) {
  donors(first: 20, page: $page) {
    data {
      ...DonorListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${DonorListItemFragmentDoc}`;

/**
 * __useDonorListQuery__
 *
 * To run a query within a React component, call `useDonorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorListQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDonorListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorListQuery, DonorListQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorListQuery, DonorListQueryVariables>(DonorListDocument, baseOptions);
      }
export function useDonorListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorListQuery, DonorListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorListQuery, DonorListQueryVariables>(DonorListDocument, baseOptions);
        }
export type DonorListQueryHookResult = ReturnType<typeof useDonorListQuery>;
export type DonorListLazyQueryHookResult = ReturnType<typeof useDonorListLazyQuery>;
export type DonorListQueryResult = ApolloReactCommon.QueryResult<DonorListQuery, DonorListQueryVariables>;
export const DonorDetailDocument = gql`
    query DonorDetail($id: ID!) {
  donor(id: $id, trashed: WITH) {
    id
    ...DonorInfo
  }
}
    ${DonorInfoFragmentDoc}`;

/**
 * __useDonorDetailQuery__
 *
 * To run a query within a React component, call `useDonorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorDetailQuery, DonorDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorDetailQuery, DonorDetailQueryVariables>(DonorDetailDocument, baseOptions);
      }
export function useDonorDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorDetailQuery, DonorDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorDetailQuery, DonorDetailQueryVariables>(DonorDetailDocument, baseOptions);
        }
export type DonorDetailQueryHookResult = ReturnType<typeof useDonorDetailQuery>;
export type DonorDetailLazyQueryHookResult = ReturnType<typeof useDonorDetailLazyQuery>;
export type DonorDetailQueryResult = ApolloReactCommon.QueryResult<DonorDetailQuery, DonorDetailQueryVariables>;
export const DonorContactsDocument = gql`
    query DonorContacts($id: ID!) {
  donor(id: $id, trashed: WITH) {
    id
    contacts {
      ...DonorContactListItem
    }
  }
}
    ${DonorContactListItemFragmentDoc}`;

/**
 * __useDonorContactsQuery__
 *
 * To run a query within a React component, call `useDonorContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorContactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorContactsQuery, DonorContactsQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorContactsQuery, DonorContactsQueryVariables>(DonorContactsDocument, baseOptions);
      }
export function useDonorContactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorContactsQuery, DonorContactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorContactsQuery, DonorContactsQueryVariables>(DonorContactsDocument, baseOptions);
        }
export type DonorContactsQueryHookResult = ReturnType<typeof useDonorContactsQuery>;
export type DonorContactsLazyQueryHookResult = ReturnType<typeof useDonorContactsLazyQuery>;
export type DonorContactsQueryResult = ApolloReactCommon.QueryResult<DonorContactsQuery, DonorContactsQueryVariables>;
export const DonorContactSelectDocument = gql`
    query DonorContactSelect($id: ID!) {
  donor(id: $id, trashed: WITH) {
    id
    contacts {
      ...ContactSelect
    }
  }
}
    ${ContactSelectFragmentDoc}`;

/**
 * __useDonorContactSelectQuery__
 *
 * To run a query within a React component, call `useDonorContactSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorContactSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorContactSelectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorContactSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorContactSelectQuery, DonorContactSelectQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorContactSelectQuery, DonorContactSelectQueryVariables>(DonorContactSelectDocument, baseOptions);
      }
export function useDonorContactSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorContactSelectQuery, DonorContactSelectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorContactSelectQuery, DonorContactSelectQueryVariables>(DonorContactSelectDocument, baseOptions);
        }
export type DonorContactSelectQueryHookResult = ReturnType<typeof useDonorContactSelectQuery>;
export type DonorContactSelectLazyQueryHookResult = ReturnType<typeof useDonorContactSelectLazyQuery>;
export type DonorContactSelectQueryResult = ApolloReactCommon.QueryResult<DonorContactSelectQuery, DonorContactSelectQueryVariables>;
export const DonorLocationsDocument = gql`
    query DonorLocations($id: ID!) {
  donor(id: $id, trashed: WITH) {
    id
    locations {
      ...LocationListItem
    }
  }
}
    ${LocationListItemFragmentDoc}`;

/**
 * __useDonorLocationsQuery__
 *
 * To run a query within a React component, call `useDonorLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorLocationsQuery, DonorLocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorLocationsQuery, DonorLocationsQueryVariables>(DonorLocationsDocument, baseOptions);
      }
export function useDonorLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorLocationsQuery, DonorLocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorLocationsQuery, DonorLocationsQueryVariables>(DonorLocationsDocument, baseOptions);
        }
export type DonorLocationsQueryHookResult = ReturnType<typeof useDonorLocationsQuery>;
export type DonorLocationsLazyQueryHookResult = ReturnType<typeof useDonorLocationsLazyQuery>;
export type DonorLocationsQueryResult = ApolloReactCommon.QueryResult<DonorLocationsQuery, DonorLocationsQueryVariables>;
export const DonorLocationSelectDocument = gql`
    query DonorLocationSelect($id: ID!) {
  donor(id: $id, trashed: WITH) {
    id
    locations {
      ...LocationSelect
    }
  }
}
    ${LocationSelectFragmentDoc}`;

/**
 * __useDonorLocationSelectQuery__
 *
 * To run a query within a React component, call `useDonorLocationSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorLocationSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorLocationSelectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorLocationSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorLocationSelectQuery, DonorLocationSelectQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorLocationSelectQuery, DonorLocationSelectQueryVariables>(DonorLocationSelectDocument, baseOptions);
      }
export function useDonorLocationSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorLocationSelectQuery, DonorLocationSelectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorLocationSelectQuery, DonorLocationSelectQueryVariables>(DonorLocationSelectDocument, baseOptions);
        }
export type DonorLocationSelectQueryHookResult = ReturnType<typeof useDonorLocationSelectQuery>;
export type DonorLocationSelectLazyQueryHookResult = ReturnType<typeof useDonorLocationSelectLazyQuery>;
export type DonorLocationSelectQueryResult = ApolloReactCommon.QueryResult<DonorLocationSelectQuery, DonorLocationSelectQueryVariables>;
export const CreateDonorDocument = gql`
    mutation CreateDonor($input: CreateDonorInput!) {
  createDonor(input: $input) {
    ...DonorInfo
  }
}
    ${DonorInfoFragmentDoc}`;
export type CreateDonorMutationFn = ApolloReactCommon.MutationFunction<CreateDonorMutation, CreateDonorMutationVariables>;

/**
 * __useCreateDonorMutation__
 *
 * To run a mutation, you first call `useCreateDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonorMutation, { data, loading, error }] = useCreateDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDonorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDonorMutation, CreateDonorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDonorMutation, CreateDonorMutationVariables>(CreateDonorDocument, baseOptions);
      }
export type CreateDonorMutationHookResult = ReturnType<typeof useCreateDonorMutation>;
export type CreateDonorMutationResult = ApolloReactCommon.MutationResult<CreateDonorMutation>;
export type CreateDonorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDonorMutation, CreateDonorMutationVariables>;
export const DonorEditDocument = gql`
    query DonorEdit($id: ID!) {
  donor(id: $id, trashed: WITH) {
    id
    ...DonorEditForm
  }
}
    ${DonorEditFormFragmentDoc}`;

/**
 * __useDonorEditQuery__
 *
 * To run a query within a React component, call `useDonorEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DonorEditQuery, DonorEditQueryVariables>) {
        return ApolloReactHooks.useQuery<DonorEditQuery, DonorEditQueryVariables>(DonorEditDocument, baseOptions);
      }
export function useDonorEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DonorEditQuery, DonorEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DonorEditQuery, DonorEditQueryVariables>(DonorEditDocument, baseOptions);
        }
export type DonorEditQueryHookResult = ReturnType<typeof useDonorEditQuery>;
export type DonorEditLazyQueryHookResult = ReturnType<typeof useDonorEditLazyQuery>;
export type DonorEditQueryResult = ApolloReactCommon.QueryResult<DonorEditQuery, DonorEditQueryVariables>;
export const UpdateDonorDocument = gql`
    mutation UpdateDonor($input: UpdateDonorInput!) {
  updateDonor(input: $input) {
    ...DonorInfo
  }
}
    ${DonorInfoFragmentDoc}`;
export type UpdateDonorMutationFn = ApolloReactCommon.MutationFunction<UpdateDonorMutation, UpdateDonorMutationVariables>;

/**
 * __useUpdateDonorMutation__
 *
 * To run a mutation, you first call `useUpdateDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonorMutation, { data, loading, error }] = useUpdateDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDonorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDonorMutation, UpdateDonorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDonorMutation, UpdateDonorMutationVariables>(UpdateDonorDocument, baseOptions);
      }
export type UpdateDonorMutationHookResult = ReturnType<typeof useUpdateDonorMutation>;
export type UpdateDonorMutationResult = ApolloReactCommon.MutationResult<UpdateDonorMutation>;
export type UpdateDonorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDonorMutation, UpdateDonorMutationVariables>;
export const SetDonorLogoDocument = gql`
    mutation SetDonorLogo($input: SetDonorLogoInput!) {
  setDonorLogo(input: $input) {
    id
    logo {
      name
      thumb_url
    }
  }
}
    `;
export type SetDonorLogoMutationFn = ApolloReactCommon.MutationFunction<SetDonorLogoMutation, SetDonorLogoMutationVariables>;

/**
 * __useSetDonorLogoMutation__
 *
 * To run a mutation, you first call `useSetDonorLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDonorLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDonorLogoMutation, { data, loading, error }] = useSetDonorLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDonorLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDonorLogoMutation, SetDonorLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDonorLogoMutation, SetDonorLogoMutationVariables>(SetDonorLogoDocument, baseOptions);
      }
export type SetDonorLogoMutationHookResult = ReturnType<typeof useSetDonorLogoMutation>;
export type SetDonorLogoMutationResult = ApolloReactCommon.MutationResult<SetDonorLogoMutation>;
export type SetDonorLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDonorLogoMutation, SetDonorLogoMutationVariables>;
export const DeleteDonorDocument = gql`
    mutation DeleteDonor($id: ID!) {
  deleteDonor(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteDonorMutationFn = ApolloReactCommon.MutationFunction<DeleteDonorMutation, DeleteDonorMutationVariables>;

/**
 * __useDeleteDonorMutation__
 *
 * To run a mutation, you first call `useDeleteDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDonorMutation, { data, loading, error }] = useDeleteDonorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDonorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDonorMutation, DeleteDonorMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDonorMutation, DeleteDonorMutationVariables>(DeleteDonorDocument, baseOptions);
      }
export type DeleteDonorMutationHookResult = ReturnType<typeof useDeleteDonorMutation>;
export type DeleteDonorMutationResult = ApolloReactCommon.MutationResult<DeleteDonorMutation>;
export type DeleteDonorMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDonorMutation, DeleteDonorMutationVariables>;
export const RestoreDonorDocument = gql`
    mutation RestoreDonor($id: ID!) {
  restoreDonor(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreDonorMutationFn = ApolloReactCommon.MutationFunction<RestoreDonorMutation, RestoreDonorMutationVariables>;

/**
 * __useRestoreDonorMutation__
 *
 * To run a mutation, you first call `useRestoreDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDonorMutation, { data, loading, error }] = useRestoreDonorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreDonorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreDonorMutation, RestoreDonorMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreDonorMutation, RestoreDonorMutationVariables>(RestoreDonorDocument, baseOptions);
      }
export type RestoreDonorMutationHookResult = ReturnType<typeof useRestoreDonorMutation>;
export type RestoreDonorMutationResult = ApolloReactCommon.MutationResult<RestoreDonorMutation>;
export type RestoreDonorMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreDonorMutation, RestoreDonorMutationVariables>;
export const ForceDeleteDonorDocument = gql`
    mutation ForceDeleteDonor($id: ID!) {
  forceDeleteDonor(id: $id) {
    id
  }
}
    `;
export type ForceDeleteDonorMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteDonorMutation, ForceDeleteDonorMutationVariables>;

/**
 * __useForceDeleteDonorMutation__
 *
 * To run a mutation, you first call `useForceDeleteDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteDonorMutation, { data, loading, error }] = useForceDeleteDonorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteDonorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteDonorMutation, ForceDeleteDonorMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteDonorMutation, ForceDeleteDonorMutationVariables>(ForceDeleteDonorDocument, baseOptions);
      }
export type ForceDeleteDonorMutationHookResult = ReturnType<typeof useForceDeleteDonorMutation>;
export type ForceDeleteDonorMutationResult = ApolloReactCommon.MutationResult<ForceDeleteDonorMutation>;
export type ForceDeleteDonorMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteDonorMutation, ForceDeleteDonorMutationVariables>;
export const EmailDetailsDocument = gql`
    query EmailDetails($id: ID!) {
  email(id: $id) {
    id
    ...EmailOverlay
  }
}
    ${EmailOverlayFragmentDoc}`;

/**
 * __useEmailDetailsQuery__
 *
 * To run a query within a React component, call `useEmailDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmailDetailsQuery, EmailDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<EmailDetailsQuery, EmailDetailsQueryVariables>(EmailDetailsDocument, baseOptions);
      }
export function useEmailDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmailDetailsQuery, EmailDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmailDetailsQuery, EmailDetailsQueryVariables>(EmailDetailsDocument, baseOptions);
        }
export type EmailDetailsQueryHookResult = ReturnType<typeof useEmailDetailsQuery>;
export type EmailDetailsLazyQueryHookResult = ReturnType<typeof useEmailDetailsLazyQuery>;
export type EmailDetailsQueryResult = ApolloReactCommon.QueryResult<EmailDetailsQuery, EmailDetailsQueryVariables>;
export const EmployeeListDocument = gql`
    query EmployeeList($status: UserStatus!, $page: Int) {
  employees(status: $status, first: 20, page: $page) {
    data {
      ...EmployeeListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${EmployeeListItemFragmentDoc}`;

/**
 * __useEmployeeListQuery__
 *
 * To run a query within a React component, call `useEmployeeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEmployeeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListQuery, EmployeeListQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListQuery, EmployeeListQueryVariables>(EmployeeListDocument, baseOptions);
      }
export function useEmployeeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListQuery, EmployeeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListQuery, EmployeeListQueryVariables>(EmployeeListDocument, baseOptions);
        }
export type EmployeeListQueryHookResult = ReturnType<typeof useEmployeeListQuery>;
export type EmployeeListLazyQueryHookResult = ReturnType<typeof useEmployeeListLazyQuery>;
export type EmployeeListQueryResult = ApolloReactCommon.QueryResult<EmployeeListQuery, EmployeeListQueryVariables>;
export const EmployeeDetailDocument = gql`
    query EmployeeDetail($id: ID!) {
  employee(id: $id, trashed: WITH) {
    id
    ...EmployeeDetailPage
  }
}
    ${EmployeeDetailPageFragmentDoc}`;

/**
 * __useEmployeeDetailQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeDetailQuery, EmployeeDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>(EmployeeDetailDocument, baseOptions);
      }
export function useEmployeeDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeDetailQuery, EmployeeDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>(EmployeeDetailDocument, baseOptions);
        }
export type EmployeeDetailQueryHookResult = ReturnType<typeof useEmployeeDetailQuery>;
export type EmployeeDetailLazyQueryHookResult = ReturnType<typeof useEmployeeDetailLazyQuery>;
export type EmployeeDetailQueryResult = ApolloReactCommon.QueryResult<EmployeeDetailQuery, EmployeeDetailQueryVariables>;
export const EmployeeEditDocument = gql`
    query EmployeeEdit($id: ID!) {
  employee(id: $id, trashed: WITH) {
    id
    ...EmployeeEditForm
  }
}
    ${EmployeeEditFormFragmentDoc}`;

/**
 * __useEmployeeEditQuery__
 *
 * To run a query within a React component, call `useEmployeeEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeEditQuery, EmployeeEditQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeEditQuery, EmployeeEditQueryVariables>(EmployeeEditDocument, baseOptions);
      }
export function useEmployeeEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeEditQuery, EmployeeEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeEditQuery, EmployeeEditQueryVariables>(EmployeeEditDocument, baseOptions);
        }
export type EmployeeEditQueryHookResult = ReturnType<typeof useEmployeeEditQuery>;
export type EmployeeEditLazyQueryHookResult = ReturnType<typeof useEmployeeEditLazyQuery>;
export type EmployeeEditQueryResult = ApolloReactCommon.QueryResult<EmployeeEditQuery, EmployeeEditQueryVariables>;
export const CreateEmployeeDocument = gql`
    mutation CreateEmployee($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    ...EmployeeDetailPage
  }
}
    ${EmployeeDetailPageFragmentDoc}`;
export type CreateEmployeeMutationFn = ApolloReactCommon.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, baseOptions);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = ApolloReactCommon.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    ...EmployeeDetailPage
  }
}
    ${EmployeeDetailPageFragmentDoc}`;
export type UpdateEmployeeMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, baseOptions);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const DeleteEmployeeDocument = gql`
    mutation DeleteEmployee($id: ID!) {
  deleteEmployee(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteEmployeeMutationFn = ApolloReactCommon.MutationFunction<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;

/**
 * __useDeleteEmployeeMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeMutation, { data, loading, error }] = useDeleteEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>(DeleteEmployeeDocument, baseOptions);
      }
export type DeleteEmployeeMutationHookResult = ReturnType<typeof useDeleteEmployeeMutation>;
export type DeleteEmployeeMutationResult = ApolloReactCommon.MutationResult<DeleteEmployeeMutation>;
export type DeleteEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;
export const RestoreEmployeeDocument = gql`
    mutation RestoreEmployee($id: ID!) {
  restoreEmployee(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreEmployeeMutationFn = ApolloReactCommon.MutationFunction<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>;

/**
 * __useRestoreEmployeeMutation__
 *
 * To run a mutation, you first call `useRestoreEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreEmployeeMutation, { data, loading, error }] = useRestoreEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>(RestoreEmployeeDocument, baseOptions);
      }
export type RestoreEmployeeMutationHookResult = ReturnType<typeof useRestoreEmployeeMutation>;
export type RestoreEmployeeMutationResult = ApolloReactCommon.MutationResult<RestoreEmployeeMutation>;
export type RestoreEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>;
export const ForceDeleteEmployeeDocument = gql`
    mutation ForceDeleteEmployee($id: ID!) {
  forceDeleteEmployee(id: $id) {
    id
  }
}
    `;
export type ForceDeleteEmployeeMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteEmployeeMutation, ForceDeleteEmployeeMutationVariables>;

/**
 * __useForceDeleteEmployeeMutation__
 *
 * To run a mutation, you first call `useForceDeleteEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteEmployeeMutation, { data, loading, error }] = useForceDeleteEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteEmployeeMutation, ForceDeleteEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteEmployeeMutation, ForceDeleteEmployeeMutationVariables>(ForceDeleteEmployeeDocument, baseOptions);
      }
export type ForceDeleteEmployeeMutationHookResult = ReturnType<typeof useForceDeleteEmployeeMutation>;
export type ForceDeleteEmployeeMutationResult = ApolloReactCommon.MutationResult<ForceDeleteEmployeeMutation>;
export type ForceDeleteEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteEmployeeMutation, ForceDeleteEmployeeMutationVariables>;
export const EventListDocument = gql`
    query EventList($page: Int, $status: EventStatus, $start: DateTimeRange, $donor: Int, $archived: Archived, $orderByField: String = "start", $orderByOrder: SortOrder = DESC) {
  events(first: 20, page: $page, status: $status, start: $start, donor: $donor, archived: $archived, orderBy: [{field: $orderByField, order: $orderByOrder}]) {
    data {
      ...EventListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${EventListItemFragmentDoc}`;

/**
 * __useEventListQuery__
 *
 * To run a query within a React component, call `useEventListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      status: // value for 'status'
 *      start: // value for 'start'
 *      donor: // value for 'donor'
 *      archived: // value for 'archived'
 *      orderByField: // value for 'orderByField'
 *      orderByOrder: // value for 'orderByOrder'
 *   },
 * });
 */
export function useEventListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventListQuery, EventListQueryVariables>) {
        return ApolloReactHooks.useQuery<EventListQuery, EventListQueryVariables>(EventListDocument, baseOptions);
      }
export function useEventListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventListQuery, EventListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventListQuery, EventListQueryVariables>(EventListDocument, baseOptions);
        }
export type EventListQueryHookResult = ReturnType<typeof useEventListQuery>;
export type EventListLazyQueryHookResult = ReturnType<typeof useEventListLazyQuery>;
export type EventListQueryResult = ApolloReactCommon.QueryResult<EventListQuery, EventListQueryVariables>;
export const EventDetailDocument = gql`
    query EventDetail($id: ID!) {
  event(id: $id) {
    id
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;

/**
 * __useEventDetailQuery__
 *
 * To run a query within a React component, call `useEventDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventDetailQuery, EventDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<EventDetailQuery, EventDetailQueryVariables>(EventDetailDocument, baseOptions);
      }
export function useEventDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventDetailQuery, EventDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventDetailQuery, EventDetailQueryVariables>(EventDetailDocument, baseOptions);
        }
export type EventDetailQueryHookResult = ReturnType<typeof useEventDetailQuery>;
export type EventDetailLazyQueryHookResult = ReturnType<typeof useEventDetailLazyQuery>;
export type EventDetailQueryResult = ApolloReactCommon.QueryResult<EventDetailQuery, EventDetailQueryVariables>;
export const EventEditDocument = gql`
    query EventEdit($id: ID!) {
  event(id: $id) {
    id
    ...EventEditForm
  }
}
    ${EventEditFormFragmentDoc}`;

/**
 * __useEventEditQuery__
 *
 * To run a query within a React component, call `useEventEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventEditQuery, EventEditQueryVariables>) {
        return ApolloReactHooks.useQuery<EventEditQuery, EventEditQueryVariables>(EventEditDocument, baseOptions);
      }
export function useEventEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventEditQuery, EventEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventEditQuery, EventEditQueryVariables>(EventEditDocument, baseOptions);
        }
export type EventEditQueryHookResult = ReturnType<typeof useEventEditQuery>;
export type EventEditLazyQueryHookResult = ReturnType<typeof useEventEditLazyQuery>;
export type EventEditQueryResult = ApolloReactCommon.QueryResult<EventEditQuery, EventEditQueryVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type UpdateEventMutationFn = ApolloReactCommon.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, baseOptions);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = ApolloReactCommon.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const CancelEventDocument = gql`
    mutation CancelEvent($input: CancelEventInput!) {
  cancelEvent(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type CancelEventMutationFn = ApolloReactCommon.MutationFunction<CancelEventMutation, CancelEventMutationVariables>;

/**
 * __useCancelEventMutation__
 *
 * To run a mutation, you first call `useCancelEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEventMutation, { data, loading, error }] = useCancelEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelEventMutation, CancelEventMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelEventMutation, CancelEventMutationVariables>(CancelEventDocument, baseOptions);
      }
export type CancelEventMutationHookResult = ReturnType<typeof useCancelEventMutation>;
export type CancelEventMutationResult = ApolloReactCommon.MutationResult<CancelEventMutation>;
export type CancelEventMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelEventMutation, CancelEventMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type CreateEventMutationFn = ApolloReactCommon.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, baseOptions);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = ApolloReactCommon.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($id: ID!) {
  deleteEvent(id: $id) {
    id
  }
}
    `;
export type DeleteEventMutationFn = ApolloReactCommon.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, baseOptions);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = ApolloReactCommon.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const PublishEventDocument = gql`
    mutation PublishEvent($id: ID!) {
  publishEvent(id: $id) {
    id
    status
  }
}
    `;
export type PublishEventMutationFn = ApolloReactCommon.MutationFunction<PublishEventMutation, PublishEventMutationVariables>;

/**
 * __usePublishEventMutation__
 *
 * To run a mutation, you first call `usePublishEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEventMutation, { data, loading, error }] = usePublishEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishEventMutation, PublishEventMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishEventMutation, PublishEventMutationVariables>(PublishEventDocument, baseOptions);
      }
export type PublishEventMutationHookResult = ReturnType<typeof usePublishEventMutation>;
export type PublishEventMutationResult = ApolloReactCommon.MutationResult<PublishEventMutation>;
export type PublishEventMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishEventMutation, PublishEventMutationVariables>;
export const DeleteSignupDocument = gql`
    mutation DeleteSignup($id: ID!) {
  deleteSignup(id: $id) {
    id
  }
}
    `;
export type DeleteSignupMutationFn = ApolloReactCommon.MutationFunction<DeleteSignupMutation, DeleteSignupMutationVariables>;

/**
 * __useDeleteSignupMutation__
 *
 * To run a mutation, you first call `useDeleteSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignupMutation, { data, loading, error }] = useDeleteSignupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSignupMutation, DeleteSignupMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSignupMutation, DeleteSignupMutationVariables>(DeleteSignupDocument, baseOptions);
      }
export type DeleteSignupMutationHookResult = ReturnType<typeof useDeleteSignupMutation>;
export type DeleteSignupMutationResult = ApolloReactCommon.MutationResult<DeleteSignupMutation>;
export type DeleteSignupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSignupMutation, DeleteSignupMutationVariables>;
export const AcceptSignupsDocument = gql`
    mutation AcceptSignups($id: ID!, $token: String!) {
  acceptSignups(id: $id, token: $token) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type AcceptSignupsMutationFn = ApolloReactCommon.MutationFunction<AcceptSignupsMutation, AcceptSignupsMutationVariables>;

/**
 * __useAcceptSignupsMutation__
 *
 * To run a mutation, you first call `useAcceptSignupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSignupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSignupsMutation, { data, loading, error }] = useAcceptSignupsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAcceptSignupsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptSignupsMutation, AcceptSignupsMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptSignupsMutation, AcceptSignupsMutationVariables>(AcceptSignupsDocument, baseOptions);
      }
export type AcceptSignupsMutationHookResult = ReturnType<typeof useAcceptSignupsMutation>;
export type AcceptSignupsMutationResult = ApolloReactCommon.MutationResult<AcceptSignupsMutation>;
export type AcceptSignupsMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptSignupsMutation, AcceptSignupsMutationVariables>;
export const AddSignupsDocument = gql`
    mutation AddSignups($input: AddSignupsInput!) {
  addSignups(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type AddSignupsMutationFn = ApolloReactCommon.MutationFunction<AddSignupsMutation, AddSignupsMutationVariables>;

/**
 * __useAddSignupsMutation__
 *
 * To run a mutation, you first call `useAddSignupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSignupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSignupsMutation, { data, loading, error }] = useAddSignupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSignupsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSignupsMutation, AddSignupsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSignupsMutation, AddSignupsMutationVariables>(AddSignupsDocument, baseOptions);
      }
export type AddSignupsMutationHookResult = ReturnType<typeof useAddSignupsMutation>;
export type AddSignupsMutationResult = ApolloReactCommon.MutationResult<AddSignupsMutation>;
export type AddSignupsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSignupsMutation, AddSignupsMutationVariables>;
export const InviteCandidatesDocument = gql`
    mutation InviteCandidates($id: ID!) {
  inviteCandidates(id: $id) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type InviteCandidatesMutationFn = ApolloReactCommon.MutationFunction<InviteCandidatesMutation, InviteCandidatesMutationVariables>;

/**
 * __useInviteCandidatesMutation__
 *
 * To run a mutation, you first call `useInviteCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCandidatesMutation, { data, loading, error }] = useInviteCandidatesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInviteCandidatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteCandidatesMutation, InviteCandidatesMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteCandidatesMutation, InviteCandidatesMutationVariables>(InviteCandidatesDocument, baseOptions);
      }
export type InviteCandidatesMutationHookResult = ReturnType<typeof useInviteCandidatesMutation>;
export type InviteCandidatesMutationResult = ApolloReactCommon.MutationResult<InviteCandidatesMutation>;
export type InviteCandidatesMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteCandidatesMutation, InviteCandidatesMutationVariables>;
export const LocationListDocument = gql`
    query LocationList($page: Int) {
  locations(first: 20, page: $page) {
    data {
      ...LocationListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${LocationListItemFragmentDoc}`;

/**
 * __useLocationListQuery__
 *
 * To run a query within a React component, call `useLocationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationListQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLocationListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationListQuery, LocationListQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationListQuery, LocationListQueryVariables>(LocationListDocument, baseOptions);
      }
export function useLocationListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationListQuery, LocationListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationListQuery, LocationListQueryVariables>(LocationListDocument, baseOptions);
        }
export type LocationListQueryHookResult = ReturnType<typeof useLocationListQuery>;
export type LocationListLazyQueryHookResult = ReturnType<typeof useLocationListLazyQuery>;
export type LocationListQueryResult = ApolloReactCommon.QueryResult<LocationListQuery, LocationListQueryVariables>;
export const LocationDetailDocument = gql`
    query LocationDetail($id: ID!) {
  location(id: $id, trashed: WITH) {
    id
    ...LocationInfo
  }
}
    ${LocationInfoFragmentDoc}`;

/**
 * __useLocationDetailQuery__
 *
 * To run a query within a React component, call `useLocationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationDetailQuery, LocationDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationDetailQuery, LocationDetailQueryVariables>(LocationDetailDocument, baseOptions);
      }
export function useLocationDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationDetailQuery, LocationDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationDetailQuery, LocationDetailQueryVariables>(LocationDetailDocument, baseOptions);
        }
export type LocationDetailQueryHookResult = ReturnType<typeof useLocationDetailQuery>;
export type LocationDetailLazyQueryHookResult = ReturnType<typeof useLocationDetailLazyQuery>;
export type LocationDetailQueryResult = ApolloReactCommon.QueryResult<LocationDetailQuery, LocationDetailQueryVariables>;
export const LocationDonorsDocument = gql`
    query LocationDonors($id: ID!) {
  location(id: $id, trashed: WITH) {
    id
    donors {
      ...DonorListItem
    }
  }
}
    ${DonorListItemFragmentDoc}`;

/**
 * __useLocationDonorsQuery__
 *
 * To run a query within a React component, call `useLocationDonorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDonorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDonorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationDonorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationDonorsQuery, LocationDonorsQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationDonorsQuery, LocationDonorsQueryVariables>(LocationDonorsDocument, baseOptions);
      }
export function useLocationDonorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationDonorsQuery, LocationDonorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationDonorsQuery, LocationDonorsQueryVariables>(LocationDonorsDocument, baseOptions);
        }
export type LocationDonorsQueryHookResult = ReturnType<typeof useLocationDonorsQuery>;
export type LocationDonorsLazyQueryHookResult = ReturnType<typeof useLocationDonorsLazyQuery>;
export type LocationDonorsQueryResult = ApolloReactCommon.QueryResult<LocationDonorsQuery, LocationDonorsQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    ...LocationInfo
  }
}
    ${LocationInfoFragmentDoc}`;
export type CreateLocationMutationFn = ApolloReactCommon.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, baseOptions);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = ApolloReactCommon.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const LocationEditDocument = gql`
    query LocationEdit($id: ID!) {
  location(id: $id, trashed: WITH) {
    id
    ...LocationEditForm
  }
}
    ${LocationEditFormFragmentDoc}`;

/**
 * __useLocationEditQuery__
 *
 * To run a query within a React component, call `useLocationEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationEditQuery, LocationEditQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationEditQuery, LocationEditQueryVariables>(LocationEditDocument, baseOptions);
      }
export function useLocationEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationEditQuery, LocationEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationEditQuery, LocationEditQueryVariables>(LocationEditDocument, baseOptions);
        }
export type LocationEditQueryHookResult = ReturnType<typeof useLocationEditQuery>;
export type LocationEditLazyQueryHookResult = ReturnType<typeof useLocationEditLazyQuery>;
export type LocationEditQueryResult = ApolloReactCommon.QueryResult<LocationEditQuery, LocationEditQueryVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($input: UpdateLocationInput!) {
  updateLocation(input: $input) {
    ...LocationInfo
  }
}
    ${LocationInfoFragmentDoc}`;
export type UpdateLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, baseOptions);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = ApolloReactCommon.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($id: ID!) {
  deleteLocation(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteLocationMutationFn = ApolloReactCommon.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, baseOptions);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = ApolloReactCommon.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const RestoreLocationDocument = gql`
    mutation RestoreLocation($id: ID!) {
  restoreLocation(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreLocationMutationFn = ApolloReactCommon.MutationFunction<RestoreLocationMutation, RestoreLocationMutationVariables>;

/**
 * __useRestoreLocationMutation__
 *
 * To run a mutation, you first call `useRestoreLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreLocationMutation, { data, loading, error }] = useRestoreLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreLocationMutation, RestoreLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreLocationMutation, RestoreLocationMutationVariables>(RestoreLocationDocument, baseOptions);
      }
export type RestoreLocationMutationHookResult = ReturnType<typeof useRestoreLocationMutation>;
export type RestoreLocationMutationResult = ApolloReactCommon.MutationResult<RestoreLocationMutation>;
export type RestoreLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreLocationMutation, RestoreLocationMutationVariables>;
export const ForceDeleteLocationDocument = gql`
    mutation ForceDeleteLocation($id: ID!) {
  forceDeleteLocation(id: $id) {
    id
  }
}
    `;
export type ForceDeleteLocationMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteLocationMutation, ForceDeleteLocationMutationVariables>;

/**
 * __useForceDeleteLocationMutation__
 *
 * To run a mutation, you first call `useForceDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteLocationMutation, { data, loading, error }] = useForceDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteLocationMutation, ForceDeleteLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteLocationMutation, ForceDeleteLocationMutationVariables>(ForceDeleteLocationDocument, baseOptions);
      }
export type ForceDeleteLocationMutationHookResult = ReturnType<typeof useForceDeleteLocationMutation>;
export type ForceDeleteLocationMutationResult = ApolloReactCommon.MutationResult<ForceDeleteLocationMutation>;
export type ForceDeleteLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteLocationMutation, ForceDeleteLocationMutationVariables>;
export const EventMatchingDocument = gql`
    query EventMatching($id: ID!) {
  event(id: $id) {
    id
    ...MatchingPage
  }
}
    ${MatchingPageFragmentDoc}`;

/**
 * __useEventMatchingQuery__
 *
 * To run a query within a React component, call `useEventMatchingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventMatchingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventMatchingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventMatchingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventMatchingQuery, EventMatchingQueryVariables>) {
        return ApolloReactHooks.useQuery<EventMatchingQuery, EventMatchingQueryVariables>(EventMatchingDocument, baseOptions);
      }
export function useEventMatchingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventMatchingQuery, EventMatchingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventMatchingQuery, EventMatchingQueryVariables>(EventMatchingDocument, baseOptions);
        }
export type EventMatchingQueryHookResult = ReturnType<typeof useEventMatchingQuery>;
export type EventMatchingLazyQueryHookResult = ReturnType<typeof useEventMatchingLazyQuery>;
export type EventMatchingQueryResult = ApolloReactCommon.QueryResult<EventMatchingQuery, EventMatchingQueryVariables>;
export const UpdateEventCandidatesDocument = gql`
    mutation UpdateEventCandidates($input: UpdateCandidatesInput!) {
  updateCandidates(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type UpdateEventCandidatesMutationFn = ApolloReactCommon.MutationFunction<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>;

/**
 * __useUpdateEventCandidatesMutation__
 *
 * To run a mutation, you first call `useUpdateEventCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventCandidatesMutation, { data, loading, error }] = useUpdateEventCandidatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventCandidatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>(UpdateEventCandidatesDocument, baseOptions);
      }
export type UpdateEventCandidatesMutationHookResult = ReturnType<typeof useUpdateEventCandidatesMutation>;
export type UpdateEventCandidatesMutationResult = ApolloReactCommon.MutationResult<UpdateEventCandidatesMutation>;
export type UpdateEventCandidatesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>;
export const CandidateSignupDocument = gql`
    query CandidateSignup($id: ID!) {
  signup(id: $id) {
    id
    ...CandidateListItem
  }
}
    ${CandidateListItemFragmentDoc}`;

/**
 * __useCandidateSignupQuery__
 *
 * To run a query within a React component, call `useCandidateSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateSignupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CandidateSignupQuery, CandidateSignupQueryVariables>) {
        return ApolloReactHooks.useQuery<CandidateSignupQuery, CandidateSignupQueryVariables>(CandidateSignupDocument, baseOptions);
      }
export function useCandidateSignupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CandidateSignupQuery, CandidateSignupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CandidateSignupQuery, CandidateSignupQueryVariables>(CandidateSignupDocument, baseOptions);
        }
export type CandidateSignupQueryHookResult = ReturnType<typeof useCandidateSignupQuery>;
export type CandidateSignupLazyQueryHookResult = ReturnType<typeof useCandidateSignupLazyQuery>;
export type CandidateSignupQueryResult = ApolloReactCommon.QueryResult<CandidateSignupQuery, CandidateSignupQueryVariables>;
export const ProspectSignupDocument = gql`
    query ProspectSignup($id: ID!) {
  signup(id: $id) {
    id
    ...ProspectListItem
  }
}
    ${ProspectListItemFragmentDoc}`;

/**
 * __useProspectSignupQuery__
 *
 * To run a query within a React component, call `useProspectSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectSignupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProspectSignupQuery, ProspectSignupQueryVariables>) {
        return ApolloReactHooks.useQuery<ProspectSignupQuery, ProspectSignupQueryVariables>(ProspectSignupDocument, baseOptions);
      }
export function useProspectSignupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProspectSignupQuery, ProspectSignupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProspectSignupQuery, ProspectSignupQueryVariables>(ProspectSignupDocument, baseOptions);
        }
export type ProspectSignupQueryHookResult = ReturnType<typeof useProspectSignupQuery>;
export type ProspectSignupLazyQueryHookResult = ReturnType<typeof useProspectSignupLazyQuery>;
export type ProspectSignupQueryResult = ApolloReactCommon.QueryResult<ProspectSignupQuery, ProspectSignupQueryVariables>;
export const ProspectGroupSignupDocument = gql`
    query ProspectGroupSignup($id: ID!) {
  signup(id: $id) {
    id
    ...ProspectGroupListItem
  }
}
    ${ProspectGroupListItemFragmentDoc}`;

/**
 * __useProspectGroupSignupQuery__
 *
 * To run a query within a React component, call `useProspectGroupSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectGroupSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectGroupSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectGroupSignupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>) {
        return ApolloReactHooks.useQuery<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>(ProspectGroupSignupDocument, baseOptions);
      }
export function useProspectGroupSignupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>(ProspectGroupSignupDocument, baseOptions);
        }
export type ProspectGroupSignupQueryHookResult = ReturnType<typeof useProspectGroupSignupQuery>;
export type ProspectGroupSignupLazyQueryHookResult = ReturnType<typeof useProspectGroupSignupLazyQuery>;
export type ProspectGroupSignupQueryResult = ApolloReactCommon.QueryResult<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>;
export const CompanionMatchingDocument = gql`
    query CompanionMatching($id: ID!) {
  event(id: $id) {
    id
    ...CompanionMatchingTab
  }
}
    ${CompanionMatchingTabFragmentDoc}`;

/**
 * __useCompanionMatchingQuery__
 *
 * To run a query within a React component, call `useCompanionMatchingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionMatchingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionMatchingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanionMatchingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionMatchingQuery, CompanionMatchingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionMatchingQuery, CompanionMatchingQueryVariables>(CompanionMatchingDocument, baseOptions);
      }
export function useCompanionMatchingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionMatchingQuery, CompanionMatchingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionMatchingQuery, CompanionMatchingQueryVariables>(CompanionMatchingDocument, baseOptions);
        }
export type CompanionMatchingQueryHookResult = ReturnType<typeof useCompanionMatchingQuery>;
export type CompanionMatchingLazyQueryHookResult = ReturnType<typeof useCompanionMatchingLazyQuery>;
export type CompanionMatchingQueryResult = ApolloReactCommon.QueryResult<CompanionMatchingQuery, CompanionMatchingQueryVariables>;
export const SoulAttendsDocument = gql`
    query SoulAttends($id: ID!, $first: Int = 20, $page: Int) {
  soul(id: $id) {
    id
    attends(first: $first, page: $page) {
      data {
        ...AttendListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
}
    ${AttendListItemFragmentDoc}`;

/**
 * __useSoulAttendsQuery__
 *
 * To run a query within a React component, call `useSoulAttendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulAttendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulAttendsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSoulAttendsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulAttendsQuery, SoulAttendsQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulAttendsQuery, SoulAttendsQueryVariables>(SoulAttendsDocument, baseOptions);
      }
export function useSoulAttendsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulAttendsQuery, SoulAttendsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulAttendsQuery, SoulAttendsQueryVariables>(SoulAttendsDocument, baseOptions);
        }
export type SoulAttendsQueryHookResult = ReturnType<typeof useSoulAttendsQuery>;
export type SoulAttendsLazyQueryHookResult = ReturnType<typeof useSoulAttendsLazyQuery>;
export type SoulAttendsQueryResult = ApolloReactCommon.QueryResult<SoulAttendsQuery, SoulAttendsQueryVariables>;
export const CompanionAttendsDocument = gql`
    query CompanionAttends($id: ID!, $first: Int = 20, $page: Int) {
  companion(id: $id) {
    id
    attends(first: $first, page: $page) {
      data {
        ...AttendListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
}
    ${AttendListItemFragmentDoc}`;

/**
 * __useCompanionAttendsQuery__
 *
 * To run a query within a React component, call `useCompanionAttendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionAttendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionAttendsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanionAttendsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionAttendsQuery, CompanionAttendsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionAttendsQuery, CompanionAttendsQueryVariables>(CompanionAttendsDocument, baseOptions);
      }
export function useCompanionAttendsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionAttendsQuery, CompanionAttendsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionAttendsQuery, CompanionAttendsQueryVariables>(CompanionAttendsDocument, baseOptions);
        }
export type CompanionAttendsQueryHookResult = ReturnType<typeof useCompanionAttendsQuery>;
export type CompanionAttendsLazyQueryHookResult = ReturnType<typeof useCompanionAttendsLazyQuery>;
export type CompanionAttendsQueryResult = ApolloReactCommon.QueryResult<CompanionAttendsQuery, CompanionAttendsQueryVariables>;
export const SoulFutureSignupsDocument = gql`
    query SoulFutureSignups($id: ID!, $first: Int = 20, $page: Int) {
  soul(id: $id) {
    id
    future_signups(first: $first, page: $page) {
      data {
        ...FutureSignupListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
}
    ${FutureSignupListItemFragmentDoc}`;

/**
 * __useSoulFutureSignupsQuery__
 *
 * To run a query within a React component, call `useSoulFutureSignupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulFutureSignupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulFutureSignupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSoulFutureSignupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>(SoulFutureSignupsDocument, baseOptions);
      }
export function useSoulFutureSignupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>(SoulFutureSignupsDocument, baseOptions);
        }
export type SoulFutureSignupsQueryHookResult = ReturnType<typeof useSoulFutureSignupsQuery>;
export type SoulFutureSignupsLazyQueryHookResult = ReturnType<typeof useSoulFutureSignupsLazyQuery>;
export type SoulFutureSignupsQueryResult = ApolloReactCommon.QueryResult<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>;
export const CompanionFutureSignupsDocument = gql`
    query CompanionFutureSignups($id: ID!, $first: Int = 20, $page: Int) {
  companion(id: $id) {
    id
    future_signups(first: $first, page: $page) {
      data {
        ...FutureSignupListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
}
    ${FutureSignupListItemFragmentDoc}`;

/**
 * __useCompanionFutureSignupsQuery__
 *
 * To run a query within a React component, call `useCompanionFutureSignupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionFutureSignupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionFutureSignupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanionFutureSignupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>(CompanionFutureSignupsDocument, baseOptions);
      }
export function useCompanionFutureSignupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>(CompanionFutureSignupsDocument, baseOptions);
        }
export type CompanionFutureSignupsQueryHookResult = ReturnType<typeof useCompanionFutureSignupsQuery>;
export type CompanionFutureSignupsLazyQueryHookResult = ReturnType<typeof useCompanionFutureSignupsLazyQuery>;
export type CompanionFutureSignupsQueryResult = ApolloReactCommon.QueryResult<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>;
export const NewsletterListDocument = gql`
    query NewsletterList($page: Int, $sentAt: DateTimeRange) {
  newsletters(first: 20, page: $page, sent_at: $sentAt, orderBy: [{field: "created_at", order: DESC}]) {
    data {
      ...NewsletterListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${NewsletterListItemFragmentDoc}`;

/**
 * __useNewsletterListQuery__
 *
 * To run a query within a React component, call `useNewsletterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsletterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsletterListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      sentAt: // value for 'sentAt'
 *   },
 * });
 */
export function useNewsletterListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsletterListQuery, NewsletterListQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsletterListQuery, NewsletterListQueryVariables>(NewsletterListDocument, baseOptions);
      }
export function useNewsletterListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsletterListQuery, NewsletterListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsletterListQuery, NewsletterListQueryVariables>(NewsletterListDocument, baseOptions);
        }
export type NewsletterListQueryHookResult = ReturnType<typeof useNewsletterListQuery>;
export type NewsletterListLazyQueryHookResult = ReturnType<typeof useNewsletterListLazyQuery>;
export type NewsletterListQueryResult = ApolloReactCommon.QueryResult<NewsletterListQuery, NewsletterListQueryVariables>;
export const NewsletterDetailDocument = gql`
    query NewsletterDetail($id: ID!) {
  newsletter(id: $id) {
    id
    ...NewsletterDetailPage
  }
}
    ${NewsletterDetailPageFragmentDoc}`;

/**
 * __useNewsletterDetailQuery__
 *
 * To run a query within a React component, call `useNewsletterDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsletterDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsletterDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewsletterDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsletterDetailQuery, NewsletterDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsletterDetailQuery, NewsletterDetailQueryVariables>(NewsletterDetailDocument, baseOptions);
      }
export function useNewsletterDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsletterDetailQuery, NewsletterDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsletterDetailQuery, NewsletterDetailQueryVariables>(NewsletterDetailDocument, baseOptions);
        }
export type NewsletterDetailQueryHookResult = ReturnType<typeof useNewsletterDetailQuery>;
export type NewsletterDetailLazyQueryHookResult = ReturnType<typeof useNewsletterDetailLazyQuery>;
export type NewsletterDetailQueryResult = ApolloReactCommon.QueryResult<NewsletterDetailQuery, NewsletterDetailQueryVariables>;
export const CreateNewsletterDocument = gql`
    mutation CreateNewsletter($input: CreateNewsletterInput!) {
  createNewsletter(input: $input) {
    ...NewsletterDetailPage
  }
}
    ${NewsletterDetailPageFragmentDoc}`;
export type CreateNewsletterMutationFn = ApolloReactCommon.MutationFunction<CreateNewsletterMutation, CreateNewsletterMutationVariables>;

/**
 * __useCreateNewsletterMutation__
 *
 * To run a mutation, you first call `useCreateNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsletterMutation, { data, loading, error }] = useCreateNewsletterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewsletterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewsletterMutation, CreateNewsletterMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewsletterMutation, CreateNewsletterMutationVariables>(CreateNewsletterDocument, baseOptions);
      }
export type CreateNewsletterMutationHookResult = ReturnType<typeof useCreateNewsletterMutation>;
export type CreateNewsletterMutationResult = ApolloReactCommon.MutationResult<CreateNewsletterMutation>;
export type CreateNewsletterMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewsletterMutation, CreateNewsletterMutationVariables>;
export const OrganisationListDocument = gql`
    query OrganisationList {
  organisations {
    ...OrganisationListItem
  }
}
    ${OrganisationListItemFragmentDoc}`;

/**
 * __useOrganisationListQuery__
 *
 * To run a query within a React component, call `useOrganisationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationListQuery, OrganisationListQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganisationListQuery, OrganisationListQueryVariables>(OrganisationListDocument, baseOptions);
      }
export function useOrganisationListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationListQuery, OrganisationListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganisationListQuery, OrganisationListQueryVariables>(OrganisationListDocument, baseOptions);
        }
export type OrganisationListQueryHookResult = ReturnType<typeof useOrganisationListQuery>;
export type OrganisationListLazyQueryHookResult = ReturnType<typeof useOrganisationListLazyQuery>;
export type OrganisationListQueryResult = ApolloReactCommon.QueryResult<OrganisationListQuery, OrganisationListQueryVariables>;
export const OrganisationDetailDocument = gql`
    query OrganisationDetail($id: ID!) {
  organisation(id: $id, trashed: WITH) {
    id
    ...OrganisationDetailPage
  }
}
    ${OrganisationDetailPageFragmentDoc}`;

/**
 * __useOrganisationDetailQuery__
 *
 * To run a query within a React component, call `useOrganisationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationDetailQuery, OrganisationDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganisationDetailQuery, OrganisationDetailQueryVariables>(OrganisationDetailDocument, baseOptions);
      }
export function useOrganisationDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationDetailQuery, OrganisationDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganisationDetailQuery, OrganisationDetailQueryVariables>(OrganisationDetailDocument, baseOptions);
        }
export type OrganisationDetailQueryHookResult = ReturnType<typeof useOrganisationDetailQuery>;
export type OrganisationDetailLazyQueryHookResult = ReturnType<typeof useOrganisationDetailLazyQuery>;
export type OrganisationDetailQueryResult = ApolloReactCommon.QueryResult<OrganisationDetailQuery, OrganisationDetailQueryVariables>;
export const OrganisationEditDocument = gql`
    query OrganisationEdit($id: ID!) {
  organisation(id: $id, trashed: WITH) {
    id
    ...OrganisationEditForm
  }
}
    ${OrganisationEditFormFragmentDoc}`;

/**
 * __useOrganisationEditQuery__
 *
 * To run a query within a React component, call `useOrganisationEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationEditQuery, OrganisationEditQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganisationEditQuery, OrganisationEditQueryVariables>(OrganisationEditDocument, baseOptions);
      }
export function useOrganisationEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationEditQuery, OrganisationEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganisationEditQuery, OrganisationEditQueryVariables>(OrganisationEditDocument, baseOptions);
        }
export type OrganisationEditQueryHookResult = ReturnType<typeof useOrganisationEditQuery>;
export type OrganisationEditLazyQueryHookResult = ReturnType<typeof useOrganisationEditLazyQuery>;
export type OrganisationEditQueryResult = ApolloReactCommon.QueryResult<OrganisationEditQuery, OrganisationEditQueryVariables>;
export const CreateOrganisationDocument = gql`
    mutation CreateOrganisation($input: CreateOrganisationInput!) {
  createOrganisation(input: $input) {
    ...OrganisationDetailPage
  }
}
    ${OrganisationDetailPageFragmentDoc}`;
export type CreateOrganisationMutationFn = ApolloReactCommon.MutationFunction<CreateOrganisationMutation, CreateOrganisationMutationVariables>;

/**
 * __useCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationMutation, { data, loading, error }] = useCreateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganisationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrganisationMutation, CreateOrganisationMutationVariables>(CreateOrganisationDocument, baseOptions);
      }
export type CreateOrganisationMutationHookResult = ReturnType<typeof useCreateOrganisationMutation>;
export type CreateOrganisationMutationResult = ApolloReactCommon.MutationResult<CreateOrganisationMutation>;
export type CreateOrganisationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>;
export const UpdateOrganisationDocument = gql`
    mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
  updateOrganisation(input: $input) {
    ...OrganisationDetailPage
  }
}
    ${OrganisationDetailPageFragmentDoc}`;
export type UpdateOrganisationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, baseOptions);
      }
export type UpdateOrganisationMutationHookResult = ReturnType<typeof useUpdateOrganisationMutation>;
export type UpdateOrganisationMutationResult = ApolloReactCommon.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;
export const DeleteOrganisationDocument = gql`
    mutation DeleteOrganisation($id: ID!) {
  deleteOrganisation(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteOrganisationMutationFn = ApolloReactCommon.MutationFunction<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>;

/**
 * __useDeleteOrganisationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganisationMutation, { data, loading, error }] = useDeleteOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganisationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>(DeleteOrganisationDocument, baseOptions);
      }
export type DeleteOrganisationMutationHookResult = ReturnType<typeof useDeleteOrganisationMutation>;
export type DeleteOrganisationMutationResult = ApolloReactCommon.MutationResult<DeleteOrganisationMutation>;
export type DeleteOrganisationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>;
export const RestoreOrganisationDocument = gql`
    mutation RestoreOrganisation($id: ID!) {
  restoreOrganisation(id: $id) {
    id
    deleted_at
  }
}
    `;
export type RestoreOrganisationMutationFn = ApolloReactCommon.MutationFunction<RestoreOrganisationMutation, RestoreOrganisationMutationVariables>;

/**
 * __useRestoreOrganisationMutation__
 *
 * To run a mutation, you first call `useRestoreOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreOrganisationMutation, { data, loading, error }] = useRestoreOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreOrganisationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreOrganisationMutation, RestoreOrganisationMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreOrganisationMutation, RestoreOrganisationMutationVariables>(RestoreOrganisationDocument, baseOptions);
      }
export type RestoreOrganisationMutationHookResult = ReturnType<typeof useRestoreOrganisationMutation>;
export type RestoreOrganisationMutationResult = ApolloReactCommon.MutationResult<RestoreOrganisationMutation>;
export type RestoreOrganisationMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreOrganisationMutation, RestoreOrganisationMutationVariables>;
export const ForceDeleteOrganisationDocument = gql`
    mutation ForceDeleteOrganisation($id: ID!) {
  forceDeleteOrganisation(id: $id) {
    id
  }
}
    `;
export type ForceDeleteOrganisationMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteOrganisationMutation, ForceDeleteOrganisationMutationVariables>;

/**
 * __useForceDeleteOrganisationMutation__
 *
 * To run a mutation, you first call `useForceDeleteOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteOrganisationMutation, { data, loading, error }] = useForceDeleteOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteOrganisationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteOrganisationMutation, ForceDeleteOrganisationMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteOrganisationMutation, ForceDeleteOrganisationMutationVariables>(ForceDeleteOrganisationDocument, baseOptions);
      }
export type ForceDeleteOrganisationMutationHookResult = ReturnType<typeof useForceDeleteOrganisationMutation>;
export type ForceDeleteOrganisationMutationResult = ApolloReactCommon.MutationResult<ForceDeleteOrganisationMutation>;
export type ForceDeleteOrganisationMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteOrganisationMutation, ForceDeleteOrganisationMutationVariables>;
export const RoleListDocument = gql`
    query RoleList {
  roles {
    ...RoleListItem
  }
}
    ${RoleListItemFragmentDoc}`;

/**
 * __useRoleListQuery__
 *
 * To run a query within a React component, call `useRoleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleListQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoleListQuery, RoleListQueryVariables>) {
        return ApolloReactHooks.useQuery<RoleListQuery, RoleListQueryVariables>(RoleListDocument, baseOptions);
      }
export function useRoleListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoleListQuery, RoleListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoleListQuery, RoleListQueryVariables>(RoleListDocument, baseOptions);
        }
export type RoleListQueryHookResult = ReturnType<typeof useRoleListQuery>;
export type RoleListLazyQueryHookResult = ReturnType<typeof useRoleListLazyQuery>;
export type RoleListQueryResult = ApolloReactCommon.QueryResult<RoleListQuery, RoleListQueryVariables>;
export const RoleDetailDocument = gql`
    query RoleDetail($id: ID!) {
  role(id: $id) {
    id
    ...RoleDetailPage
  }
}
    ${RoleDetailPageFragmentDoc}`;

/**
 * __useRoleDetailQuery__
 *
 * To run a query within a React component, call `useRoleDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoleDetailQuery, RoleDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<RoleDetailQuery, RoleDetailQueryVariables>(RoleDetailDocument, baseOptions);
      }
export function useRoleDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoleDetailQuery, RoleDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoleDetailQuery, RoleDetailQueryVariables>(RoleDetailDocument, baseOptions);
        }
export type RoleDetailQueryHookResult = ReturnType<typeof useRoleDetailQuery>;
export type RoleDetailLazyQueryHookResult = ReturnType<typeof useRoleDetailLazyQuery>;
export type RoleDetailQueryResult = ApolloReactCommon.QueryResult<RoleDetailQuery, RoleDetailQueryVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    ...RoleDetailPage
  }
}
    ${RoleDetailPageFragmentDoc}`;
export type CreateRoleMutationFn = ApolloReactCommon.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, baseOptions);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = ApolloReactCommon.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const RoleEditDocument = gql`
    query RoleEdit($id: ID!) {
  role(id: $id) {
    id
    ...RoleEditForm
  }
}
    ${RoleEditFormFragmentDoc}`;

/**
 * __useRoleEditQuery__
 *
 * To run a query within a React component, call `useRoleEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoleEditQuery, RoleEditQueryVariables>) {
        return ApolloReactHooks.useQuery<RoleEditQuery, RoleEditQueryVariables>(RoleEditDocument, baseOptions);
      }
export function useRoleEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoleEditQuery, RoleEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoleEditQuery, RoleEditQueryVariables>(RoleEditDocument, baseOptions);
        }
export type RoleEditQueryHookResult = ReturnType<typeof useRoleEditQuery>;
export type RoleEditLazyQueryHookResult = ReturnType<typeof useRoleEditLazyQuery>;
export type RoleEditQueryResult = ApolloReactCommon.QueryResult<RoleEditQuery, RoleEditQueryVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    ...RoleDetailPage
  }
}
    ${RoleDetailPageFragmentDoc}`;
export type UpdateRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, baseOptions);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = ApolloReactCommon.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: ID!) {
  deleteRole(id: $id) {
    id
  }
}
    `;
export type DeleteRoleMutationFn = ApolloReactCommon.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, baseOptions);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = ApolloReactCommon.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const BranchDetailDocument = gql`
    query BranchDetail($branchId: ID!) {
  branch(id: $branchId) {
    id
    ...SettingsDetailPage
  }
}
    ${SettingsDetailPageFragmentDoc}`;

/**
 * __useBranchDetailQuery__
 *
 * To run a query within a React component, call `useBranchDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchDetailQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useBranchDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BranchDetailQuery, BranchDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<BranchDetailQuery, BranchDetailQueryVariables>(BranchDetailDocument, baseOptions);
      }
export function useBranchDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BranchDetailQuery, BranchDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BranchDetailQuery, BranchDetailQueryVariables>(BranchDetailDocument, baseOptions);
        }
export type BranchDetailQueryHookResult = ReturnType<typeof useBranchDetailQuery>;
export type BranchDetailLazyQueryHookResult = ReturnType<typeof useBranchDetailLazyQuery>;
export type BranchDetailQueryResult = ApolloReactCommon.QueryResult<BranchDetailQuery, BranchDetailQueryVariables>;
export const UpdateBranchDocument = gql`
    mutation UpdateBranch($input: UpdateBranchInput!) {
  updateBranch(input: $input) {
    ...SettingsDetailPage
  }
}
    ${SettingsDetailPageFragmentDoc}`;
export type UpdateBranchMutationFn = ApolloReactCommon.MutationFunction<UpdateBranchMutation, UpdateBranchMutationVariables>;

/**
 * __useUpdateBranchMutation__
 *
 * To run a mutation, you first call `useUpdateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBranchMutation, { data, loading, error }] = useUpdateBranchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBranchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBranchMutation, UpdateBranchMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBranchMutation, UpdateBranchMutationVariables>(UpdateBranchDocument, baseOptions);
      }
export type UpdateBranchMutationHookResult = ReturnType<typeof useUpdateBranchMutation>;
export type UpdateBranchMutationResult = ApolloReactCommon.MutationResult<UpdateBranchMutation>;
export type UpdateBranchMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBranchMutation, UpdateBranchMutationVariables>;
export const RegenerateApiKeyDocument = gql`
    mutation RegenerateApiKey($branchId: ID!) {
  regenerateApiKey(id: $branchId) {
    ...SettingsDetailPage
  }
}
    ${SettingsDetailPageFragmentDoc}`;
export type RegenerateApiKeyMutationFn = ApolloReactCommon.MutationFunction<RegenerateApiKeyMutation, RegenerateApiKeyMutationVariables>;

/**
 * __useRegenerateApiKeyMutation__
 *
 * To run a mutation, you first call `useRegenerateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateApiKeyMutation, { data, loading, error }] = useRegenerateApiKeyMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useRegenerateApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegenerateApiKeyMutation, RegenerateApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<RegenerateApiKeyMutation, RegenerateApiKeyMutationVariables>(RegenerateApiKeyDocument, baseOptions);
      }
export type RegenerateApiKeyMutationHookResult = ReturnType<typeof useRegenerateApiKeyMutation>;
export type RegenerateApiKeyMutationResult = ApolloReactCommon.MutationResult<RegenerateApiKeyMutation>;
export type RegenerateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<RegenerateApiKeyMutation, RegenerateApiKeyMutationVariables>;
export const SoulListDocument = gql`
    query SoulList($status: UserStatus!, $page: Int) {
  souls(status: $status, first: 20, page: $page) {
    data {
      ...SoulListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${SoulListItemFragmentDoc}`;

/**
 * __useSoulListQuery__
 *
 * To run a query within a React component, call `useSoulListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSoulListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulListQuery, SoulListQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulListQuery, SoulListQueryVariables>(SoulListDocument, baseOptions);
      }
export function useSoulListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulListQuery, SoulListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulListQuery, SoulListQueryVariables>(SoulListDocument, baseOptions);
        }
export type SoulListQueryHookResult = ReturnType<typeof useSoulListQuery>;
export type SoulListLazyQueryHookResult = ReturnType<typeof useSoulListLazyQuery>;
export type SoulListQueryResult = ApolloReactCommon.QueryResult<SoulListQuery, SoulListQueryVariables>;
export const SoulDetailDocument = gql`
    query SoulDetail($id: ID!) {
  soul(id: $id, trashed: WITH) {
    id
    ...SoulInfo
  }
}
    ${SoulInfoFragmentDoc}`;

/**
 * __useSoulDetailQuery__
 *
 * To run a query within a React component, call `useSoulDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoulDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulDetailQuery, SoulDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulDetailQuery, SoulDetailQueryVariables>(SoulDetailDocument, baseOptions);
      }
export function useSoulDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulDetailQuery, SoulDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulDetailQuery, SoulDetailQueryVariables>(SoulDetailDocument, baseOptions);
        }
export type SoulDetailQueryHookResult = ReturnType<typeof useSoulDetailQuery>;
export type SoulDetailLazyQueryHookResult = ReturnType<typeof useSoulDetailLazyQuery>;
export type SoulDetailQueryResult = ApolloReactCommon.QueryResult<SoulDetailQuery, SoulDetailQueryVariables>;
export const SoulEmailsDocument = gql`
    query SoulEmails($id: ID!, $page: Int) {
  soul(id: $id, trashed: WITH) {
    id
    emails(first: 10, page: $page) {
      data {
        ...EmailListItem
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
}
    ${EmailListItemFragmentDoc}`;

/**
 * __useSoulEmailsQuery__
 *
 * To run a query within a React component, call `useSoulEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulEmailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSoulEmailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulEmailsQuery, SoulEmailsQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulEmailsQuery, SoulEmailsQueryVariables>(SoulEmailsDocument, baseOptions);
      }
export function useSoulEmailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulEmailsQuery, SoulEmailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulEmailsQuery, SoulEmailsQueryVariables>(SoulEmailsDocument, baseOptions);
        }
export type SoulEmailsQueryHookResult = ReturnType<typeof useSoulEmailsQuery>;
export type SoulEmailsLazyQueryHookResult = ReturnType<typeof useSoulEmailsLazyQuery>;
export type SoulEmailsQueryResult = ApolloReactCommon.QueryResult<SoulEmailsQuery, SoulEmailsQueryVariables>;
export const SoulEditDocument = gql`
    query SoulEdit($id: ID!) {
  soul(id: $id, trashed: WITH) {
    ...SoulEditForm
  }
}
    ${SoulEditFormFragmentDoc}`;

/**
 * __useSoulEditQuery__
 *
 * To run a query within a React component, call `useSoulEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoulEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoulEditQuery, SoulEditQueryVariables>) {
        return ApolloReactHooks.useQuery<SoulEditQuery, SoulEditQueryVariables>(SoulEditDocument, baseOptions);
      }
export function useSoulEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoulEditQuery, SoulEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoulEditQuery, SoulEditQueryVariables>(SoulEditDocument, baseOptions);
        }
export type SoulEditQueryHookResult = ReturnType<typeof useSoulEditQuery>;
export type SoulEditLazyQueryHookResult = ReturnType<typeof useSoulEditLazyQuery>;
export type SoulEditQueryResult = ApolloReactCommon.QueryResult<SoulEditQuery, SoulEditQueryVariables>;
export const CreateSoulDocument = gql`
    mutation CreateSoul($input: CreateSoulInput!) {
  createSoul(input: $input) {
    ...SoulInfo
  }
}
    ${SoulInfoFragmentDoc}`;
export type CreateSoulMutationFn = ApolloReactCommon.MutationFunction<CreateSoulMutation, CreateSoulMutationVariables>;

/**
 * __useCreateSoulMutation__
 *
 * To run a mutation, you first call `useCreateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSoulMutation, { data, loading, error }] = useCreateSoulMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSoulMutation, CreateSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSoulMutation, CreateSoulMutationVariables>(CreateSoulDocument, baseOptions);
      }
export type CreateSoulMutationHookResult = ReturnType<typeof useCreateSoulMutation>;
export type CreateSoulMutationResult = ApolloReactCommon.MutationResult<CreateSoulMutation>;
export type CreateSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSoulMutation, CreateSoulMutationVariables>;
export const UpdateSoulDocument = gql`
    mutation UpdateSoul($input: UpdateSoulInput!) {
  updateSoul(input: $input) {
    ...SoulInfo
  }
}
    ${SoulInfoFragmentDoc}`;
export type UpdateSoulMutationFn = ApolloReactCommon.MutationFunction<UpdateSoulMutation, UpdateSoulMutationVariables>;

/**
 * __useUpdateSoulMutation__
 *
 * To run a mutation, you first call `useUpdateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSoulMutation, { data, loading, error }] = useUpdateSoulMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSoulMutation, UpdateSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSoulMutation, UpdateSoulMutationVariables>(UpdateSoulDocument, baseOptions);
      }
export type UpdateSoulMutationHookResult = ReturnType<typeof useUpdateSoulMutation>;
export type UpdateSoulMutationResult = ApolloReactCommon.MutationResult<UpdateSoulMutation>;
export type UpdateSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSoulMutation, UpdateSoulMutationVariables>;
export const ApproveSoulDocument = gql`
    mutation ApproveSoul($id: ID!) {
  approveSoul(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type ApproveSoulMutationFn = ApolloReactCommon.MutationFunction<ApproveSoulMutation, ApproveSoulMutationVariables>;

/**
 * __useApproveSoulMutation__
 *
 * To run a mutation, you first call `useApproveSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSoulMutation, { data, loading, error }] = useApproveSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveSoulMutation, ApproveSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveSoulMutation, ApproveSoulMutationVariables>(ApproveSoulDocument, baseOptions);
      }
export type ApproveSoulMutationHookResult = ReturnType<typeof useApproveSoulMutation>;
export type ApproveSoulMutationResult = ApolloReactCommon.MutationResult<ApproveSoulMutation>;
export type ApproveSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveSoulMutation, ApproveSoulMutationVariables>;
export const DenySoulDocument = gql`
    mutation DenySoul($id: ID!) {
  denySoul(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type DenySoulMutationFn = ApolloReactCommon.MutationFunction<DenySoulMutation, DenySoulMutationVariables>;

/**
 * __useDenySoulMutation__
 *
 * To run a mutation, you first call `useDenySoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenySoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denySoulMutation, { data, loading, error }] = useDenySoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDenySoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DenySoulMutation, DenySoulMutationVariables>) {
        return ApolloReactHooks.useMutation<DenySoulMutation, DenySoulMutationVariables>(DenySoulDocument, baseOptions);
      }
export type DenySoulMutationHookResult = ReturnType<typeof useDenySoulMutation>;
export type DenySoulMutationResult = ApolloReactCommon.MutationResult<DenySoulMutation>;
export type DenySoulMutationOptions = ApolloReactCommon.BaseMutationOptions<DenySoulMutation, DenySoulMutationVariables>;
export const ActivateSoulDocument = gql`
    mutation ActivateSoul($id: ID!) {
  activateSoul(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type ActivateSoulMutationFn = ApolloReactCommon.MutationFunction<ActivateSoulMutation, ActivateSoulMutationVariables>;

/**
 * __useActivateSoulMutation__
 *
 * To run a mutation, you first call `useActivateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSoulMutation, { data, loading, error }] = useActivateSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateSoulMutation, ActivateSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateSoulMutation, ActivateSoulMutationVariables>(ActivateSoulDocument, baseOptions);
      }
export type ActivateSoulMutationHookResult = ReturnType<typeof useActivateSoulMutation>;
export type ActivateSoulMutationResult = ApolloReactCommon.MutationResult<ActivateSoulMutation>;
export type ActivateSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateSoulMutation, ActivateSoulMutationVariables>;
export const DeactivateSoulDocument = gql`
    mutation DeactivateSoul($id: ID!) {
  deactivateSoul(id: $id) {
    id
    user {
      id
      status
    }
  }
}
    `;
export type DeactivateSoulMutationFn = ApolloReactCommon.MutationFunction<DeactivateSoulMutation, DeactivateSoulMutationVariables>;

/**
 * __useDeactivateSoulMutation__
 *
 * To run a mutation, you first call `useDeactivateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateSoulMutation, { data, loading, error }] = useDeactivateSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeactivateSoulMutation, DeactivateSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<DeactivateSoulMutation, DeactivateSoulMutationVariables>(DeactivateSoulDocument, baseOptions);
      }
export type DeactivateSoulMutationHookResult = ReturnType<typeof useDeactivateSoulMutation>;
export type DeactivateSoulMutationResult = ApolloReactCommon.MutationResult<DeactivateSoulMutation>;
export type DeactivateSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<DeactivateSoulMutation, DeactivateSoulMutationVariables>;
export const DeleteSoulDocument = gql`
    mutation DeleteSoul($id: ID!) {
  deleteSoul(id: $id) {
    id
    deleted_at
  }
}
    `;
export type DeleteSoulMutationFn = ApolloReactCommon.MutationFunction<DeleteSoulMutation, DeleteSoulMutationVariables>;

/**
 * __useDeleteSoulMutation__
 *
 * To run a mutation, you first call `useDeleteSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSoulMutation, { data, loading, error }] = useDeleteSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSoulMutation, DeleteSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSoulMutation, DeleteSoulMutationVariables>(DeleteSoulDocument, baseOptions);
      }
export type DeleteSoulMutationHookResult = ReturnType<typeof useDeleteSoulMutation>;
export type DeleteSoulMutationResult = ApolloReactCommon.MutationResult<DeleteSoulMutation>;
export type DeleteSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSoulMutation, DeleteSoulMutationVariables>;
export const RestoreSoulDocument = gql`
    mutation RestoreSoul($id: ID!) {
  restoreSoul(id: $id, trashed: WITH) {
    id
    deleted_at
  }
}
    `;
export type RestoreSoulMutationFn = ApolloReactCommon.MutationFunction<RestoreSoulMutation, RestoreSoulMutationVariables>;

/**
 * __useRestoreSoulMutation__
 *
 * To run a mutation, you first call `useRestoreSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSoulMutation, { data, loading, error }] = useRestoreSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreSoulMutation, RestoreSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreSoulMutation, RestoreSoulMutationVariables>(RestoreSoulDocument, baseOptions);
      }
export type RestoreSoulMutationHookResult = ReturnType<typeof useRestoreSoulMutation>;
export type RestoreSoulMutationResult = ApolloReactCommon.MutationResult<RestoreSoulMutation>;
export type RestoreSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreSoulMutation, RestoreSoulMutationVariables>;
export const ForceDeleteSoulDocument = gql`
    mutation ForceDeleteSoul($id: ID!) {
  forceDeleteSoul(id: $id, trashed: WITH) {
    id
  }
}
    `;
export type ForceDeleteSoulMutationFn = ApolloReactCommon.MutationFunction<ForceDeleteSoulMutation, ForceDeleteSoulMutationVariables>;

/**
 * __useForceDeleteSoulMutation__
 *
 * To run a mutation, you first call `useForceDeleteSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteSoulMutation, { data, loading, error }] = useForceDeleteSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceDeleteSoulMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForceDeleteSoulMutation, ForceDeleteSoulMutationVariables>) {
        return ApolloReactHooks.useMutation<ForceDeleteSoulMutation, ForceDeleteSoulMutationVariables>(ForceDeleteSoulDocument, baseOptions);
      }
export type ForceDeleteSoulMutationHookResult = ReturnType<typeof useForceDeleteSoulMutation>;
export type ForceDeleteSoulMutationResult = ApolloReactCommon.MutationResult<ForceDeleteSoulMutation>;
export type ForceDeleteSoulMutationOptions = ApolloReactCommon.BaseMutationOptions<ForceDeleteSoulMutation, ForceDeleteSoulMutationVariables>;
export const OverviewStatsDocument = gql`
    query OverviewStats($input: OverviewStatsInput!) {
  overviewStats(input: $input) {
    tickets
    signups
    attends
  }
}
    `;

/**
 * __useOverviewStatsQuery__
 *
 * To run a query within a React component, call `useOverviewStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverviewStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OverviewStatsQuery, OverviewStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<OverviewStatsQuery, OverviewStatsQueryVariables>(OverviewStatsDocument, baseOptions);
      }
export function useOverviewStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OverviewStatsQuery, OverviewStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OverviewStatsQuery, OverviewStatsQueryVariables>(OverviewStatsDocument, baseOptions);
        }
export type OverviewStatsQueryHookResult = ReturnType<typeof useOverviewStatsQuery>;
export type OverviewStatsLazyQueryHookResult = ReturnType<typeof useOverviewStatsLazyQuery>;
export type OverviewStatsQueryResult = ApolloReactCommon.QueryResult<OverviewStatsQuery, OverviewStatsQueryVariables>;
export const TargetGroupStatsDocument = gql`
    query TargetGroupStats($input: TargetGroupStatsInput!) {
  targetGroupStats(input: $input) {
    target_group
    ...TargetGroupStats
  }
}
    ${TargetGroupStatsFragmentDoc}`;

/**
 * __useTargetGroupStatsQuery__
 *
 * To run a query within a React component, call `useTargetGroupStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTargetGroupStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTargetGroupStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTargetGroupStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TargetGroupStatsQuery, TargetGroupStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<TargetGroupStatsQuery, TargetGroupStatsQueryVariables>(TargetGroupStatsDocument, baseOptions);
      }
export function useTargetGroupStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TargetGroupStatsQuery, TargetGroupStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TargetGroupStatsQuery, TargetGroupStatsQueryVariables>(TargetGroupStatsDocument, baseOptions);
        }
export type TargetGroupStatsQueryHookResult = ReturnType<typeof useTargetGroupStatsQuery>;
export type TargetGroupStatsLazyQueryHookResult = ReturnType<typeof useTargetGroupStatsLazyQuery>;
export type TargetGroupStatsQueryResult = ApolloReactCommon.QueryResult<TargetGroupStatsQuery, TargetGroupStatsQueryVariables>;
export const CategoryStatsDocument = gql`
    query CategoryStats($input: CategoryStatsInput!) {
  categoryStats(input: $input) {
    category {
      id
    }
    ...CategoryStats
  }
}
    ${CategoryStatsFragmentDoc}`;

/**
 * __useCategoryStatsQuery__
 *
 * To run a query within a React component, call `useCategoryStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCategoryStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoryStatsQuery, CategoryStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoryStatsQuery, CategoryStatsQueryVariables>(CategoryStatsDocument, baseOptions);
      }
export function useCategoryStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoryStatsQuery, CategoryStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoryStatsQuery, CategoryStatsQueryVariables>(CategoryStatsDocument, baseOptions);
        }
export type CategoryStatsQueryHookResult = ReturnType<typeof useCategoryStatsQuery>;
export type CategoryStatsLazyQueryHookResult = ReturnType<typeof useCategoryStatsLazyQuery>;
export type CategoryStatsQueryResult = ApolloReactCommon.QueryResult<CategoryStatsQuery, CategoryStatsQueryVariables>;
export const TagListDocument = gql`
    query TagList {
  tags {
    ...TagListItem
  }
}
    ${TagListItemFragmentDoc}`;

/**
 * __useTagListQuery__
 *
 * To run a query within a React component, call `useTagListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagListQuery, TagListQueryVariables>) {
        return ApolloReactHooks.useQuery<TagListQuery, TagListQueryVariables>(TagListDocument, baseOptions);
      }
export function useTagListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagListQuery, TagListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagListQuery, TagListQueryVariables>(TagListDocument, baseOptions);
        }
export type TagListQueryHookResult = ReturnType<typeof useTagListQuery>;
export type TagListLazyQueryHookResult = ReturnType<typeof useTagListLazyQuery>;
export type TagListQueryResult = ApolloReactCommon.QueryResult<TagListQuery, TagListQueryVariables>;
export const TagDetailDocument = gql`
    query TagDetail($id: ID!) {
  tag(id: $id) {
    id
    ...TagDetailPage
  }
}
    ${TagDetailPageFragmentDoc}`;

/**
 * __useTagDetailQuery__
 *
 * To run a query within a React component, call `useTagDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagDetailQuery, TagDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<TagDetailQuery, TagDetailQueryVariables>(TagDetailDocument, baseOptions);
      }
export function useTagDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagDetailQuery, TagDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagDetailQuery, TagDetailQueryVariables>(TagDetailDocument, baseOptions);
        }
export type TagDetailQueryHookResult = ReturnType<typeof useTagDetailQuery>;
export type TagDetailLazyQueryHookResult = ReturnType<typeof useTagDetailLazyQuery>;
export type TagDetailQueryResult = ApolloReactCommon.QueryResult<TagDetailQuery, TagDetailQueryVariables>;
export const TagEditDocument = gql`
    query TagEdit($id: ID!) {
  tag(id: $id) {
    id
    ...TagEditForm
  }
}
    ${TagEditFormFragmentDoc}`;

/**
 * __useTagEditQuery__
 *
 * To run a query within a React component, call `useTagEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagEditQuery, TagEditQueryVariables>) {
        return ApolloReactHooks.useQuery<TagEditQuery, TagEditQueryVariables>(TagEditDocument, baseOptions);
      }
export function useTagEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagEditQuery, TagEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagEditQuery, TagEditQueryVariables>(TagEditDocument, baseOptions);
        }
export type TagEditQueryHookResult = ReturnType<typeof useTagEditQuery>;
export type TagEditLazyQueryHookResult = ReturnType<typeof useTagEditLazyQuery>;
export type TagEditQueryResult = ApolloReactCommon.QueryResult<TagEditQuery, TagEditQueryVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($input: CreateTagInput!) {
  createTag(input: $input) {
    ...TagDetailPage
  }
}
    ${TagDetailPageFragmentDoc}`;
export type CreateTagMutationFn = ApolloReactCommon.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, baseOptions);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = ApolloReactCommon.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation UpdateTag($input: UpdateTagInput!) {
  updateTag(input: $input) {
    ...TagDetailPage
  }
}
    ${TagDetailPageFragmentDoc}`;
export type UpdateTagMutationFn = ApolloReactCommon.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, baseOptions);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = ApolloReactCommon.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: ID!) {
  deleteTag(id: $id) {
    id
  }
}
    `;
export type DeleteTagMutationFn = ApolloReactCommon.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, baseOptions);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = ApolloReactCommon.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const TagsDocument = gql`
    query Tags {
  tags {
    ...PersonTagMultiSelect
  }
}
    ${PersonTagMultiSelectFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        return ApolloReactHooks.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
      }
export function useTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = ApolloReactCommon.QueryResult<TagsQuery, TagsQueryVariables>;
export const PermissionsDocument = gql`
    query Permissions {
  permissions {
    ...PermissionMultiSelect
  }
}
    ${PermissionMultiSelectFragmentDoc}`;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, baseOptions);
      }
export function usePermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, baseOptions);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = ApolloReactCommon.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  roles {
    ...RoleMultiSelect
  }
}
    ${RoleMultiSelectFragmentDoc}`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        return ApolloReactHooks.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
      }
export function useRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = ApolloReactCommon.QueryResult<RolesQuery, RolesQueryVariables>;
export const CategoriesDocument = gql`
    query Categories {
  categories {
    ...CategoryMultiSelect
  }
}
    ${CategoryMultiSelectFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
      }
export function useCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = ApolloReactCommon.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const AllotmentsDocument = gql`
    query Allotments {
  allotments {
    ...AllotmentSelect
  }
}
    ${AllotmentSelectFragmentDoc}`;

/**
 * __useAllotmentsQuery__
 *
 * To run a query within a React component, call `useAllotmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllotmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllotmentsQuery, AllotmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllotmentsQuery, AllotmentsQueryVariables>(AllotmentsDocument, baseOptions);
      }
export function useAllotmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentsQuery, AllotmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllotmentsQuery, AllotmentsQueryVariables>(AllotmentsDocument, baseOptions);
        }
export type AllotmentsQueryHookResult = ReturnType<typeof useAllotmentsQuery>;
export type AllotmentsLazyQueryHookResult = ReturnType<typeof useAllotmentsLazyQuery>;
export type AllotmentsQueryResult = ApolloReactCommon.QueryResult<AllotmentsQuery, AllotmentsQueryVariables>;
export const OrganisationsDocument = gql`
    query Organisations {
  organisations {
    ...PersonOrganisationSelect
  }
}
    ${PersonOrganisationSelectFragmentDoc}`;

/**
 * __useOrganisationsQuery__
 *
 * To run a query within a React component, call `useOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, baseOptions);
      }
export function useOrganisationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, baseOptions);
        }
export type OrganisationsQueryHookResult = ReturnType<typeof useOrganisationsQuery>;
export type OrganisationsLazyQueryHookResult = ReturnType<typeof useOrganisationsLazyQuery>;
export type OrganisationsQueryResult = ApolloReactCommon.QueryResult<OrganisationsQuery, OrganisationsQueryVariables>;
export const EmailUsersDocument = gql`
    mutation EmailUsers($input: EmailUsersInput!) {
  emailUsers(input: $input)
}
    `;
export type EmailUsersMutationFn = ApolloReactCommon.MutationFunction<EmailUsersMutation, EmailUsersMutationVariables>;

/**
 * __useEmailUsersMutation__
 *
 * To run a mutation, you first call `useEmailUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailUsersMutation, { data, loading, error }] = useEmailUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmailUsersMutation, EmailUsersMutationVariables>) {
        return ApolloReactHooks.useMutation<EmailUsersMutation, EmailUsersMutationVariables>(EmailUsersDocument, baseOptions);
      }
export type EmailUsersMutationHookResult = ReturnType<typeof useEmailUsersMutation>;
export type EmailUsersMutationResult = ApolloReactCommon.MutationResult<EmailUsersMutation>;
export type EmailUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<EmailUsersMutation, EmailUsersMutationVariables>;