/** @jsx jsx */
import { Elevation, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { gql } from '@apollo/client';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { PropertyList, PropertyListHeader, PropertyListItem } from '../../../components/PropertyList';
import TrashTag from '../../../components/TrashTag';
import { useCategoryDetailQuery } from '../../../generated/graphql';
import CategoryDetailMenu from '../components/CategoryDetailMenu';
import CategoryEditOverlay from '../components/CategoryEditOverlay';

const CategoryDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { categoryId } = useParams();

  const options = {
    variables: {
      id: categoryId || '',
    },
  };

  const { data, loading, error } = useCategoryDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.category) return <NotFoundCard resource="Kategorie" />;

  const { category } = data;

  return (
    <Fragment>
      <CategoryEditOverlay id={category.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} css={styles.spacedCard}>
        <ContentCardHeader
          leftElement={
            <Fragment>
              <span css={styles.heading}>{category.name}</span>
              <Tag minimal css={styles.personTag}>
                Kategorie
              </Tag>
              {!!category.deleted_at && <TrashTag />}
            </Fragment>
          }
          rightElement={<CategoryDetailMenu category={category} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div css={styles.content}>
          <PropertyList>
            <PropertyListHeader>Kategorie</PropertyListHeader>
            <PropertyListItem
              label="Beschreibung"
              value={category.description || '—'}
              showValueDisabled={!category.description}
            />
            <PropertyListItem label="Farbe">
              <Tag style={{ backgroundColor: category.color }} />
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
    </Fragment>
  );
};

CategoryDetailPage.fragments = {
  category: gql`
    fragment CategoryDetailPage on Category {
      id
      name
      description
      color
      deleted_at
    }
  `,
};

export default CategoryDetailPage;

const styles = {
  personTag: css`
    margin-left: 10px;
  `,
  heading: css`
    font-size: 16px;
  `,
  content: css`
    display: flex;
    padding: 16px 20px;
  `,
  table: css`
    width: 100%;
  `,
  spacedCard: css`
    margin-bottom: 20px;
  `,
};
