import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContactEditOverlay from '../components/ContactEditOverlay';
import ContactInfo from '../components/ContactInfo';
import ContactEmails from '../components/ContactEmails';

const ContactDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { contactId } = useParams();

  return (
    <Fragment>
      <ContactEditOverlay id={contactId || ''} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContactInfo onEdit={() => setIsEditDialogOpen(true)} />
      <ContactEmails />
    </Fragment>
  );
};

export default ContactDetailPage;
