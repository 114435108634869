/** @jsx jsx */
import { Colors } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';

type TableHeaderCellProps = {
  colSpan?: number;
  minWidth?: number;
  width?: number;
  children?: React.ReactNode;
};

const TableHeaderCell = ({ colSpan, width, minWidth, children }: TableHeaderCellProps) => (
  <td css={styles.tableHeadCell} style={styles.width(width, minWidth)} colSpan={colSpan}>
    {!!children && children}
  </td>
);

export default TableHeaderCell;

const styles = {
  width: (width?: number, minWidth?: number) => ({
    ...(width && { width }),
    ...(minWidth && { minWidth }),
  }),
  tableHeadCell: css`
    background-color: ${Colors.LIGHT_GRAY5};
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
  `,
};
