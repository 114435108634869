/** @jsx jsx */
import { Card, Elevation } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { gql } from '@apollo/client';
import { AttendeeGroupFragment } from '../../../generated/graphql';
import AttendeeListItem from './AttendeeListItem';

type AttendeeGroupProps = {
  item: AttendeeGroupFragment;
};

const AttendeeGroup = ({ item: companion }: AttendeeGroupProps) => (
  <Card css={styles.spacedCard} elevation={Elevation.TWO}>
    <AttendeeListItem item={companion} />
    {companion.group.map((soul) => (
      <AttendeeListItem key={soul.id} item={soul} isInGroup />
    ))}
  </Card>
);

AttendeeGroup.fragments = {
  groups: gql`
    fragment AttendeeGroup on Signup {
      ...AttendeeListItem
      group {
        ...AttendeeListItem
      }
    }
    ${AttendeeListItem.fragments.attendees}
  `,
};

export default AttendeeGroup;

const styles = {
  spacedCard: css`
    overflow: hidden;
    padding: 0;
    margin-bottom: 12px;
  `,
};
