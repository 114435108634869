import { Card } from '@blueprintjs/core';
import React from 'react';
import Error, { ErrorProps } from './Error';

const ErrorCard = (props: ErrorProps) => (
  <Card>
    <Error {...props} />
  </Card>
);

export default ErrorCard;
